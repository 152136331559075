import language from '../../i18n/language';

const injectCommonRouteParams = (
  params: Record<string, string | number | undefined>
): Record<string, string | number | undefined> => {
  const routeParams = { ...(params ?? {}) };

  routeParams.lang = routeParams.lang || language.current;

  return routeParams;
};

export default injectCommonRouteParams;
