import React from 'react';
import { useDrag } from '@use-gesture/react';
import useBodyScrollLock from 'enhancers/use-body-scroll-lock';
import css from 'utils/css';
import toArray from 'utils/to-array';
import type { StyledElement, AriaAttributes, TestAutomation } from 'contracts';
import ScrollShadow from 'components/scroll-shadow';

import Portal from '../portal';
import Html from '../html';

import styles from './drawer.module.scss';
import { thresholdMin, thresholdMax, thresholdPercentage } from './drawer.settings';

interface DrawerAPI {
  close: () => void;
}

interface DrawerElement extends StyledElement, AriaAttributes, TestAutomation {
  escapable?: boolean;
  onClose: () => void;
  children: React.ReactNode;
  variant?: 'primary' | 'secondary';
}

const Drawer = React.forwardRef<DrawerAPI, DrawerElement>((props, ref) => {
  const { testId, escapable = true, variant = 'primary', children, onClose, className, style, ...rest } = props;
  const contentRef = React.useRef<HTMLDivElement>(undefined);
  const drawerRef = React.useRef<HTMLDivElement>(undefined);
  const initialHeight = React.useRef<number>(0);
  const drawerPaddingTop = React.useRef<number>(0);
  const draggable = escapable !== false;

  useBodyScrollLock(true);

  React.useLayoutEffect(() => {
    if (!drawerRef.current) return;
    drawerPaddingTop.current = css(window.getComputedStyle(drawerRef.current).paddingTop).size;
  }, []);

  const stopBubbling = React.useCallback((event: React.MouseEvent<HTMLDivElement>): void => {
    event.stopPropagation();
  }, []);

  const escape = React.useCallback((): void => {
    if (!escapable) return;

    drawerRef.current?.classList.add(styles.close);
  }, [escapable]);

  React.useImperativeHandle(ref, () => ({
    close: escape,
  }));

  const handleAnimationEnd = React.useCallback(() => {
    if (!drawerRef.current || !contentRef.current) return;

    if (drawerRef.current.classList.contains(styles.close)) {
      drawerRef.current.classList.remove(styles.maximized);
      contentRef.current.classList.remove(styles.close);
      onClose();
    }

    contentRef.current.style.height = `${drawerRef.current.offsetHeight - drawerPaddingTop.current}px`;
  }, [onClose]);

  React.useEffect(() => {
    if (!drawerRef.current) return;

    drawerRef.current.addEventListener('animationend', handleAnimationEnd);

    return (): void => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      drawerRef.current?.removeEventListener('animationend', handleAnimationEnd);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const bindDrag = useDrag(
    ({ movement: [, my], last, first }) => {
      if (!drawerRef.current || !contentRef.current) return;

      if (first) {
        initialHeight.current = drawerRef.current.offsetHeight;
        contentRef.current.style.height = `${initialHeight.current - drawerPaddingTop.current - my}px`;
      }

      if (last) {
        const { offsetHeight } = drawerRef.current;
        const delta = Math.min(Math.max(thresholdMin, offsetHeight * thresholdPercentage), thresholdMax);

        if (my > delta) {
          escape();

          return;
        }
        drawerRef.current.style.height = '';
        contentRef.current.style.height = `${initialHeight.current - drawerPaddingTop.current}px`;

        return;
      }

      drawerRef.current.style.height = `${initialHeight.current - my}px`;
    },
    {
      delay: true,
      enabled: draggable,
    }
  );

  return (
    <Portal>
      <Html.div
        testId={testId && `${testId}-scrim`}
        className={[styles.scrim, variant === 'secondary' && styles.scrimSecondary]}
        onClick={escape}
      >
        <Html.div
          testId={testId}
          className={[
            styles.drawer,
            styles.open,
            styles[variant as string],
            draggable ? 'pt-12' : 'pt-7',
            ...toArray(className),
          ]}
          role="dialog"
          aria-modal="true"
          style={style}
          arias={rest}
          onClick={stopBubbling}
          ref={drawerRef}
        >
          {draggable && (
            <Html.div className={[styles.dragHandle, 'pt-12']} {...bindDrag()} testId={testId && `${testId}-handle`} />
          )}
          {variant !== 'secondary' && (
            <ScrollShadow testId={testId} direction="vertical" ref={contentRef}>
              <Html.div
                className={[styles.content, styles.scrollbar, 'ps-8 pe-4 pb-8']}
                ref={contentRef}
                testId={testId && `${testId}-content`}
              >
                {children}
              </Html.div>
            </ScrollShadow>
          )}
          {variant === 'secondary' && (
            <Html.div className={[styles.content, 'px-8 pb-8']} ref={contentRef} testId={testId && `${testId}-content`}>
              {children}
            </Html.div>
          )}
        </Html.div>
      </Html.div>
    </Portal>
  );
});

export type { DrawerElement, DrawerAPI };
export default Drawer;
