import React from 'react';

import is from './is';

const render = {
  element<P = unknown>(
    Element: React.ReactElement | React.FunctionComponent | React.ReactNode | (() => React.ReactNode),
    props?: React.PropsWithChildren<P>
  ): React.ReactNode {
    if (is.nullish(Element)) return;

    if (is.func(Element)) {
      return Element(props || {}) as Promise<Awaited<React.ReactNode>>;
    }

    if (is.object<React.ReactElement>(Element)) {
      return <Element.type {...Element.props!} {...(props || {})} />;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return <Element {...(props || {})} />;
  },
};

export default render;
