import React from 'react';
import { defer, of } from 'rxjs';
import { useIntl } from 'react-intl';
import { sendTracking } from 'services/google-analytics';
import useReadonlyObservable from 'enhancers/use-readonly-observable';
import language from 'services/i18n/language';
import Link from 'components/link';
import Badge from 'components/badge';
import Html from 'components/html';
import Icon, { IconList } from 'components/icon';
import Shimmer from 'components/shimmer';
import is from 'utils/is';
import toArray from 'utils/to-array';
import uri from 'utils/uri';
import type { StyledElement, TestAutomation, NavigationMenuItem, PartialToNullable } from 'contracts';
import type { NavigationIcon } from 'contracts/internals/navigation';

import tracking from './item.tracking';
import styles from './item.module.scss';

type NavigationMenuEntriesItem = Required<NavigationMenuItem>['entries'][number];

interface ItemElement
  extends PartialToNullable<
      Pick<NavigationMenuEntriesItem, 'label' | 'link' | 'new' | 'icon' | 'notification$' | 'warning$'>
    >,
    StyledElement,
    TestAutomation {
  active: boolean;
  locationPathname: string;
  shimmer?: boolean;
  loading?: boolean;
  visibility: boolean | undefined;
  children?: React.ReactNode;
}

const Item: React.FunctionComponent<ItemElement> = (props) => {
  const {
    testId,
    label,
    link,
    active: initialStatus,
    new: newBadge,
    icon,
    visibility,
    notification$,
    warning$,
    shimmer,
    loading,
    locationPathname,
    className,
    style,
    children,
  } = props;
  const { formatMessage } = useIntl();
  const [active, toggleStatus] = React.useState<boolean>(initialStatus);
  const [open, toggleOpen] = React.useState(initialStatus);
  const [notification] = useReadonlyObservable(
    defer(() => notification$ ?? of(0)),
    0
  );
  const [warning] = useReadonlyObservable(
    defer(() => warning$ ?? of(false)),
    false
  );
  const href = toArray(link?.())?.[0];
  const hasSubitems = Boolean(children);
  const untranslatedBadge = 'new';
  const visible = visibility === true;
  const hidden = visibility === false;
  const resolvingAuth = visibility === undefined;
  const navigationIcon: NavigationIcon | undefined =
    icon && (is.object(icon) ? icon : { active: icon, inactive: icon });
  const currentLanguage = language.current;

  React.useEffect(() => {
    toggleStatus(initialStatus);
    toggleOpen(initialStatus);
  }, [initialStatus, locationPathname]);

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      if (loading || (!is.nullish(href) && uri.external(href)) || initialStatus) {
        event.preventDefault();

        return;
      }

      toggleOpen(!hasSubitems || !open);

      if (!is.nullish(href)) {
        sendTracking(tracking.item.click, { href: href.replace(`/${currentLanguage}`, '') });

        return;
      }

      sendTracking(tracking.group.toggle, { status: open ? 'close' : 'open' });

      event.preventDefault();
    },
    [loading, href, open, hasSubitems, currentLanguage, initialStatus]
  );

  const renderBadges = React.useCallback((): React.ReactNode => {
    return (
      <React.Fragment>
        {hasSubitems && (
          <Icon className={styles.caret} name={IconList.caretDown} size={20} testId={testId && `${testId}-caret`} />
        )}
        {notification > 0 && !hasSubitems && (
          <Html.span className={styles.notification} testId={testId && `${testId}-notification`}>
            {notification}
          </Html.span>
        )}
        {newBadge?.() && (
          <Badge className={['m-0', styles.badge]} testId={testId && `${testId}-badge-new`}>
            {untranslatedBadge}
          </Badge>
        )}
        {warning && (
          <Html.div className={styles.warning} testId={testId && `${testId}-warning`}>
            <Icon name={IconList.warningCircularSolid} size={20} />
          </Html.div>
        )}
      </React.Fragment>
    );
  }, [hasSubitems, notification, warning, newBadge, testId]);

  if ((hidden && !loading) || (!shimmer && (loading || resolvingAuth))) {
    return null;
  }

  return (
    <React.Fragment>
      <Link
        testId={testId}
        href={href}
        className={[
          styles.item,
          active && styles.active,
          open && hasSubitems && styles.open,
          visible && (notification !== 0 || warning || newBadge?.()) && styles.dot,
          initialStatus && styles.unclickable,
          ...toArray(className),
        ]}
        style={style}
        onClick={handleClick}
        aria-expanded={open}
      >
        {(navigationIcon || shimmer) && (
          <Shimmer theme="light" loading={loading}>
            <Html.div testId={testId && `${testId}-icon`}>
              {navigationIcon && (
                <React.Fragment>
                  <Icon name={navigationIcon.inactive} className={styles.inactiveIcon} />
                  <Icon name={navigationIcon.active} className={styles.activeIcon} />
                </React.Fragment>
              )}
            </Html.div>
          </Shimmer>
        )}
        <Shimmer theme="light" className="ms-3" loading={loading}>
          <Html.span className={styles.label} testId={testId && `${testId}-label`}>
            {formatMessage(label)}
          </Html.span>
        </Shimmer>
        {!loading && renderBadges()}
      </Link>
      {hasSubitems && (
        <Html.div className={[styles.subitems, open && styles.subitemsOpen]} testId={testId && `${testId}-subitems`}>
          <Html.div className={styles.subitemsWrap}>{children}</Html.div>
        </Html.div>
      )}
    </React.Fragment>
  );
};

export type { ItemElement };
export default React.memo(Item);
