import type { Constraint } from './contracts';
import * as constraintMethods from './constraints';

const validateConstraints = (
  paramName: string,
  value: string | number | boolean | undefined,
  constraints: ReadonlyArray<Constraint>
): void => {
  for (let i = 0; i < constraints.length; i += 1) {
    const constraint = constraints[i];
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const validator = constraintMethods[constraint.type];

    if (!validator) {
      throw Error(`[Constraint]: Unknown constraint type: ${constraint.type}`);
    }

    validator(paramName, value, constraint.params);
  }
};

export default validateConstraints;
