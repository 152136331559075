// eslint-disable-next-line filenames/match-exported
import React from 'react';
import { combineLatest, distinctUntilChanged, map } from 'rxjs';
import useReadonlyObservable from 'enhancers/use-readonly-observable';
import authorization from 'services/authorization';
import account from 'store/account';
import navigationHistory from 'store/navigation-history';
import { link } from 'services/navigation';

import UI from './account-settings.ui';

const AccountSettings: React.FunctionComponent<unknown> = () => {
  const [isActive] = useReadonlyObservable(
    navigationHistory.onChange$.pipe(
      map(({ current }) => current.location.pathname === link.settings()),
      distinctUntilChanged()
    ),
    false
  );

  const [authorized] = useReadonlyObservable(
    combineLatest([
      account.onChange$.pipe(
        map((data) => data?.flags?.registrationPending === false),
        distinctUntilChanged()
      ),
      authorization.getAuthorization$('settings')!,
    ]).pipe(
      map(([hasEnrollmentCompleted, hasRequiredProperties]) => hasEnrollmentCompleted && hasRequiredProperties),
      distinctUntilChanged()
    ),
    false
  );

  if (!authorized) {
    return null;
  }

  return <UI active={isActive} />;
};

export default AccountSettings;
