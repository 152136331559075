import { defineMessages } from 'react-intl';
import type { LocalePerProduct } from 'contracts';

const seo: LocalePerProduct = {
  livejasmin: defineMessages({
    title: {
      id: 'pages.musicLibrary.seo.livejasmin.title',
      defaultMessage: 'Music Library',
    },
    description: {
      id: 'pages.musicLibrary.seo.livejasmin.description',
      defaultMessage: 'Music Library',
    },
  }),
  oranum: defineMessages({
    title: {
      id: 'pages.musicLibrary.seo.oranum.title',
      defaultMessage: 'Music Library',
    },
    description: {
      id: 'pages.musicLibrary.seo.oranum.description',
      defaultMessage: 'Music Library',
    },
  }),
};

const copyright: LocalePerProduct = {
  livejasmin: defineMessages({
    first: {
      id: 'pages.musicLibrary.copyright.livejasmin.first',
      defaultMessage:
        "Only the music available within the Websites' official music library, as well as the music owned by and/or licensed to you for commercial purposes, are authorized for use in connection with the Services on the Websites.",
    },
    second: {
      id: 'pages.musicLibrary.copyright.livejasmin.second',
      defaultMessage:
        'Any other use of music than mentioned above is strictly forbidden and may result in enforcement actions against the Model Account (including but not limited to suspend or terminate the Model Account).',
    },
  }),
  oranum: defineMessages({
    first: {
      id: 'pages.musicLibrary.copyright.oranum.first',
      defaultMessage:
        "Only the music available within the Websites' official music library, as well as the music owned by and/or licensed to you for commercial purposes, are authorized for use in connection with the Services on the Websites.",
    },
    second: {
      id: 'pages.musicLibrary.copyright.oranum.second',
      defaultMessage:
        'Any other use of music than mentioned above is strictly forbidden and may result in enforcement actions against the Broadcaster Account (including but not limited to suspend or terminate the Broadcaster Account).',
    },
  }),
};

export default {
  pages: {
    musicLibrary: {
      seo,
      navigationMenu: {
        ...defineMessages({
          label: {
            id: 'pages.musicLibrary.navigationMenu.label',
            defaultMessage: 'Music Library',
          },
        }),
      },
      copyright,
    },
  },
};
