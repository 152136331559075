import parse from 'utils/parse';
import type { OmitTypeFromProperties } from 'contracts';

import match from '../pathway/match';
import type { LiteralSegment, ParameterSegment } from '../pathway/contracts';
import type { Parameters, SearchParameters, HashParameters } from '../contracts';
import tokenize from '../pathway/tokenize';

const isParameterToken = (token: LiteralSegment | ParameterSegment): token is ParameterSegment =>
  token.type === 'parameter';

const getRouteParams = <T extends Parameters = Parameters>(routePattern: string, hrefPathname: string): Readonly<T> => {
  const matches = match<T>(routePattern)(hrefPathname);

  if (!matches.match) return Object.freeze({}) as T;

  return Object.freeze(matches.params!);
};

const getRoutePartialParams = <T extends Parameters = Parameters>(
  routePartialPattern: string,
  routeMatchPattern: string,
  hrefPathname: string
): Readonly<T> => {
  const segments = tokenize(routePartialPattern);
  const parameterTokens = segments.filter(isParameterToken).map((token) => token.name);
  const params = getRouteParams<T>(routeMatchPattern, hrefPathname);

  return Object.freeze(
    Object.fromEntries(Object.entries(params).filter(([key]) => parameterTokens.includes(key))) as T
  );
};

const getSearchParams = (
  searchParams: SearchParameters | undefined,
  locationSearchParams: string,
  global = false
): Readonly<OmitTypeFromProperties<Parameters, string[]>> => {
  if (!global && (!searchParams?.length || !locationSearchParams.trim())) return Object.freeze({});
  const normalizedSearchParams =
    searchParams?.map((param) => {
      const regexpIndex = param.indexOf(':');
      const pureParam = regexpIndex !== -1 ? param.substring(0, regexpIndex) : param;

      return parse.toCamelCase(pureParam.replace(/[^\w-]/g, ''));
    }) ?? [];

  const params = locationSearchParams
    .replace(/^\?/g, '')
    .split('&')
    .map((query) => {
      const [key, value] = query.split('=');

      return [parse.toCamelCase(key), decodeURIComponent(value)];
    })
    .filter(([key]) => (global ? true : normalizedSearchParams.includes(key)))
    .reduce(
      (acc, [key, value]) => {
        return { ...acc, [key]: value };
      },
      {} satisfies OmitTypeFromProperties<Parameters, string[]>
    );

  return Object.freeze(params);
};

const getHashParams = (
  hashParams: HashParameters | undefined,
  locationHash: string,
  global = false
): HashParameters => {
  if (!global && (!hashParams?.length || !locationHash.trim())) return [];

  const normalizedHashParams = hashParams?.map((h) => parse.toCamelCase(h)) ?? [];

  const hashes: HashParameters = locationHash
    .split('#')
    .filter(Boolean)
    .map((h) => parse.toCamelCase(h))
    .filter((hash) => (global ? true : normalizedHashParams.includes(hash)));

  return Object.freeze<HashParameters>(hashes);
};

export { getRouteParams, getRoutePartialParams, getSearchParams, getHashParams };
