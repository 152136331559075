import React from 'react';
import { map } from 'rxjs';
import application from 'services/application';
import location from 'services/router/location';
import authorization from 'services/authorization';
import useReadonlyObservable from 'enhancers/use-readonly-observable';
import user from 'store/user';
import type { ApplicationRoute } from 'contracts';

import UI from './header.ui';

const Header: React.FunctionComponent<unknown> = () => {
  const [content] = useReadonlyObservable(application.header.onContentChange$);
  const [isNavigationMenuOpen] = useReadonlyObservable(application.container.onNavigationMenuVisibilityChange$, false);
  const [removeMenu] = useReadonlyObservable<boolean>(
    application.container.onRemoveMenuChange$,
    application.container.data.removeMenu
  );
  const [userViewType] = useReadonlyObservable(
    user.onViewTypeChange$.pipe(map(({ viewType }) => viewType)),
    user.data?.viewType
  );

  const handleOnHamburgerToggle = React.useCallback(() => {
    application.container.toggleMobileNavigationMenu(!isNavigationMenuOpen);
  }, [isNavigationMenuOpen]);

  const showHamburger =
    !removeMenu &&
    (authorization.has(location.current?.name) ||
      authorization.has((location.current?.parent ?? '') as keyof ApplicationRoute));

  return (
    <UI
      viewType={userViewType}
      showHamburger={showHamburger}
      isNavigationMenuOpen={isNavigationMenuOpen}
      onHamburgerToggle={handleOnHamburgerToggle}
    >
      {content}
    </UI>
  );
};

export { Header };
export default React.memo(Header);
