import React from 'react';
import type { ObservableResource } from 'utils/create-observable-resource';

import { useRouter } from './hooks';
import type { InternalRoute, RequestContext, RouteAccessStatus } from './contracts';

interface RouteEventsElement {
  requestContext: RequestContext;
  onBeforeEnter: ObservableResource<RouteAccessStatus>;
  onBeforeLeave: InternalRoute['onBeforeLeave'];
  children: React.ReactNode;
}

function useForceUpdate(): () => void {
  const updateState = React.useState(0)[1];

  return React.useRef(() => updateState((n: number): number => (n + 1) % 1000000)).current;
}

const RouteLifeCycle: React.FunctionComponent<RouteEventsElement> = (props) => {
  const { onBeforeEnter, onBeforeLeave, requestContext, children } = props;
  const { navigation } = useRouter();
  const forceUpdate = useForceUpdate();

  const [state, setState] = React.useState<boolean | undefined>(onBeforeEnter.read());

  React.useEffect(() => {
    const valueSubscription = onBeforeEnter?.valueRef$$?.subscribe(setState);
    const signalSubscription = onBeforeEnter?.shouldUpdate$$?.subscribe(forceUpdate);

    return () => {
      valueSubscription?.unsubscribe();
      signalSubscription?.unsubscribe();

      onBeforeLeave?.(requestContext, navigation);
    };
  }, [forceUpdate, requestContext, navigation, onBeforeEnter, onBeforeLeave]);

  if (!state) return null;

  return children;
};

export default React.memo(RouteLifeCycle);
