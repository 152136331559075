import React from 'react';
import toArray from 'utils/to-array';
import type { StyledElement, AriaAttributes, TestAutomation } from 'contracts';

import Html from '../html';
import Icon, { IconList } from '../icon';

import styles from './alert.module.scss';

type AlertType = 'success' | 'warning' | 'error';

interface AlertElement extends StyledElement, AriaAttributes, TestAutomation {
  type?: AlertType;
  variant?: 'minimal';
  caption?: string;
  children: React.ReactNode;
}

const Alert: React.FunctionComponent<AlertElement> = (props) => {
  const { testId, variant, type = 'warning', className, style, caption, children, ...rest } = props;

  const iconName = React.useMemo(() => {
    switch (type) {
      case 'success':
        return IconList.checkCircularSolid;
      case 'error':
        return IconList.closeCircularSolid;
      case 'warning':
      default:
        if (variant === 'minimal') {
          return IconList.warningCircularSolid;
        }

        return IconList.warningCircularSolid;
    }
  }, [type, variant]);

  return (
    <Html.div
      testId={testId}
      className={[
        styles.alert,
        styles[type as string],
        variant && styles[variant],
        caption && styles.hasCaption,
        'm-0',
        ...toArray(className),
      ]}
      style={style}
      arias={rest}
      typography="body2"
    >
      <Html.div className={styles.iconWrapper}>
        <Icon name={iconName} className={styles.icon} testId={testId && `${testId}-icon`} />
      </Html.div>
      <Html.div>
        <Html.p className={styles.title}>{children}</Html.p>
        {caption && <Html.p className={styles.caption}>{caption}</Html.p>}
      </Html.div>
    </Html.div>
  );
};

export type { AlertElement };
export default Alert;
