import React from 'react';
import Html from 'components/html';
import toArray from 'utils/to-array';
import type { StyledElement, TestAutomation, AriaAttributes } from 'contracts';

import { Signature } from '../../signatures';
import type { ElementSignature } from '../../contracts';

import styles from './row.module.scss';

interface LayoutPageRow extends StyledElement, TestAutomation, AriaAttributes {
  /**
   * Remove row paddings
   */
  spaceless?: boolean;
  children: React.ReactNode;
}

const Row: React.FunctionComponent<LayoutPageRow> & ElementSignature = (props) => {
  const { testId, className, style, spaceless, children, ...rest } = props;

  return (
    <Html.div
      testId={testId && `${testId}-row`}
      className={[styles.row, !spaceless && styles.spaced, ...toArray(className)]}
      style={style}
      arias={rest}
    >
      {children}
    </Html.div>
  );
};

Row.signature = Signature.PAGE_ROW;

export type { LayoutPageRow };
export default Row;
