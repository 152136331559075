import React from 'react';
import { useIntl } from 'react-intl';
import useReadonlyObservable from 'enhancers/use-readonly-observable';
import internetConnectivity from 'store/internet-connectivity';
import Html from 'components/html';
import Alert from 'components/alert';

import messages from './monitoring.i18n';

const InternetConnectivity: React.FunctionComponent = () => {
  const { formatMessage } = useIntl();
  const [{ status }] = useReadonlyObservable(internetConnectivity.onChange$, internetConnectivity.data);

  if (status === 'online') return null;

  return (
    <Alert type="warning" caption={formatMessage(messages.pages.root.monitoring.noInternetConnection.description)}>
      {formatMessage(messages.pages.root.monitoring.noInternetConnection.title, {
        b: (text: React.ReactNode) => <Html.b>{text}</Html.b>,
      })}
    </Alert>
  );
};

export default InternetConnectivity;
