import React from 'react';
import { firstValueFrom, map, switchMap, take } from 'rxjs';
import Cookies from 'js-cookie';
import useReadonlyObservable from 'enhancers/use-readonly-observable';
import useToast from 'enhancers/use-toast';
import ff from 'services/feature-toggle';
import type { CookieCategorySelections, CookieConsentCategory } from 'store/cookie-consent';
import cookieConsentStore from 'store/cookie-consent';
import { sendTracking } from 'services/google-analytics';

import Banner from './banner';
import Dialog from './dialog';
import tracking from './cookie-consent.tracking';

const toastErrorId = Symbol('cookie-consent-error');

const CookieConsent: React.FunctionComponent = () => {
  const toast = useToast();
  const [showDialog, setShowDialog] = React.useState(false);
  const [toggleSelections, setToggleSelections] = React.useState<CookieCategorySelections>({
    analytical: false,
    functionality: false,
  });
  const [consentLoading, setConsentLoading] = React.useState(false);
  const [cookieConsentStatus] = useReadonlyObservable(cookieConsentStore.onStatusChange$, undefined);

  React.useEffect(() => {
    if (!ff.enabled('enableUnknownCookieCollection')) return;

    const onCookiesChange = cookieConsentStore.onCookiesChange$
      .pipe(
        take(1),
        map((configCookies) => Object.keys(Cookies.get()).filter((cookieName) => !configCookies?.[cookieName])),
        switchMap((unknownCookies) => cookieConsentStore.reportUnknownCookies$(unknownCookies))
      )
      .subscribe();

    return () => {
      onCookiesChange.unsubscribe();
    };
  }, []);

  React.useEffect(() => {
    if (cookieConsentStatus === 'incomplete') {
      sendTracking(tracking.view.banner);
    }
  }, [cookieConsentStatus]);

  const saveConsentSettings = React.useCallback(
    (selections: CookieCategorySelections) => {
      setConsentLoading(true);

      void firstValueFrom(cookieConsentStore.updateConsentSignature$(window.location.origin, selections)).catch(() => {
        setConsentLoading(false);
        toast.error(toastErrorId);
      });
    },
    [toast]
  );

  const handleBannerAcceptClick = React.useCallback(() => {
    sendTracking(tracking.cookies.accept);
    saveConsentSettings({
      functionality: true,
      analytical: true,
    });
  }, [saveConsentSettings]);

  const handleBannerRejectClick = React.useCallback(() => {
    sendTracking(tracking.cookies.reject);
    saveConsentSettings({
      functionality: false,
      analytical: false,
    });
  }, [saveConsentSettings]);

  const handleBannerManageClick = React.useCallback(() => {
    sendTracking(tracking.cookies.manage);
    setShowDialog(true);
  }, []);

  const handleOverlayToggleClick = React.useCallback((category: CookieConsentCategory, enabled: boolean) => {
    setToggleSelections((prev) => ({
      ...prev,
      [category]: enabled,
    }));
  }, []);

  const handleOverlayConfirmClick = React.useCallback(() => {
    const { analytical, functionality } = toggleSelections;

    sendTracking(tracking.cookies.confirm, {
      analytical: (analytical && 'enabled') || 'disabled',
      functionality: (functionality && 'enabled') || 'disabled',
    });

    saveConsentSettings(toggleSelections);
  }, [toggleSelections, saveConsentSettings]);

  if (cookieConsentStatus !== 'incomplete') return null;

  if (showDialog)
    return (
      <Dialog
        desktopMode="overlay"
        selections={toggleSelections}
        onToggleClick={handleOverlayToggleClick}
        onConfirmClick={handleOverlayConfirmClick}
        loading={consentLoading}
      />
    );

  return (
    <Banner
      onAcceptClick={handleBannerAcceptClick}
      onRejectClick={handleBannerRejectClick}
      onManageClick={handleBannerManageClick}
      loading={consentLoading}
    />
  );
};

export default CookieConsent;
