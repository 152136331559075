import { distinctUntilChanged, fromEvent, map, of, throttleTime } from 'rxjs';
import { throttle } from 'throttle-debounce';
import type { Observable } from 'rxjs';
import type { BrowserHistory } from 'history';
import element from 'utils/element';
import breakpoints from 'utils/breakpoints';
import appProd from 'utils/product';
import is from 'utils/is';
import { Product } from 'contracts';
import history from 'services/router/history';

import ServiceBase from '../service-base';

import ApplicationHeader from './header';
import ApplicationContainer from './container';

interface ApplicationService {
  loading: boolean;
  viewportType: 'desktop' | 'mobile';
}

class Application extends ServiceBase<ApplicationService> {
  name = 'application';

  header = ApplicationHeader;

  container = ApplicationContainer;

  private loadingActorsRef = new Set();

  private unblockHistory: undefined | (() => void) = undefined;

  constructor() {
    super({ initialState: { loading: false, viewportType: breakpoints.max('md') ? 'mobile' : 'desktop' } });

    window.addEventListener(
      'resize',
      throttle(150, () => {
        this.set((data) => ({ ...data, viewportType: breakpoints.max('md') ? 'mobile' : 'desktop' }));
      })
    );
  }

  get onViewportTypeChange$(): Observable<ApplicationService['viewportType']> {
    return super.onChange$.pipe(
      map((data) => data.viewportType),
      distinctUntilChanged()
    );
  }

  get onLoad$(): Observable<boolean> {
    return super.onChange$.pipe(
      map((data) => data.loading),
      distinctUntilChanged()
    );
  }

  get onScroll$(): Observable<{ event: Event; root: HTMLElement } | undefined> {
    const root = document.documentElement;

    if (!root) return of(undefined);

    return fromEvent(window, 'scroll').pipe(
      throttleTime(150, undefined, { leading: false, trailing: true }),
      map((event) => {
        return { event, root };
      })
    );
  }

  get viewportType(): ApplicationService['viewportType'] {
    return this.data.viewportType;
  }

  get loading(): boolean {
    return this.data.loading;
  }

  get current(): Product {
    return appProd.current;
  }

  get LiveJasmin(): boolean {
    return this.current === Product.LiveJasmin;
  }

  get Oranum(): boolean {
    return this.current === Product.Oranum;
  }

  get viewportMobile(): boolean {
    return this.data.viewportType === 'mobile';
  }

  startLoading<T extends object>(origin: T): void {
    this.loadingActorsRef.add(origin);
    this.set({
      ...this.data,
      loading: true,
    });
  }

  stopLoading<T extends object>(origin: T): void {
    this.loadingActorsRef.delete(origin);

    if (!this.loadingActorsRef.size) {
      this.set({
        ...this.data,
        loading: false,
      });
    }
  }

  scrollTop(top: number): void {
    element(window).scrollTop(top);
  }

  toggleNavigationLock(lock: boolean): void {
    ApplicationContainer.toggleRemoveMenu(lock);

    if (!lock) {
      if (is.func(this.unblockHistory)) {
        this.unblockHistory();
      }

      return;
    }
    if (this.unblockHistory !== undefined) {
      this.unblockHistory();
    }

    this.unblockHistory = (history as unknown as BrowserHistory).block(() => {});
  }
}

export default new Application();
