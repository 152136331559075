import {
  merge,
  defer,
  map,
  fromEvent,
  switchMap,
  from,
  iif,
  of,
  tap,
  catchError,
  asyncScheduler,
  type Subscription,
} from 'rxjs';
import happen from 'utils/happen';

import Store from './store';

interface ConnectivityStatus {
  status: 'online' | 'offline';
}

const interval = 60_000;

const checkConnectivity$ = defer(() => from(fetch('/pixel.jpg'))).pipe(
  map((response) => happen(response.status).between(200, 299)),
  catchError(() => of(false))
);

class InternetConnectivity extends Store<ConnectivityStatus> {
  private scheduler: Subscription | undefined;

  source$ = merge(fromEvent(window, 'online'), fromEvent(window, 'offline')).pipe(
    map(() => navigator.onLine),
    switchMap((networkOnline) =>
      iif(
        () => networkOnline,
        checkConnectivity$.pipe(
          tap((status) => {
            switch (status) {
              case false: {
                this.scheduler = asyncScheduler.schedule(() => {
                  this.refresh();
                }, interval);

                break;
              }

              default:
                this.scheduler?.unsubscribe();
                break;
            }
          })
        ),
        of(false)
      )
    ),
    map((hasConnectivity) => ({ status: hasConnectivity ? 'online' : 'offline' }) satisfies ConnectivityStatus)
  );

  constructor() {
    super({ name: 'internet-connectivity', initialState: { status: 'online' } });
  }
}

export type { ConnectivityStatus };
export default new InternetConnectivity();
