import { defineMessages } from 'react-intl';

export default {
  pages: {
    statistics: {
      dashboard: {
        navigationMenu: {
          ...defineMessages({
            label: {
              id: 'pages.dashboard.navigationMenu.label',
              defaultMessage: 'Dashboard',
            },
          }),
        },
        ...defineMessages({
          breadcrumb: {
            id: 'pages.statistics.dashboard.breadcrumb',
            defaultMessage: 'Dashboard',
          },
          title: {
            id: 'pages.statistics.dashboard.title',
            defaultMessage: 'Statistics',
          },
          description: {
            id: 'pages.statistics.dashboard.description',
            defaultMessage: 'Select the timeframes to compare and explore your detailed statistics.',
          },
        }),
      },
    },
  },
};
