import { Subject, filter } from 'rxjs';
import type { Observable } from 'rxjs';
import type { ToastNotification, ToastAPI, ToastInstanceId } from 'contracts';
import is from 'utils/is';

import ServiceBase from './service-base';

type ToastEvent =
  | {
      action: 'dispatch';
      toast: ToastNotification;
    }
  | {
      action: 'remove';
      id: ToastInstanceId;
    }
  | {
      action: 'clear';
    };

class Toast extends ServiceBase<ToastEvent> {
  name = 'toast-notification';

  constructor() {
    super({
      subject$: new Subject<ToastEvent>(),
    });
  }

  get onChange$(): Observable<ToastEvent> {
    return super.onChange$.pipe(filter((notification) => !is.nullish(notification)));
  }

  dispatch(notification: Parameters<ToastAPI['dispatch']>[0]): void {
    this.set({
      action: 'dispatch',
      toast: notification,
    });
  }

  remove(id: Parameters<ToastAPI['remove']>[0]): void {
    this.set({
      action: 'remove',
      id,
    });
  }

  clear(): void {
    this.set({
      action: 'clear',
    });
  }
}

export type { ToastEvent };
export default new Toast();
