import toArray from 'utils/to-array';
import tree from 'utils/tree';

import type { Route } from './contracts';
import createRoutesAssistant from './utils/create-routes-assistant';

type Module = Record<string, Route | Array<Route>>;

const modules = new Set<Route>();

const addModule = (module: Module): void => {
  tree.flatten(toArray(module.default)).forEach((r: Route) => modules.add(r));
};

if (process.env.NODE_ENV === 'test') {
  const defaultRoutes: Array<Route> = [
    {
      name: 'placeholder',
      path: '/{lang?}/placeholder/{view:list(foo|bar)?}/{id?}',
      searchParams: ['filterBy', 'orderBy'],
      element: null,
    },
    {
      name: 'search',
      path: '/{lang?}/search/{scope?}',
      searchParams: ['term', 'page'],
      element: null,
    },
    {
      name: 'subscriptions',
      path: '/{lang?}/subscriptions/{type:list(active|deactivated)}/{id?}',
      searchParams: ['filterBy', 'orderBy'],
      element: null,
    },
  ];

  addModule({ default: defaultRoutes });

  // eslint-disable-next-line @typescript-eslint/no-require-imports
  const files = require('glob').globSync('src/pages/**/@@global/routes.*(tsx|ts)') as string[];

  files.forEach((file) => {
    // eslint-disable-next-line @typescript-eslint/no-require-imports,@typescript-eslint/restrict-template-expressions
    const filepath = file.replace(`src${require('path').sep}`, '');

    // eslint-disable-next-line @typescript-eslint/no-require-imports,import/no-dynamic-require
    addModule(require(filepath) as Module);
  });
} else {
  const context = require.context('../../pages', true, /\/@@global\/routes\.(ts|tsx)$/u);

  context
    .keys()
    .filter((path) => path.startsWith('./'))
    .map(context)
    .forEach((module) => {
      addModule(module as Module);
    });
}

const routes = createRoutesAssistant(tree.mount(Array.from(modules)));

export default routes;
