import React from 'react';
import type { Route } from 'contracts';

import parent from '../../../@@global/id';

import id from './id';

const AsyncPage = React.lazy(() => import('../status'));

const routes: Route[] = [
  {
    parent,
    name: id,
    path: '/{status:list(active|pending|rejected)}',
    element: <AsyncPage />,
  },
];

export default routes;
