import { defer, map, of } from 'rxjs';
import application from 'services/application';
import { link } from 'services/navigation';
import type { NavigationMenuItem } from 'contracts';
import legacyId from 'pages/redirection/legacy/@@global/id';
import { getIsHolidayAwardsActive } from 'pages/shared/holiday-awards/utils';
import authorization from 'services/authorization';

import messages from '../holiday-awards.i18n';

import id from './id';

const menu: NavigationMenuItem = {
  entries: [
    {
      name: id,
      label: messages.pages.bestOf.holidayAwards.navigationMenu.label,
      link: () => link.holidayAwards(),
      notification$: defer(() =>
        of(application.LiveJasmin && getIsHolidayAwardsActive()).pipe(map((enabled) => (enabled ? -1 : 0)))
      ),
      visibility$: authorization.getAuthorization$(id),
      new: () => application.LiveJasmin && getIsHolidayAwardsActive(),
      parent: legacyId.bestOf,
      weight: 1,
    },
  ],
  activates: {
    [id]: [id],
  },
};

export default menu;
