import React from 'react';
import type { Route } from 'contracts';

import id from './id';

const AsyncPage = React.lazy(() => import('../automated-messages'));
const AsyncSkeletonPage = React.lazy(() => import('../automated-messages.skeleton'));

const routes: Route = {
  name: id,
  path: '/{lang?}/messenger/automated-messages',
  element: <AsyncPage />,
  skeleton: <AsyncSkeletonPage />,
};

export default routes;
