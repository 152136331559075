import { map } from 'rxjs';
import moduleAuth from 'store/module-authorization';
import { link } from 'services/navigation';
import authorization from 'services/authorization';
import type { NavigationMenuItem } from 'contracts';
import legacyId from 'pages/redirection/legacy/@@global/id';

import messages from '../loyalfans.i18n';

import id from './id';

const menu: NavigationMenuItem = {
  entries: [
    {
      name: id,
      parent: legacyId.earnMore,
      label: messages.pages.earnMore.loyalfans.navigationMenu.label,
      link: () => link.earnMore({ page: 'loyalfans' }),
      weight: 17,
      visibility$: authorization
        .getAuthorization$(id)
        ?.pipe(map((page) => page && moduleAuth.hasLoyalfansPermission())),
    },
  ],
  activates: {
    [id]: [id],
  },
};

export default menu;
