import React from 'react';
import type { Route } from 'contracts';

import id from './id';

const AsyncPage = React.lazy(() => import('../overview'));

const routes: Route[] = [
  {
    name: id,
    path: '/{lang?}/payout/overview',
    element: <AsyncPage />,
    searchParams: [`year:regex(^[12]\\d{3}$)`, 'page'],
  },
];

export default routes;
