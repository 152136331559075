import { defineMessages } from 'react-intl';
import type { LocalePerProduct } from 'contracts';
import { Product } from 'contracts';

const seo: LocalePerProduct = {
  [Product.LiveJasmin]: defineMessages({
    title: {
      id: 'pages.payout.overview.seo.livejasmin.title',
      defaultMessage: 'Payout Overview',
    },
    description: {
      id: 'pages.payout.overview.seo.livejasmin.description',
      defaultMessage: 'Payout Overview',
    },
  }),
  [Product.Oranum]: defineMessages({
    title: {
      id: 'pages.payout.overview.seo.oranum.title',
      defaultMessage: 'Payout Overview',
    },
    description: {
      id: 'pages.payout.overview.seo.oranum.description',
      defaultMessage: 'Payout Overview',
    },
  }),
};

export default {
  pages: {
    payout: {
      overview: {
        seo,
        navigationMenu: {
          ...defineMessages({
            label: {
              id: 'pages.payout.overview.navigationMenu.label',
              defaultMessage: 'Payout Overview',
            },
          }),
        },
        ...defineMessages({
          title: {
            id: 'pages.payout.overview.title',
            defaultMessage: 'Payout Overview',
          },
          info: {
            id: 'pages.payout.overview.info',
            defaultMessage: `Starting 01.09.2020, <b>payout dates are closed in every two weeks ({payoutCloseDays} days)</b>. The Period End date is indicated for each closure. The Period Start date is the day 
              following the Period End date of the previous closure. If you have no previous closure, the Period Start date is your account registration date.{br}{br}
              If you use <b>Standard Payout type</b>, your payouts are performed {payoutDefaultDays} days after the Period End date. This is indicated under Due Date. If you prefer a faster payout, you can withdraw your last period earnings any time before the Standard payout date.{br}{br}
              You can also set your payout type to <b>Fast Payout</b>, so to have your funds sent always one day after the period closure (instead of {payoutDefaultDays} days).{br}{br}
              A payout period is closed in {minClosureDays}-{maxClosureDays} days after the calendar end of the period.
            `,
          },
          footerText: {
            id: 'pages.payout.overview.footerText',
            defaultMessage:
              'For any question about payouts and payment details, you can contact us at {link}.{br}Please note that complaints about payouts must be raised within {days} days of the payout date.',
          },
          invoiceUpload: {
            id: 'pages.payout.overview.invoiceUpload',
            defaultMessage: `Invoice upload information`,
          },
        }),
        payoutCards: {
          autoFastPayout: defineMessages({
            title: {
              id: 'pages.payout.overview.payoutCards.autoFastPayouts.title',
              defaultMessage: 'Auto-Fast Payout',
            },
            disabled: {
              id: 'pages.payout.overview.payoutCards.autoFastPayouts.disabled',
              defaultMessage:
                'If you enable Auto-Fast Payout, your funds will be sent as soon as the period is closed.',
            },
            enabled: {
              id: 'pages.payout.overview.payoutCards.autoFastPayouts.enabled',
              defaultMessage:
                'You have Auto-Instant Payouts enabled, so you cannot use another {highlightedText} until you change it.',
            },
            tooltip: {
              id: 'pages.payout.overview.payoutCards.autoFastPayouts.tooltip',
              defaultMessage:
                'Get your payment sent {days} business day after period closure. The fee for this service is {percentage} of the related payout (min. {minWithdrawCost}, max. {maxWithdrawCost}) and will be deducted each period this service is active. Fast Payouts may be activated/deactivated at any time.',
            },
            highlightedText: {
              id: 'pages.payout.overview.payoutCards.autoFastPayouts.highlightedText',
              defaultMessage: 'payout types',
            },
          }),
          autoInvoice: defineMessages({
            title: {
              id: 'pages.payout.overview.payoutCards.autoInvoice.title',
              defaultMessage: 'Auto-Invoice',
            },
            description: {
              id: 'pages.payout.overview.payoutCards.autoInvoice.description',
              defaultMessage:
                'With Auto-Invoicing, we will create an invoice on your behalf based on the company details you provided.',
            },
            tooltip: {
              id: 'pages.payout.overview.payoutCards.autoInvoice.tooltip',
              defaultMessage:
                'If you enabled Auto-Invoicing, we will create an Invoice on your behalf based on the company details you provided. However, if Auto-Invoicing is not enabled, you must upload your own company invoices.',
            },
          }),
        },
      },
    },
  },
};
