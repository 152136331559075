import type { RouteAuthorization } from 'contracts';
import { combineLatest, of, switchMap } from 'rxjs';
import evalAuthorization from 'utils/rxjs/eval-authorization';
import application from 'services/application/application';
import is from 'utils/is';
import performer from 'store/performer/performer';
import moduleAuth from 'store/module-authorization';

import parentAuth from '../../@@global/authorization';

const authorization: RouteAuthorization = {
  name: 'profilePrivacyFaceVisibility',
  authorization$: moduleAuth.onChange$.pipe(
    evalAuthorization(
      () => is.nullish(moduleAuth.data),
      () =>
        combineLatest([parentAuth.authorization$, performer.onStatusChange$]).pipe(
          switchMap(() => of(application.LiveJasmin && moduleAuth.hasHiddenFacePermission()))
        )
    )
  ),
};

export default authorization;
