import React from 'react';
import type { Route } from 'contracts';

import id from './id';

const AsyncPage = React.lazy(() => import('../music-library'));
const AsyncSkeletonPage = React.lazy(() => import('../music-library.skeleton'));

const routes: Route = {
  name: id,
  path: '/{lang?}/music-library',
  element: <AsyncPage />,
  skeleton: <AsyncSkeletonPage />,
};

export default routes;
