import { map, distinctUntilChanged, filter } from 'rxjs';
import type { Observable, Subscription } from 'rxjs';
import performerCategory from '@dh-msc/performer-category';
import websocket, { LiveNotificationEvent } from 'services/websocket';
import settings from 'configurations/application';
import is from 'utils/is';
import parse from 'utils/parse';
import filterWhileNullish from 'utils/rxjs/filter-while-nullish';
import { type DateInput } from 'utils/date';

import Store from '../store';

import type { PerformerStatus, PerformerStore, Person } from './source';
import { source } from './source';

interface PerformerStatusUpdatedContent {
  performerId: number;
  statusId: number;
}

const getTopCategoryWikiLink = (categoryId = -1): string => {
  const page =
    {
      1: 'Categories-nude',
      2: 'Categories-HotFlirt',
      3: 'Categories-celebrity',
      109: 'Categories-Amateur',
    }[categoryId] ?? '';

  return parse.url(settings.envVars.wikiUri, 'Categories', page && `#${page}`);
};

class Performer extends Store<PerformerStore | undefined> {
  private watchList = [LiveNotificationEvent.PerformerStatusUpdated];

  private websocketSubscription: Subscription | undefined = undefined;

  source$ = source(
    this.initialState,
    this.closeWebsocket.bind(this),
    this.subscribeToWebSocket.bind(this),
    super.meta.setLoading.bind(super.meta),
    this.reset.bind(this),
    this.close.bind(this)
  );

  constructor() {
    super({
      name: 'performer',
      initialState: undefined,
    });
  }

  private subscribeToWebSocket(): void {
    if (this.websocketSubscription) return;

    this.websocketSubscription = websocket
      .on$<PerformerStatusUpdatedContent>(this.watchList)
      .pipe(filter(({ content }) => content.performerId !== this.data?.id))
      .subscribe(({ content }) => {
        this.set('status', this.statusIdToName(content.statusId));
      });
  }

  private closeWebsocket(): void {
    this.websocketSubscription?.unsubscribe?.();
    this.websocketSubscription = undefined;
  }

  // @TODO: this method should exist until BE team fix inconsistency in status type across all system
  private statusIdToName(statusId: number): PerformerStatus {
    return ['new', 'pending', 'rejected', 'active', 'inactive', 'suspended', 'closed'][statusId - 1] as PerformerStatus;
  }

  private getPersonOrDefaultPerson(personId?: number): Person | undefined {
    if (!this.data?.personIds) return undefined;

    if (personId) {
      return this.data.persons?.find((person) => person.id === personId);
    }

    return this.data.persons?.[0];
  }

  public setPerformerFlag(flag: string, value: boolean): void {
    // @ts-expect-error: ignore type assertion until better assessment
    this.set('flags', {
      ...this.data?.flags,
      [flag]: value,
    });
  }

  public get onChange$(): Observable<PerformerStore> {
    return super.onChange$.pipe(filterWhileNullish());
  }

  public get onStatusChange$(): Observable<PerformerStore['status']> {
    return this.onChange$.pipe(
      map(({ status }) => status),
      distinctUntilChanged()
    );
  }

  public get onCategoryChange$(): Observable<number> {
    return this.onChange$.pipe(
      map(({ category }) => category.id!),
      distinctUntilChanged()
    );
  }

  get onDisplayNameChange$(): Observable<string> {
    return this.onChange$.pipe(
      map(({ displayName }) => displayName),
      distinctUntilChanged()
    );
  }

  hasNudeCategory(): boolean {
    return performerCategory.is.nude(this.data?.category.id);
  }

  hasNudeAndGirlCategory(): boolean {
    return this.data?.category.id === performerCategory.values.NudeGirl;
  }

  hasNudeAndGayCategory(): boolean {
    return this.data?.category.id === performerCategory.values.GayMale;
  }

  hasNudeAndTransgenderCategory(): boolean {
    return this.data?.category.id === performerCategory.values.NudeTransgender;
  }

  hasNudeAndHermaphroditeCategory(): boolean {
    return this.data?.category.id === performerCategory.values.NudeHermaphrodite;
  }

  hasNudeAndShemaleCategory(): boolean {
    return this.data?.category.id === performerCategory.values.NudeShemale;
  }

  hasHotFlirtCategory(): boolean {
    return performerCategory.is.hotFlirt(this.data?.category.id);
  }

  hasCelebrityCategory(): boolean {
    return performerCategory.is.celebrity(this.data?.category.id);
  }

  hasAmateurCategory(): boolean {
    return performerCategory.is.amateur(this.data?.category.id);
  }

  hasFreeShowCategory(): boolean {
    return performerCategory.is.freeShow(this.data?.category.id);
  }

  hasFreeShowAndGirlCategory(): boolean {
    return this.data?.category.id === performerCategory.values.FreeShowGirl;
  }

  hasMissingDataStatus(): boolean {
    return this.data?.status === 'new';
  }

  hasActiveStatus(): boolean {
    return this.data?.status === 'active';
  }

  hasInactiveStatus(): boolean {
    return this.data?.status === 'inactive';
  }

  hasSuspendedStatus(): boolean {
    return this.data?.status === 'suspended';
  }

  hasClosedStatus(): boolean {
    return this.data?.status === 'closed';
  }

  hasPromoPeriod(): boolean {
    return this.data?.promoTime.free?.timeNeeded !== 0 || this.data?.promoTime.private?.timeNeeded !== 0;
  }

  get topCategoryWikiLink(): string {
    return getTopCategoryWikiLink(this.data?.category?.parent?.parent?.id);
  }

  isCouple(): boolean {
    return is.array(this.data?.personIds) && this.data.personIds.length > 1;
  }

  getBirthday(personId?: number): DateInput | null | undefined {
    return this.getPersonOrDefaultPerson(personId)?.birthday;
  }

  getGender(personId?: number): number | undefined {
    return this.getPersonOrDefaultPerson(personId)?.genderId;
  }

  getFullName(personId?: number): string | undefined {
    return this.getPersonOrDefaultPerson(personId)?.fullName;
  }
}

export default new Performer();
