import React from 'react';
import type { Route } from 'contracts';

import { StepRouteParam } from '../../../contracts';
import parentId from '../../../@@global/id';

import id from './id';

const AsyncPage = React.lazy(() => import('../personal-data'));

const routes: Route = {
  name: id,
  parent: parentId,
  // eslint-disable-next-line max-len
  path: `/{step:list(${StepRouteParam.OwnerDetails}|${StepRouteParam.SimplifiedOwnerDetails}|${StepRouteParam.CreateModelProfile})}`,
  element: <AsyncPage rejected={false} onNextStep={() => null} />,
  meta: {
    layout: false,
  },
};

export default routes;
