import history from 'services/router/history';
import createNavigationHistory, { mountInitialState } from 'services/router/create-navigation-history';
import { Store } from 'store';
import type { NavigationHistory as NavigationHistoryType } from 'services/router/contracts';

class NavigationHistory extends Store<NavigationHistoryType> {
  source$ = createNavigationHistory(history).onHistoryChange$;

  constructor() {
    super({
      name: 'navigation-history',
      initialState: { previous: undefined, current: mountInitialState(history) },
    });
  }
}

export default new NavigationHistory();
