/**
 * Page ID
 */
const id = {
  dashboard: 'dashboard',
  contact: 'contact',
  help: 'help',
  helpAndInfo: 'helpAndInfo',
  cookies: 'cookies',
  faq: 'faq',
  recoverPassword: 'recoverPassword',
  privacy: 'privacy',
  terms: 'terms',
  certifiedPartners: 'certifiedPartners',
  statisticsIncome: 'statisticsIncome',
  statisticsFanClub: 'statisticsFanClub',
  profile: 'profile',
  myContent: 'myContent',
  myContentShorts: 'myContentShorts',
  myContentEditor: 'myContentEditor',
  messages: 'messages',
  messengerStudio: 'messengerStudio',
  messengerSupport: 'messengerSupport',
  messengerSystem: 'messengerSystem',
  promotionToolsEroticTeaser: 'promotionToolsEroticTeaser',
  promotionToolsPromoTeaser: 'promotionToolsPromoTeaser',
  promotionToolsVideoCall: 'promotionToolsVideoCall',
  promotionToolsVideoAds: 'promotionToolsVideoAds',
  bestOf: 'bestOf',
  bestOfTopModels: 'bestOfTopModels',
  awards: 'awards',
  referral: 'referral',
  referrals: 'referrals',
  musicLibrary: 'musicLibrary',
  payout: 'payout',
  payoutOptions: 'payoutOptions',
  earnMore: 'earnMore',
  statisticsStudio: 'statisticsStudio',
};

export default id;
