import React from 'react';
import product from 'utils/product';
import LoyaltyMask, { type LoyaltyMaskElement } from 'components/loyalty-mask/loyalty-mask';

const LoyaltyMaskHidden: React.FunctionComponent<Omit<LoyaltyMaskElement, 'loyalty'>> = (props) => {
  if (product.Oranum) return <LoyaltyMask {...props} loyalty="Anonymous" />;

  return <LoyaltyMask {...props} loyalty="Secret" />;
};

export default LoyaltyMaskHidden;
