import React from 'react';

import { RouteContext, RouterContext, RouteRequestContext, type RouterProvider, type RouteProvider } from './context';
import type { RequestContext } from './contracts';

const useRouter = (): RouterProvider => {
  const context = React.useContext(RouterContext);

  if (!context) throw new Error('useRouter must be used within a RouterProvider');

  return context;
};

const useRoute = (): RouteProvider => {
  const context = React.useContext(RouteContext);

  if (!context) throw new Error('useRoute must be used within a Route');

  return context;
};

const useRequestContext = <T extends RequestContext = RequestContext>(): T => {
  const context = React.useContext(RouteRequestContext);

  if (!context) throw new Error('useRequestContext must be used within a RouterProvider');

  return context as T;
};

const useParams = (): Pick<RouteProvider, 'params' | 'searchParams' | 'hashParams'> => {
  const { params, searchParams, hashParams } = useRoute();

  return {
    params,
    searchParams,
    hashParams,
  };
};

export { useRouter, useRoute, useParams, useRequestContext };
