import { Subject, combineLatest, switchMap, distinctUntilChanged, type Observable } from 'rxjs';

import type { InternalRoute, RequestContext, RouteMiddleware, RouteAccessStatus } from '../contracts';

const cache = new Set<RouteMiddleware>();
const signal$ = new Subject<Parameters<RouteMiddleware>>();
const resource$ = signal$.pipe(
  distinctUntilChanged((prev, next) => {
    const [prevRoutes = []] = prev || [];
    const [nextRoutes = []] = next;

    return (
      nextRoutes.length === prevRoutes.length &&
      nextRoutes.every((nextRoute) => prevRoutes.some((prevRoute) => prevRoute?.path === nextRoute.path))
    );
  }),
  switchMap(([routes, context]) => combineLatest(Array.from(cache).map((middleware) => middleware(routes, context))))
);

interface MiddlewareRegistry {
  readonly watch$: Observable<Array<RouteAccessStatus>>;
  register(middleware$: RouteMiddleware): void;
  run<T extends RequestContext>(routes: Array<InternalRoute>, context: T): void;
}
const middlewareRegistry: MiddlewareRegistry = {
  register(middleware$: RouteMiddleware) {
    if (cache.has(middleware$)) return;
    cache.add(middleware$);
  },
  get watch$() {
    return resource$;
  },
  run(route, context) {
    signal$.next([route, context]);
  },
};

export default middlewareRegistry;
