import React from 'react';
import type { Route } from 'contracts';

import id from './id';

const AsyncPage = React.lazy(() => import('../model-referral'));
const AsyncSkeletonPage = React.lazy(() => import('../model-referral.skeleton'));

const routes: Route = {
  name: id,
  path: '/{lang?}/referrals/model',
  searchParams: ['page', 'search', 'status', 'period'],
  element: <AsyncPage />,
  skeleton: <AsyncSkeletonPage />,
};

export default routes;
