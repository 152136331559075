import React from 'react';
import type { Route } from 'contracts';

import parentId from '../../../@@global/id';

import id from './id';

const AsyncPage = React.lazy(() => import('../expertise'));
const AsyncSkeleton = React.lazy(() => import('../expertise.skeleton'));

const routes: Route = {
  parent: parentId,
  name: id,
  path: '/expertise',
  element: <AsyncPage />,
  skeleton: <AsyncSkeleton />,
};

export default routes;
