import Cookies, { type CookieAttributes } from 'js-cookie';
import { type Consent } from 'services/api/proxy-consent/data-contracts';
import daysCountdown from 'utils/days-countdown';
import cookies from 'utils/cookies';

import { type MSAConsent } from './consent';

const cookieExpiration = new Date();

// 1 day expiration for the cookie
cookieExpiration.setDate(cookieExpiration.getDate() + 1);

const cookieInfo = {
  name: cookies.nameCreator('functional', 'serviceAgreementLastSeen'),
  attributes: (): CookieAttributes => {
    return {
      expires: cookieExpiration,
      domain: cookies.domain(),
    };
  },
};

const mapConsentToConsentItem = (consent: Consent | undefined): MSAConsent => {
  if (consent === undefined) {
    return {
      id: '',
      isSigned: false,
      gracePeriodDays: 0,
      expiredGracePeriod: true,
    };
  }

  const shouldBeSignedUntil = consent.shouldBeSignedUntil ? new Date(consent.shouldBeSignedUntil) : new Date();
  const gracePeriodDays = daysCountdown(shouldBeSignedUntil.getTime());

  return {
    id: consent.id,
    isSigned: Boolean(consent.isSigned),
    gracePeriodDays,
    expiredGracePeriod: !consent.isSigned && gracePeriodDays < 1,
  };
};

const setConsentCookie = (userId: number): void => {
  Cookies.set(cookieInfo.name, userId.toString(), cookieInfo.attributes());
};

const isConsentCookieMatching = (userId: number): boolean => {
  const matching = userId.toString() === Cookies.get(cookieInfo.name);

  if (!matching) {
    Cookies.remove(cookieInfo.name);
  }

  return matching;
};

export { mapConsentToConsentItem, setConsentCookie, isConsentCookieMatching };
