import { switchMap, map, tap, distinctUntilChanged, catchError, of, defer } from 'rxjs';
import type { Observable } from 'rxjs';
import { getDirectPayoutAvailability } from 'services/api/proxy-direct-payout/inner';
import { Store } from 'store';
import user from 'store/user';
import notifyUserOnError from 'store/utils/notify-user-on-error';
import filterWhileNullish from 'utils/rxjs/filter-while-nullish';
import progressiveRetry from 'utils/rxjs/progressive-retry';

interface DirectPayoutStudioStore {
  isAvailable: boolean;
}

class DirectPayoutStudio extends Store<DirectPayoutStudioStore | undefined> {
  source$ = defer(() => user.onViewTypeChangeToStudio$).pipe(
    tap(() => {
      super.meta.setLoading(true);
    }),
    switchMap(({ viewTypeId }) =>
      getDirectPayoutAvailability({
        headers: {
          'X-Actor-Type': 'studio',
          'X-Actor-Id': viewTypeId!,
        },
      }).pipe(
        progressiveRetry(),
        notifyUserOnError({ type: 'error', message: 'request-failed', onClick: () => this.reset() }),
        map(({ data }) => data.data),
        catchError(() => of({ isAvailable: false }))
      )
    )
  );

  closeOnSignal$ = user.onViewTypeChangeToModel$;

  constructor() {
    super({ name: 'direct-payout-studio', initialState: undefined });
  }

  get onChange$(): Observable<DirectPayoutStudioStore> {
    return super.onChange$.pipe(filterWhileNullish());
  }

  get onAvailabilityChange$(): Observable<boolean> {
    return this.onChange$.pipe(
      distinctUntilChanged(),
      map(({ isAvailable }) => isAvailable)
    );
  }
}

export type { DirectPayoutStudioStore };
export default new DirectPayoutStudio();
