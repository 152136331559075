import { BehaviorSubject } from 'rxjs';

import type { InternalRoute, Route } from '../contracts';

import mapToInternalRoutes from './map-to-internal-routes';
import matchRoutes from './match-routes';

type Grouped<T> = {
  [K: string]: Array<T>;
};

interface RouteStore {
  sortedByScore: Array<InternalRoute>;
  byName: Grouped<InternalRoute>;
  allNames: Array<string>;
}

interface RoutesAssistant {
  findMatches(pathname: string): Array<InternalRoute>;
  findRoutesByName(name: InternalRoute['name']): Array<InternalRoute>;
  getRoutesName(): Array<string>;
  readonly size: number;
}

const map = (routes: Array<Route>): RouteStore => {
  const internalRoutes = mapToInternalRoutes(routes);

  const groupedByNamed = Object.groupBy(internalRoutes, (route) => route.name) as Grouped<InternalRoute>;

  return {
    sortedByScore: internalRoutes,
    byName: groupedByNamed,
    allNames: Object.keys(groupedByNamed),
  };
};

const createRoutesAssistant = (routes: Array<Route>): RoutesAssistant => {
  const store$ = new BehaviorSubject<RouteStore>(map(routes));
  const cache = new Map<string, Array<InternalRoute>>();

  return {
    findMatches(pathname: string): Array<InternalRoute> {
      if (cache.has(pathname)) {
        return cache.get(pathname)!;
      }

      const matches = matchRoutes(store$.value.sortedByScore, pathname, { trailing: true });

      cache.set(pathname, matches);

      return matches;
    },
    findRoutesByName(name: InternalRoute['name']): Array<InternalRoute> {
      return store$.value.byName[name] || [];
    },
    getRoutesName() {
      return store$.value.allNames;
    },
    get size() {
      return store$.value.sortedByScore.length;
    },
  };
};

export type { RoutesAssistant };
export default createRoutesAssistant;
