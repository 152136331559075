import type { FavouriteAddedNotification } from 'services/api/notifications-service/data-contracts';
import type { Loyalty } from 'components/loyalty-mask/contracts';

import messages from './favourite.i18n';
import type { Mapping } from './contracts';
import { intlLocaleDefaultsRTF, mountMessengerLink, mountAvatar } from './utils';

const favouriteAdded: Mapping<FavouriteAddedNotification> = (response) => {
  const {
    member: { memberNick, loyalty, memberId },
    entanglement: { level, threadId },
  } = response;

  return {
    title: [
      messages.pages.layout.header.notifications.mappings.favourite.added,
      {
        ...intlLocaleDefaultsRTF,
        memberNick,
      },
    ],
    avatar: mountAvatar.loyalty(memberNick, {
      level: loyalty?.loyaltyName as Loyalty['level'],
      isVisible: loyalty?.isVisible,
    }),
    handleClick: mountMessengerLink(level, memberId, threadId),
  };
};

export { favouriteAdded };
