import React from 'react';
import type { Route } from 'contracts';

import { StepRouteParam } from '../../../contracts';
import parentId from '../../../@@global/id';

import id from './id';

const AsyncPage = React.lazy(() => import('../account-type'));
const AsyncSkeleton = React.lazy(() => import('../account-type.skeleton'));

const routes: Route = {
  name: id,
  parent: parentId,
  path: `/{step:list(${StepRouteParam.AccountType}|${StepRouteParam.CategoryAndDisplayName})}`,
  element: <AsyncPage rejected={false} onNextStep={() => null} />,
  skeleton: <AsyncSkeleton />,
  meta: {
    layout: false,
  },
};

export default routes;
