import type { HashParameters } from 'contracts';

/**
 * @example
 * const hashParameters = ['firstLogin', 'tour']
 * const params = { tour: true, firstLogin: true };
 * compileHashParameters(queryStrings, params); // returns '#firstLogin#tour'
 */
const compileHashParameters = (
  hashParameters: HashParameters = [],
  params: Record<string, string | number | boolean | undefined> = {}
): string => {
  if (!hashParameters?.length) {
    return '';
  }

  const hash = hashParameters.filter((key) => params[key] === true || params[key] === 'true').join('#');

  return hash?.length ? `#${hash}` : '';
};

export default compileHashParameters;
