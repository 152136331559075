import is from 'utils/is';

import match, { type MatchOptions } from '../pathway/match';
import type { InternalRoute } from '../contracts';

const matchRoutes = (routes: Array<InternalRoute>, pathname: string, options?: MatchOptions): Array<InternalRoute> => {
  const matches: Array<InternalRoute> = [];

  if (!routes?.length) return matches;

  let result = routes.find((route) => match(route.path, options)(pathname).match);

  while (!is.nullish(result)) {
    matches.unshift(result);

    const { parent } = result;

    result = routes.find((route) => route.name === parent);
  }

  return matches;
};

export default matchRoutes;
