import { link } from 'services/navigation';
import authorization from 'services/authorization';
import { IconList } from 'components/icon';
import type { NavigationMenuItem } from 'contracts';
import happen from 'utils/happen';
import date from 'utils/date';

import messages from '../performance.i18n';

import id from './id';

const menu: NavigationMenuItem = {
  entries: [
    {
      name: id,
      label: messages.pages.performance.navigationMenu.label,
      icon: { active: IconList.performanceSolid, inactive: IconList.performanceOutline },
      weight: 6,
      link: () => link[id](),
      visibility$: authorization.getAuthorization$(id),
      new: () =>
        happen(new Date()).between(date('2024-03-25').toLuxAt('11:00:00'), date('2024-05-24').toLuxAt('11:00:00')),
    },
  ],
  activates: {
    [id]: [id],
  },
};

export default menu;
