import compile, { type CompileOptions } from 'services/router/pathway/compile';
import type { History } from 'history';
import type { HistoryLocation } from 'contracts';
import type { InternalRoute } from 'services/router/contracts';

import compileHashParameters from './compile-hash-parameters';
import compileSearchParameters from './compile-search-parameters';
import createLocationDescriptor from './create-location-descriptor';

type CompileRouteToLocationDescriptor = (
  history: History,
  route: InternalRoute
) => (params?: Record<string, string | number | boolean | undefined>) => HistoryLocation;

const compileRouteToLocationDescriptor: CompileRouteToLocationDescriptor = (
  history,
  route,
  options?: CompileOptions
) => {
  return (params = {}) => {
    const pathname = compile(route.path, options)(params);
    const searchParams = compileSearchParameters(route.searchParams, params);
    const hashParams = compileHashParameters(route.hashParams, params);

    return createLocationDescriptor(history, pathname, searchParams, hashParams);
  };
};

export default compileRouteToLocationDescriptor;
