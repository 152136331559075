import React from 'react';
import type { Route } from 'contracts';

import parentId from '../../@@global/id';

import id from './id';

const AsyncPage = React.lazy(() => import('../traffic-score'));

const routes: Route = {
  parent: parentId,
  name: id.performanceTrafficScore,
  path: '/traffic-score',
  element: <AsyncPage />,
};

export default routes;
