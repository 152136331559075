import React from 'react';
import { createPortal } from 'react-dom';
import cn from 'utils/class-name';
import toArray from 'utils/to-array';
import type { StyledElement, TestAutomation } from 'contracts';

import styles from './portal.module.scss';

interface PortalElement extends StyledElement, TestAutomation {
  onClick?: HTMLDivElement['onclick'];
  children: React.ReactNode;
}

const Portal: React.FunctionComponent<PortalElement> = (props) => {
  const { onClick, testId, className, style, children } = props;

  const portal = React.useMemo<HTMLDivElement>(() => {
    return document.createElement('div');
  }, []);

  React.useLayoutEffect(() => {
    portal.onclick = function OnClick(this, event) {
      event.stopPropagation();

      onClick?.call(this, event);
    };

    document.body.append(portal);

    return (): void => {
      portal.remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (testId) {
      portal.setAttribute('data-testid', testId);
    }

    portal.className = cn(...toArray(className), styles.portal);

    if (style) {
      Object.keys(style).forEach((rule) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        portal.style[rule] = style[rule];
      });
    }
  }, [testId, className, style, portal]);

  return createPortal(children, portal);
};

export type { PortalElement };
export default Portal;
