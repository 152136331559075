import { defineMessages } from 'react-intl';
import type { LocalePerProduct } from 'contracts';

const seo: LocalePerProduct = {
  livejasmin: defineMessages({
    title: {
      id: 'pages.bestOf.holidayAwards.seo.livejasmin.title',
      defaultMessage: 'Holiday Awards',
    },
    description: {
      id: 'pages.bestOf.holidayAwards.seo.livejasmin.description',
      defaultMessage: 'Holiday Awards',
    },
  }),
  oranum: defineMessages({
    title: {
      id: 'pages.bestOf.holidayAwards.seo.oranum.title',
      defaultMessage: 'Holiday Awards',
    },
    description: {
      id: 'pages.bestOf.holidayAwards.seo.oranum.description',
      defaultMessage: 'Holiday Awards',
    },
  }),
};

export default {
  pages: {
    bestOf: {
      holidayAwards: {
        seo,
        navigationMenu: {
          ...defineMessages({
            label: {
              id: 'pages.bestOf.holidayAwards.navigationMenu.label',
              defaultMessage: 'Holiday Awards',
            },
          }),
        },
      },
    },
  },
};
