import React from 'react';
import toArray from 'utils/to-array';
import useBodyScrollLock from 'enhancers/use-body-scroll-lock';
import type { StyledElement, AriaAttributes, TestAutomation } from 'contracts';
import ScrollShadow from 'components/scroll-shadow';

import Html from '../html';
import Icon, { IconList } from '../icon';
import Portal from '../portal';

import styles from './modal.module.scss';

interface ModalAPI {
  close: () => void;
}

interface ModalElement extends StyledElement, AriaAttributes, TestAutomation {
  width?: number;
  maxWidth?: number;
  minWidth?: number;
  escapable?: boolean;
  borderless?: boolean;
  fullscreen?: boolean | 'vertical' | 'horizontal';
  onClose: () => void;
  children: React.ReactNode;
}

const Modal = React.forwardRef<ModalAPI, ModalElement>((props, ref) => {
  const {
    testId,
    width,
    maxWidth,
    minWidth,
    escapable = true,
    borderless = false,
    fullscreen = false,
    className,
    style,
    children,
    onClose,
    ...rest
  } = props;

  const stopBubbling = React.useCallback((event: React.MouseEvent<HTMLDivElement>): void => {
    event.stopPropagation();
  }, []);

  const escape = React.useCallback((): void => {
    if (!escapable) return;

    onClose();
  }, [escapable, onClose]);

  React.useImperativeHandle(ref, () => ({
    close: escape,
  }));

  useBodyScrollLock(true);

  return (
    <Portal>
      <Html.div testId={testId && `${testId}-scrim`} className={styles.scrim} onClick={escape}>
        <Html.div
          testId={testId}
          role="dialog"
          aria-modal="true"
          className={['rounded-1', styles.modal, borderless && styles.modalBorderless, ...toArray(className)]}
          style={{ ...style, width, maxWidth, minWidth }}
          arias={rest}
          onClick={stopBubbling}
        >
          {escapable && !borderless && (
            <Html.button
              testId={testId && `${testId}-close`}
              className={[styles.close, 'd-block px-3 py-3']}
              onClick={escape}
            >
              <Icon name={IconList.closeOutline} className={styles.icon} />
            </Html.button>
          )}
          <ScrollShadow testId={testId} direction="vertical">
            <Html.div
              className={[
                styles.content,
                'px-12',
                (fullscreen === true || fullscreen === 'vertical') && 'mh-100',
                (fullscreen === true || fullscreen === 'horizontal') && 'mw-100',
              ]}
              testId={testId && `${testId}-content`}
            >
              {children}
            </Html.div>
          </ScrollShadow>
        </Html.div>
      </Html.div>
    </Portal>
  );
});

export type { ModalElement, ModalAPI };
export default Modal;
