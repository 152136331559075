import { charToTokenType, tokenTypeCharList } from './utils/token';
import type { Token } from './contracts';

// Lexer function
const lexer = (route: string): Array<Token> => {
  const tokens: Array<Token> = [];
  const { length } = route;
  let i = 0;

  while (i < length) {
    const iterationSafeguard = i;
    const char = route[i];
    const match = charToTokenType[char];

    switch (match) {
      case 'EscapedChar': {
        if (i + 1 < length) {
          // Include the backslash in the value
          tokens.push({ type: 'EscapedChar', value: `\\${route[i + 1]}`, position: i });
          i += 2;
          break;
        }

        throw new Error(`[Lexer] Unexpected end of input after escape character at index ${i + 1}`);
      }

      default: {
        // Token type match
        if (match) {
          tokens.push({ type: match, position: i });
          i += 1;
          break;
        }

        // Identifier or Literal
        let value = '';
        const startPos = i;

        while (i < length && !tokenTypeCharList.includes(route[i])) {
          value += route[i];
          i += 1;
        }

        tokens.push({ type: 'Identifier', value, position: startPos });
        break;
      }
    }

    // **Iteration Safeguard Check**
    if (i === iterationSafeguard) {
      throw new Error(
        `[Lexer] Infinite loop detected: index 'i' did not increment at position ${i}. Current character: '${route[i]}'`
      );
    }
  }

  tokens.push({ type: 'EndOfInput', position: i });

  return tokens;
};

export default lexer;
