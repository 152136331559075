import React from 'react';
import type { Route } from 'contracts';

import id from './id';

const AsyncPage = React.lazy(() => import(/* webpackPreload: true */ '../logout'));

const routes: Route = {
  name: id,
  path: '/{lang?}/logout',
  element: <AsyncPage />,
  meta: {
    layout: false,
  },
};

export default routes;
