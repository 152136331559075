import React from 'react';
import type { Route } from 'contracts';

import parentId from '../../@@global/id';

import id from './id';

const AsyncPage = React.lazy(() => import('../transactions'));
const AsyncSkeleton = React.lazy(() => import('../transactions.skeleton'));

const routes: Route[] = [
  {
    parent: parentId.payingMembersDetails,
    name: id.payingMembersTransactions,
    path: '/transactions',
    element: <AsyncPage />,
    skeleton: <AsyncSkeleton />,
  },
];

export default routes;
