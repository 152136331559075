import { map } from 'rxjs';
import { IconList } from 'components/icon';
import { link } from 'services/navigation';
import type { NavigationMenuItem } from 'contracts';
import authorization from 'services/authorization';
import user from 'store/user';

import studioMessages from '../studio/studio.i18n';
import modelMessages from '../model/model.i18n';

import id from './id';

const menu: NavigationMenuItem = {
  entries: [
    {
      name: id,
      label: modelMessages.pages.payout.directPayout.model.navigationMenu.label,
      icon: { active: IconList.dollarSolid, inactive: IconList.dollarOutline },
      link: () => link[id](),
      weight: 26,
      visibility$: authorization.getAuthorization$(id)?.pipe(map((auth) => auth && user.isModelView())),
    },
    {
      parent: 'payout',
      name: id,
      label: studioMessages.pages.payout.directPayout.studio.navigationMenu.label,
      link: () => link[id](),
      weight: 4,
      visibility$: authorization.getAuthorization$(id)?.pipe(map((auth) => auth && user.isStudioView())),
    },
  ],
  activates: {
    [id]: [id],
  },
};

export default menu;
