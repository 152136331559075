import { switchMap, map, tap, filter, iif, of, defer, type Observable } from 'rxjs';
import { getConsentList } from 'services/api/proxy-consent/consent';
import { PolicyTypeEnum, UserRoleEnum, type Consent as ConsentType } from 'services/api/proxy-consent/data-contracts';
import consentService from 'services/consent';
import { Store } from 'store';
import performer from 'store/performer';
import account from 'store/account';
import notifyUserOnError from 'store/utils/notify-user-on-error';
import progressiveRetry from 'utils/rxjs/progressive-retry';
import filterWhileNullish from 'utils/rxjs/filter-while-nullish';
import type { ConsentDetails } from 'services/consent/contracts';

import { isConsentCookieMatching, mapConsentToConsentItem, setConsentCookie } from './utils';

interface MSAConsent {
  id: string;
  isSigned: boolean;
  gracePeriodDays: number;
  expiredGracePeriod: boolean;
}

type ConsentStore = MSAConsent | undefined;

class Consent extends Store<ConsentStore> {
  source$ = performer.onChange$
    .pipe(
      filter((performerData) => Boolean(performerData?.personIds?.[0])),
      map(
        (performerData) =>
          // If couples, use the correct personID, otherwise get the first one in case of studio
          performerData.personIds.find((personId) => personId === account.data?.userId) ?? performerData.personIds[0]
      )
    )
    .pipe(
      tap(() => {
        super.meta.setLoading(true);
      }),
      switchMap((userId) =>
        iif(
          () => isConsentCookieMatching(userId),
          of({
            id: '',
            isSigned: true,
            gracePeriodDays: 1,
            expiredGracePeriod: false,
          }),
          defer(() =>
            getConsentList(
              PolicyTypeEnum.Service,
              { userIds: [userId] },
              {
                headers: {
                  'X-Actor-Id': account.data?.userId ?? '',
                  'X-Actor-Type': 'user',
                },
              }
            ).pipe(
              progressiveRetry(),
              notifyUserOnError({ type: 'error', message: 'request-failed', onClick: () => this.reset() }),
              map((response) => response.data.data.items),
              map((items) => items?.[0]),
              switchMap((consentItem) =>
                iif<ConsentDetails, ConsentType>(
                  () => consentItem === undefined && account.data!.userId === userId,
                  consentService.createConsent$(
                    PolicyTypeEnum.Service,
                    account.data!.userId,
                    UserRoleEnum.PerformerPerson
                  ),
                  of(consentItem as ConsentType)
                )
              ),
              map((consentItem) => mapConsentToConsentItem(consentItem)),
              tap((consent) => {
                if (consent.isSigned) {
                  setConsentCookie(account.data!.userId);
                }
              })
            )
          )
        )
      )
    );

  constructor() {
    super({ name: 'msa-consent', initialState: undefined });
  }

  get onChange$(): Observable<MSAConsent> {
    return super.onChange$.pipe(filterWhileNullish());
  }
}

export type { ConsentStore, MSAConsent };
export default new Consent();
