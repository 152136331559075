import React from 'react';
import { createRoot } from 'react-dom/client';
import settings from 'configurations/application';
import { init as initSentry } from 'services/sentry';
import { init as initHotjar } from 'services/hotjar';
import initGA, { activate as activateGA, activateWebVitals } from 'services/google-analytics/init';
import Root from 'pages/root';
// eslint-disable-next-line import/no-unassigned-import
import 'services/feature-toggle';

// eslint-disable-next-line import/no-unassigned-import
import './polyfills';
import './styles/reset.scss';
import './styles/grid-system.scss';
import './styles/base.scss';

if (settings.envVars.wpp) {
  // eslint-disable-next-line camelcase
  __webpack_public_path__ = settings.envVars.wpp;
}

initSentry();
initGA();
activateWebVitals();
activateGA();
initHotjar();

createRoot(document.getElementById(settings.react.rootDivId)!).render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>
);
