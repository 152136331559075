import is from 'utils/is';

import tokenize from './tokenize';
import validateConstraints from './validate-constraints';
import escapeRegex from './utils/escape-regex';
import { isLiteralToken, isParameterToken } from './utils/segment-filters';
import * as constraintMethods from './constraints';
import type { RouteSegment, ParameterSegment, MatchedParam } from './contracts';

interface MatchOptions {
  /**
   * The default delimiter for segments. (default: `'/'`)
   */
  delimiter?: string;
  /**
   * Allows optional trailing delimiter to match. (default: `true`)
   */
  trailing?: boolean;
}

const defaultOptions: Required<MatchOptions> = {
  delimiter: '/',
  trailing: true,
};

// Function to generate regex pattern for a parameter segment
const getParamPattern = (paramSegment: ParameterSegment): string => {
  if (!paramSegment.constraints.length) {
    return paramSegment.wildcard ? '.*' : '[^/]+';
  }

  let paramPattern = '';

  for (let i = 0; i < paramSegment.constraints.length; i += 1) {
    const constraint = paramSegment.constraints[i];
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const constraintRegExp = constraintMethods[constraint.type]?.toRegExp(constraint.params);

    if (constraintRegExp) {
      paramPattern = constraintRegExp as string;

      continue;
    }

    paramPattern = '[^/]+';
  }

  return paramPattern;
};

// Simplified buildRegexFromRoute function
const buildRegexFromRoute = (
  segments: ReadonlyArray<RouteSegment>,
  options: MatchOptions = {}
): { pattern: RegExp; paramNames: Array<string> } => {
  const { delimiter, trailing } = { ...defaultOptions, ...options } as Required<MatchOptions>;
  const paramNames: Array<string> = [];
  const regexParts: Array<string> = [];
  const ROUTE_DELIMITER = new RegExp(`${delimiter}$`);

  for (let i = 0; i < segments.length; i += 1) {
    const segment = segments[i];

    if (isParameterToken(segment)) {
      const paramName = segment.name;

      paramNames.push(paramName);

      // Generate the regex pattern for the parameter segment
      const paramPattern = getParamPattern(segment);

      // Handle optional parameters with preceding literals
      if (segment.optional) {
        // Include any preceding literal in the optional group
        let precedingLiteral = '';
        // const endsWithDelimiter = ROUTE_DELIMITER.test(precedingLiteral);

        if (i > 0 && isLiteralToken(segments[i - 1])) {
          precedingLiteral = escapeRegex(segments[i - 1].value!.replace(ROUTE_DELIMITER, ''));

          // Remove the preceding literal from the regex parts
          regexParts.pop();
        }

        if (!regexParts.length) {
          // Enforce matching of the first part from the literal segment
          // This excludes sequential optional params to match literal segment
          regexParts.push(`${precedingLiteral}${delimiter}?(?<${paramName}>${paramPattern})?`);

          continue;
        }

        // Encapsulate the remaining parts from the literal segment within RegExp group
        regexParts.push(`(?:${precedingLiteral}${delimiter}?)(?<${paramName}>${paramPattern})?`);
        continue;
      }

      regexParts.push(`(?<${paramName}>${paramPattern})`);

      continue;
    }

    if (isLiteralToken(segment)) {
      regexParts.push(escapeRegex(segment.value!));
    }
  }

  if (trailing) {
    regexParts.push(`${delimiter}?`);
  }

  const pattern = new RegExp(`^${regexParts.join('')}$`);

  return { pattern, paramNames };
};

// Function to match the route pattern with a path
const match = <T extends MatchedParam = MatchedParam>(route: string, options: MatchOptions = {}) => {
  const segments = tokenize(route);
  const { pattern, paramNames } = buildRegexFromRoute(segments, options);

  return (path: string): { match: boolean; params: T | null } => {
    const result = pattern.exec(path);

    if (!result) {
      return {
        match: false,
        params: null,
      };
    }

    const params = {} as T;

    for (let i = 0; i < paramNames.length; i += 1) {
      const paramName = paramNames[i];
      const value = result.groups ? result.groups[paramName] : undefined;

      // Find the parameter segment to get constraints
      const paramSegment = segments.filter(isParameterToken).find((token) => token.name === paramName)!;

      if (!is.nullish(value)) {
        try {
          validateConstraints(paramName, value, paramSegment.constraints);
          // @ts-expect-error: ignoring type inference
          params[paramName] = value;
        } catch {
          return {
            match: false,
            params: null,
          };
        }
      }
    }

    return {
      match: true,
      params,
    };
  };
};

export type { MatchOptions };
export default match;
