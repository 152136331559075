import React from 'react';
import toast from 'services/toast';
import type {
  ToastInstanceId,
  SuccessToastNotification,
  WarningToastNotification,
  ErrorToastNotification,
} from 'contracts';

interface UseToast {
  success: (overrides: Omit<SuccessToastNotification, 'type'>) => symbol;
  warning: (id: ToastInstanceId, overrides?: Partial<Omit<WarningToastNotification, 'id'>>) => symbol;
  error: (id: ToastInstanceId, overrides?: Partial<Omit<ErrorToastNotification, 'id' | 'type'>>) => symbol;
}

const useToast = (): UseToast => {
  const success: UseToast['success'] = React.useCallback((overrides) => {
    toast.dispatch({
      ...overrides,
      type: 'success',
    });

    return overrides.id;
  }, []);

  const warning: UseToast['warning'] = React.useCallback((id, overrides) => {
    toast.dispatch({
      message: 'generic',
      ...overrides,
      id,
      type: 'warning',
    });

    return id;
  }, []);

  const error: UseToast['error'] = React.useCallback((id, overrides) => {
    toast.dispatch({
      message: 'generic',
      ...overrides,
      id,
      type: 'error',
    });

    return id;
  }, []);

  return { success, warning, error };
};

export default useToast;
