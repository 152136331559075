import React from 'react';
import toArray from 'utils/to-array';
import is from 'utils/is';
import type { StyledElement, AriaAttributes, TestAutomation, RenderIconOrElement, Size } from 'contracts';
import withGATracking from 'enhancers/with-ga-tracking';

import Html from '../html';
import renderElement from '../utils/render-element';

import styles from './icon-hover.module.scss';

interface IconHoverElement extends StyledElement, AriaAttributes, TestAutomation {
  active: RenderIconOrElement;
  inactive: RenderIconOrElement;
  forceActive?: boolean;
  onClick?: React.MouseEventHandler<HTMLSpanElement>;
  children?: React.ReactNode;
  orientation?: 'left' | 'right';
  size?: Size;
}

const IconHover: React.FunctionComponent<IconHoverElement> = (props) => {
  const {
    testId,
    className,
    style,
    inactive,
    active,
    forceActive,
    onClick,
    children,
    orientation = 'left',
    size,
    ...rest
  } = props;

  return (
    <Html.span
      testId={testId}
      className={[
        styles.iconHover,
        forceActive && styles.forceActive,
        'cursor-pointer',
        orientation === 'right' && styles.reverseOrientation,
        ...toArray(className),
      ]}
      style={style}
      arias={rest}
      onClick={onClick}
    >
      {renderElement(inactive, size, {
        className:
          is.string(inactive) || is.func(inactive)
            ? styles.inactive
            : [...(inactive.props?.className ?? []), styles.inactive],
      })}
      {renderElement(active, size, {
        className:
          is.string(active) || is.func(active) ? styles.active : [...(active.props?.className ?? []), styles.active],
      })}
      {children}
    </Html.span>
  );
};

export type { IconHoverElement };
export default withGATracking(IconHover, { action: 'click', handler: 'onClick' });
