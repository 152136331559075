import type { PerformerChannelContentRejectedNotification } from 'services/api/notifications-service/data-contracts';
import navigation from 'services/navigation';

import messages from './channel.i18n';
import type { Mapping } from './contracts';
import { closeNotificationPopover, mountAvatar } from './utils';

const channelContentRejected: Mapping<PerformerChannelContentRejectedNotification> = (notification) => {
  const {
    content: { media, contentType } = {},
  } = notification;
  const { hasPreview = false } = media!;

  const isNote = contentType === 'note';

  if (isNote) {
    return {
      title: [messages.pages.layout.header.notifications.mappings.channel.content.rejected.noteTitle],
      description: [messages.pages.layout.header.notifications.mappings.channel.content.rejected.message],
      avatar: mountAvatar.system(),
      handleClick() {
        closeNotificationPopover();

        return navigation.myContentEditor({ type: 'free', notes: true });
      },
    };
  }

  return {
    title: [messages.pages.layout.header.notifications.mappings.channel.content.rejected.title],
    description: [
      hasPreview
        ? messages.pages.layout.header.notifications.mappings.channel.content.rejected.message
        : messages.pages.layout.header.notifications.mappings.channel.content.rejected.deleted,
    ],
    avatar: mountAvatar.system(),
    preview: hasPreview ? (media?.thumbnail?.uri as string) : true,
    handleClick:
      !hasPreview && !isNote
        ? undefined
        : () => {
            closeNotificationPopover();

            return navigation.onlineSupportChat({ topic: 'picture-or-video' });
          },
  };
};

export { channelContentRejected };
