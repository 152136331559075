import React from 'react';
import { useIntl } from 'react-intl';
import application from 'services/application';
import location from 'services/router/location';
import Html from 'components/html';
import Spinner from 'components/loading';
import Shimmer from 'components/shimmer';
import type { StyledElement } from 'contracts';
import toArray from 'utils/to-array';

import Page from '../../layout/page';
import { shouldDisplayLayout } from '../../layout/container/utils';

import messages from './loading.i18n';
import { testId } from './loading.settings';
import styles from './loading.module.scss';

interface LoadingElement extends StyledElement {
  center?: boolean;
  hidden?: boolean;
  onMount?: () => void;
  onUnmount?: () => void;
}

const Loading: React.FunctionComponent<LoadingElement> = (props) => {
  const { center = true, className, style, hidden = false, onMount, onUnmount } = props;
  const { formatMessage } = useIntl();

  const showPageTitle = shouldDisplayLayout(location.current);

  React.useEffect(
    function Effect() {
      application.startLoading(Effect);

      onMount?.();

      return () => {
        application.stopLoading(Effect);
        onUnmount?.();
      };
    },
    [onMount, onUnmount]
  );

  if (hidden) return null;

  return (
    <Page
      title={
        showPageTitle && (
          <Shimmer theme="light" textClip loading>
            {formatMessage(messages.pages.shared.loading.message)}
          </Shimmer>
        )
      }
    >
      <Html.div
        testId={testId.loading}
        className={[styles.loading, 'row', center && 'h-100', 'align-items-center', ...toArray(className)]}
        style={style}
      >
        <Html.div className={['d-inline-block', 'my-5']}>
          <Spinner variant="dot" delay={0} />
          <Html.div typography="body2" className="text-center text-uppercase mt-4">
            <Shimmer theme="light" textClip loading>
              {formatMessage(messages.pages.shared.loading.message)}
            </Shimmer>
          </Html.div>
        </Html.div>
      </Html.div>
    </Page>
  );
};

export default Loading;
