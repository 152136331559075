import React from 'react';
import type { Route } from 'contracts';

import id from './id';

const AsyncPage = React.lazy(() => import('pages/scheduled-shows/scheduled-shows'));
const AsyncSkeletonPage = React.lazy(() => import('../scheduled-shows.skeleton'));

const routes: Route[] = [
  {
    name: id,
    path: '/{lang?}/scheduled-shows',
    element: <AsyncPage />,
    skeleton: <AsyncSkeletonPage />,
  },
];

export default routes;
