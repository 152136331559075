import type { NavigationMenuItem } from 'contracts';
import { link } from 'services/navigation';
import authorization from 'services/authorization';
import legacyId from 'pages/redirection/legacy/@@global/id';

import messages from '../payinfo.i18n';

import id from './id';

const menu: NavigationMenuItem = {
  entries: [
    {
      name: id,
      parent: legacyId.payout,
      label: messages.pages.payout.payinfo.navigationMenu.label,
      link: () => link.payinfo(),
      weight: 5,
      visibility$: authorization.getAuthorization$('payinfo'),
    },
  ],
  activates: {
    [id]: [id],
  },
};

export default menu;
