import React from 'react';
import type { Route } from 'contracts';
import Outlet from 'services/router/outlet';

import id from './id';

const AsyncSkeletonPage = React.lazy(() => import('../paying-members.skeleton'));

const routes: Route = {
  name: id,
  path: '/{lang?}/statistics/paying-members',
  skeleton: <AsyncSkeletonPage />,
  element: <Outlet />,
};

export default routes;
