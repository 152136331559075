import type { FetchResponse, RequestParams } from 'services/fetch';
import fetch, { ContentType } from 'services/fetch';

import type {
  NotificationListResponse,
  NotificationPatchDocument,
  NotificationUnreadCountResponse,
} from './data-contracts';

/**
 * No description
*
 * @tags Notifications Service
 * @name GetNotificationList
 * @summary Gets the notifications for the performer.
 * @request GET:/v1/me/performers/{performerId}/notifications
 * @secure
 * @response `200` `NotificationListResponse`
 * @response `403` `{
    errors?: ({
  \** @example "d6a6cd3e6d3e22148ff37a8059421b17" *\
    correlationId?: string,
  \** @example "403" *\
    status?: string,
  \** @example "Forbidden" *\
    title?: string,
  \** @example 1002 *\
    code?: number,
  \** @example "Insufficient permissions" *\
    details?: string,

})[],

}` Forbidden error message
 * @response `404` `{
    errors?: ({
  \** @example "d6a6cd3e6d3e22148ff37a8059421b17" *\
    correlationId?: string,
  \** @example "404" *\
    status?: string,
  \** @example "Not found" *\
    title?: string,
  \** @example 1001 *\
    code?: number,
  \** @example "Resource not found" *\
    details?: string,

})[],

}` Not Found Error message

*/
export const getNotificationList = (
  performerId: number,
  query?: {
    /** Status of the notification. */
    'filter[status]'?: 'unread' | 'read';
    /** Type of the notification. */
    'filter[types]'?: (
      | 'my_content.commented'
      | 'my_content.liked'
      | 'my_content.image_folder.subscribed'
      | 'my_content.video.subscribed'
      | 'favourite.added'
      | 'performer.profile_picture.rejected'
      | 'performer.profile_picture.accepted'
      | 'performer.status.updated'
      | 'my_story.story.subscription.created'
      | 'competitor.rewarded'
      | 'performer.performer_referral.consent.accepted'
      | 'performer.performer_referral.consent.revoked'
      | 'performer.performer_referral.connection.created'
      | 'performer.performer_referral.connection.cut'
      | 'performer.action_requests.disabled'
      | 'performer.interactive_toy.disabled'
      | 'performer.hot_show.ended'
      | 'performer.battle.ended'
      | 'performer.battle.enabled'
      | 'performer.battle.disabled'
      | 'performer.story.item.rejected'
      | 'performer.channel.content.rejected'
      | 'performer.mobile_live.disabled'
      | 'performer.mobile_live.enabled'
      | 'performer.video_call.disabled'
      | 'performer.video_call.enabled'
      | 'competition.points.reminder'
      | 'competition.games.elimination'
      | 'performer.promotion.teaser.status.accepted'
      | 'performer.promotion.teaser.status.rejected'
      | 'performer.promotion.teaser.participant.added'
      | 'performer.promotion.teaser.participant.confirmation_required'
      | 'promotools.promotion_content.status.accepted'
      | 'promotools.promotion_content.status.rejected'
      | 'performer.performer_referral.share_income.overrode'
      | 'fanclub.member_subscribed'
      | 'fanclub.member_resubscribed'
      | 'fanclub.member_unsubscribed'
      | 'excluded.award.game'
      | 'performer.survey_notification'
      | 'achievement.level.changed'
      | 'achievement.points.changed'
      | 'performer.shop.payment_order.placed'
      | 'loyalfans.registration_status.updated'
      | 'instant_payouts.updated'
      | 'direct_payout.pending'
      | 'direct_payout.approved'
      | 'direct_payout.deleted'
      | 'direct_payout.disabled_by_support'
      | 'internal_partner.consent.revoked'
      | 'performer.hidden_face.setting.changed'
    )[];
    /**
     * The page of the result list.
     * @format int32
     * @default 1
     */
    'page[page]'?: number;
    /**
     * The maximum number of result to get.
     * @format int32
     * @min 1
     * @max 100
     * @default 50
     */
    'page[limit]'?: number;
    /** The cursor key of the next page. */
    'page[cursor]'?: string;
  },
  params: RequestParams = {}
): FetchResponse<NotificationListResponse> => {
  return fetch<NotificationListResponse>(`/v1/me/performers/${performerId}/notifications`, {
    method: 'GET',
    query: query,
    ...params,
  });
};

/**
 * No description
*
 * @tags Notifications Service
 * @name GetUnreadNotificationCount
 * @summary Gets the unread count of notifications for the performer.
 * @request GET:/v1/me/performers/{performerId}/notifications/unread-count
 * @secure
 * @response `200` `NotificationUnreadCountResponse`
 * @response `403` `void`

*/
export const getUnreadNotificationCount = (
  performerId: string,
  query?: {
    /** Type of the notification. */
    'filter[types]'?: (
      | 'my_content.commented'
      | 'my_content.liked'
      | 'my_content.image_folder.subscribed'
      | 'my_content.video.subscribed'
      | 'favourite.added'
      | 'performer.profile_picture.rejected'
      | 'performer.profile_picture.accepted'
      | 'performer.status.updated'
      | 'my_story.story.subscription.created'
      | 'competitor.rewarded'
      | 'performer.performer_referral.consent.accepted'
      | 'performer.performer_referral.consent.revoked'
      | 'performer.performer_referral.connection.created'
      | 'performer.performer_referral.connection.cut'
      | 'performer.action_requests.disabled'
      | 'performer.interactive_toy.disabled'
      | 'performer.hot_show.ended'
      | 'performer.battle.ended'
      | 'performer.battle.enabled'
      | 'performer.battle.disabled'
      | 'performer.story.item.rejected'
      | 'performer.channel.content.rejected'
      | 'performer.mobile_live.disabled'
      | 'performer.mobile_live.enabled'
      | 'performer.video_call.disabled'
      | 'performer.video_call.enabled'
      | 'competition.points.reminder'
      | 'competition.games.elimination'
      | 'performer.promotion.teaser.status.accepted'
      | 'performer.promotion.teaser.status.rejected'
      | 'performer.promotion.teaser.participant.added'
      | 'performer.promotion.teaser.participant.confirmation_required'
      | 'promotools.promotion_content.status.accepted'
      | 'promotools.promotion_content.status.rejected'
      | 'performer.performer_referral.share_income.overrode'
      | 'fanclub.member_subscribed'
      | 'fanclub.member_resubscribed'
      | 'fanclub.member_unsubscribed'
      | 'excluded.award.game'
      | 'performer.survey_notification'
      | 'achievement.level.changed'
      | 'achievement.points.changed'
      | 'performer.shop.payment_order.placed'
      | 'loyalfans.registration_status.updated'
      | 'instant_payouts.updated'
      | 'direct_payout.pending'
      | 'direct_payout.approved'
      | 'direct_payout.deleted'
      | 'direct_payout.disabled_by_support'
      | 'internal_partner.consent.revoked'
      | 'performer.hidden_face.setting.changed'
    )[];
  },
  params: RequestParams = {}
): FetchResponse<NotificationUnreadCountResponse> => {
  return fetch<NotificationUnreadCountResponse>(`/v1/me/performers/${performerId}/notifications/unread-count`, {
    method: 'GET',
    query: query,
    ...params,
  });
};

/**
 * No description
*
 * @tags Notifications Service
 * @name UpdateNotification
 * @summary Patch the notifications for the performer.
 * @request PATCH:/v1/me/performers/{performerId}/notifications/{notificationId}
 * @secure
 * @response `204` `void` OK
 * @response `403` `void`
 * @response `404` `void`

*/
export const updateNotification = <T>(
  notificationId: string,
  performerId: string,
  data: NotificationPatchDocument,
  params: RequestParams = {}
): FetchResponse<T> => {
  return fetch<T>(`/v1/me/performers/${performerId}/notifications/${notificationId}`, {
    method: 'PATCH',
    body: data,
    contentType: ContentType.Json,
    ...params,
  });
};

/**
 * No description
*
 * @tags Notifications Service
 * @name MarkAllAsRead
 * @summary Marks all notifications of a performer as read.
 * @request PATCH:/v1/me/performers/{performerId}/notifications/mark-all-as-read
 * @secure
 * @response `204` `void` OK
 * @response `403` `void`
 * @response `404` `void`
 * @response `500` `{
    errors?: ({
  \** @example "d6a6cd3e6d3e22148ff37a8059421b17" *\
    correlationId?: string,
  \** @example "500" *\
    status?: string,
  \** @example "Server error" *\
    title?: string,
  \** @example 1000 *\
    code?: number,
  \** @example "Unexpected error occurred" *\
    details?: string,

})[],

}` Error message

*/
export const markAllAsRead = <T>(
  performerId: string,
  query?: {
    /**
     * Creation date and time of the notification.
     * @format date-time
     * @example "2019-12-03T15:31:51+01:00"
     */
    'filter[olderThan]'?: string;
  },
  params: RequestParams = {}
): FetchResponse<T> => {
  return fetch<T>(`/v1/me/performers/${performerId}/notifications/mark-all-as-read`, {
    method: 'PATCH',
    query: query,
    ...params,
  });
};
