export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
};

export type Account = {
  __typename?: 'Account';
  email: Scalars['String']['output'];
  flags: AccountFlags;
  hasLimitedAccess: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  marketingCommunicationConsent: MarketingCommunicationConsent;
  personFirstName: Scalars['String']['output'];
  studio: Studio;
  type: AccountType;
};

export type AccountFlags = {
  __typename?: 'AccountFlags';
  emailConfirmed: Scalars['Boolean']['output'];
  isCompany: Scalars['Boolean']['output'];
  isLimitedAccess: Scalars['Boolean']['output'];
  isTestAccount: Scalars['Boolean']['output'];
  personalDataDownloadRequestStatus?: Maybe<Scalars['String']['output']>;
  registrationFlowId?: Maybe<Scalars['String']['output']>;
  registrationPending: Scalars['Boolean']['output'];
};

export enum AccountType {
  Model = 'model',
  Single = 'single',
  Studio = 'studio',
  User = 'user',
}

export enum ExclusiveBadgeStatus {
  Active = 'active',
  Available = 'available',
  Default = 'default',
  Disabled = 'disabled',
  Pending = 'pending',
  Rejected = 'rejected',
}

export enum Gender {
  Female = 'female',
  Male = 'male',
}

export type MarketingCommunicationConsent = {
  __typename?: 'MarketingCommunicationConsent';
  requestedAt?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Boolean']['output']>;
};

export type Me = {
  __typename?: 'Me';
  defaultPerformerId?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  persons: Array<Person>;
  studio?: Maybe<Studio>;
  studioId: Scalars['Float']['output'];
};

export type MusicLibraryAudio = {
  __typename?: 'MusicLibraryAudio';
  category: Scalars['String']['output'];
  description: Scalars['String']['output'];
  duration: Scalars['String']['output'];
  durationInSeconds: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  isFavorite: Scalars['Boolean']['output'];
  link: Scalars['String']['output'];
  markedAsFavoriteAt?: Maybe<Scalars['String']['output']>;
  subcategory: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type MusicLibraryAudioCategory = {
  __typename?: 'MusicLibraryAudioCategory';
  name: Scalars['String']['output'];
  subcategories: Array<Scalars['String']['output']>;
};

export enum MusicLibraryAudioCategoryDocumentStatusFilter {
  MwhValidationFailed = 'MWHValidationFailed',
  Converted = 'converted',
  Converting = 'converting',
  Deleted = 'deleted',
  Enabled = 'enabled',
  Pending = 'pending',
  PendingUpload = 'pending_upload',
  Rejected = 'rejected',
  Uploaded = 'uploaded',
}

export type MusicLibraryAudioCategoryRequest = {
  documentStatusFilters?: InputMaybe<Array<MusicLibraryAudioCategoryDocumentStatusFilter>>;
};

export type MusicLibraryAudioCategoryResponse = {
  __typename?: 'MusicLibraryAudioCategoryResponse';
  items: Array<MusicLibraryAudioCategory>;
};

export type MusicLibraryAudioCriterionInput = {
  field: MusicLibraryAudioRequestField;
  operator: Scalars['String']['input'];
  value?: InputMaybe<Scalars['String']['input']>;
  values?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type MusicLibraryAudioOrderByInput = {
  direction: MusicLibraryDirection;
  field: MusicLibraryAudioOrderField;
};

export enum MusicLibraryAudioOrderField {
  Category = 'category',
  CreatedAt = 'createdAt',
  DurationInSeconds = 'durationInSeconds',
  Id = 'id',
  MarkedAsFavoriteAt = 'markedAsFavoriteAt',
  Subcategory = 'subcategory',
  Title = 'title',
  UpdatedAt = 'updatedAt',
}

export enum MusicLibraryAudioRequestField {
  Category = 'category',
  CreatedAt = 'createdAt',
  Description = 'description',
  Duration = 'duration',
  Status = 'status',
  Subcategory = 'subcategory',
  Title = 'title',
  UpdatedAt = 'updatedAt',
}

export type MusicLibraryAudioRequestParameters = {
  criteria?: InputMaybe<Array<MusicLibraryAudioCriterionInput>>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  orderDirection?: InputMaybe<MusicLibraryDirection>;
  orderField?: InputMaybe<MusicLibraryAudioOrderField>;
};

export type MusicLibraryAudioResponse = {
  __typename?: 'MusicLibraryAudioResponse';
  limit: Scalars['Float']['output'];
  nodes?: Maybe<Array<MusicLibraryAudio>>;
  offset: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
};

export enum MusicLibraryDirection {
  Asc = 'asc',
  Desc = 'desc',
}

export type NamedStringProperty = {
  __typename?: 'NamedStringProperty';
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type Performer = {
  __typename?: 'Performer';
  category?: Maybe<PerformerCategory>;
  displayName: Scalars['String']['output'];
  flags: PerformerFlags;
  id: Scalars['ID']['output'];
  newbie: PerformerNewbieStatus;
  persons: Array<PerformerPerson>;
  profilePictures: Array<PerformerProfilePictures>;
  screenName: Scalars['String']['output'];
  status?: Maybe<PerformerStatus>;
  studio: Studio;
  studioId: Scalars['Float']['output'];
  unreadCounts: PerformerUnreadCounts;
};

export type PerformerProfilePicturesArgs = {
  statuses?: InputMaybe<Array<PerformerProfilePicturesStatus>>;
};

export type PerformerCategory = {
  __typename?: 'PerformerCategory';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  parent?: Maybe<PerformerCategory>;
};

export type PerformerFlags = {
  __typename?: 'PerformerFlags';
  birthdayNotification: Scalars['Boolean']['output'];
  canSeeStatistics: Scalars['Boolean']['output'];
  exclusiveBadgeStatus?: Maybe<ExclusiveBadgeStatus>;
  isOnline: Scalars['Boolean']['output'];
  isSelected: Scalars['Boolean']['output'];
  messengerReadReceipts: Scalars['Boolean']['output'];
  soldierAidCampaignParticipant: Scalars['Boolean']['output'];
};

export type PerformerNewbieStatus = {
  __typename?: 'PerformerNewbieStatus';
  disabledByAdmin: Scalars['Boolean']['output'];
  isNewbie: Scalars['Boolean']['output'];
  newbieUntil: Scalars['String']['output'];
};

export type PerformerPerson = {
  __typename?: 'PerformerPerson';
  birthDate: Scalars['String']['output'];
  countryCode: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  genderId: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  idNumber: Scalars['String']['output'];
  idTypeId?: Maybe<Scalars['Float']['output']>;
  nationalityId: Scalars['Float']['output'];
};

export type PerformerProfilePictures = {
  __typename?: 'PerformerProfilePictures';
  category: PerformerProfilePicturesCategory;
  createdAt: Scalars['String']['output'];
  crops: Array<PerformerProfilePicturesCrop>;
  hasAdminSeen: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isSelected: Scalars['Boolean']['output'];
  performerScreenName: Scalars['String']['output'];
  rating: PerformerProfilePicturesRating;
  status: PerformerProfilePicturesStatus;
  statusReasons: Array<Scalars['String']['output']>;
  tags: Array<PerformerProfilePicturesTag>;
  updatedAt: Scalars['String']['output'];
  versions: Array<PerformerProfilePicturesVersion>;
};

export type PerformerProfilePicturesArea = {
  __typename?: 'PerformerProfilePicturesArea';
  bottomRight: PerformerProfilePicturesCoordinate;
  topLeft: PerformerProfilePicturesCoordinate;
};

export enum PerformerProfilePicturesCategory {
  Erotic = 'erotic',
  Glamour = 'glamour',
  Undefined = 'undefined',
}

export type PerformerProfilePicturesCoordinate = {
  __typename?: 'PerformerProfilePicturesCoordinate';
  unit: Scalars['String']['output'];
  x: Scalars['Float']['output'];
  y: Scalars['Float']['output'];
};

export type PerformerProfilePicturesCrop = {
  __typename?: 'PerformerProfilePicturesCrop';
  area: PerformerProfilePicturesArea;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  profilePictureId: Scalars['String']['output'];
  source: Scalars['String']['output'];
};

export enum PerformerProfilePicturesRating {
  Bad = 'bad',
  Good = 'good',
  None = 'none',
}

export enum PerformerProfilePicturesStatus {
  Accepted = 'accepted',
  Cropping = 'cropping',
  Deleted = 'deleted',
  Inactive = 'inactive',
  MwhValidationFailed = 'mwh_validation_failed',
  Pending = 'pending',
  Rejected = 'rejected',
  Waiting = 'waiting',
}

export type PerformerProfilePicturesTag = {
  __typename?: 'PerformerProfilePicturesTag';
  createdAt: Scalars['String']['output'];
  group: PerformerProfilePicturesTagGroup;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  profilePictureId: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

export enum PerformerProfilePicturesTagGroup {
  Appearance = 'appearance',
  Willingness = 'willingness',
}

export type PerformerProfilePicturesVersion = {
  __typename?: 'PerformerProfilePicturesVersion';
  contentUri: Scalars['String']['output'];
  fileSize: Scalars['Float']['output'];
  format: Scalars['String']['output'];
  height: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  mainColor: Scalars['String']['output'];
  mediaKey: PerformerProfilePicturesVersionMediaKey;
  profilePictureId: Scalars['String']['output'];
  width: Scalars['Float']['output'];
};

export enum PerformerProfilePicturesVersionMediaKey {
  Parent = 'parent',
  ProfilePictureCrop_4_3_56_42Jpg = 'profile_picture_crop_4_3_56_42_jpg',
  ProfilePictureCrop_4_3_56_42Webp = 'profile_picture_crop_4_3_56_42_webp',
  ProfilePictureCrop_4_3_147_110Jpg = 'profile_picture_crop_4_3_147_110_jpg',
  ProfilePictureCrop_4_3_147_110Webp = 'profile_picture_crop_4_3_147_110_webp',
  ProfilePictureCrop_4_3_800_600Jpg = 'profile_picture_crop_4_3_800_600_jpg',
  ProfilePictureCrop_4_3_800_600Webp = 'profile_picture_crop_4_3_800_600_webp',
  ProfilePictureCrop_9_16_504_896Jpg = 'profile_picture_crop_9_16_504_896_jpg',
  ProfilePictureCrop_9_16_504_896Webp = 'profile_picture_crop_9_16_504_896_webp',
  ProfilePictureCrop_9_16_900_1600Jpg = 'profile_picture_crop_9_16_900_1600_jpg',
  ProfilePictureCrop_9_16_900_1600Webp = 'profile_picture_crop_9_16_900_1600_webp',
  ProfilePictureCrop_16_9_215_121Jpg = 'profile_picture_crop_16_9_215_121_jpg',
  ProfilePictureCrop_16_9_215_121Webp = 'profile_picture_crop_16_9_215_121_webp',
  ProfilePictureCrop_16_9_896_504Jpg = 'profile_picture_crop_16_9_896_504_jpg',
  ProfilePictureCrop_16_9_896_504Webp = 'profile_picture_crop_16_9_896_504_webp',
  ProfilePictureCrop_16_9_1600_900Jpg = 'profile_picture_crop_16_9_1600_900_jpg',
  ProfilePictureCrop_16_9_1600_900Webp = 'profile_picture_crop_16_9_1600_900_webp',
}

export enum PerformerStatus {
  Active = 'active',
  Closed = 'closed',
  Inactive = 'inactive',
  New = 'new',
  Pending = 'pending',
  Rejected = 'rejected',
  Suspended = 'suspended',
}

export type PerformerUnreadCounts = {
  __typename?: 'PerformerUnreadCounts';
  messages: PerformerUnreadCountsMessages;
  myContent: PerformerUnreadCountsMyContent;
  news: Scalars['Float']['output'];
};

export type PerformerUnreadCountsMessages = {
  __typename?: 'PerformerUnreadCountsMessages';
  member: Scalars['Float']['output'];
  support: Scalars['Float']['output'];
  system: Scalars['Float']['output'];
};

export type PerformerUnreadCountsMyContent = {
  __typename?: 'PerformerUnreadCountsMyContent';
  free: Scalars['Float']['output'];
  premium: Scalars['Float']['output'];
};

export type Person = {
  __typename?: 'Person';
  birthDate?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Gender>;
  guidedTourLevel?: Maybe<Scalars['Float']['output']>;
  hasBillingAddress?: Maybe<Scalars['Boolean']['output']>;
  hasBirthday?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  isGiftSendingDisabled?: Maybe<Scalars['Boolean']['output']>;
  properties: Array<NamedStringProperty>;
  zodiacSign?: Maybe<ZodiacSign>;
};

export type Query = {
  __typename?: 'Query';
  audio?: Maybe<MusicLibraryAudioResponse>;
  audioCategories?: Maybe<MusicLibraryAudioCategoryResponse>;
  me?: Maybe<Me>;
  performer?: Maybe<Performer>;
};

export type QueryAudioArgs = {
  parameters: MusicLibraryAudioRequestParameters;
};

export type QueryAudioCategoriesArgs = {
  parameters?: InputMaybe<MusicLibraryAudioCategoryRequest>;
};

export type QueryPerformerArgs = {
  id: Scalars['Int']['input'];
};

export type Studio = {
  __typename?: 'Studio';
  createdAt: Scalars['String']['output'];
  flags: StudioFlags;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  owner: Account;
  payoutActiveMethod?: Maybe<Scalars['String']['output']>;
  payoutCurrency?: Maybe<Scalars['String']['output']>;
  payoutLimit?: Maybe<Scalars['Float']['output']>;
  status: StudioStatus;
};

export type StudioFlags = {
  __typename?: 'StudioFlags';
  hasMultiplePerformers: Scalars['Boolean']['output'];
  payoutOptionAdded: Scalars['Boolean']['output'];
  studioCertified: Scalars['Boolean']['output'];
};

export enum StudioStatus {
  Active = 'active',
  Closed = 'closed',
  New = 'new',
  Pending = 'pending',
  Rejected = 'rejected',
  Unknown = 'unknown',
}

export enum ZodiacSign {
  Aquarius = 'aquarius',
  Aries = 'aries',
  Cancer = 'cancer',
  Capricorn = 'capricorn',
  Gemini = 'gemini',
  Leo = 'leo',
  Libra = 'libra',
  Pisces = 'pisces',
  Sagittarius = 'sagittarius',
  Scorpio = 'scorpio',
  Taurus = 'taurus',
  Virgo = 'virgo',
}
