import React from 'react';
import { useIntl } from 'react-intl';
import settings from 'configurations/application';
import useNavigation from 'enhancers/use-navigation';
import useProductModule from 'enhancers/use-product-module';
import type { UserStore } from 'store/user';
import Html from 'components/html';
import Link from 'components/link';
import Hamburger from 'components/hamburger';
import Loading from 'components/loading';

import UserBadge from './user-badge';
import Notifications from './notifications';
import AccountSettings from './account-settings';
import messages from './header.i18n';
import { testId } from './header.settings';
import tracking from './header.tracking';
import styles from './header.module.scss';

interface HeaderElement extends Pick<UserStore, 'viewType'> {
  showHamburger: boolean;
  isNavigationMenuOpen: boolean;
  onHamburgerToggle: () => void;
  children: React.ReactNode;
}

const Header: React.FunctionComponent<HeaderElement> = (props) => {
  const { viewType, showHamburger, isNavigationMenuOpen, onHamburgerToggle, children } = props;
  const [, , link] = useNavigation();
  const { formatMessage } = useIntl();
  const DesktopLogo = useProductModule<React.FunctionComponent<Partial<SVGAElement>>>(
    (product) => import(`assets/logo/header/${product}-${viewType}.svg`),
    [viewType]
  );
  const MobileLogo = useProductModule<React.FunctionComponent<Partial<SVGAElement>>>(
    (product) => import(`assets/logo/header/${product}-${viewType}-mobile.svg`),
    [viewType]
  );

  const renderLogo = (): React.ReactNode => {
    return (
      <React.Suspense fallback={<Loading size={20} />}>
        <Link
          // @ts-expect-error Extending the ApplicationRoute provides too many variants to interpret
          href={link[settings.homepage]()}
          testId={testId.logoLink}
          className={styles.logo}
          aria-label={formatMessage(messages.pages.layout.header.links.logo)}
          tracking={tracking.logo.click}
        >
          <DesktopLogo className="d-none d-lg-block w-100" />
          <MobileLogo className="d-lg-none" />
        </Link>
      </React.Suspense>
    );
  };

  return (
    <Html.header testId={testId.header} className={styles.header}>
      <Html.div className="container-fluid">
        <Html.div className="row">
          <Html.div testId={testId.lhs} className="col-7 col-md-6 align-items-center d-flex gap-4">
            {showHamburger && (
              <Hamburger
                testId={testId.hamburgerMenu}
                active={isNavigationMenuOpen}
                ariaLabel={formatMessage(messages.pages.layout.header.aria.hamburgerLabel)}
                ariaControls="navigation-menu"
                onToggle={onHamburgerToggle}
                className="d-inline-flex d-lg-none"
                tracking={tracking.hamburger.toggle.format({ status: isNavigationMenuOpen ? 'open' : 'closed' })}
              />
            )}
            {renderLogo()}
          </Html.div>
          <Html.div
            testId={testId.rhs}
            className={[
              'col-5',
              'col-md-6',
              'align-items-center',
              'd-flex',
              'gap-3',
              'flex-nowrap',
              'justify-content-end',
            ]}
          >
            {children}
            <Notifications />
            <AccountSettings />
            <UserBadge />
          </Html.div>
        </Html.div>
      </Html.div>
    </Html.header>
  );
};

export type { HeaderElement };
export default Header;
