import { defineMessages } from 'react-intl';

const seo = defineMessages({
  title: {
    id: 'pages.referrals.memberReferral.seo.title',
    defaultMessage: 'Member Referral',
  },
  description: {
    id: 'pages.referrals.memberReferral.seo.description',
    defaultMessage: 'Member Referral',
  },
});

export default {
  pages: {
    referrals: {
      memberReferral: {
        seo,
        navigationMenu: {
          ...defineMessages({
            label: {
              id: 'pages.memberReferral.navigationMenu.label',
              defaultMessage: 'Member Referrals',
            },
          }),
        },
      },
    },
  },
};
