import React from 'react';
import anime from 'animejs';
import { useDrag } from '@use-gesture/react';
import { useIntl } from 'react-intl';
import toArray from 'utils/to-array';
import is from 'utils/is';
import type { PartialToNullable, CustomToastNotification, ToastInstanceId } from 'contracts';

import Html from '../html';
import Icon, { IconList } from '../icon';
import renderElement from '../utils/render-element';

import timer from './timer';
import { defaultTimeoutInterval } from './toast.settings';
import styles from './toast.module.scss';

interface ToastElement extends PartialToNullable<Omit<CustomToastNotification, 'onClose'>> {
  exitDelay?: number;
  onCloseRequest: (id: ToastInstanceId) => void;
}

const Toast: React.FunctionComponent<ToastElement> = (props) => {
  const {
    testId,
    id,
    icon,
    message,
    caption,
    closable = true,
    freezeOnHover = true,
    timeout: timeoutInterval = defaultTimeoutInterval,
    exitDelay = 0,
    className,
    style,
    onClick,
    onCloseRequest,
  } = props;
  const { formatMessage } = useIntl();
  const ref = React.useRef<HTMLDivElement>(null);

  const handleOnCloseRequest = React.useCallback(
    (event?: React.MouseEvent<HTMLSpanElement>) => {
      event?.stopPropagation?.();
      timeout.current.stop();

      onCloseRequest(id);
    },
    [id, onCloseRequest]
  );

  const timeout = React.useRef(
    timer(handleOnCloseRequest, {
      timeout: timeoutInterval === false ? 0 : timeoutInterval + exitDelay,
      enabled: timeoutInterval !== false,
    })
  );

  React.useEffect(() => {
    anime({
      targets: ref.current,
      easing: 'cubicBezier(0.25, 0.46, 0.45, 0.94)',
      opacity: [0, 1],
      translateY: [-10, 0],
      duration: 250,
    });

    if (timeoutInterval === false) return;

    timeout.current.resume();

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      timeout.current.stop();
    };
  }, [timeoutInterval]);

  const bindDrag = useDrag(
    ({ last, movement: [movementX], cancel, event }) => {
      // disable dragging in the opposite direction (right)
      if (movementX >= 0) {
        cancel();

        return;
      }

      // we set a threshold (min distance) of 140px on drag release time
      // if distance is not reached, then we restore original position
      // otherwise, the "close" event is triggered
      if (last) {
        if (movementX > -140) {
          cancel();
          ref.current!.style.left = '0px';

          return;
        }

        // close toast
        handleOnCloseRequest(event as unknown as React.MouseEvent<HTMLSpanElement>);
      }

      ref.current!.style.left = `${movementX}px`;
    },
    {
      delay: true,
      enabled: closable ?? false,
    }
  );

  const handleOnHover = React.useCallback(
    (enter: boolean) => (): void => {
      if (!freezeOnHover) return;

      if (enter) timeout.current.pause();
      if (!enter) timeout.current.resume();
    },
    [freezeOnHover]
  );

  return (
    <Html.div
      {...bindDrag()}
      testId={testId}
      style={style}
      className={[
        'p-4 rounded-1',
        styles.toast,
        is.func(onClick) && 'cursor-pointer',
        closable && styles.drag,
        ...toArray(className),
      ]}
      typography="caption"
      onClick={onClick}
      onMouseEnter={handleOnHover(true)}
      onMouseLeave={handleOnHover(false)}
      ref={ref}
    >
      {icon && (
        <Html.span testId={testId && `${testId}-icon`} className={['me-2', styles.prefix]}>
          {renderElement(icon)}
        </Html.span>
      )}
      <Html.div className={styles.message}>
        <Html.p testId={testId && `${testId}-message`} typography="body2" className="mb-0">
          {is.formatMessageParams(message) ? formatMessage(...message) : message}
        </Html.p>
        {caption && (
          <Html.p testId={testId && `${testId}-caption`} typography="caption" className={[styles.caption, 'mb-0']}>
            {is.formatMessageParams(caption) ? formatMessage(...caption) : caption}
          </Html.p>
        )}
      </Html.div>
      {closable && (
        <Html.span
          testId={testId && `${testId}-close`}
          className={['ms-2', styles.close]}
          onClick={handleOnCloseRequest}
        >
          <Icon name={IconList.closeCircularSolid} />
        </Html.span>
      )}
    </Html.div>
  );
};

export type { ToastElement };
export default Toast;
