import React from 'react';
import toArray from 'utils/to-array';
import type { StyledElement, AriaAttributes, TestAutomation } from 'contracts';
import is from 'utils/is';
import withGATracking from 'enhancers/with-ga-tracking';

import Html from '../html';
import Loading from '../loading';

import styles from './toggle.module.scss';

interface ToggleElement extends StyledElement, AriaAttributes, TestAutomation {
  onChange: (checked: boolean) => void | boolean;
  checked?: boolean;
  disabled?: boolean;
  loading?: boolean;
}

const defaultSettings = {
  testId: 'toggle-component',
};

const Toggle: React.FunctionComponent<ToggleElement> = (props) => {
  const { onChange, testId, className, checked: defaultChecked, disabled, loading, style, ...rest } = props;
  const [checked, setChecked] = React.useState(defaultChecked || false);

  React.useEffect(() => {
    setChecked(defaultChecked ?? false);
  }, [defaultChecked]);

  const handleClick = React.useCallback(() => {
    if (disabled || loading) return;

    setChecked((prev) => {
      const onChangeReturn = onChange(!prev);

      return is.bool(onChangeReturn) ? onChangeReturn : !prev;
    });
  }, [disabled, loading, onChange]);

  const untranslatedLabel = checked ? 'On' : 'Off';

  return (
    <Html.button
      testId={testId ?? defaultSettings.testId}
      className={[
        'p-0',
        styles.toggle,
        ...toArray(className),
        checked && styles.checked,
        (disabled || loading) && styles.disabled,
      ]}
      style={style}
      arias={rest}
      onClick={handleClick}
    >
      <Html.div className={styles.handle}>
        {loading && <Loading className={styles.loading} size={20} thickness={2} theme="light" />}
      </Html.div>
      <Html.span className={styles.label} typography="caption" fontWeight="bold">
        {untranslatedLabel}
      </Html.span>
    </Html.button>
  );
};

export type { ToggleElement };
export { defaultSettings };
export default withGATracking(Toggle, { action: 'change', handler: 'onChange' });
