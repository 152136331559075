import React from 'react';
import toArray from 'utils/to-array';
import type { StyledElement, AriaAttributes, TestAutomation } from 'contracts';
import Html from 'components/html';
import Shimmer from 'components/shimmer';
import Icon, { IconList } from 'components/icon';
import is from 'utils/is';

import type { NotificationData } from '../../notifications.store';

import styles from './notification-message.module.scss';

interface NotificationMessageElement
  extends StyledElement,
    AriaAttributes,
    TestAutomation,
    Pick<NotificationData, 'preview'> {
  avatar: React.ReactNode;
  time: React.ReactNode;
  title: string | React.ReactNode;
  description: string | React.ReactNode | null;
  loading: boolean;
  handleClick: (() => void) | undefined;
}

const NotificationMessage: React.FunctionComponent<NotificationMessageElement> = (props) => {
  const { testId, className, style, avatar, time, title, description, preview, loading, handleClick, ...rest } = props;

  return (
    <Html.div
      testId={testId}
      className={[
        styles.notificationMessage,
        Boolean(handleClick) && styles.interactive,
        'py-2',
        'ps-3',
        'pe-4',
        ...toArray(className),
      ]}
      style={style}
      arias={rest}
      onClick={handleClick}
    >
      <Shimmer loading={loading} theme="light">
        <Html.div className={['me-2 rounded-circle', styles.avatar]}>{avatar}</Html.div>
      </Shimmer>

      <Html.div className={[styles.content, 'me-4']}>
        <Html.div className={['d-flex', 'w-100', 'justify-content-between']}>
          <Html.div className={['d-flex', 'flex-column']}>
            <Shimmer loading={loading} theme="light">
              <Html.span typography="caption" className={[styles.title, 'm-0', 'me-1']}>
                {title}
              </Html.span>
            </Shimmer>
            <Shimmer loading={loading} theme="light">
              <Html.span typography="caption" className={[styles.description, 'm-0']}>
                {description}
              </Html.span>
            </Shimmer>
          </Html.div>
          <Shimmer loading={loading} theme="light">
            <Html.span className={['m-0', 'ms-4']} typography="caption">
              {time}
            </Html.span>
          </Shimmer>
        </Html.div>
      </Html.div>

      <Html.div className={styles.preview}>
        {!is.nullish(preview) && (
          <Html.div className={styles.previewContainer} aria-hidden="true" testId="preview">
            <Html.div className={['rounded-1', styles.previewFallback]}>
              <Icon name={IconList.disabledOutline} className={styles.icon} />
            </Html.div>
            <Html.div
              className={['rounded-1', styles.previewImage]}
              style={{ backgroundImage: `url(${preview})` }}
              aria-hidden="true"
            />
          </Html.div>
        )}
      </Html.div>
    </Html.div>
  );
};

export type { NotificationMessageElement };
export { NotificationMessage };
export default React.memo(NotificationMessage);
