import React from 'react';
import type { Route } from 'contracts';

import parentId from '../../@@global/id';

import id from './id';

const AsyncPage = React.lazy(() => import('../chat'));
const AsyncSkeletonPage = React.lazy(() => import('../chat.skeleton'));

const routes: Route = {
  parent: parentId,
  name: id,
  path: '/topic/{topic:list(other|technical|registration|picture-or-video|audio-or-video-rating)}',
  element: <AsyncPage />,
  skeleton: <AsyncSkeletonPage />,
};

export default routes;
