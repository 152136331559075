import type { ReactNode } from 'react';

import type { StyledElement } from './styled';
import type { TestAutomation } from './test-automation';
import type { TranslationParams } from './translation';
import type { RenderIconOrElement } from './render';

type ToastInstanceId = symbol;

interface CustomToastNotification extends StyledElement, TestAutomation {
  id: ToastInstanceId;
  icon?: RenderIconOrElement;
  message: TranslationParams | ReactNode;
  caption?: TranslationParams | ReactNode;
  /**
   * default: false
   * @desc add close button to UI
   */
  closable?: boolean;
  /**
   * @desc exit timeout interval. Value can be either a number in milliseconds
   * or set to "false", which disables automatic exiting.
   */
  timeout?: number | false;
  /**
   * default: medium
   */
  priority?: 'low' | 'medium' | 'high' | 'critical';
  /**
   * default: true
   * @desc on mouse hover, timeout is cleaned. this prevents the toast to be closed.
   * on mouse out, timeout is set again.
   */
  freezeOnHover?: boolean;
  onClick?: () => void;
  onClose?: () => void;
}

type NotificationTemplate = Omit<CustomToastNotification, 'icon'>;

enum ErrorTemplate {
  Generic = 'generic',
  RequestFailed = 'request-failed',
}

enum WarningTemplate {
  Generic = 'generic',
}

interface ErrorToastNotification extends NotificationTemplate {
  type: 'error';
  message: NotificationTemplate['message'] | ErrorTemplate;
}

interface WarningToastNotification extends NotificationTemplate {
  type: 'warning';
  message: NotificationTemplate['message'] | WarningTemplate;
}

interface SuccessToastNotification
  extends Omit<NotificationTemplate, 'message'>,
    Pick<CustomToastNotification, 'message'> {
  type: 'success';
}

type ToastNotification =
  | SuccessToastNotification
  | WarningToastNotification
  | ErrorToastNotification
  | CustomToastNotification;

interface ToastAPI {
  dispatch: (toast: ToastNotification) => void;
  remove: (id: ToastInstanceId) => void;
  clear: () => void;
}

export type {
  ToastAPI,
  ToastInstanceId,
  ToastNotification,
  SuccessToastNotification,
  WarningToastNotification,
  ErrorToastNotification,
  CustomToastNotification,
};
export { WarningTemplate, ErrorTemplate };
