import { defineMessages } from 'react-intl';

export default {
  components: {
    toast: {
      generic: defineMessages({
        error: {
          id: 'components.toast.generic.error',
          defaultMessage: 'Something went wrong. Please try again.',
        },
      }),
      requestError: defineMessages({
        title: {
          id: 'components.toast.requestError.title',
          defaultMessage: "Sorry about that, we're having trouble fetching the data.",
        },
        message: {
          id: 'components.toast.requestError.message',
          defaultMessage:
            'A quick retry should help, please refresh the page or try again shortly by clicking on this message. ' +
            'We’re on it!',
        },
      }),
    },
  },
};
