import { defineMessages } from 'react-intl';
import type { LocalePerProduct } from 'contracts';

const seo: LocalePerProduct = {
  livejasmin: defineMessages({
    title: {
      id: 'pages.messages.automatedMessages.seo.livejasmin.title',
      defaultMessage: 'Automated Messages',
    },
    description: {
      id: 'pages.messages.automatedMessages.seo.livejasmin.description',
      defaultMessage: 'Automated Messages',
    },
  }),
  oranum: defineMessages({
    title: {
      id: 'pages.messages.automatedMessages.seo.oranum.title',
      defaultMessage: 'Automated Messages',
    },
    description: {
      id: 'pages.messages.automatedMessages.seo.oranum.description',
      defaultMessage: 'Automated Messages',
    },
  }),
};

export default {
  pages: {
    messages: {
      automatedMessages: {
        seo,
        navigationMenu: {
          ...defineMessages({
            label: {
              id: 'pages.messages.automatedMessages.navigationMenu.label',
              defaultMessage: 'Automated Messages',
            },
          }),
        },
        toasts: {
          errors: {
            ...defineMessages({
              unexpected: {
                id: 'pages.messages.automatedMessages.toasts.errors.unexpected',
                defaultMessage: 'An error occurred. Please, reload the page or try again later.',
              },
            }),
          },
          success: {
            ...defineMessages({
              saved: {
                id: 'pages.messages.automatedMessages.toasts.success.saved',
                defaultMessage: 'Auto-message successfully saved',
              },
              deleted: {
                id: 'pages.messages.automatedMessages.toasts.success.deleted',
                defaultMessage: 'Auto-message successfully deleted',
              },
            }),
          },
        },
        ...defineMessages({
          moreInformation: {
            id: 'pages.messages.automatedMessages.moreInformation',
            defaultMessage: 'More information',
          },
        }),
      },
    },
  },
};
