// eslint-disable-next-line no-restricted-imports
import location from 'services/router/location';

import parse from './parse';
import env from './environment';

const COOKIE_PREFIX = '__msc';

type CookieCategories = 'essential' | 'analytical' | 'functional';

// Dots and spaces in cookie name got replaced to underscore!
// https://www.php.net/manual/en/reserved.variables.cookies.php
const cookies = {
  nameCreator(category: CookieCategories, uniqueName: string) {
    const cookieName = `${COOKIE_PREFIX}-${parse.toKebabCase(category)}-${parse.toKebabCase(uniqueName)}`;

    return cookieName;
  },
  domain() {
    return !env.isLocalhost ? `.${location.url.subdomain!.split('.').splice(-1)[0]}.${location.url.domain}` : undefined;
  },
};

export default cookies;
