import { map, switchMap, tap, iif, of, defer, filter, distinctUntilChanged } from 'rxjs';
import type { Observable, Subscription } from 'rxjs';
import is from 'utils/is';
import progressiveRetry from 'utils/rxjs/progressive-retry';
import websocket, { LiveNotificationEvent } from 'services/websocket';
import {
  PerformerProfilePicturesStatus as Status,
  PerformerProfilePicturesCategory as Category,
  PerformerProfilePicturesVersionMediaKey as VersionMediaKey,
} from 'services/graphql/generated/types';
import filterWhileNullish from 'utils/rxjs/filter-while-nullish';

import graphQLQuery from './utils/graphql-query';
import { GetPerformerProfilePictures } from './performer-profile-pictures.graphql';
import type { GetPerformerProfilePicturesQuery } from './performer-profile-pictures.contracts';
import Store from './store';
import user from './user';
import notifyUserOnError from './utils/notify-user-on-error';

type ProfilePicturesArray = NonNullable<GetPerformerProfilePicturesQuery['performer']>['profilePictures'];
type ProfilePicture = ProfilePicturesArray[number];

interface ProfilePictureStatusContent {
  id: string;
}

interface PerformerProfilePicturesStore {
  pictures: Array<ProfilePicture>;
  selectedPicture: ProfilePicture | undefined;
}

class PerformerProfilePictures extends Store<PerformerProfilePicturesStore | undefined> {
  private watchList = [LiveNotificationEvent.ProfilePictureAccepted, LiveNotificationEvent.ProfilePictureRejected];

  private websocketSubscription: Subscription | undefined = undefined;

  source$ = user.onChange$.pipe(
    tap(() => super.meta.setLoading(true)),
    switchMap(({ viewTypeId }) =>
      iif(
        () => user.isStudioView() || is.nullish(viewTypeId),
        of(this.initialState).pipe(tap(() => this.closeWebsocket())),
        defer(() =>
          graphQLQuery<GetPerformerProfilePicturesQuery>(GetPerformerProfilePictures, {
            variables: {
              id: viewTypeId,
              statuses: [Status.Accepted, Status.Cropping, Status.Rejected, Status.Inactive, Status.Waiting],
            },
            context: {
              name: 'GetPerformerProfilePictures',
              headers: {
                'X-Actor-Type': 'performer',
                'X-Actor-Id': viewTypeId,
              },
            },
          }).pipe(
            progressiveRetry(),
            notifyUserOnError({ type: 'error', message: 'request-failed', onClick: () => this.reset() }),
            map((response) => response.data.performer?.profilePictures || []),
            map((pictures) => ({
              pictures,
              selectedPicture: pictures?.find((picture) => picture.isSelected && picture.category === Category.Glamour),
            })),
            tap(() => this.subscribeToWebSocket())
          )
        )
      )
    )
  );

  constructor() {
    super({
      name: 'performer-profile-pictures',
      initialState: undefined,
    });
  }

  private subscribeToWebSocket(): void {
    if (this.websocketSubscription) return;

    this.websocketSubscription = websocket
      .on$<ProfilePictureStatusContent>(this.watchList)
      .pipe(
        filter(
          ({ content }) => content.id === this.data?.selectedPicture?.id && this.isSelectedPictureWaitingApproval()
        )
      )
      .subscribe(({ event }) => {
        if (event === LiveNotificationEvent.ProfilePictureRejected) {
          this.setRejectedStatusForSelectedPicture();

          return;
        }

        if (event === LiveNotificationEvent.ProfilePictureAccepted) {
          this.setAcceptedStatusForSelectedPicture();
        }
      });
  }

  private closeWebsocket(): void {
    this.websocketSubscription?.unsubscribe?.();
    this.websocketSubscription = undefined;
  }

  public get onChange$(): Observable<PerformerProfilePicturesStore> {
    return super.onChange$.pipe(filterWhileNullish());
  }

  public get onSelectedPictureApproveStatusChange$(): Observable<boolean> {
    return this.onChange$.pipe(
      map(() => this.isSelectedPictureApproved()),
      distinctUntilChanged()
    );
  }

  public get onAcceptedGlamourProfilePictureStatusChange$(): Observable<boolean> {
    return this.onChange$.pipe(
      map(() => this.hasAcceptedGlamourProfilePicture()),
      distinctUntilChanged()
    );
  }

  hasPictures(): boolean {
    return (this.data?.pictures?.length ?? 0) > 0;
  }

  selectedPictureUrl(): string | undefined {
    const contentUri = this.data?.selectedPicture?.versions?.find(
      (version) => VersionMediaKey.ProfilePictureCrop_4_3_147_110Jpg === version.mediaKey
    )?.contentUri;

    return contentUri ?? this.data?.selectedPicture?.versions[0].contentUri;
  }

  isSelectedPictureWaitingApproval(): boolean {
    return this.data?.selectedPicture?.status === Status.Waiting;
  }

  isSelectedPictureApproved(): boolean {
    return this.data?.selectedPicture?.status === Status.Accepted;
  }

  hasAcceptedGlamourProfilePicture(): boolean {
    return Boolean(
      this.data?.pictures.find(
        (picture: ProfilePicture) => picture.status === Status.Accepted && picture.category === Category.Glamour
      )
    );
  }

  hasSelectedPicturePortraitCrop(): boolean {
    return this.data?.selectedPicture?.crops.some((crop) => crop.name === 'portrait') ?? false;
  }

  setAcceptedStatusForSelectedPicture(): void {
    if (!is.nullish(this.data?.selectedPicture)) return;

    this.set('selectedPicture', {
      status: Status.Accepted,
    } as ProfilePicture);

    this.set(
      'pictures',
      this.data!.pictures.map((picture) => {
        if (this.data!.selectedPicture?.id !== picture.id) {
          return picture;
        }

        return {
          ...picture,
          status: Status.Accepted,
        };
      }) satisfies ProfilePicture[]
    );
  }

  setRejectedStatusForSelectedPicture(): void {
    if (!is.nullish(this.data?.selectedPicture)) return;

    this.set('selectedPicture', undefined);
    this.set(
      'pictures',
      this.data!.pictures.map((picture) => {
        if (this.data!.selectedPicture?.id !== picture.id) {
          return picture;
        }

        return {
          ...picture,
          isSelected: false,
          status: Status.Rejected,
        };
      }) satisfies ProfilePicture[]
    );
  }
}

export type { PerformerProfilePicturesStore };
export default new PerformerProfilePictures();
