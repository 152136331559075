import { combineLatest, defer, iif, of, switchMap, tap, filter, map, catchError, type Observable } from 'rxjs';
import type { PerformerPromoPeriodTimeDetails } from 'services/api/account-services/data-contracts';
import type { PerformerCategory } from 'services/api/proxy-registration/data-contracts';
import { mePerformersPromoPeriodTimeDetail } from 'services/api/account-services/me';
import type { ExclusiveBadgeStatus } from 'services/graphql/generated/types';
import progressiveRetry from 'utils/rxjs/progressive-retry';
import date, { type DateInput } from 'utils/date';
import is from 'utils/is';
import type { ObservableType } from 'contracts';

import account from '../account';
import user from '../user';
import graphQLQuery from '../utils/graphql-query';
import notifyUserOnError from '../utils/notify-user-on-error';

import { GetPerformerData } from './performer.graphql';
import { GetPerformerNewbieStatusData } from './performer-newbie-status.graphql';
import type { GetPerformerDataQuery } from './performer.contracts';
import type { GetPerformerNewbieStatusDataQuery } from './performer-newbie-status.contracts';

type PerformerStatus = 'new' | 'pending' | 'rejected' | 'active' | 'inactive' | 'suspended' | 'closed';

type PerformerPromoTime = Exclude<
  ObservableType<ReturnType<typeof mePerformersPromoPeriodTimeDetail>>['data'],
  undefined
>;

type Person = {
  id: number;
  genderId: number;
  fullName: string;
  birthday: DateInput | null;
};

interface PerformerStore {
  id: number;
  displayName: string;
  screenName: string;
  status: PerformerStatus;
  category: PerformerCategory;
  personIds: Array<number>;
  persons: Array<Person>;
  promoTime: PerformerPromoTime;
  flags: {
    messengerReadReceipts: boolean;
    birthdayNotification: boolean;
    exclusiveBadgeStatus?: ExclusiveBadgeStatus | null;
    soldierAidCampaignParticipant: boolean;
    isSelected: boolean;
  };
}

const statusNotNew = (status?: PerformerStatus): boolean => status !== 'new';

const fetchPerformerData$ = (viewTypeId: number): Observable<GetPerformerDataQuery['performer'] | undefined> =>
  graphQLQuery<GetPerformerDataQuery>(GetPerformerData, {
    variables: { id: viewTypeId },
    context: {
      name: 'GetPerformerData',
      important: true,
      headers: { 'X-Actor-Type': 'performer', 'X-Actor-Id': viewTypeId },
    },
  }).pipe(
    progressiveRetry(),
    map((response) => response.data?.performer ?? undefined)
  );

const getNewbieStatus$ = (viewTypeId: number): Observable<boolean> =>
  graphQLQuery<GetPerformerNewbieStatusDataQuery>(GetPerformerNewbieStatusData, {
    variables: { id: viewTypeId },
    context: {
      name: 'GetPerformerNewbieStatusData',
      important: true,
      headers: { 'X-Actor-Type': 'performer', 'X-Actor-Id': viewTypeId },
    },
  }).pipe(
    progressiveRetry(),
    map((response) => response.data.performer?.newbie.isNewbie ?? false),
    catchError(() => of(false))
  );

const getPromoPeriodTime$ = (viewTypeId: number): Observable<PerformerPromoTime> =>
  mePerformersPromoPeriodTimeDetail(viewTypeId.toString(), {
    headers: { 'X-Actor-Type': 'performer', 'X-Actor-Id': viewTypeId },
  }).pipe(
    map((response) => response.data),
    progressiveRetry({ skipWhen: (error) => error.response.status === 404 }),
    catchError(() => of({} satisfies PerformerPromoPeriodTimeDetails))
  );

const source = (
  initialState,
  closeWebsocket: () => void,
  subscribeToWebSocket: () => void,
  setLoading: (loading: boolean) => void,
  reset: () => void,
  close: () => void
): Observable<PerformerStore> =>
  account.onChange$.pipe(
    filter(() => account.hasEnrollmentCompleted),
    switchMap(() => user.onChange$),
    filter(({ viewTypeId }) => !is.nullish(viewTypeId)),
    tap(() => setLoading(true)),
    switchMap(({ viewTypeId }) =>
      iif(
        () => user.isStudioView(),
        of(initialState).pipe(
          tap(() => {
            closeWebsocket();
          })
        ),
        defer(() =>
          fetchPerformerData$(viewTypeId!).pipe(
            switchMap((details) =>
              combineLatest([
                of(details),
                statusNotNew(details?.status as PerformerStatus) ? getNewbieStatus$(viewTypeId!) : of(undefined),
                statusNotNew(details?.status as PerformerStatus) ? getPromoPeriodTime$(viewTypeId!) : of(undefined),
              ])
            )
          )
        ).pipe(
          notifyUserOnError({ type: 'error', message: 'request-failed', onClick: () => reset() }),
          map(([details, isNewbie, promoTime]) => {
            const { displayName = '', screenName = '', status = 'new', category, flags, persons } = details ?? {};

            return {
              id: viewTypeId!,
              displayName,
              screenName,
              status: status as PerformerStatus,
              category: category
                ? {
                    id: +category.id,
                    name: category.name,
                    parent: {
                      parent: {
                        id: +(category.parent?.parent?.id ?? 0),
                      },
                    },
                  }
                : {},
              personIds: persons?.map((person) => +person.id) ?? [],
              persons:
                persons?.map((person) => ({
                  id: +person.id,
                  genderId: person.genderId,
                  fullName: person.fullName,
                  birthday: person.birthDate ? date(person.birthDate).toISODateString() : '',
                })) ?? [],
              promoTime: promoTime ?? {},
              flags: {
                newbie: isNewbie,
                exclusiveBadgeStatus: flags?.exclusiveBadgeStatus,
                messengerReadReceipts: Boolean(flags?.messengerReadReceipts),
                birthdayNotification: Boolean(flags?.birthdayNotification),
                soldierAidCampaignParticipant: Boolean(flags?.soldierAidCampaignParticipant),
                isSelected: Boolean(flags?.isSelected),
              },
            };
          }),
          tap(() => subscribeToWebSocket()),
          catchError((error) => {
            close();
            throw error;
          })
        )
      )
    )
  );

export type { PerformerStore, PerformerStatus, Person };
export { source };
