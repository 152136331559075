import { of } from 'rxjs';
import { link } from 'services/navigation';
import type { NavigationMenuItem } from 'contracts';
import legacyId from 'pages/redirection/legacy/@@global/id';

import messages from '../online-support.i18n';

import id from './id';

const menu: NavigationMenuItem = {
  entries: [
    {
      name: id,
      label: messages.pages.help.onlineSupport.navigationMenu.label,
      link: () => link.onlineSupport(),
      visibility$: of(true),
      parent: legacyId.helpAndInfo,
      weight: 2,
    },
  ],
};

export default menu;
