import React from 'react';
import { debounce } from 'throttle-debounce';
import useResizeObserver from 'enhancers/use-resize-observer';
import type { TestAutomation } from 'contracts';

import Html from '../html';

import styles from './scroll-shadow.module.scss';

const SCROLLING_BG_POSITION_LIMIT = 64;

interface ScrollShadowElement extends TestAutomation {
  direction: 'horizontal' | 'vertical';
  // Scrollable div container
  children: React.ReactElement<{ ref: React.RefObject<HTMLDivElement | undefined> }>;
}

const calculateIsHorizontallyScrollable = (ref): boolean => {
  if (!ref.current) return false;

  return ref.current.scrollWidth > ref.current.clientWidth;
};

const calculateIsVerticallyScrollable = (ref): boolean => {
  if (!ref.current) return false;

  return ref.current.scrollHeight > ref.current.clientHeight;
};

const ScrollShadow = React.forwardRef<HTMLDivElement | undefined, ScrollShadowElement>((props, ref) => {
  const { children, testId, direction } = props;
  const scrollContainerRef = React.useRef<HTMLDivElement>(undefined);

  const [overlayPositions, setOverlayPositions] = React.useState({
    left: -SCROLLING_BG_POSITION_LIMIT,
    right: 0,
    top: -SCROLLING_BG_POSITION_LIMIT,
    bottom: 0,
  });

  const [isHorizontallyScrollable, setIsHorizontallyScrollable] = React.useState(false);
  const [isVerticallyScrollable, setIsVerticallyScrollable] = React.useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleScroll = React.useCallback(
    debounce(50, (): void => {
      const scrollContainer = scrollContainerRef.current;

      if (!scrollContainer) return;

      const leftOffset = scrollContainer.scrollLeft;
      const rightOffset = scrollContainer.scrollWidth - scrollContainer.clientWidth - leftOffset;
      const topOffset = scrollContainer.scrollTop;
      const bottomOffset = scrollContainer.scrollHeight - scrollContainer.clientHeight - topOffset;

      setOverlayPositions({
        left: leftOffset <= SCROLLING_BG_POSITION_LIMIT ? leftOffset - SCROLLING_BG_POSITION_LIMIT : 0,
        right: rightOffset <= SCROLLING_BG_POSITION_LIMIT ? SCROLLING_BG_POSITION_LIMIT - rightOffset : 0,
        top: topOffset <= SCROLLING_BG_POSITION_LIMIT ? topOffset - SCROLLING_BG_POSITION_LIMIT : 0,
        bottom: bottomOffset <= SCROLLING_BG_POSITION_LIMIT ? SCROLLING_BG_POSITION_LIMIT - bottomOffset : 0,
      });
    }),
    [scrollContainerRef]
  );

  useResizeObserver(scrollContainerRef, () => {
    setIsHorizontallyScrollable(calculateIsHorizontallyScrollable(scrollContainerRef));
    setIsVerticallyScrollable(calculateIsVerticallyScrollable(scrollContainerRef));
  });

  React.useEffect(() => {
    const scrollContainer = scrollContainerRef.current;

    if (!scrollContainer || (!isHorizontallyScrollable && !isVerticallyScrollable)) return;

    scrollContainer.addEventListener('scroll', handleScroll);

    return () => scrollContainer.removeEventListener('scroll', handleScroll);
  }, [isHorizontallyScrollable, isVerticallyScrollable, handleScroll]);

  React.useImperativeHandle(ref, () => scrollContainerRef.current, []);

  return (
    <Html.div className="position-relative h-100">
      {direction === 'horizontal' && isHorizontallyScrollable && (
        <React.Fragment>
          <Html.div
            testId={testId && `${testId}-left-shadow`}
            className={[styles.scrollShadow, styles.leftScrollingOverlay]}
            style={{
              backgroundPositionX: overlayPositions.left,
              width: `${SCROLLING_BG_POSITION_LIMIT}px`,
            }}
            aria-hidden="true"
          />

          <Html.div
            testId={testId && `${testId}-right-shadow`}
            className={[styles.scrollShadow, styles.rightScrollingOverlay]}
            style={{
              backgroundPositionX: overlayPositions.right,
              width: `${SCROLLING_BG_POSITION_LIMIT}px`,
            }}
            aria-hidden="true"
          />
        </React.Fragment>
      )}

      {direction === 'vertical' && isVerticallyScrollable && (
        <React.Fragment>
          <Html.div
            testId={testId && `${testId}-top-shadow`}
            className={[styles.scrollShadow, styles.topScrollingOverlay]}
            style={{
              backgroundPositionY: overlayPositions.top,
              height: `${SCROLLING_BG_POSITION_LIMIT}px`,
            }}
            aria-hidden="true"
          />

          <Html.div
            testId={testId && `${testId}-bottom-shadow`}
            className={[styles.scrollShadow, styles.bottomScrollingOverlay]}
            style={{
              backgroundPositionY: overlayPositions.bottom,
              height: `${SCROLLING_BG_POSITION_LIMIT}px`,
            }}
            aria-hidden="true"
          />
        </React.Fragment>
      )}
      {React.cloneElement(children, {
        ref: scrollContainerRef,
      })}
    </Html.div>
  );
});

export type { ScrollShadowElement };
export default ScrollShadow;
