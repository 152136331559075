import React from 'react';
import useNodeDimensions from 'enhancers/use-node-dimensions';
import type { Dimensions } from 'enhancers/use-node-dimensions';
import classNames from 'utils/class-name';
import toArray from 'utils/to-array';
import css from 'utils/css';
import is from 'utils/is';
import product from 'utils/product';
import type { StyledElement, AriaAttributes, TestAutomation } from 'contracts';

import Html from '../html';

import styles from './shimmer.module.scss';

interface ShimmerElement extends StyledElement, AriaAttributes, TestAutomation {
  theme?: 'dark' | 'light';
  /** Shimmer width, it accepts value without unit. Default unit: "px"  */
  width?: number | string;
  /** Shimmer min width., it accepts value without unit. Default unit: "px"  */
  minWidth?: number | string;
  /** Shimmer height, it accepts value without unit. Default unit: "px" */
  height?: number | string;
  /** Shimmer min height, it accepts value without unit. Default unit: "px" */
  minHeight?: number | string;
  /** Toggle Shimmer effect */
  loading?: boolean;
  /** Set Shimmer effect for text */
  textClip?: boolean;
  /** Shimmer content */
  children?: React.ReactElement | string | boolean;
}

const Shimmer: React.FunctionComponent<ShimmerElement> = (props) => {
  const {
    testId,
    theme: initialTheme,
    width,
    minWidth,
    height,
    minHeight,
    loading,
    textClip,
    style,
    className,
    children = <Html.span />,
    ...rest
  } = props;
  const [ref, dimensions] = useNodeDimensions<HTMLDivElement>(false);
  const {
    marginTop,
    marginBottom,
    marginLeft,
    marginRight,
    borderTopLeftRadius,
    borderTopRightRadius,
    borderBottomLeftRadius,
    borderBottomRightRadius,
  } = dimensions! || ({} as Dimensions);

  const theme = initialTheme || (product.LiveJasmin ? 'dark' : 'light');

  const borderRadius = React.useMemo(() => {
    const radius: string | undefined = [
      borderTopLeftRadius,
      borderTopRightRadius,
      borderBottomLeftRadius,
      borderBottomRightRadius,
    ].find((entry) => css(entry || '').size > 3);

    return radius || '3px';
  }, [borderTopLeftRadius, borderTopRightRadius, borderBottomLeftRadius, borderBottomRightRadius]);

  if (is.bool(children)) {
    return null;
  }

  if (!loading) {
    return children;
  }

  const Component =
    !is.string(children) && (React.Children.only(children) as React.ReactElement<TestAutomation & StyledElement>);

  return (
    <Html.div
      testId={testId}
      className={[...toArray(className), loading && styles.shimmer, styles[theme], textClip && styles.shimmerText]}
      style={{
        width,
        height,
        minHeight,
        minWidth,
        marginTop,
        marginBottom,
        marginLeft,
        marginRight,
        borderRadius,
        ...style,
      }}
      arias={rest}
    >
      <Html.span
        className={classNames(
          ...toArray(is.nullish(dimensions) && !is.bool(Component) ? (Component.props?.className as string[]) : []),
          !is.nullish(dimensions) && styles.clearMargins,
          !textClip && styles.hidden
        )}
      >
        {is.string(children) && children}
        {!is.bool(Component) && (
          <Component.type
            {...Component.props}
            testId={!loading ? Component.props?.testId : undefined}
            className={classNames(
              ...toArray(is.nullish(dimensions) ? (Component.props?.className as string[]) : []),
              !is.nullish(dimensions) && styles.clearMargins
            )}
            ref={ref}
          />
        )}
      </Html.span>
    </Html.div>
  );
};

export type { ShimmerElement };
export default React.memo(Shimmer);
