import Cookies from 'js-cookie';

import httpRequest from './http-request';
import { OAuthAction } from './contracts';
import getAuthData from './get-auth-data';
import eventStream from './event-stream';
import activity from './activity';
import taskScheduler from './task-scheduler';
import utils from './utils';

const http = httpRequest(OAuthAction.REVOKE_TOKEN);

const signOut = async (): Promise<void> => {
  return new Promise<void>(function Executor(resolve) {
    const { accessToken, refreshToken } = getAuthData() || {};

    const activityDone = activity.setAsBusy(this);

    // eslint-disable-next-line camelcase
    http.abort().send({ token: refreshToken as string, token_type_hint: 'refresh_token' }, () => {
      // eslint-disable-next-line camelcase
      http.send({ token: accessToken as string, token_type_hint: 'access_token' }, () => {
        Cookies.remove(utils.cookies.CACHE_KEY, utils.cookies.attributes());
        window.sessionStorage.clear();

        activityDone();

        eventStream.set(false);

        taskScheduler.update();

        resolve();
      });
    });
  });
};

export default signOut;
