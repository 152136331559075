import deepEqual from '../../../utils/deep-equal';
import is from '../../../utils/is';
import type { AllowedDataType } from '../contracts';

interface MountNextStoreState<T> {
  modified: boolean;
  data: T;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
const mountNextStoreState =
  <T extends AllowedDataType>(currentState: T) =>
  <K>(key: K | T, value?: T extends Record<any, any> ? T[K] : never): MountNextStoreState<T> => {
    if (is.nullish(value) && (is.nullish(currentState) || is.array(currentState))) {
      return {
        modified: !deepEqual(currentState, key),
        data: key as T,
      };
    }

    return {
      // @ts-expect-error: type inference issue
      modified: !deepEqual(currentState?.[key], value),
      data: { ...(currentState || {}), [key as string | number]: value } as T,
    };
  };

/* eslint-enable @typescript-eslint/no-explicit-any */

export default mountNextStoreState;
