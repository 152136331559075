import { defineMessages } from 'react-intl';

export default {
  pages: {
    layout: {
      header: {
        notifications: {
          mappings: {
            performer: {
              battle: {
                ended: defineMessages({
                  title: {
                    id: 'pages.layout.header.notifications.mappings.performer.battle.ended.title',
                    defaultMessage:
                      '{memberTipCount} {memberTipCount, plural, one {member tipped you} other {members tipped you}} during your Battle. Don’t forget to say thanks!',
                  },
                }),
                enabled: defineMessages({
                  title: {
                    id: 'pages.layout.header.notifications.mappings.performer.battle.enabled.title',
                    defaultMessage:
                      'Battle feature was enabled by your account administrator. You can activate Battle during Live Show, collect more Credits and win the Battle!',
                  },
                  message: {
                    id: 'pages.layout.header.notifications.mappings.performer.battle.enabled.message',
                    defaultMessage: 'If you have a question, please contact our Support Team',
                  },
                }),
                disabled: defineMessages({
                  title: {
                    id: 'pages.layout.header.notifications.mappings.performer.battle.disabled.title',
                    defaultMessage: 'Battle feature was disabled by your account administrator',
                  },
                  message: {
                    id: 'pages.layout.header.notifications.mappings.performer.battle.disabled.message',
                    defaultMessage: 'If you have a question, please contact our Support Team',
                  },
                }),
              },
              interactiveToy: {
                disabled: {
                  ...defineMessages({
                    title: {
                      id: 'pages.layout.header.notifications.mappings.performer.interactiveToy.disabled.title',
                      defaultMessage: 'Interactive Toy is no longer available for you',
                    },
                    message: {
                      id: 'pages.layout.header.notifications.mappings.performer.interactiveToy.disabled.message',
                      defaultMessage: 'For more information, please contact our Support team',
                    },
                  }),
                },
              },
              videoCall: {
                enabled: {
                  ...defineMessages({
                    title: {
                      id: 'pages.layout.header.notifications.mappings.performer.videoCall.enabled.title',
                      defaultMessage:
                        'Video Call feature was enabled by your account administrator. You can choose Video Call settings and answer them with the mobile app',
                    },
                    message: {
                      id: 'pages.layout.header.notifications.mappings.performer.videoCall.enabled.message',
                      defaultMessage: 'If you have a question, please contact our Support Team',
                    },
                  }),
                },
                disabled: {
                  ...defineMessages({
                    title: {
                      id: 'pages.layout.header.notifications.mappings.performer.videoCall.disabled.title',
                      defaultMessage: 'Video Call feature was disabled by your account administrator',
                    },
                    message: {
                      id: 'pages.layout.header.notifications.mappings.performer.videoCall.disabled.message',
                      defaultMessage: 'If you have a question, please contact our Support Team',
                    },
                  }),
                },
              },
              status: {
                active: {
                  ...defineMessages({
                    title: {
                      id: 'pages.layout.header.notifications.mappings.performer.status.active.title',
                      defaultMessage: 'Congratulations, your account has been activated',
                    },
                  }),
                },
                closed: {
                  ...defineMessages({
                    title: {
                      id: 'pages.layout.header.notifications.mappings.performer.status.closed.title',
                      defaultMessage: 'Your account has been closed',
                    },
                    message: {
                      id: 'pages.layout.header.notifications.mappings.performer.status.closed.message',
                      defaultMessage: 'Please contact our Support Team for details',
                    },
                  }),
                },
                pending: {
                  ...defineMessages({
                    title: {
                      id: 'pages.layout.header.notifications.mappings.performer.status.pending.title',
                      defaultMessage: 'Your account is being approved',
                    },
                    message: {
                      id: 'pages.layout.header.notifications.mappings.performer.status.pending.message',
                      defaultMessage: 'We’ll be done checking your profile soon',
                    },
                  }),
                },
                inactive: {
                  ...defineMessages({
                    title: {
                      id: 'pages.layout.header.notifications.mappings.performer.status.inactive.title',
                      defaultMessage: 'Your personal account was deactivated by your account administrator',
                    },
                    message: {
                      id: 'pages.layout.header.notifications.mappings.performer.status.inactive.message',
                      defaultMessage: 'Please contact our Support Team for details',
                    },
                  }),
                },
                suspended: {
                  ...defineMessages({
                    title: {
                      id: 'pages.layout.header.notifications.mappings.performer.status.suspended.title',
                      defaultMessage: 'Your account has been suspended',
                    },
                    message: {
                      id: 'pages.layout.header.notifications.mappings.performer.status.suspended.message',
                      defaultMessage: 'Please contact our Support Team for details',
                    },
                  }),
                },
                rejected: {
                  ...defineMessages({
                    title: {
                      id: 'pages.layout.header.notifications.mappings.performer.status.rejected.title',
                      defaultMessage: 'Your account has been rejected',
                    },
                    message: {
                      id: 'pages.layout.header.notifications.mappings.performer.status.rejected.message',
                      defaultMessage: 'Please contact our Support Team for details',
                    },
                  }),
                },
              },
              mobileLive: {
                enabled: {
                  ...defineMessages({
                    title: {
                      id: 'pages.layout.header.notifications.mappings.performer.mobileLive.enabled.title',
                      defaultMessage:
                        'Mobile Live feature was enabled by your account administrator. You can go online with the mobile app',
                    },
                    message: {
                      id: 'pages.layout.header.notifications.mappings.performer.mobileLive.enabled.message',
                      defaultMessage: 'If you have a question, please contact our Support Team',
                    },
                  }),
                },
                disabled: {
                  ...defineMessages({
                    title: {
                      id: 'pages.layout.header.notifications.mappings.performer.mobileLive.disabled.title',
                      defaultMessage: 'Mobile Live feature was disabled by your account administrator',
                    },
                    message: {
                      id: 'pages.layout.header.notifications.mappings.performer.mobileLive.disabled.message',
                      defaultMessage: 'If you have a question, please contact our Support Team',
                    },
                  }),
                },
              },
              story: {
                rejected: {
                  ...defineMessages({
                    title: {
                      id: 'pages.layout.header.notifications.mappings.performer.story.rejected.title',
                      defaultMessage: 'Your story was rejected',
                    },
                    shortsTitle: {
                      id: 'pages.layout.header.notifications.mappings.performer.story.rejected.shortsTitle',
                      defaultMessage: 'Your Shorts video was rejected',
                    },
                    message: {
                      id: 'pages.layout.header.notifications.mappings.performer.story.rejected.message',
                      defaultMessage: 'Click to find out more',
                    },
                    deleted: {
                      id: 'pages.layout.header.notifications.mappings.performer.story.rejected.deleted',
                      defaultMessage: 'This content is no longer available',
                    },
                  }),
                },
              },
              actionRequest: {
                disabled: {
                  ...defineMessages({
                    title: {
                      id: 'pages.layout.header.notifications.mappings.performer.actionRequest.disabled.title',
                      defaultMessage: 'You can no longer access Action Requests',
                    },
                    message: {
                      id: 'pages.layout.header.notifications.mappings.performer.actionRequest.disabled.message',
                      defaultMessage: 'For more information, please contact our Support team',
                    },
                  }),
                },
              },
              shopPaymentOrderPlaced: {
                ...defineMessages({
                  title: {
                    id: 'pages.layout.header.notifications.mappings.performer.shopPaymentOrderPlaced.title',
                    defaultMessage: '<b>{memberName}</b> purchased Real Gifts for you.',
                  },
                  message: {
                    id: 'pages.layout.header.notifications.mappings.performer.shopPaymentOrderPlaced.message',
                    defaultMessage:
                      "Don't forget to tell your member how much you love it! Click this notification to open Messages.",
                  },
                }),
              },
              hiddenFace: {
                changed: {
                  ...defineMessages({
                    title: {
                      id: 'pages.layout.header.notifications.mappings.performer.hiddenFace.changed.title',
                      defaultMessage:
                        'Support has rejected your hide your face request due to compliance issues, such as country restrictions or account violations.',
                    },
                    message: {
                      id: 'pages.layout.header.notifications.mappings.performer.hiddenFace.changed.message',
                      defaultMessage: 'If you have a question, please contact our Support Team.',
                    },
                  }),
                },
              },
            },
          },
        },
      },
    },
  },
};
