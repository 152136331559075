import React from 'react';
// eslint-disable-next-line no-restricted-imports
import location from 'services/router/location';
import withGATracking from 'enhancers/with-ga-tracking';
import toArray from 'utils/to-array';
import type { StyledElement, AriaAttributes, TestAutomation } from 'contracts';
import parse from 'utils/parse';

import type { Typography } from '../html';
import Html from '../html';
import type { ButtonElement } from '../button';
import buttonStyles from '../button/button.module.scss';

import styles from './link.module.scss';

type HTMLAnchor = React.AnchorHTMLAttributes<HTMLAnchorElement>;

interface LinkElement
  extends StyledElement,
    AriaAttributes,
    TestAutomation,
    Pick<ButtonElement, 'disabled'>,
    Partial<Omit<Typography, 'size'>> {
  href: NonNullable<HTMLAnchor['href']> | undefined;
  rel?: HTMLAnchor['rel'];
  target?: Extract<HTMLAnchor['target'], string>;
  type?: HTMLAnchor['type'];
  /**
   *  Set that the href target is a downloadable content.
   * Optionally you can set the "filename" here
   * */
  download?: boolean | string;
  onClick?: HTMLAnchor['onClick'];
  variant?: 'primary' | 'secondary1' | 'secondary2' | `button-${NonNullable<ButtonElement['variant']>}`;
  /** For variant "button-*" only */
  size?: `button-${NonNullable<ButtonElement['size']>}`;
  children: React.ReactNode;
}

const Link: React.FunctionComponent<LinkElement> = (props) => {
  const {
    href,
    rel,
    target,
    type,
    download,
    variant,
    disabled,
    testId,
    className,
    style,
    size,
    onClick,
    italic,
    uppercase,
    ellipsis,
    typography,
    fontWeight,
    children,
    ...rest
  } = props;

  const handleOnClick = React.useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>): void => {
      if (disabled) return;

      if (onClick) {
        onClick(event);
      }

      if (!event.isDefaultPrevented()) {
        event.preventDefault();

        location.navigate(href, target);
      }
    },
    [disabled, href, onClick, target]
  );

  const buttonClassNames = React.useMemo<string[]>(() => {
    const values: string[] = [];

    if (![variant, size].some((entry) => entry?.startsWith('button-'))) {
      return values;
    }

    const sanitise = function Sanitise<T extends keyof typeof buttonStyles>(value: string | undefined): T {
      if (!value?.startsWith('button-')) {
        return '' as T;
      }

      return value.replace('button-', '') as T;
    };

    values.push(buttonStyles.button);
    values.push(buttonStyles[parse.toCamelCase(sanitise<string>(variant))]);
    values.push(buttonStyles[sanitise(size)]);

    if (disabled) {
      values.push(buttonStyles.disabled);
    }

    return values;
  }, [variant, size, disabled]);

  const relationship = React.useMemo(() => {
    if (rel) return rel;

    return target === '_blank' ? 'noopener' : undefined;
  }, [rel, target]);

  return (
    <Html.a
      testId={testId}
      href={href}
      rel={relationship}
      target={target}
      type={type}
      typography={typography}
      download={download}
      className={[
        !buttonClassNames.length && styles.link,
        !buttonClassNames.length && disabled && styles.disabled,
        !buttonClassNames.length && variant && styles[parse.toCamelCase(variant)],
        ...buttonClassNames,
        ...toArray(className),
      ]}
      style={style}
      fontWeight={fontWeight}
      italic={italic}
      uppercase={uppercase}
      ellipsis={ellipsis}
      onClick={handleOnClick}
      arias={rest}
    >
      {children}
    </Html.a>
  );
};

export type { LinkElement };
export default withGATracking(Link, { action: 'click', handler: 'onClick' });
