import { defineMessages } from 'react-intl';

const livejasmin = defineMessages({
  title: {
    id: 'pages.shared.seo.livejasmin.title',
    defaultMessage: 'LiveJasmin.com - Model Center - %s',
  },
});

const oranum = defineMessages({
  title: {
    id: 'pages.shared.seo.oranum.title',
    defaultMessage: 'Oranum.com - Broadcaster Center - %s',
  },
});

export default {
  pages: {
    shared: {
      seo: {
        livejasmin,
        oranum,
      },
    },
  },
};
