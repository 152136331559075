import { defer, iif, map, of } from 'rxjs';
import type { RouteAuthorization } from 'contracts';
import { getIsHolidayAwardsActive } from 'pages/shared/holiday-awards/utils';
import application from 'services/application';
import performer from 'store/performer';
import user from 'store/user';
import evalAuthorization from 'utils/rxjs/eval-authorization';
import is from 'utils/is';

import pageId from './id';

const authorization: RouteAuthorization = {
  name: pageId,
  authorization$: user.onChange$.pipe(
    evalAuthorization(
      ({ viewTypeId }) => is.nullish(viewTypeId),
      () =>
        iif(
          () => user.isModelView(),
          defer(() =>
            performer.onStatusChange$.pipe(
              map(() => application.LiveJasmin && !performer.hasClosedStatus() && getIsHolidayAwardsActive())
            )
          ),
          of(false)
        )
    )
  ),
};

export default authorization;
