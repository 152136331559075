import { link } from 'services/navigation';
import authorization from 'services/authorization';
import type { NavigationMenuItem } from 'contracts';

import messages from '../../../redirection/legacy/legacy.i18n';

import id from './id';

const menu: NavigationMenuItem = {
  entries: [
    {
      parent: 'statistics',
      name: id,
      label: messages.legacy.pages.statistics.payingMembers.navigationMenu.label,
      link: () => link.payingMembers(),
      visibility$: authorization.getAuthorization$(id),
      weight: 3,
    },
  ],
  activates: {
    [id]: [id],
  },
};

export default menu;
