import application from 'services/application';

const getBaseUrl = (): string => {
  switch (application.current) {
    case 'oranum':
      return 'https://wiki.oranum.com/display/DW';
    case 'livejasmin':
    default:
      return 'https://wiki.livejasmin.com/display/EW';
  }
};

const baseUrl = getBaseUrl();
const ljBaseUrlV2 = 'https://livejasminwiki.com';
const oranumBaseUrlV2 = 'https://oranumwiki.com';

const wiki = {
  baseUrl,
  categoryNude: `${ljBaseUrlV2}/categories/#nude-categories`,
  categoryHotFlirt: `${ljBaseUrlV2}/categories/#hot-flirt`,
  categorySoulMate: `${ljBaseUrlV2}/categories/#soul-mate`,
  bankSEPA: `${ljBaseUrlV2}/payout-system/#eur---sepa`,
  bankEUR: `${ljBaseUrlV2}/payout-system/#eur---international-wire-transfer`,
  bankACH: `${ljBaseUrlV2}/payout-system/#usd---ach`,
  holidayAwards: `${ljBaseUrlV2}/holiday-awards`,
  payoutPandablue: `${ljBaseUrlV2}/payout-system/#pandablue`,
  payoutEPayService: `${ljBaseUrlV2}/payout-system/#PayoutSystem-epayservice`,
  payoutPaxum: `${ljBaseUrlV2}/payout-system/#PayoutSystem-Paxum`,
  payoutPayoneer: `${ljBaseUrlV2}/payout-system/#PayoutSystem-Payoneer`,
  payoutPaypal: `${ljBaseUrlV2}/payout-system/#PayoutSystem-Paypal`,
  payoutSkrill: `${ljBaseUrlV2}/payout-system/#PayoutSystem-Skrill`,
  payoutWebMoney: `${ljBaseUrlV2}/payout-system/#webmoney`,
  payoutCosmo: `${ljBaseUrlV2}/payout-system/#PayoutSystem-cosmo`,
  payoutTriplea: `${ljBaseUrlV2}/payout-system/#crypto`,
  payoutPagomundo: `${ljBaseUrlV2}/payout-system/#pagomundo`,
  tips: `${baseUrl}/Tips+and+Surprises`,
  stories: `${baseUrl}/MyStory`,
  explicitVideos: `${ljBaseUrlV2}/explicit-videos/`,
  explicitVideosConsent: `${ljBaseUrlV2}/explicit-videos/#which-websites-will-promote-my-videos`,
  explicitVideosRequirements: `${ljBaseUrlV2}/explicit-videos/#technical-requirements`,
  explicitVideosModelTagging: `${ljBaseUrlV2}/explicit-videos/#model-tagging`,
  rules: `${ljBaseUrlV2}/penalty-system`,
  referral: `${ljBaseUrlV2}/model-referral`,
  automatedMessages: `${ljBaseUrlV2}/livejasmin-messenger/#automated-messages`,
  faq: ljBaseUrlV2,
};

if (application.Oranum) {
  wiki.bankSEPA = `${oranumBaseUrlV2}/payout-system/#sepa`;
  wiki.bankEUR = `${oranumBaseUrlV2}/payout-system/#international-wire-transfer`;
  wiki.bankACH = `${oranumBaseUrlV2}/payout-system/#ach`;
  wiki.payoutPandablue = `${oranumBaseUrlV2}/payout-system/#pandablue`;
  wiki.payoutPaxum = `${oranumBaseUrlV2}/payout-system/#paxum`;
  wiki.payoutPayoneer = `${oranumBaseUrlV2}/payout-system/#payoneer`;
  wiki.payoutPaypal = `${oranumBaseUrlV2}/payout-system/#paypal`;
  wiki.payoutWebMoney = `${oranumBaseUrlV2}/payout-system/#webmoney`;
  wiki.payoutCosmo = `${oranumBaseUrlV2}/payout-system/#cosmo`;
  wiki.rules = `${oranumBaseUrlV2}/rules`;
  wiki.stories = `${oranumBaseUrlV2}/story`;
  wiki.referral = `${oranumBaseUrlV2}/referral-program`;
  wiki.faq = `${oranumBaseUrlV2}/oranum-faqs`;
}

export default Object.freeze(wiki);
