import React from 'react';
import type { Route } from 'contracts';

import id from './id';

const AsyncPage = React.lazy(() => import('../direct-payout'));
const AsyncSkeleton = React.lazy(() => import('../direct-payout.skeleton'));

const routes: Route[] = [
  {
    name: id,
    path: '/{lang?}/payout/direct-payout',
    element: <AsyncPage />,
    skeleton: <AsyncSkeleton />,
  },
];

export default routes;
