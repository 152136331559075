import React from 'react';
import Helmet from 'react-helmet';
import { useIntl } from 'react-intl';
import application from 'services/application';
import language from 'services/i18n/language';
import { Product } from 'contracts';

import messages from './seo.i18n';

interface SeoElement {
  title: string;
  description?: string;
  keywords?: string;
}

const Seo: React.FunctionComponent<SeoElement> = (props) => {
  const { title, description, keywords } = props;
  const { formatMessage } = useIntl();

  const getProductThemeColor = (productName: Product): string | undefined => {
    switch (productName) {
      case Product.LiveJasmin:
        return '#b32626';
      case Product.Oranum:
        return '#563375';
      default:
        break;
    }
  };

  const currentLanguage = language.current;
  const currentProduct = application.current;

  return (
    <Helmet
      encodeSpecialCharacters
      htmlAttributes={{
        lang: currentLanguage,
        'xml:lang': currentLanguage,
      }}
      titleTemplate={formatMessage(messages.pages.shared.seo[currentProduct].title)}
      title={title}
      meta={[
        { name: 'description', content: description },
        { name: 'keywords', content: keywords },
        { name: 'mobile-web-app-capable', content: 'yes' },
        { name: 'apple-mobile-web-app-capable', content: 'yes' },
        { name: 'theme-color', content: getProductThemeColor(currentProduct) },
        { name: 'msapplication-TileColor', content: getProductThemeColor(currentProduct) },
      ]}
    />
  );
};

export type { SeoElement };
export default React.memo(Seo);
