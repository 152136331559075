import React from 'react';
import { distinctUntilChanged, map } from 'rxjs';
import navigationHistory from 'store/navigation-history';
import location from 'services/router/location';
import useReadonlyObservable from 'enhancers/use-readonly-observable';
import Html from 'components/html';

import NavigationMenu from '../navigation-menu';
import { Signature } from '../signatures';
import type { ElementSignature } from '../contracts';

import { shouldDisplayLayout } from './utils';
import { testId } from './container.settings';
import styles from './container.module.scss';

interface LayoutPageContainer {
  children: React.ReactNode;
}

const Container: React.FunctionComponent<LayoutPageContainer> & ElementSignature = (props) => {
  const { children } = props;
  const [withLayout] = useReadonlyObservable(
    navigationHistory.onChange$.pipe(
      map(() => shouldDisplayLayout(location.current)),
      distinctUntilChanged()
    ),
    shouldDisplayLayout(location.current)
  );

  return (
    <Html.div
      testId={testId.page}
      className={[
        styles.container,
        'container-fluid',
        withLayout && 'flex-row',
        withLayout && styles.fixedHeight,
        styles.spaceless,
      ]}
    >
      {!withLayout && children}
      {withLayout && <NavigationMenu />}
      {withLayout && <Html.div className={[styles.wrapper, 'flex-grow-1']}>{children}</Html.div>}
    </Html.div>
  );
};

Container.signature = Signature.PAGE_CONTAINER;

export type { LayoutPageContainer };
export default Container;
