import is from 'utils/is';
import typeOf from 'utils/type-of';
import type { TypeOrArray } from 'contracts';

import tokenize from './tokenize';
import validateConstraints from './validate-constraints';
import { isLiteralToken, isParameterToken } from './utils/segment-filters';

interface CompileOptions {
  /**
   * The default delimiter for segments. (default: `'/'`)
   */
  delimiter?: string;
  /**
   * Specifies the pruning behavior for the method.
   *
   * - "all": Prunes all extraneous delimiter, both trailing and duplicated.
   * - "trailing"`: Removes only trailing delimiter.
   * - "duplication": Eliminates duplicate delimiters only.
   * - "false": Disable extraneous delimiter pruning
   *
   * Default: 'all'
   */
  prune?: 'all' | 'trailing' | 'duplication' | false;
}

type CompileMethod = (
  route: string,
  options?: CompileOptions
) => (params?: Record<string, TypeOrArray<string | number | boolean> | undefined | null>) => string;

const defaultOptions: Required<CompileOptions> = {
  delimiter: '/',
  prune: 'all',
};

const buildPruneRegex = (options: Required<CompileOptions>): RegExp => {
  const { delimiter, prune } = options;

  const behaviour: Array<Exclude<CompileOptions['prune'], 'all'>> =
    prune === 'all' ? ['trailing', 'duplication'] : [prune];
  const pattern: Array<string> = [];

  if (behaviour.includes('duplication')) {
    pattern.push(`${delimiter}(?=${delimiter})`);
  }

  if (behaviour.includes('trailing')) {
    pattern.push(`(?<!^)${delimiter}$`);
  }

  return new RegExp(pattern.join('|'), 'g');
};

const compile: CompileMethod = (route, options = {}) => {
  const { delimiter, prune } = { ...defaultOptions, ...options };
  const segments = tokenize(route);
  let compiledRoute = '';

  return (params = {}) => {
    for (let i = 0; i < segments.length; i += 1) {
      const segment = segments[i];

      if (isParameterToken(segment)) {
        const paramName = segment.name;
        const paramValue = is.array(params[paramName]) ? params[paramName].join(delimiter) : params[paramName];

        if (is.nullish(paramValue)) {
          if (segment.optional) {
            // Optional parameter missing, skip it
            continue;
          }

          throw new Error(`[Compile] Missing required parameter: ${paramName}`);
        }

        if (!is.string(paramValue) && !is.number(paramValue) && !is.bool(paramValue)) {
          throw new Error(
            `[Compile] Parameter '${paramName}' value must be a string, number or boolean,` +
              // @ts-expect-error: type inference issue
              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
              ` instead received: '${paramValue?.toString()}' (${typeOf(paramValue)}).`
          );
        }

        // Validate constraints
        if (segment.constraints.length) {
          validateConstraints(paramName, paramValue, segment.constraints);
        }

        compiledRoute += paramValue;

        continue;
      }

      if (isLiteralToken(segment)) {
        compiledRoute += segment.value;
      }
    }

    if (prune) {
      compiledRoute = compiledRoute.replace(buildPruneRegex({ delimiter, prune }), '');
    }

    return compiledRoute;
  };
};

export type { CompileOptions };
export default compile;
