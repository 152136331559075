import { defineMessages } from 'react-intl';
import type { LocalePerProduct } from 'contracts';

const seo: LocalePerProduct = {
  livejasmin: defineMessages({
    title: {
      id: 'pages.referrals.modelReferral.seo.livejasmin.title',
      defaultMessage: 'Model Referral',
    },
    description: {
      id: 'pages.referrals.modelReferral.seo.livejasmin.description',
      defaultMessage: 'Model Referral',
    },
  }),
  oranum: defineMessages({
    title: {
      id: 'pages.referrals.modelReferral.seo.oranum.title',
      defaultMessage: 'Referrals',
    },
    description: {
      id: 'pages.referrals.modelReferral.seo.oranum.description',
      defaultMessage: 'Referrals',
    },
  }),
};

export default {
  pages: {
    referrals: {
      modelReferral: {
        seo,
        navigationMenu: {
          ...defineMessages({
            label: {
              id: 'pages.referrals.modelReferral.navigationMenu.label',
              defaultMessage: 'Model Referrals',
            },
          }),
        },
        moreInfo: {
          ...defineMessages({
            label: {
              id: 'pages.referrals.modelReferral.moreInfo.label',
              defaultMessage: 'How it works?',
            },
          }),
        },
      },
    },
  },
};
