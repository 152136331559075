import { of, defer, switchMap, map, iif } from 'rxjs';
import { link } from 'services/navigation';
import authorization from 'services/authorization';
import type { NavigationMenuItem } from 'contracts';
import user from 'store/user';
import consentStore from 'store/consent';

import messages from '../services-agreement-update.i18n';

import id from './id';

const menu: NavigationMenuItem = {
  entries: [
    {
      name: id,
      label: messages.pages.servicesAgreementUpdate.navigationMenu.label,
      link: () => link[id](),
      parent: 'helpAndInfo',
      weight: 6,
      notification$: defer(() => of(-1)),
      // Menu is only visible when latest contract is not signed
      visibility$: authorization.getAuthorization$(id)!.pipe(
        switchMap((auth) =>
          iif(
            () => Boolean(user.isModelView() && auth),
            defer(() => consentStore.onChange$.pipe(map((consent) => !consent?.isSigned))),
            defer(() => of(false))
          )
        )
      ),
    },
  ],
};

export default menu;
