import is from 'utils/is';

import escapeRegex from '../utils/escape-regex';
import type { ConstraintValidation } from '../contracts';

const list: ConstraintValidation = (paramName, value, params) => {
  list.verify(paramName, params);

  const options = params?.split('|') || [];

  if (is.nullish(value) || !options.includes(value.toString())) {
    throw new Error(
      `[Constraint] Parameter "${paramName}" must be one of: ${params?.split('|').join(', ')}, instead got '${value!}'`
    );
  }
};

list.verify = () => {};

list.toRegExp = (params) => {
  const options = params.split('|').map(escapeRegex);

  return `(?:${options.join('|')})`;
};

export default list;
