import { onCLS, onFCP, onINP, onLCP, onTTFB } from 'web-vitals/attribution';
import authentication from 'services/authentication';
import application from 'services/application';
import language from 'services/i18n/language';
import shallowEqual from 'utils/shallow-equal';
import environment from 'utils/environment';

import { sendWebVitals } from './send-web-vitals';
import gTag from './g-tag';
import { TRACKING_ID } from './tracking-id';
import { convertKeyToSnakeCase } from './utils';

interface Parameters {
  userId?: string;
  viewType: string;
  userAccountType: string;
  limitedAccess: boolean;
  authenticated: boolean;
  multiplePerformers: boolean;
  testAccount: boolean;
  language: string;
}

interface GAConfig extends Parameters {
  application: 'PWA Nautilus';
  trafficType: string;
}

let customParams: Parameters;

let initialized = false;
let activated = false;

/* eslint-disable camelcase, @typescript-eslint/no-unsafe-assignment */
const init = (params: Partial<Parameters> = {}): void => {
  initialized = true;

  customParams = {
    viewType: 'model',
    userAccountType: 'user',
    language: language.current,
    limitedAccess: false,
    multiplePerformers: false,
    testAccount: false,
    authenticated: authentication.isAuthenticated(),
    ...params,
  };

  gTag('js', new Date());
  gTag(
    'config',
    TRACKING_ID,
    convertKeyToSnakeCase({
      sendPageView: false,
      transportType: 'beacon',
      appName: application.current,
      userProperties: convertKeyToSnakeCase({
        application: 'PWA Nautilus',
        trafficType: environment.current,
        ...customParams,
      } satisfies GAConfig),
    })
  );

  if (!activated) {
    gTag('set', 'allow_google_signals', false);
  }
};

const activate = (): void => {
  if (activated) {
    return;
  }

  activated = true;

  gTag('set', 'allow_google_signals', true);
};

const persist = (params: Partial<Parameters>): void => {
  if (!initialized || shallowEqual({ ...customParams, ...params }, customParams)) {
    return;
  }

  customParams = { ...customParams, ...params };

  const { userId, ...properties } = customParams;

  if (userId) {
    gTag('set', convertKeyToSnakeCase({ userId }));
  }

  init(convertKeyToSnakeCase(properties));
};

const activateWebVitals = (): void => {
  const vitals = [onFCP, onLCP, onCLS, onINP, onTTFB];

  vitals.forEach((vital) => vital?.(sendWebVitals));
};
/* eslint-enable camelcase, @typescript-eslint/no-unsafe-assignment */

export type { Parameters };
export { persist, activate, activateWebVitals };
export default init;
