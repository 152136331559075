import happen from 'utils/happen';

import type { ContestState } from './contracts';

const awardsDates = {
  promoStart: '2024-12-13T11:00:00Z',
  contestStart: '2024-12-16T11:00:00Z',
  contestEnd: '2025-01-13T11:00:00Z',
  visibilityEnd: '2025-01-28T23:00:00Z',
};

const getHolidayAwardsContestState = (date: Date): ContestState | undefined => {
  if (
    happen(date).lessThan(new Date(awardsDates.promoStart)) ||
    happen(date).greaterThanOrEqual(new Date(awardsDates.visibilityEnd))
  )
    return;

  if (happen(date).greaterThanOrEqual(new Date(awardsDates.contestEnd))) return 'review';

  if (happen(date).greaterThanOrEqual(new Date(awardsDates.contestStart))) return 'active';

  return 'promo';
};

const getIsHolidayAwardsActive = (): boolean => {
  return Boolean(getHolidayAwardsContestState(new Date()));
};

export { awardsDates, getHolidayAwardsContestState, getIsHolidayAwardsActive };
