import { BehaviorSubject, Subject, of, iif, distinctUntilChanged, switchMap, debounceTime, tap } from 'rxjs';
import type { Observable, Subscription } from 'rxjs';
import deepmerge from 'deepmerge';
import invariant from 'invariant';
import { captureException as SentryCaptureException } from 'services/sentry';
import shallowCopy from 'utils/shallow-copy';
import is from 'utils/is';

import Cache from './cache';
import mountNextStoreState from './utils/mount-next-store-state';
import type {
  AllowedDataType,
  RequestStatus,
  StoreDataType,
  StoreLifeCycle,
  StoreError,
  StoreMetadata,
  StoreSettings,
  MetadataActions,
  Logger,
} from './contracts';

/**
 * Store
 * @description Reactive application state management
 * @abstract
 * @template T
 */
/* eslint-disable no-underscore-dangle */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
abstract class Store<T extends AllowedDataType> extends Cache {
  /**
   * Data Source (a.k.a. Data Producer).
   * @description This is the data `producer` from your store, where the data will be fed.
   * @protected
   */
  protected readonly source$?: Observable<StoreDataType<T>>;

  /**
   * Close Store on Signal
   * @description Closes store on signal receive.
   * @protected
   */
  protected readonly closeOnSignal$?: Observable<unknown>;

  /**
   * Delay Store Data Update
   * @description Delay store data update when given condition is satisfied.
   * @protected
   * @abstract
   * @readonly
   */
  protected readonly delayStoreUpdateWhen?: () => boolean;

  /**
   * Store Name
   * @description Used for tracing (debug) purpose.
   * @readonly
   */
  public readonly name: string;

  /**
   * RxJs Store Behaviour Subject
   * @description Controls store's data event stream,
   * by holding both its current value and emitting data changes to all subscribers.
   * @private
   */
  private readonly __store$: BehaviorSubject<StoreDataType<T>>;

  /**
   * Store Life Cycle
   * @private
   */
  private readonly __lifeCycle: StoreLifeCycle = 'auto';

  /**
   * Reset store to its initial state once source$ property emits error
   * @private
   */
  private readonly __resetOnSourceError: boolean = true;

  private __snapshot: StoreDataType<T> | undefined;

  /**
   * RxJs Store Metadata Behaviour Subject
   * @description Controls store's Metadata event stream,
   * by holding both its current value and emitting data changes to all subscribers.
   * @private
   */
  private readonly __meta$ = new BehaviorSubject<StoreMetadata>({
    status: 'initial',
    initialized: false,
    loading: false,
    ready: false,
    error: undefined,
  });

  /**
   * RxJs Signal Subject
   * @description "__signal$" is a binary subject that is used to control the source data flow.
   * If signal is "false", then the initial value is restore; if "true", source$ is (re)fetched.
   * @private
   */
  private readonly __signal$ = new Subject<boolean>();

  /**
   * Internal __signal$ subscription.
   * @private
   */
  private __sourceSubscription: Subscription | undefined;

  /**
   * Internal closeOnSignal$ subscription.
   * @private
   */
  private __closeSubscription: Subscription | undefined;

  /**
   * Internal Store Data __queue
   * @description Only used together with "delayStoreUpdateWhen" property.
   * @private
   */
  private __queue: StoreDataType<T> | undefined = undefined;

  /**
   * Flags if store is closed
   */
  public closed = true;

  /**
   * Store Initial Data State
   * @property {T}
   * @private
   */
  readonly initialState: StoreDataType<T>;

  /**
   * Store Data
   * @description Access store's current data set.
   * @readonly
   */
  public get data(): StoreDataType<T> {
    return this.__store$.value;
  }

  /**
   * Return Store Request Status Metadata
   */
  public get status(): RequestStatus {
    return this.__meta$.value.status;
  }

  /**
   * Flags if Store is initialized
   */
  public get initialized(): boolean {
    return this.__meta$.value.initialized;
  }

  /**
   * Flags is Store is loading
   */
  public get loading(): boolean {
    return this.__meta$.value.loading;
  }

  /**
   * Flags is Store is ready
   */
  public get ready(): boolean {
    return this.__meta$.value.ready;
  }

  /**
   * Store Error Data
   * @description If "source$" by any means throws an error, this is the place you will get more details about,
   * otherwise value will be always "undefined".
   */
  public get error(): StoreError | undefined {
    return this.__meta$.value.error;
  }

  /**
   * On Store Metadata change.
   * @description Event stream to get notified when Store Metadata changes.
   */
  public get onMetaChange$(): Observable<StoreMetadata> {
    if (this.closed && this.__lifeCycle === 'auto') {
      this.init();
    }

    return this.__meta$.asObservable().pipe(distinctUntilChanged());
  }

  /**
   * On Data change.
   * @description Event stream to get notified when Store Data changes.
   * @public
   */
  public get onChange$(): Observable<StoreDataType<T>> {
    if (this.closed && this.__lifeCycle === 'auto') {
      this.init();
    }

    return this.__store$.asObservable().pipe(debounceTime(50));
  }

  /**
   * Event/Message Logger
   * @protected
   */
  protected get log(): Logger {
    return {
      error(message: unknown, origin: string): void {
        SentryCaptureException(message, {
          tags: {
            class: 'Store',
            severity: 'error',
            origin,
          },
        });
      },
      fatal(message: unknown, origin: string): void {
        SentryCaptureException(message, {
          tags: {
            class: 'Store',
            severity: 'fatal',
            origin,
          },
        });
      },
    };
  }

  /**
   * Store Metadata
   * @type {MetadataActions}
   * @protected
   */
  protected get meta(): MetadataActions {
    const { __meta$, name, log } = this;

    return {
      setReady() {
        __meta$.next({
          status: 'resolved',
          ready: true,
          loading: false,
          initialized: true,
          error: undefined,
        });
      },
      setLoading(status) {
        __meta$.next({
          status: 'pending',
          loading: status,
          ready: __meta$.value.initialized && !status,
          initialized: __meta$.value.initialized,
          error: undefined,
        });
      },
      setError(error) {
        log.error(error.details, name);

        __meta$.next({
          status: 'rejected',
          loading: false,
          ready: true,
          initialized: true,
          error,
        });
      },
      reset() {
        __meta$.next({ status: 'initial', initialized: false, loading: false, ready: false, error: undefined });
      },
    };
  }

  protected constructor(settings: StoreSettings<T>) {
    super();
    invariant(
      is.keyOf(settings, 'initialState'),
      `[Store]: "initialState" was not found. Its expected to have it declared on the constructor.`
    );

    const { name, initialState, lifeCycle = 'auto', resetOnSourceError = true } = Object.freeze(settings);

    this.name = name;
    this.initialState = shallowCopy(initialState);
    this.__store$ = new BehaviorSubject<StoreDataType<T>>(shallowCopy(initialState));
    this.__snapshot = undefined;
    this.__lifeCycle = lifeCycle;
    this.__resetOnSourceError = resetOnSourceError;
  }

  /**
   * Dispatch Store Data
   * @param {T} data
   * @private
   */
  private dispatch(data: StoreDataType<T>): void {
    const next: StoreDataType<T> = this.__queue ? deepmerge<StoreDataType<T>>(data, this.__queue) : data;

    this.__queue = undefined;

    // save as current store state
    this.__snapshot = shallowCopy(this.__store$.value);

    this.__store$.next(next);
  }

  /**
   * Connect Store to Data Source (source$)
   * @private
   */
  private connectToSource(): void {
    if (is.nullish(this.source$)) {
      if (!this.initialized) {
        this.meta.setReady();
      }

      return;
    }

    this.__closeSubscription = this.closeOnSignal$?.subscribe(() => {
      this.close();
    });

    this.__sourceSubscription = this.__signal$
      .pipe(
        // prevents duplications
        distinctUntilChanged(),
        tap(() => this.meta.reset()),
        // __signal$ represents a boolean value
        // which turns on and off the store
        // if the signal is positive, then we call the sourceObservable$
        // otherwise, we restore the initialState
        switchMap((signal) => iif(() => signal, this.source$!, of(this.initialState)))
      )
      .subscribe({
        next: (data) => {
          this.dispatch(data);

          if (!this.closed) {
            this.meta.setReady();
          }
        },
        error: (error) => {
          if (process.env.NODE_ENV === 'development') {
            console.error(`[STORE][${this.name}]:`, error);
          }

          if (this.__resetOnSourceError) {
            this.dispatch(this.initialState);
          }

          this.meta.setError({
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            details: error,
            timestamp: new Date().getTime(),
          });
        },
      });
  }

  /**
   * Initialize Store
   * @protected
   */
  protected init(): void {
    this.__sourceSubscription?.unsubscribe();
    this.__closeSubscription?.unsubscribe();

    this.connectToSource();

    this.closed = false;

    this.__signal$.next(true);
  }

  public refresh(): void {
    this.__signal$.next(false);
    this.__signal$.next(true);
  }

  /**
   * Change Store Data
   * @description Updates store data and emits "onChange$" notification to all subscribers
   * @param value
   */
  public set(value: T): void;

  /**
   * Change Store Data
   * @description Updates store data and emits "onChange$" notification to all subscribers
   * @param {string} key
   * @param {any} value
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public set<K extends keyof Exclude<T, undefined>>(key: K, value: T extends Record<any, any> ? T[K] : never): void;

  public set<K extends keyof Exclude<T, undefined>>(
    key: K | T,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value?: T extends Record<any, any> ? T[K] : never
  ): void {
    invariant(!is.object(key), `[Store]: "set" method should not be called with an object, instead got ${typeof key}.`);

    const { modified, data } = mountNextStoreState(this.__store$.value)(key, value);

    if (!modified) return;

    if (this.delayStoreUpdateWhen?.()) {
      this.__queue = is.array(data)
        ? data
        : ({ ...(this.__queue || {}), [key as string | number]: value } as StoreDataType<T>);
    } else {
      this.dispatch(data);
    }
  }

  /**
   * Turn Store off and on.
   * @description alias for `this.close() || this.init()`;`
   */
  public reset(): void {
    this.close();
    this.init();
  }

  /**
   * Close Store
   */
  public close(): void {
    this.closed = true;

    super.clearCache();

    this.__signal$.next(false);

    this.__sourceSubscription?.unsubscribe();
    this.__closeSubscription?.unsubscribe();
  }

  public revertLastChange(): void {
    if (is.nullish(this.__snapshot)) return;

    this.dispatch(this.__snapshot);
    this.__snapshot = undefined;
  }
}
/* eslint-enable no-underscore-dangle */

export type { StoreError, StoreMetadata };
export default Store;
