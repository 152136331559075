import compile from 'services/router/pathway/compile';
import tokenize from 'services/router/pathway/tokenize';
import type { SearchParameters } from 'contracts';
import type { LiteralSegment, ParameterSegment } from 'services/router/pathway/contracts';

const isParameterToken = (token: LiteralSegment | ParameterSegment): token is ParameterSegment =>
  token.type === 'parameter';

/**
 * @example
 * const queryStrings = ['page', 'period']
 * const params = { page: 1, period: '2021-07-21' };
 * compileQueryStrings(queryStrings, params); // returns '?page=1&sort=2021-07-21''
 */
const compileSearchParameters = (
  searchParams: SearchParameters = [],
  params: Record<string, string | number | boolean | undefined> = {}
): string => {
  if (!searchParams?.length) {
    return '';
  }

  const query = searchParams
    .reduce((acc, key) => {
      const pattern = `{${key}?}`;
      const [segment] = tokenize(pattern);
      const value = compile(pattern)(params);

      if (Boolean(value.toString().trim()) && isParameterToken(segment)) {
        acc.push(`${segment.name}=${encodeURIComponent(value)}`);
      }

      return acc;
    }, [] as string[])
    .join('&');

  return query.length ? `?${query}` : '';
};

export default compileSearchParameters;
