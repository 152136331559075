import { catchError, throwError, type Observable, tap } from 'rxjs';
import toastService from 'services/toast';
import is from 'utils/is';
import type { ErrorTemplate, WarningTemplate } from 'contracts/internals/toast';
import type { WarningToastNotification, ErrorToastNotification } from 'contracts';

type Prefix<P extends string, T extends string> = `${P}-${T}`;

type ErrorTemplateType = Prefix<'error', ErrorTemplate>;
type WarningTemplateType = Prefix<'warning', WarningTemplate>;

interface NotifyUserOnErrorOptions {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  skipWhen?: (error: any) => boolean;
}

const toastIds: Record<ErrorTemplateType | WarningTemplateType, symbol> = {
  'error-generic': Symbol('rxjs-error-notification'),
  'error-request-failed': Symbol('rxjs-error-notification'),
  'warning-generic': Symbol('rxjs-error-notification'),
};

const STORE = Symbol('notification-store');

const handler = {
  [STORE]: new Map<symbol, Array<NonNullable<WarningToastNotification['onClick']>>>(),
  add(key: symbol, callback: WarningToastNotification['onClick']) {
    if (is.nullish(callback)) return;

    handler[STORE].set(key, [...(handler[STORE].get(key) || []), callback]);
  },
  size(key: symbol): number {
    return (handler[STORE].get(key) || []).length;
  },
  remove(key: symbol, callback: WarningToastNotification['onClick']) {
    if (is.nullish(callback)) return;

    const list = (handler[STORE].get(key) || []).filter((cb) => cb === callback);

    if (!list.length) {
      handler[STORE].delete(key);
    }

    handler[STORE].set(key, list);
  },
  call(key: symbol) {
    (handler[STORE].get(key) || []).forEach((callback) => callback());
    handler[STORE].delete(key);
  },
};

const notifyUserOnError = <T>(
  notification: Omit<WarningToastNotification, 'id'> | Omit<ErrorToastNotification, 'id'>,
  options?: NotifyUserOnErrorOptions
): ((source$: Observable<T>) => Observable<T>) => {
  const key = is.string(notification.message) ? `${notification.type}-${notification.message}` : undefined;
  const toastId = toastIds[(key as keyof typeof toastIds) || ''] || Symbol('rxjs-error-notification');

  handler.add(toastId, notification?.onClick);

  return (source$) =>
    source$.pipe(
      catchError((error) => {
        if (options?.skipWhen?.(error)) return throwError(() => error);

        toastService.dispatch({
          id: toastId,
          closable: false,
          timeout: false,
          ...notification,
          onClick() {
            handler.call(toastId);

            toastService.remove(toastId);
          },
        });

        return throwError(() => error);
      }),
      tap(() => {
        handler.remove(toastId, notification?.onClick);

        if (!handler.size(toastId)) {
          toastService.remove(toastId);
        }
      })
    );
};

export default notifyUserOnError;
