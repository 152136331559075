import React from 'react';
import type { Route } from 'contracts';

import parentId from '../../@@global/id';

import id from './id';

const AsyncPage = React.lazy(() => import('../notes'));
const AsyncSkeleton = React.lazy(() => import('../notes.skeleton'));

const routes: Route[] = [
  {
    parent: parentId.payingMembersDetails,
    name: id.payingMembersNotes,
    path: '/notes',
    searchParams: ['search', 'order', 'tags', 'bookmark', 'page'],
    element: <AsyncPage />,
    skeleton: <AsyncSkeleton />,
  },
];

export default routes;
