import React from 'react';
import type { ToastNotification } from 'contracts';

import Icon, { IconList } from '../icon';

const priorityWeights: Record<Exclude<ToastNotification['priority'], undefined>, number> = {
  low: 1,
  medium: 2,
  high: 3,
  critical: 4,
};

const defaultTimeoutInterval = 5000;
const maxToastVisible = 3;

const generateId = (): string => Math.floor(Math.random() * Date.now()).toString();

const defaultIcons = {
  success: <Icon name={IconList.checkCircularSolid} className="color-alert-success_500" />,
  warning: <Icon name={IconList.warningCircularSolid} className="color-yellow-500" />,
  error: <Icon name={IconList.closeCircularSolid} className="color-alert-danger_500" />,
};

export { priorityWeights, defaultTimeoutInterval, maxToastVisible, generateId, defaultIcons };
