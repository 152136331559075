import type React from 'react';
import history from 'services/router/history';
import uri from 'utils/uri';
import is from 'utils/is';
import type { Search } from 'history';
import type { BrowserHistory, Parameters, ApplicationRoute, HashParameters, OmitTypeFromProperties } from 'contracts';

import createLocationDescriptor from '../navigation/utils/create-location-descriptor';

import routes from './routes';
import { getRouteParams, getSearchParams, getHashParams } from './utils/route-params';
import type { InternalRoute } from './contracts';

type HTMLAnchor = React.AnchorHTMLAttributes<HTMLAnchorElement>;

type NamedRoute = Omit<InternalRoute, 'name'> & { name: keyof ApplicationRoute };

interface LocationUrl {
  host: string;
  hostname: string;
  domain: string;
  subdomain: string | null;
  pathname: string;
  protocol: string;
  localhost: boolean;
  origin: string;
}

interface Location {
  readonly current: NamedRoute;
  readonly previous: undefined | NamedRoute;
  readonly url: LocationUrl;
  params: <T extends Parameters = Parameters>() => T;
  searchParams: (global?: boolean) => Readonly<OmitTypeFromProperties<Parameters, string[]>>;
  hashParams: (global?: boolean) => HashParameters;
  navigate: (url: string | undefined, target?: HTMLAnchor['target'], replace?: boolean) => void;
}

const findMatchingRoute = (pathname: string): NamedRoute | undefined => {
  const route = routes.findMatches(pathname).at(-1);

  if (!route) return;

  return {
    ...route,
    name: route.name as keyof ApplicationRoute,
  } satisfies NamedRoute;
};

const location: Location = {
  get current() {
    return findMatchingRoute(history.location.pathname)!;
  },
  get previous() {
    const { from } = (history as unknown as BrowserHistory).location.state || {};

    if (!from) {
      return;
    }

    return findMatchingRoute(from.pathname);
  },
  params<T extends Parameters = Parameters>(): T {
    const route = location.current;

    if (!route) return {} as T;

    return getRouteParams<T>(route.path, history.location.pathname);
  },
  searchParams(global = false) {
    const route = findMatchingRoute(history.location.pathname)!;

    if (!route) return {};

    return getSearchParams(route.searchParams, history.location.search, global);
  },
  hashParams(global = false) {
    const route = location.current;

    if (!route) return [];

    return getHashParams(route.hashParams, history.location.hash, global);
  },
  /**
   *  URL format composition:
   * ┌─────────────────────────────────────────────────────────────────────────────────────────────────────────────┐
   * │                                                   href                                                      │
   * ├──────────┬──┬─────────────────────┬─────────────────────────────┬───────────────────────────────────┬───────┤
   * │ protocol │  │        auth         │             host            │           path                    │ hash  │
   * │          │  │                     ├─────────────────────────────┬──────┼──────────┬─────────────────┤       │
   * │          │  │                     │           hostname          │ port │ pathname │     search      │       │
   * │          │  │                     │                             │      │          ├─┬───────────────┤       │
   * │          │  │                     │                             │      │          │ │    query      │       │
   * "  https:   //    user   :   pass   @     sub     .  example.com  : 8080   /p/a/t/h  ?  query=string    #hash "
   * │          │  │          │          │         hostname            │ port │          │                 │       │
   * │          │  │          │          ├─────────────────────────────┴──────┤          │                 │       │
   * │ protocol │  │ username │ password │  subdomain  |     domain    │      │ pathname │                 │       │
   * │          │  │          │          ├─────────────────────────────┴──────┤          │                 │       │
   * │ protocol │  │ username │ password │                host                │          │                 │       │
   * ├──────────┴──┼──────────┴──────────┼────────────────────────────────────┤          │                 │       │
   * │   origin    │                     │               origin               │ pathname │      search     │ hash  │
   * ├─────────────┴─────────────────────┴────────────────────────────────────┴──────────┴─────────────────┴───────┤
   * │                                                   href                                                      │
   * └─────────────────────────────────────────────────────────────────────────────────────────────────────────────┘
   */
  /**
   * Location Url
   * @returns {LocationUrl}
   */
  get url(): LocationUrl {
    const { protocol, host, hostname, pathname, origin } = window.location;

    const isIPAddress = uri.ip(hostname);

    return {
      protocol,
      host,
      hostname,
      origin,
      get domain(): string {
        if (isIPAddress) {
          return hostname;
        }

        return hostname.substring(hostname.lastIndexOf('.', hostname.lastIndexOf('.') - 1) + 1);
      },
      get subdomain(): string | null {
        if (isIPAddress) {
          return null;
        }

        const subdomain = hostname.replace(this.domain as string, '');

        return subdomain.endsWith('.') ? subdomain.substring(0, subdomain.lastIndexOf('.')) : subdomain;
      },
      get localhost(): boolean {
        return hostname === 'localhost' || hostname === '127.0.0.1';
      },
      pathname,
    };
  },
  navigate: (url, target: HTMLAnchor['target'], replace = false): void => {
    if (is.nullish(url)) return;

    if (target === '_blank' || uri.external(url)) {
      if (target !== '_blank') {
        window.sessionStorage.clear();

        void navigator?.serviceWorker?.ready?.then((registration) => {
          void registration?.active?.postMessage({ type: 'NAUTILUS_APP_UNLOAD', data: window.location.href });
        });
      }

      if (replace) {
        window.location.replace(url);

        return;
      }

      window.open(url, target || '_blank');

      return;
    }

    let sanitizedUrl = uri.absolute(url) ? url.replace(/^.*\/\/[^/]+/, '') : url;
    const search: Search = /\?.+/g.exec(sanitizedUrl)?.[0] ?? '';

    sanitizedUrl = sanitizedUrl.replace(search, '');

    const locationDescriptor = createLocationDescriptor(history, sanitizedUrl, search);

    if (replace) {
      locationDescriptor.state = locationDescriptor.state?.from?.state;

      return history.replace(locationDescriptor, locationDescriptor.state);
    }

    history.push(locationDescriptor, locationDescriptor.state);
  },
};

export type { Location };
export default location;
