import is from 'utils/is';
import happen from 'utils/happen';

import type { ConstraintValidation } from '../contracts';

const range: ConstraintValidation = (paramName, value, params) => {
  range.verify(paramName, params);

  if (is.nullish(value) || Number.isNaN(+value)) {
    throw new Error(
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      `[Constraint] Parameter '${paramName}' expects numeric value, instead got '${value}'.`
    );
  }

  const [min, max] = params.split(',').map(Number) satisfies Array<number>;
  const parsedValue = +value;

  if (!happen(parsedValue).between(min, max)) {
    throw new Error(
      `[Constraint] Parameter "${paramName}" must be a number between ${min} and ${max}, received ${value}`
    );
  }
};

range.verify = (paramName, params) => {
  const interval = params.split(',');

  if (interval.length !== 2) {
    throw new Error(
      `[Constraint] Constraint 'range' declared for '${paramName}' parameter` +
        ` expects expected 2 parameters, received ${params.length}`
    );
  }

  if (interval.some((n) => Number.isNaN(+n))) {
    throw new Error(
      `[Constraint] Constraint 'range' declared for '${paramName}' expects numeric parameters.` +
        ` Received: ${interval
          .filter((n) => Number.isNaN(+n))
          .map((n) => `'${n}'`)
          .join(', ')}.`
    );
  }
};

range.toRegExp = () => {
  return '\\d+';
};

export default range;
