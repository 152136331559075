import { BehaviorSubject, Subject, type Observable } from 'rxjs';
import is from 'utils/is';

interface Handler {
  suspender: Promise<void>;
  resolve: () => void;
}

interface ObservableResource<T> {
  readonly shouldUpdate$$: Subject<true>;
  readonly valueRef$$: BehaviorSubject<T>;
  readonly isDestroyed: boolean;
  read(): T;
  reload(newInput$?: Observable<T>): void;
  destroy(): void;
}

const defaultIsSuccess = <T>(data: T): boolean => !is.nullish(data);

/**
 * Create an Observable Resource.
 *
 * @param input$ - An Observable emitting input values.
 * @param isSuccess - A type guard function to determine if a value is successful.
 * @returns An object representing the CreateObservableResource with necessary methods and observables.
 */
function createObservableResource<T>(input$: Observable<T>, isSuccess?: (value: T) => boolean): ObservableResource<T> {
  // Subject to signal when an update should occur
  const shouldUpdate$$ = new Subject<true>();

  // BehaviorSubject to hold the current value
  const valueRef$$ = new BehaviorSubject<T>(undefined as T);

  // Internal state variables
  let error: unknown = null;
  let handler: Handler | null = getHandler();
  let isDestroyed = false;
  let currentInput$ = input$;

  const checkSuccess = isSuccess || defaultIsSuccess;

  // Subscription to the input observable
  let subscription = currentInput$.subscribe({
    next: handleNext,
    error: handleError,
    complete: handleComplete,
  });

  /**
   * Handles incoming values from the input observable.
   *
   * @param value - The emitted value from the input observable.
   */
  function handleNext(value: T): void {
    error = null;

    if (checkSuccess(value)) {
      const current = valueRef$$.value;

      if (current !== value) {
        valueRef$$.next(value);
      }

      if (handler) {
        const { resolve } = handler;

        handler = null;

        resolve();
      }

      return;
    }

    if (!handler) {
      handler = getHandler();
      shouldUpdate$$.next(true);
    }
  }

  /**
   * Handles errors from the input observable.
   *
   * @param err - The error emitted by the input observable.
   */
  function handleError(err: unknown): void {
    error = err;

    if (!handler) {
      shouldUpdate$$.next(true);

      return;
    }

    const { resolve } = handler;

    handler = null;

    resolve();
  }

  /**
   * Handles the completion of the input observable.
   */
  function handleComplete(): void {
    if (!handler) return;

    error = new Error('[Observable Resource]: Suspender ended unexpectedly.');

    const { resolve } = handler;

    handler = null;

    resolve();
  }

  /**
   * Creates a new handler for suspending operations.
   *
   * @returns A new Handler object.
   */
  function getHandler(): Handler {
    let resolveFn: () => void;

    // eslint-disable-next-line promise/avoid-new
    const suspender = new Promise<void>((resolve) => {
      resolveFn = resolve;
    });

    return {
      suspender,
      resolve: resolveFn!,
    };
  }

  /**
   * Reads the current value, throwing errors or suspending if necessary.
   *
   * @returns The current successful value.
   * @throws An error if one exists, or the suspender promise if loading.
   */
  function read(): T {
    if (error) {
      throw error;
    }

    if (handler) {
      throw handler.suspender;
    }

    return valueRef$$.value;
  }

  /**
   * Reloads the resource with an optional new input observable.
   *
   * @param newInput$ - An optional new Observable to replace the current input.
   * @throws An error if the resource has been destroyed.
   */
  function reload(newInput$?: Observable<T>): void {
    if (isDestroyed) {
      throw new Error('[Observable Resource]: Cannot reload a destroyed Observable Resource');
    }

    if (newInput$) {
      currentInput$ = newInput$;
    }

    subscription.unsubscribe();
    error = null;

    if (handler) {
      handler.resolve();
      handler = null;
    }

    subscription = currentInput$.subscribe({
      next: handleNext,
      error: handleError,
      complete: handleComplete,
    });
  }

  /**
   * Destroys the resource, cleaning up all subscriptions and handlers.
   */
  function destroy(): void {
    if (isDestroyed) return;

    isDestroyed = true;
    subscription.unsubscribe();
    shouldUpdate$$.complete();
    valueRef$$.complete();

    if (handler) {
      error = new Error('[Observable Resource]: Resource has been destroyed.');
      handler.resolve();
      handler = null;
    }

    // Nullify references
    error = null;
    subscription = null!;
    currentInput$ = null!;
  }

  return Object.freeze({
    shouldUpdate$$,
    valueRef$$,
    read,
    reload,
    destroy,
    get isDestroyed() {
      return isDestroyed;
    },
  });
}

export type { ObservableResource };
export default createObservableResource;
