import type { History, Pathname, Search } from 'history';
import type { HistoryLocation } from 'contracts';

const createLocationDescriptor = (
  history: History,
  pathname: Pathname,
  search: Search = '',
  hash = ''
): HistoryLocation => ({
  hash: hash ?? '',
  pathname,
  search,
  state: {
    from: history.location as HistoryLocation,
  },
});

export default createLocationDescriptor;
