import tree from 'utils/tree';
import toArray from 'utils/to-array';
import is from 'utils/is';

import validateRoute from '../pathway/validate-route';
import type { Route, InternalRoute, RouteMeta } from '../contracts';

import computeScore from './compute-score';

const TRAILING_AND_DUPLICATED_SLASHES = /\/(?=\/)|(?<!^)\/$/g;

const getParentPath = (parent: Route | Array<RouteMeta> | undefined): Array<string> => {
  if (is.nullish(parent)) return [''];

  if (is.array(parent)) {
    return parent.map(({ fullPath }) => fullPath);
  }

  // @ts-expect-error: type inference issue with ReadonlyArray
  return toArray(parent.path);
};

const mapToInternalRoutes = (routes: Array<Route>): Array<InternalRoute> => {
  if (!routes?.length) return [];

  const meta = new Map<string, Array<RouteMeta>>();
  const cache = new Map<string, Route>();
  const score = new WeakMap<InternalRoute, number>();

  const mountPaths = (parent: Route | undefined, route: Route): Array<RouteMeta> => {
    return getParentPath(meta.get(parent?.name ?? '') || parent).flatMap((parentPath) => {
      // @ts-expect-error: type inference issue with ReadonlyArray
      return toArray(route?.path ?? '').map((path) => {
        const index = path.replace(TRAILING_AND_DUPLICATED_SLASHES, '') === '/';
        const fullPath = `${parentPath}${path}`.replace(TRAILING_AND_DUPLICATED_SLASHES, '');
        const weight = computeScore(fullPath, index);

        return {
          index,
          fullPath,
          weight,
        };
      });
    });
  };

  const flattenedRoutes = tree.flatten(routes);

  flattenedRoutes.forEach((route) => {
    cache.set(route.name, route);
  });

  const internalRoutes: Array<InternalRoute> = flattenedRoutes.flatMap((route) => {
    const parent = cache.get(route.parent!);
    const mountedPaths: Array<RouteMeta> = mountPaths(parent, route);

    meta.set(route.name, mountedPaths);

    return mountedPaths.map(({ fullPath, weight }) => {
      if (process.env.NODE_ENV !== 'production') {
        validateRoute(fullPath);
      }

      const entry: InternalRoute = {
        ...route,
        path: fullPath,
      };

      score.set(entry, weight);

      return entry;
    });
  });

  return internalRoutes.sort((a, b) => {
    return score.get(b)! - score.get(a)!;
  });
};

export default mapToInternalRoutes;
