import { link } from 'services/navigation';
import authorization from 'services/authorization';
import type { NavigationMenuItem } from 'contracts';
import legacyId from 'pages/redirection/legacy/@@global/id';

import messages from '../income-shares.i18n';

import id from './id';

const menu: NavigationMenuItem = {
  entries: [
    {
      name: id,
      label: messages.pages.help.incomeShares.navigationMenu.label,
      link: () => link[id](),
      visibility$: authorization.getAuthorization$(id),
      parent: legacyId.help,
    },
  ],
  activates: {
    [id]: [id],
  },
};

export default menu;
