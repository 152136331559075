import type { Environment } from 'utils/environment';
import type { TypeOrArray } from 'contracts';

interface FlagSettings {
  lifetime?: 'default' | 'session';
  envBlacklist?: TypeOrArray<Environment>;
  parser?: (value: string | undefined | null) => unknown;
  enabled?: (value: unknown) => boolean;
}

const defaultFlagSettings: Required<FlagSettings> = {
  lifetime: 'default',
  envBlacklist: [],
  parser: (value) => (['1', 'true', true].includes(value ?? '') ? '1' : '0'),
  enabled: (value) => value === '1',
};

export type { FlagSettings };
export { defaultFlagSettings };
