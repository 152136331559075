import { defer, iif, map, of } from 'rxjs';
import performer from 'store/performer';
import user from 'store/user';
import evalAuthorization from 'utils/rxjs/eval-authorization';
import is from 'utils/is';
import type { RouteAuthorization } from 'contracts';

import id from './id';

const authorization: RouteAuthorization = {
  name: id,
  authorization$: user.onChange$.pipe(
    evalAuthorization(
      ({ viewTypeId }) => is.nullish(viewTypeId),
      () =>
        iif(
          () => user.isModelView(),
          defer(() =>
            performer.onStatusChange$.pipe(
              map(() => performer.hasActiveStatus() || performer.hasInactiveStatus() || performer.hasSuspendedStatus())
            )
          ),
          of(false)
        )
    )
  ),
};

export default authorization;
