// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

/** @default "OK" */
export enum ResponseStatusEnum {
  OK = 'OK',
  ERROR = 'ERROR',
}
export type ResponseRequestParams = object;
/**
 * @format int32
 * @example null
 */
export type ResponseErrorCode = number | null;
/** @example null */
export type ResponseErrorMessage = string | null;
export enum PolicyTypeEnum {
  Service = 'service',
  Privacy = 'privacy',
  DirectPayout = 'direct-payout',
}
export enum UserRoleEnum {
  PerformerPerson = 'performer-person',
  StudioOwner = 'studio-owner',
  Performer = 'performer',
}
export enum UserRoleWithUnknownEnum {
  PerformerPerson = 'performer-person',
  StudioOwner = 'studio-owner',
  Performer = 'performer',
  Unknown = 'unknown',
}
export interface ConsentListResponse {
  requestParams: ResponseRequestParams;
  status: ResponseStatusEnum;
  errorCode: ResponseErrorCode;
  errorMessage: ResponseErrorMessage;
  data: {
    items?: Consent[];
  };
}
export interface ConsentResponse {
  requestParams: ResponseRequestParams;
  status: ResponseStatusEnum;
  errorCode: ResponseErrorCode;
  errorMessage: ResponseErrorMessage;
  data: Consent;
}
export interface Consent {
  /** @format uuid */
  id: string;
  policyType: PolicyTypeEnum;
  policyVersion: PolicyVersion;
  product?: ProductEnum;
  /**
   * @format date-time
   * @example "2022-08-20T00:00:00+00:00"
   */
  createdAt: string;
  /** @example true */
  isSigned?: boolean;
  /**
   * @format date-time
   * @example "2022-08-20T00:00:00+00:00"
   */
  shouldBeSignedUntil?: string | null;
  signatures: {
    items?: Signature[];
  };
}
export interface Signature {
  /** @example 123 */
  userId: number;
  userRole: UserRoleWithUnknownEnum;
  /** @example true */
  isSigned: boolean;
}
export interface CreateSignature {
  /** @example 123 */
  userId: number;
  userRole: UserRoleEnum;
}
export interface GeneratedConsentResponse {
  requestParams: ResponseRequestParams;
  status: ResponseStatusEnum;
  errorCode: ResponseErrorCode;
  errorMessage: ResponseErrorMessage;
  data: GeneratedConsent;
}
export interface GeneratedConsent {
  /** @format uuid */
  id: string;
  policyType: PolicyTypeEnum;
  policyVersion: PolicyVersion;
  /**
   * @format date-time
   * @example "2022-08-20T00:00:00+00:00"
   */
  createdAt: string;
  /** @example true */
  isSigned?: boolean;
  /**
   * @format date-time
   * @example "2022-08-20T00:00:00+00:00"
   */
  shouldBeSignedUntil?: string | null;
  signatures: {
    items?: Signature[];
  };
  personDetails: PersonDetails;
  /**
   * @example "#Last modified: 1 March 2022\n
   * Full name: **Lorem Ipsum**"
   */
  content: string;
}
export interface PersonDetails {
  /** @example "John Doe" */
  fullName: string;
}
/** @example 105 */
export type PolicyVersion = number;
export enum ProductEnum {
  Livejasmin = 'livejasmin',
  Oranum = 'oranum',
}
export interface ConsentSignatureListRequestBody {
  users?: CreateSignature[];
}
export interface ConsentSignatureRequestBody {
  /** @example true */
  isSigned: boolean;
}
export interface ApiError {
  /** @example "Error message" */
  message: string | null;
  /**
   * @format int32
   * @example null
   */
  errorCode: number | null;
  errors: object;
}
