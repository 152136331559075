import { combineLatest, defer, iif, map, of } from 'rxjs';
import user from 'store/user';
import performer from 'store/performer';
import evalAuthorization from 'utils/rxjs/eval-authorization';
import is from 'utils/is';
import application from 'services/application';
import type { RouteAuthorization } from 'contracts';

import directPayoutStudioStore from '../direct-payout-studio.store';
import directPayoutPerformerStore from '../direct-payout-performer.store';

import id from './id';

const authorization: RouteAuthorization = {
  name: id,
  authorization$: user.onChange$.pipe(
    evalAuthorization(
      ({ viewTypeId }) => is.nullish(viewTypeId),
      () =>
        iif(
          () => user.isModelView(),
          defer(() => {
            if (!application.LiveJasmin) return of(false);

            return combineLatest([
              directPayoutPerformerStore.onAvailabilityChange$,
              performer.onChange$.pipe(map((perf) => user.isImpersonating(perf?.personIds))),
            ]).pipe(map(([availability, isImpersonating]) => availability && !isImpersonating));
          }),
          defer(() => {
            if (!application.LiveJasmin) return of(false);

            return directPayoutStudioStore.onAvailabilityChange$;
          })
        )
    )
  ),
};

export default authorization;
