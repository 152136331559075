import type {
  MyContentCommentedNotification,
  MyContentImageFolderSubscribedNotification,
  MyContentLikedNotification,
  MyContentVideoSubscribedNotification,
} from 'services/api/notifications-service/data-contracts';
import navigation from 'services/navigation';
import parse from 'utils/parse';
import type { Loyalty } from 'components/loyalty-mask/contracts';

import type { NotificationData } from '../notifications.store';

import messages from './my-content.i18n';
import { intlLocaleDefaultsRTF, mountAvatar } from './utils';
import type { Mapping } from './contracts';

const myContentHashParams = {
  // eslint-disable-next-line camelcase
  video: { videos_folder: true },
  // eslint-disable-next-line camelcase
  image: { photos_folder: true },
  // eslint-disable-next-line camelcase
  album: { photos_folder: true },
  note: { notes: true },
};

const mountMyContentLink =
  (
    privacy: MyContentLikedNotification['privacy'],
    contentType: MyContentLikedNotification['contentType'],
    contentId: string
  ): (() => void) =>
  () => {
    document.body.click();
    navigation.myContentEditor({
      type: privacy === 'exclusive' ? 'premium' : 'free',
      paymentType: privacy === 'exclusive' ? 'paying' : 'free',
      contentId,
      mediaType: contentType === 'video' ? 'video' : 'photo',
      ...myContentHashParams[contentType],
    });
  };

const getMediaPreview = (media: MyContentCommentedNotification['media']): NotificationData['preview'] => {
  return media?.hasPreview ? media?.thumbnail?.uri : true;
};

const myContentCommented: Mapping<MyContentCommentedNotification> = (response) => {
  const {
    contentId,
    contentType,
    privacy,
    member: { memberNick, loyalty },
    comment,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    media,
  } = response;

  const contentTypeNormalized = parse.toCamelCase(contentType);

  if (!contentTypeNormalized) return null;

  return {
    title: [
      messages.pages.layout.header.notifications.mappings.myContent.commented[contentTypeNormalized],
      {
        memberName: memberNick,
        ...intlLocaleDefaultsRTF,
      },
    ],
    description: comment || '',
    preview: getMediaPreview(media),
    avatar: mountAvatar.loyalty(memberNick, {
      level: loyalty?.loyaltyName as Loyalty['level'],
      isVisible: loyalty?.isVisible,
    }),
    handleClick: mountMyContentLink(privacy, contentType, contentId),
  };
};

const myContentLiked: Mapping<MyContentLikedNotification> = (response) => {
  const {
    contentId,
    contentType,
    privacy,
    member: { memberNick, loyalty },
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    media,
  } = response;

  const contentTypeNormalized = parse.toCamelCase(contentType);

  if (!contentTypeNormalized) return null;

  return {
    title: [
      messages.pages.layout.header.notifications.mappings.myContent.liked[contentTypeNormalized],
      {
        memberName: memberNick,
        ...intlLocaleDefaultsRTF,
      },
    ],
    preview: getMediaPreview(media),
    avatar: mountAvatar.loyalty(memberNick, {
      level: loyalty?.loyaltyName as Loyalty['level'],
      isVisible: loyalty?.isVisible,
    }),
    handleClick: mountMyContentLink(privacy, contentType, contentId),
  };
};

const myContentImageFolderSubscribed: Mapping<MyContentImageFolderSubscribedNotification> = (response) => {
  const {
    firstImage,
    price,
    member: { memberNick, loyalty },
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    media,
  } = response;

  return {
    title: [
      messages.pages.layout.header.notifications.mappings.myContent.imageFolderSubscribed,
      {
        ...intlLocaleDefaultsRTF,
        memberNick,
        numCredits: price || 1,
      },
    ],
    preview: getMediaPreview(media),
    avatar: mountAvatar.loyalty(memberNick, {
      level: loyalty?.loyaltyName as Loyalty['level'],
      isVisible: loyalty?.isVisible,
    }),
    handleClick: mountMyContentLink(
      firstImage?.privacy ?? 'exclusive',
      firstImage?.type ?? 'image',
      firstImage?.id ?? ''
    ),
  };
};

const myContentVideoSubscribed: Mapping<MyContentVideoSubscribedNotification> = (response) => {
  const {
    video,
    price,
    member: { memberNick, loyalty },
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    media,
  } = response;

  return {
    title: [
      messages.pages.layout.header.notifications.mappings.myContent.videoSubscribed,
      {
        ...intlLocaleDefaultsRTF,
        memberNick,
        numCredits: price || 1,
      },
    ],
    preview: getMediaPreview(media),
    avatar: mountAvatar.loyalty(memberNick, {
      level: loyalty?.loyaltyName as Loyalty['level'],
      isVisible: loyalty?.isVisible,
    }),
    handleClick: mountMyContentLink(video?.privacy ?? 'exclusive', video?.type ?? 'video', video?.id ?? ''),
  };
};

export { myContentCommented, myContentLiked, myContentImageFolderSubscribed, myContentVideoSubscribed };
