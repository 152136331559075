import type { ReactNode, ReactElement, FunctionComponent } from 'react';
import is from 'utils/is';

import { Signature } from './signatures';
import type { ElementSignature } from './contracts';

interface ElementInfo {
  readonly type: 'page' | 'page-container' | 'page-row' | undefined;
  readonly isPage: boolean;
  readonly isPageContainer: boolean;
  readonly isPageRow: boolean;
}

const element = (node: ReactNode): ElementInfo => {
  const signature: symbol | undefined = is.object<ReactElement<unknown, FunctionComponent & ElementSignature>>(node)
    ? node.type.signature
    : undefined;

  return {
    get type() {
      switch (signature) {
        case Signature.PAGE:
          return 'page';
        case Signature.PAGE_CONTAINER:
          return 'page-container';
        case Signature.PAGE_ROW:
          return 'page-row';
        default:
          return undefined;
      }
    },
    get isPage() {
      return this.type === 'page';
    },
    get isPageContainer() {
      return this.type === 'page-container';
    },
    get isPageRow() {
      return this.type === 'page-row';
    },
  };
};

export default element;
