import React from 'react';
import type { Route } from 'contracts';

import { StepRouteParam } from '../../../contracts';
import parentId from '../../../@@global/id';

import id from './id';

const AsyncPage = React.lazy(() => import('../email-confirmation'));

const routes: Route = {
  name: id,
  parent: parentId,
  path: `/${StepRouteParam.ConfirmEmailAddress}`,
  element: <AsyncPage rejected={false} onNextStep={() => null} />,
  meta: {
    layout: false,
  },
};

export default routes;
