import React from 'react';
import withGATracking from 'enhancers/with-ga-tracking';
import type { TypeOrArray } from 'contracts';

import { ComponentSignature } from './utils/component-signature';
import pipeline from './utils/pipeline';
import validate from './pipes/validate';
import InternalSelect from './internal/select';
import type { PublicComponent, PublicSelectElement, Template, TemplateOption } from './contracts';

const Select: PublicComponent<HTMLSelectElement, PublicSelectElement> = (props) => {
  const {
    testId,
    placeholder,
    searchable,
    error,
    disabled,
    className,
    style,
    children,
    loading,
    containerMaxHeight,
    isDefaultOpen,
    onChange,
    onBottomReached,
    ...rest
  } = props;

  const template = React.useMemo<Template>(() => {
    return pipeline<Template>()
      .use((arg: TypeOrArray<TemplateOption>) => validate(arg, loading, disabled))
      .process(React.Children.toArray(children));
  }, [children, loading, disabled]);

  return (
    <InternalSelect
      testId={testId}
      placeholder={placeholder}
      searchable={searchable}
      disabled={disabled}
      style={style}
      className={className}
      loading={loading}
      error={error}
      containerMaxHeight={containerMaxHeight}
      isDefaultOpen={isDefaultOpen}
      onChange={onChange}
      onBottomReached={onBottomReached}
      {...rest}
    >
      {template}
    </InternalSelect>
  );
};

Select.signature = ComponentSignature.SELECT;

export default withGATracking(Select, { action: 'select', handler: 'onChange' });
