import React from 'react';
import type { Route } from 'contracts';

import id from './id';

const AsyncPage = React.lazy(() => import('../explicit-videos'));
const AsyncSkeletonPage = React.lazy(() => import('../explicit-videos.skeleton'));

const routes: Route = {
  name: id,
  path: '/{lang?}/promotion-tools/explicit-videos',
  element: <AsyncPage />,
  skeleton: <AsyncSkeletonPage />,
};

export default routes;
