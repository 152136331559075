import navigation, { link } from 'services/navigation';
import type { Navigation, NavigationLink } from 'contracts';
import { useParams } from 'services/router/hooks';

type NavigationParams = Record<string, string>;
type UseNavigation = [navigation: Navigation, parameters: NavigationParams, link: NavigationLink];

const useNavigation = (): UseNavigation => {
  const { params, searchParams, hashParams } = useParams();

  return [
    navigation,
    { ...params, ...searchParams, ...hashParams.reduce((acc, hash) => ({ ...acc, [hash]: true }), {}) },
    link,
  ];
};

export type { UseNavigation };
export default useNavigation;
