import { defineMessages } from 'react-intl';

export default {
  pages: {
    layout: {
      header: {
        notifications: {
          mappings: {
            competitor: {
              rewarded: {
                title: defineMessages({
                  videoCallGame: {
                    id: 'pages.layout.header.notifications.mappings.competitor.rewarded.title.videoCallGame',
                    defaultMessage: 'Congrats! You won in last week’s Video Call Award!',
                  },
                  messagesGame: {
                    id: 'pages.layout.header.notifications.mappings.competitor.rewarded.title.messagesGame',
                    defaultMessage: 'Congrats! You won in last week’s Messenger Award!',
                  },
                  myStoryGame: {
                    id: 'pages.layout.header.notifications.mappings.competitor.rewarded.title.myStoryGame',
                    defaultMessage: 'Congrats! You won in last week’s Stories Award!',
                  },
                  newModelsGame: {
                    id: 'pages.layout.header.notifications.mappings.competitor.rewarded.title.newModelsGame',
                    defaultMessage: 'Congrats! You won in last week’s Newbie Award!',
                  },
                  dailyAwards: {
                    id: 'pages.layout.header.notifications.mappings.competitor.rewarded.title.dailyAwards',
                    defaultMessage: 'Congrats! You won in yesterday’s Daily Award!',
                  },
                  picturesGame: {
                    id: 'pages.layout.header.notifications.mappings.competitor.rewarded.title.picturesGame',
                    defaultMessage: 'Congrats! You won in last week’s Pictures Award!',
                  },
                  videosGame: {
                    id: 'pages.layout.header.notifications.mappings.competitor.rewarded.title.videosGame',
                    defaultMessage: 'Congrats! You won in last week’s Videos Award!',
                  },
                  oranumWeeklyVideoCallAwards: {
                    id: 'pages.layout.header.notifications.mappings.competitor.rewarded.title.oranumWeeklyVideoCallAwards',
                    defaultMessage: 'Congrats! You won in last week’s Video Call Award!',
                  },
                  oranumWeeklyMessagesAwards: {
                    id: 'pages.layout.header.notifications.mappings.competitor.rewarded.title.oranumWeeklyMessagesAwards',
                    defaultMessage: 'Congrats! You won in last week’s Messenger Award!',
                  },
                  oranumWeeklyHighlightsAwards: {
                    id: 'pages.layout.header.notifications.mappings.competitor.rewarded.title.oranumWeeklyHighlightsAwards',
                    defaultMessage: 'Congrats! You won in last week’s Highlights Award!',
                  },
                  oranumWeeklyNewModelsAwards: {
                    id: 'pages.layout.header.notifications.mappings.competitor.rewarded.title.oranumWeeklyNewModelsAwards',
                    defaultMessage: 'Congrats! You won in last week’s Newbie Award!',
                  },
                  oranumWeeklySurprisesAwards: {
                    id: 'pages.layout.header.notifications.mappings.competitor.rewarded.title.oranumWeeklySurprisesAwards',
                    defaultMessage: 'Congrats! You won in last week’s Surprises Award!',
                  },
                  interactiveToy: {
                    id: 'pages.layout.header.notifications.mappings.competitor.rewarded.title.interactiveToy',
                    defaultMessage: 'Congrats! You won in last week’s Interactive Toy Award!',
                  },
                  actions: {
                    id: 'pages.layout.header.notifications.mappings.competitor.rewarded.title.actions',
                    defaultMessage: 'Congrats! You won in last week’s Actions Award!',
                  },
                  tips: {
                    id: 'pages.layout.header.notifications.mappings.competitor.rewarded.title.tips',
                    defaultMessage: 'Congrats! You won in last week’s Tips Award!',
                  },
                  goodieBasket: {
                    id: 'pages.layout.header.notifications.mappings.competitor.rewarded.title.goodieBasket',
                    defaultMessage: 'Congrats! You won in last week’s Real Gifts Award!',
                  },
                  fanClub: {
                    id: 'pages.layout.header.notifications.mappings.competitor.rewarded.title.fanClub',
                    defaultMessage: 'Congrats! You won in last week’s Fan Club Award!',
                  },
                  newbie: {
                    id: 'pages.layout.header.notifications.mappings.competitor.rewarded.title.newbie',
                    defaultMessage: 'Congrats! You won in last week’s Newbie Award!',
                  },
                  girl: {
                    id: 'pages.layout.header.notifications.mappings.competitor.rewarded.title.girl',
                    defaultMessage: 'Congrats! You won in last week’s Girl Award!',
                  },
                  boy: {
                    id: 'pages.layout.header.notifications.mappings.competitor.rewarded.title.boy',
                    defaultMessage: 'Congrats! You won in last week’s Boy Award!',
                  },
                  guy: {
                    id: 'pages.layout.header.notifications.mappings.competitor.rewarded.title.guy',
                    defaultMessage: 'Congrats! You won in last week’s Guys Award!',
                  },
                  trans: {
                    id: 'pages.layout.header.notifications.mappings.competitor.rewarded.title.trans',
                    defaultMessage: 'Congrats! You won in last week’s Trans Stars Award!',
                  },
                  couple: {
                    id: 'pages.layout.header.notifications.mappings.competitor.rewarded.title.couple',
                    defaultMessage: 'Congrats! You won in last week’s Couple Award!',
                  },
                  lesbian: {
                    id: 'pages.layout.header.notifications.mappings.competitor.rewarded.title.lesbian',
                    defaultMessage: 'Congrats! You won in last week’s Lesbian Award!',
                  },
                  milf: {
                    id: 'pages.layout.header.notifications.mappings.competitor.rewarded.title.milf',
                    defaultMessage: 'Congrats! You won in last week’s Milf Award!',
                  },
                  asian: {
                    id: 'pages.layout.header.notifications.mappings.competitor.rewarded.title.asian',
                    defaultMessage: 'Congrats! You won in last week’s Asian Award!',
                  },
                  fetish: {
                    id: 'pages.layout.header.notifications.mappings.competitor.rewarded.title.fetish',
                    defaultMessage: 'Congrats! You won in last week’s Fetish Stars Award!',
                  },
                  soulMate: {
                    id: 'pages.layout.header.notifications.mappings.competitor.rewarded.title.soulMate',
                    defaultMessage: 'Congrats! You won in last week’s Soul Mate Award!',
                  },
                  hotFlirt: {
                    id: 'pages.layout.header.notifications.mappings.competitor.rewarded.title.hotFlirt',
                    defaultMessage: 'Congrats! You won in last week’s Hot Flirt Award!',
                  },
                  freeShow: {
                    id: 'pages.layout.header.notifications.mappings.competitor.rewarded.title.freeShow',
                    defaultMessage: 'Congrats! You won in last week’s Free Show Award!',
                  },
                  realGifts: {
                    id: 'pages.layout.header.notifications.mappings.competitor.rewarded.title.realGifts',
                    defaultMessage: 'Congrats! You won in last week’s Real Gifts Award!',
                  },
                  videoCallConversion: {
                    id: 'pages.layout.header.notifications.mappings.competitor.rewarded.title.videoCallConversion',
                    defaultMessage: 'Congrats! You won in last week’s Video Call Conversion Award!',
                  },
                  freeChatConversion: {
                    id: 'pages.layout.header.notifications.mappings.competitor.rewarded.title.freeChatConversion',
                    defaultMessage: 'Congrats! You won in last week’s Free Chat Conversion Award!',
                  },
                  hotDealConversion: {
                    id: 'pages.layout.header.notifications.mappings.competitor.rewarded.title.hotDealConversion',
                    defaultMessage: 'Congrats! You won in last week’s Hot Deal Conversion Award!',
                  },
                  vipShowConversion: {
                    id: 'pages.layout.header.notifications.mappings.competitor.rewarded.title.vipShowConversion',
                    defaultMessage: 'Congrats! You won in last week’s VIP Show Conversion Award!',
                  },
                  teaserConversion: {
                    id: 'pages.layout.header.notifications.mappings.competitor.rewarded.title.teaserConversion',
                    defaultMessage: 'Congrats! You won in last week’s Teaser Conversion Award!',
                  },
                  risingStar: {
                    id: 'pages.layout.header.notifications.mappings.competitor.rewarded.title.risingStar',
                    defaultMessage: 'Congrats! You won in last week’s Rising Stars Award!',
                  },
                  icon: {
                    id: 'pages.layout.header.notifications.mappings.competitor.rewarded.title.icon',
                    defaultMessage: 'Congrats! You won in last week’s Icons Award!',
                  },
                  superstar: {
                    id: 'pages.layout.header.notifications.mappings.competitor.rewarded.title.superstar',
                    defaultMessage: 'Congrats! You won in last week’s Superstars Award!',
                  },
                  newbieRisingStarHoliday: {
                    id: 'pages.layout.header.notifications.mappings.competitor.rewarded.title.newbieRisingStarHoliday',
                    defaultMessage: 'Congrats! You won in the Newbies & Rising Stars Holiday Edition Awards!',
                  },
                  iconSuperstarHoliday: {
                    id: 'pages.layout.header.notifications.mappings.competitor.rewarded.title.iconSuperstarHoliday',
                    defaultMessage: 'Congrats! You won in the Icons & Superstars Holiday Edition Awards!',
                  },
                }),
                ...defineMessages({
                  messageWithCredits: {
                    id: 'pages.layout.header.notifications.mappings.competitor.rewarded.messageWithCredits',
                    defaultMessage: 'Your rank is: {position}. You earned: {creditReward, number, ::currency/USD}',
                  },
                  messageWithoutCredits: {
                    id: 'pages.layout.header.notifications.mappings.competitor.rewarded.messageWithoutCredits',
                    defaultMessage: 'Your rank is: {position}.',
                  },
                }),
              },
              excluded: {
                title: defineMessages({
                  videoCallGame: {
                    id: 'pages.layout.header.notifications.mappings.competitor.excluded.title.videoCallGame',
                    defaultMessage: "Your participation in Video Call Awards' category has been revoked",
                  },
                  messagesGame: {
                    id: 'pages.layout.header.notifications.mappings.competitor.excluded.title.messagesGame',
                    defaultMessage: "Your participation in Messenger Awards' category has been revoked",
                  },
                  myStoryGame: {
                    id: 'pages.layout.header.notifications.mappings.competitor.excluded.title.myStoryGame',
                    defaultMessage: "Your participation in Stories Awards' category has been revoked",
                  },
                  newModelsGame: {
                    id: 'pages.layout.header.notifications.mappings.competitor.excluded.title.newModelsGame',
                    defaultMessage: "Your participation in Newbie Awards' category has been revoked",
                  },
                  dailyAwards: {
                    id: 'pages.layout.header.notifications.mappings.competitor.excluded.title.dailyAwards',
                    defaultMessage: "Your participation in Daily Awards' category has been revoked",
                  },
                  picturesGame: {
                    id: 'pages.layout.header.notifications.mappings.competitor.excluded.title.picturesGame',
                    defaultMessage: "Your participation in Pictures Awards' category has been revoked",
                  },
                  videosGame: {
                    id: 'pages.layout.header.notifications.mappings.competitor.excluded.title.videosGame',
                    defaultMessage: "Your participation in Videos Awards' category has been revoked",
                  },
                  oranumWeeklyVideoCallAwards: {
                    id: 'pages.layout.header.notifications.mappings.competitor.excluded.title.oranumWeeklyVideoCallAwards',
                    defaultMessage: "Your participation in Video Call Awards' category has been revoked",
                  },
                  oranumWeeklyMessagesAwards: {
                    id: 'pages.layout.header.notifications.mappings.competitor.excluded.title.oranumWeeklyMessagesAwards',
                    defaultMessage: "Your participation in Messenger Awards' category has been revoked",
                  },
                  oranumWeeklyHighlightsAwards: {
                    id: 'pages.layout.header.notifications.mappings.competitor.excluded.title.oranumWeeklyHighlightsAwards',
                    defaultMessage: "Your participation in Highlights Awards' category has been revoked",
                  },
                  oranumWeeklyNewModelsAwards: {
                    id: 'pages.layout.header.notifications.mappings.competitor.excluded.title.oranumWeeklyNewModelsAwards',
                    defaultMessage: "Your participation in Newbie Awards' category has been revoked",
                  },
                  oranumWeeklySurprisesAwards: {
                    id: 'pages.layout.header.notifications.mappings.competitor.excluded.title.oranumWeeklySurprisesAwards',
                    defaultMessage: "Your participation in Surprises Awards' category has been revoked",
                  },
                  interactiveToy: {
                    id: 'pages.layout.header.notifications.mappings.competitor.excluded.title.interactiveToy',
                    defaultMessage: "Your participation in Interactive Toys Awards' category has been revoked",
                  },
                  actions: {
                    id: 'pages.layout.header.notifications.mappings.competitor.excluded.title.actions',
                    defaultMessage: "Your participation in Actions Awards' category has been revoked",
                  },
                  tips: {
                    id: 'pages.layout.header.notifications.mappings.competitor.excluded.title.tips',
                    defaultMessage: "Your participation in Tips Awards' category has been revoked",
                  },
                  goodieBasket: {
                    id: 'pages.layout.header.notifications.mappings.competitor.excluded.title.goodieBasket',
                    defaultMessage: "Your participation in Real Gifts Awards' category has been revoked",
                  },
                  fanClub: {
                    id: 'pages.layout.header.notifications.mappings.competitor.excluded.title.fanClub',
                    defaultMessage: "Your participation in Fan Club Awards' category has been revoked",
                  },
                  newbie: {
                    id: 'pages.layout.header.notifications.mappings.competitor.excluded.title.newbie',
                    defaultMessage: "Your participation in Newbie Awards' category has been revoked",
                  },
                  girl: {
                    id: 'pages.layout.header.notifications.mappings.competitor.excluded.title.girl',
                    defaultMessage: "Your participation in Girl Awards' category has been revoked",
                  },
                  boy: {
                    id: 'pages.layout.header.notifications.mappings.competitor.excluded.title.boy',
                    defaultMessage: "Your participation in Boy Awards' category has been revoked",
                  },
                  guy: {
                    id: 'pages.layout.header.notifications.mappings.competitor.excluded.title.guy',
                    defaultMessage: "Your participation in Guys Awards' category has been revoked",
                  },
                  trans: {
                    id: 'pages.layout.header.notifications.mappings.competitor.excluded.title.trans',
                    defaultMessage: "Your participation in Trans Stars Awards' category has been revoked",
                  },
                  couple: {
                    id: 'pages.layout.header.notifications.mappings.competitor.excluded.title.couple',
                    defaultMessage: "Your participation in Couple Awards' category has been revoked",
                  },
                  lesbian: {
                    id: 'pages.layout.header.notifications.mappings.competitor.excluded.title.lesbian',
                    defaultMessage: "Your participation in Lesbian Awards' category has been revoked",
                  },
                  milf: {
                    id: 'pages.layout.header.notifications.mappings.competitor.excluded.title.milf',
                    defaultMessage: "Your participation in Milf Awards' category has been revoked",
                  },
                  asian: {
                    id: 'pages.layout.header.notifications.mappings.competitor.excluded.title.asian',
                    defaultMessage: "Your participation in Asian Awards' category has been revoked",
                  },
                  fetish: {
                    id: 'pages.layout.header.notifications.mappings.competitor.excluded.title.fetish',
                    defaultMessage: "Your participation in Fetish Stars Awards' category has been revoked",
                  },
                  soulMate: {
                    id: 'pages.layout.header.notifications.mappings.competitor.excluded.title.soulMate',
                    defaultMessage: "Your participation in Soul Mate Awards' category has been revoked",
                  },
                  hotFlirt: {
                    id: 'pages.layout.header.notifications.mappings.competitor.excluded.title.hotFlirt',
                    defaultMessage: "Your participation in Hot Flirt Awards' category has been revoked",
                  },
                  freeShow: {
                    id: 'pages.layout.header.notifications.mappings.competitor.excluded.title.freeShow',
                    defaultMessage: "Your participation in Free Show Awards' category has been revoked",
                  },
                  realGifts: {
                    id: 'pages.layout.header.notifications.mappings.competitor.excluded.title.realGifts',
                    defaultMessage: "Your participation in Real Gifts Awards' category has been revoked",
                  },
                  videoCallConversion: {
                    id: 'pages.layout.header.notifications.mappings.competitor.excluded.title.videoCallConversion',
                    defaultMessage: "Your participation in Video Call Conversion Awards' category has been revoked.",
                  },
                  freeChatConversion: {
                    id: 'pages.layout.header.notifications.mappings.competitor.excluded.title.freeChatConversion',
                    defaultMessage: "Your participation in Free Chat Conversion Awards' category has been revoked.",
                  },
                  hotDealConversion: {
                    id: 'pages.layout.header.notifications.mappings.competitor.excluded.title.hotDealConversion',
                    defaultMessage: "Your participation in Hot Deal Conversion Awards' category has been revoked.",
                  },
                  vipShowConversion: {
                    id: 'pages.layout.header.notifications.mappings.competitor.excluded.title.vipShowConversion',
                    defaultMessage: "Your participation in VIP Show Conversion Awards' category has been revoked.",
                  },
                  teaserConversion: {
                    id: 'pages.layout.header.notifications.mappings.competitor.excluded.title.teaserConversion',
                    defaultMessage: "Your participation in Teaser Conversion Awards' category has been revoked.",
                  },
                  risingStar: {
                    id: 'pages.layout.header.notifications.mappings.competitor.excluded.title.risingStar',
                    defaultMessage: "Your participation in Rising Stars Awards' category has been revoked.",
                  },
                  icon: {
                    id: 'pages.layout.header.notifications.mappings.competitor.excluded.title.icon',
                    defaultMessage: "Your participation in Icons Awards' category has been revoked.",
                  },
                  superstar: {
                    id: 'pages.layout.header.notifications.mappings.competitor.excluded.title.superstar',
                    defaultMessage: "Your participation in Superstars Awards' category has been revoked.",
                  },
                  newbieRisingStarHoliday: {
                    id: 'pages.layout.header.notifications.mappings.competitor.excluded.title.newbieRisingStarHoliday',
                    defaultMessage:
                      "Your participation in the Newbies & Rising Stars Holiday Edition Awards' category has been revoked.",
                  },
                  iconSuperstarHoliday: {
                    id: 'pages.layout.header.notifications.mappings.competitor.excluded.title.iconSuperstarHoliday',
                    defaultMessage:
                      "Your participation in the Icons & Superstars Holiday Edition Awards' category has been revoked.",
                  },
                }),
                ...defineMessages({
                  message: {
                    id: 'pages.layout.header.notifications.mappings.competitor.excluded.message',
                    defaultMessage: 'For more information, please contact Support.',
                  },
                }),
              },
            },
          },
        },
      },
    },
  },
};
