import React from 'react';
import toArray from 'utils/to-array';
import type { StyledElement, AriaAttributes, TestAutomation, Size } from 'contracts';

import Html from '../html';
import Icon, { IconList } from '../icon';

import styles from './avatar.module.scss';

interface AvatarElement extends StyledElement, AriaAttributes, TestAutomation {
  src?: string;
  icon?: IconList;
  blur?: boolean;
  size?: number;
  iconSize?: Size;
  variant?: 'light' | 'dark';
}

const Avatar = React.forwardRef<HTMLDivElement, AvatarElement>((props, ref) => {
  const {
    testId,
    variant = 'light',
    size,
    iconSize,
    icon = IconList.profileSolid,
    src,
    blur,
    className,
    style,
    ...rest
  } = props;

  const handleOnError = React.useCallback((event) => {
    // eslint-disable-next-line no-param-reassign
    event.target.style.display = 'none';
  }, []);

  return (
    <Html.div
      testId={testId}
      className={['rounded-circle', styles.avatar, styles[variant as string], ...toArray(className)]}
      style={{ ...style, height: size, width: size }}
      arias={rest}
      ref={ref}
    >
      <Icon name={icon} testId={testId && `${testId}-icon`} className={styles.icon} size={iconSize} />
      {src && (
        <Html.img
          src={src}
          testId={testId && `${testId}-image`}
          onError={handleOnError}
          loading="lazy"
          className={[styles.image, blur && styles.imageBlur]}
        />
      )}
    </Html.div>
  );
});

export type { AvatarElement };
export default Avatar;
