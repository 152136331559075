import type {
  Metric,
  LCPAttribution,
  INPAttribution,
  CLSAttribution,
  FCPAttribution,
  TTFBAttribution,
} from 'web-vitals/attribution';

import gTag from './g-tag';
import { convertKeyToSnakeCase, purgeNullishEntries } from './utils';
import { TRACKING_ID } from './tracking-id';

type MetricName = Exclude<Metric['name'], 'FID'>;
type DebugInfo = Record<string, string | number | undefined>;

type Attribution = LCPAttribution | INPAttribution | CLSAttribution | FCPAttribution | TTFBAttribution;

interface Params extends Metric {
  name: MetricName;
  attribution?: Attribution;
}

const emptyDebugTarget = '(not set)';

const getFCPDebugInfo = (attribution: FCPAttribution): DebugInfo => ({
  debugTimeToFirstByte: attribution.timeToFirstByte,
  debugTimeFirstByteToFCP: attribution.firstByteToFCP,
  debugLoadState: attribution.loadState,
});

const getLCPDebugInfo = (attribution: LCPAttribution): DebugInfo => {
  return {
    debugUrl: attribution.url,
    debugTimeToFirstByte: attribution.timeToFirstByte,
    debugResourceLoadDelay: attribution.resourceLoadDelay,
    resourceLoadDuration: attribution.resourceLoadDuration,
    debugElementRenderDelay: attribution.elementRenderDelay,
    debugTarget: attribution.element || emptyDebugTarget,
  };
};

const getCLSDebugInfo = (attribution: CLSAttribution): DebugInfo => ({
  debugTime: attribution.largestShiftTime,
  debugLoadState: attribution.loadState,
  debugTarget: attribution.largestShiftTarget || emptyDebugTarget,
});

const getINPDebugInfo = (attribution: INPAttribution): DebugInfo => ({
  debugEvent: attribution.interactionType,
  debugTime: Math.round(attribution.interactionTime),
  debugLoadState: attribution.loadState,
  debugTarget: attribution.interactionTarget || emptyDebugTarget,
  debugInteractionDelay: Math.round(attribution.inputDelay),
  debugProcessingDuration: Math.round(attribution.processingDuration),
  debugPresentationDelay: Math.round(attribution.presentationDelay),
});

const getTTFBDebugInfo = (attribution: TTFBAttribution): DebugInfo => ({
  debugConnectionDuration: attribution.connectionDuration,
  debugRequestDuration: attribution.requestDuration,
  debugCacheDuration: attribution.cacheDuration,
  debugWaitingDuration: attribution.waitingDuration,
});

const getDebugInfo = (name: MetricName, attribution?: Attribution): DebugInfo => {
  if (!attribution) {
    return { debugTarget: emptyDebugTarget };
  }

  const debugInfoHandlers: Record<MetricName, (attribution: Attribution) => DebugInfo> = {
    FCP: (attr) => getFCPDebugInfo(attr as FCPAttribution),
    LCP: (attr) => getLCPDebugInfo(attr as LCPAttribution),
    CLS: (attr) => getCLSDebugInfo(attr as CLSAttribution),
    INP: (attr) => getINPDebugInfo(attr as INPAttribution),
    TTFB: (attr) => getTTFBDebugInfo(attr as TTFBAttribution),
  };

  return debugInfoHandlers[name] ? debugInfoHandlers[name](attribution) : { debugTarget: emptyDebugTarget };
};

const sendWebVitals = (params: Params): void => {
  const { name, delta, value, id, attribution, rating } = params || {};

  if (!name) return;

  const info = {
    eventCategory: 'Web Vitals',
    eventLabel: id,
    nonInteraction: true,
    sendTo: TRACKING_ID,
    // Built-in params:
    value: delta, // Use `delta` so the value can be summed.
    // Custom params:
    metricId: id, // Needed to aggregate events.
    metricValue: value, // Value for querying in BQ
    metricDelta: delta, // Delta for querying in BQ
    metricRating: rating,
    ...getDebugInfo(name, attribution),
  };

  gTag('event', name, convertKeyToSnakeCase(purgeNullishEntries(info)));
};

export { sendWebVitals };
export type { Params };
