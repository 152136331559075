import type { FetchResponse, RequestParams } from 'services/fetch';
import fetch from 'services/fetch';

import type {
  ConsentListResponse,
  ConsentResponse,
  ConsentSignatureListRequestBody,
  ConsentSignatureRequestBody,
  GeneratedConsentResponse,
  PolicyTypeEnum,
} from './data-contracts';

/**
 * @description Get consent list
*
 * @tags Consents
 * @name GetConsentList
 * @request GET:/gw/consent/v1/policies/{policyType}/consents
 * @secure
 * @response `200` `ConsentListResponse`
 * @response `400` `(ApiError & {
  \** @example 400 *\
    errorCode?: any,

})`
 * @response `401` `{
  \** @example "access_denied" *\
    error?: string,
      \**
          * copy of "message"
          * @example "The resource owner or authorization server denied the request."
          *\
    error_description?: string,
  \** @example "Missing "Authorization" header" *\
    hint?: string | null,
      \**
          * Deprecated, use error_description instead.
          * @deprecated
          * @example "The resource owner or authorization server denied the request."
          *\
    message?: string,

}` Unauthorized error message
 * @response `403` `(ApiError & {
  \** @example 403 *\
    errorCode?: any,

})`
 * @response `404` `(ApiError & {
  \** @example 404 *\
    errorCode?: any,

})`
 * @response `429` `{
  \** @example "Request limit reached." *\
    error?: string,

}` Too many requests error message.
 * @response `500` `(ApiError & {
  \** @example 500 *\
    errorCode?: any,

})`

*/
export const getConsentList = (
  policyType: PolicyTypeEnum,
  query?: {
    /** List of user IDs */
    userIds?: number[];
  },
  params: RequestParams = {}
): FetchResponse<ConsentListResponse> => {
  return fetch<ConsentListResponse>(`/gw/consent/v1/policies/${policyType}/consents`, {
    method: 'GET',
    query: query,
    ...params,
  });
};

/**
 * @description Create a new consent
*
 * @tags Consents
 * @name CreateConsent
 * @request POST:/gw/consent/v1/policies/{policyType}/consents
 * @secure
 * @response `200` `GeneratedConsentResponse`
 * @response `400` `(ApiError & {
  \** @example 400 *\
    errorCode?: any,

})`
 * @response `401` `void`
 * @response `403` `(ApiError & {
  \** @example 403 *\
    errorCode?: any,

})`
 * @response `404` `(ApiError & {
  \** @example 404 *\
    errorCode?: any,

})`
 * @response `409` `(ApiError & {
  \** @example 409 *\
    errorCode?: any,

})`
 * @response `429` `void`
 * @response `500` `(ApiError & {
  \** @example 500 *\
    errorCode?: any,

})`

*/
export const createConsent = (
  policyType: PolicyTypeEnum,
  data: ConsentSignatureListRequestBody,
  params: RequestParams = {}
): FetchResponse<GeneratedConsentResponse> => {
  return fetch<GeneratedConsentResponse>(`/gw/consent/v1/policies/${policyType}/consents`, {
    method: 'POST',
    body: data,
    ...params,
  });
};

/**
 * @description Update consent signature
*
 * @tags Consents
 * @name UpdateConsentSignature
 * @request PATCH:/gw/consent/v1/consents/{consentId}/signatures
 * @secure
 * @response `200` `ConsentResponse`
 * @response `400` `(ApiError & {
  \** @example 400 *\
    errorCode?: any,

})`
 * @response `401` `void`
 * @response `403` `(ApiError & {
  \** @example 403 *\
    errorCode?: any,

})`
 * @response `404` `(ApiError & {
  \** @example 404 *\
    errorCode?: any,

})`
 * @response `429` `void`
 * @response `500` `(ApiError & {
  \** @example 500 *\
    errorCode?: any,

})`

*/
export const updateConsentSignature = (
  consentId: string,
  data: ConsentSignatureRequestBody,
  params: RequestParams = {}
): FetchResponse<ConsentResponse> => {
  return fetch<ConsentResponse>(`/gw/consent/v1/consents/${consentId}/signatures`, {
    method: 'PATCH',
    body: data,
    ...params,
  });
};

/**
 * @description Get generated consent
*
 * @tags Consents
 * @name GetGeneratedConsent
 * @request GET:/gw/consent/v1/consents/{consentId}/generated
 * @secure
 * @response `200` `GeneratedConsentResponse`
 * @response `400` `(ApiError & {
  \** @example 400 *\
    errorCode?: any,

})`
 * @response `401` `void`
 * @response `403` `(ApiError & {
  \** @example 403 *\
    errorCode?: any,

})`
 * @response `404` `(ApiError & {
  \** @example 404 *\
    errorCode?: any,

})`
 * @response `429` `void`
 * @response `500` `(ApiError & {
  \** @example 500 *\
    errorCode?: any,

})`

*/
export const getGeneratedConsent = (
  consentId: string,
  params: RequestParams = {}
): FetchResponse<GeneratedConsentResponse> => {
  return fetch<GeneratedConsentResponse>(`/gw/consent/v1/consents/${consentId}/generated`, {
    method: 'GET',
    ...params,
  });
};
