import { distinctUntilChanged, map } from 'rxjs';
import type { Observable } from 'rxjs';

import ServiceBase from '../service-base';

interface Container {
  /** Shows/hides the navigation menu on mobile, usually based on hamburger button click. */
  showNavigationMenu: boolean;
  /** Removes the menu completely on both mobile and desktop */
  removeMenu: boolean;
}

class ApplicationContainer extends ServiceBase<Container> {
  name = 'application-container';

  constructor() {
    super({
      initialState: {
        showNavigationMenu: false,
        removeMenu: false,
      },
    });
  }

  get onNavigationMenuVisibilityChange$(): Observable<boolean> {
    return super.onChange$.pipe(
      map((data) => data.showNavigationMenu),
      distinctUntilChanged()
    );
  }

  get onRemoveMenuChange$(): Observable<boolean> {
    return super.onChange$.pipe(
      map((data) => data.removeMenu),
      distinctUntilChanged()
    );
  }

  toggleMobileNavigationMenu(open: Container['showNavigationMenu']): void {
    super.set({ ...this.data, showNavigationMenu: open });
  }

  toggleRemoveMenu(remove: Container['removeMenu']): void {
    super.set({ ...this.data, removeMenu: remove });
  }

  get data(): Container {
    return super.data;
  }
}

export default new ApplicationContainer();
