import { map, Observable, pairwise, startWith } from 'rxjs';
import type { History, Update } from 'history';

import type { NavigationHistory } from './contracts';

type CreateNavigationHistory = (history: History) => {
  history$: Observable<Update>;
  onHistoryChange$: Observable<NavigationHistory>;
};

const mountInitialState = (history: History): NavigationHistory['current'] => ({
  get action() {
    return history.action;
  },
  get location() {
    return history.location;
  },
});

const createNavigationHistory: CreateNavigationHistory = (history) => {
  const history$ = new Observable<Update>((subscriber) => {
    return history.listen((location) => {
      subscriber.next(location);
    });
  });

  const onHistoryChange$: Observable<NavigationHistory> = history$.pipe(
    startWith(mountInitialState(history)),
    startWith(undefined),
    pairwise(),
    map(([previous, current]) => ({ previous, current: current! }) satisfies NavigationHistory)
  );

  return { history$, onHistoryChange$ };
};

export { mountInitialState };
export default createNavigationHistory;
