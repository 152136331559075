import type { Tree } from 'contracts';

import is from './is';
import shallowCopy from './shallow-copy';
import toArray from './to-array';

interface TreeMountCallback<T> {
  onMountParent?: (prev: T | undefined, next: T) => T;
  onNodePush?: (entry: T) => T | Array<T>;
}

type Comparator<T extends object> = ((node: T) => boolean) | Record<string, string>;

const compare = <T extends object>(node: T, strategy: Comparator<T>): boolean => {
  if (is.func(strategy)) {
    return strategy(node);
  }

  return Object.entries(strategy).every(([key, value]) => node[key] === value);
};

const tree = {
  /**
   * Pick and remove node using custom comparator
   * @example tree.pick([{ name: 'root }], (node) => node.name === 'root');
   */
  pluck<T extends Tree<object>>(source: Array<T>, comparator: Comparator<T>): T | null {
    if (!source) return null;

    for (let i = 0; i < source.length; i += 1) {
      const node = source[i];

      if (compare(node, comparator)) {
        return source.splice(i, 1)[0];
      }

      const result = tree.pluck(node.children as Array<T>, comparator);

      if (result) {
        return result;
      }
    }

    return null;
  },
  /**
   * Pick and remove one or more node using custom comparator
   * @example
   *  tree.pluckAll(
   *    [{ name: 'root', path: '/:lang' }, { name: 'rootFallback', path: '/:lang' }],
   *    (node) => node.path === ':/lang'
   *  );
   */
  pluckAll<T extends Tree<object>>(source: Array<T>, comparator: Comparator<T>): Array<T> | null {
    if (!source) return null;

    const found: Array<T> = [];

    for (let i = 0; i < source.length; i += 1) {
      const node = source[i];

      found.push(...(tree.pluckAll(node.children as Array<T>, comparator) || []));

      if (compare(node, comparator)) {
        found.push(...source.splice(i, 1));
        found.push(...(tree.pluckAll(source, comparator) || []));
        break;
      }
    }

    return found.length ? found : null;
  },
  /**
   * Pick a node using custom comparator
   * @example tree.pick([{ name: 'root' }], (node) => node.name === 'root');
   */
  pick<T extends Tree<object>>(source: Array<T>, comparator: Comparator<T>): T | null {
    if (!source) return null;

    for (let i = 0; i < source.length; i += 1) {
      const node = source[i];

      if (compare(node, comparator)) {
        return node;
      }

      const result = tree.pick(node.children as Array<T>, comparator);

      if (result) {
        return result;
      }
    }

    return null;
  },
  /**
   * Pick one or more node using custom comparator
   * @example
   *  tree.pickAll(
   *    [{ name: 'root', path: '/:lang' }, { name: 'rootFallback', path: '/:lang' }],
   *    (node) => node.path === ':/lang'
   *    );
   */
  pickAll<T extends Tree<object>>(source: Array<T>, comparator: Comparator<T>): Array<T> | null {
    if (!source) return null;

    const found: Array<T> = [];

    for (let i = 0; i < source.length; i += 1) {
      const node = source[i];

      (tree.pickAll(node.children as Array<T>, comparator) || []).forEach((n) => {
        found.push(n);
      });

      if (compare(node, comparator)) {
        found.push(node);
      }
    }

    return found.length ? found : null;
  },
  /**
   * Pick a node hierarchy using custom comparator
   * @example tree.pickHierarchy([{ name: 'root' }], (node) => node.name === 'root');
   */
  pickHierarchy<T extends Tree<object>>(source: Array<T>, comparator: Comparator<T>): T | null {
    if (!source) return null;

    for (let i = 0; i < source.length; i += 1) {
      const node = shallowCopy(source[i]);

      if (compare(node, comparator)) {
        return node;
      }

      const result = tree.pickHierarchy(shallowCopy(node.children) as Array<T>, comparator);

      if (result) {
        return { ...node, children: [result] };
      }
    }

    return null;
  },
  /**
   * Pick one or more node hierarchies using custom comparator
   * @example tree.pickAllHierarchy([{ name: 'root' }], (node) => node.name === 'root');
   */
  pickAllHierarchy<T extends Tree<object>>(source: Array<T>, comparator: Comparator<T>): Array<T> | null {
    if (!source) return null;

    const found: Array<T> = [];

    for (let i = 0; i < source.length; i += 1) {
      const node = source[i];

      if (compare(node, comparator)) {
        found.push(node);
      }

      const result = tree.pickHierarchy(node.children as Array<T>, comparator);

      if (result) {
        found.push({ ...node, children: [result] });
      }
    }

    return found.length ? found : null;
  },
  /**
   * Check if tree contains a node
   * @example tree.has([{ name: 'root }], { name: 'root' })
   * // outputs "true"
   */
  has<T extends Tree<object>>(source: T | Array<T>, comparator: Comparator<T>): boolean | null {
    if (!source) return null;

    return Boolean(tree.pick(toArray(source), comparator));
  },
  /**
   * Flatten tree structure
   */
  flatten<T extends Tree<object>>(source: T | Array<T>, parent?: T['parent']): Array<T> {
    if (is.nullish(source) || !toArray(source).length) return [];

    const flattened: Array<T> = [];

    for (let i = 0; i < (is.array(source) ? source.length : 1); i += 1) {
      const entry = shallowCopy(toArray(source)[i]);
      const children = [...(entry.children || [])];

      delete entry.children;

      flattened.push({
        ...entry,
        parent: entry.parent ?? parent,
      });

      if (children.length) {
        flattened.push(...(tree.flatten(children, entry.name) as Array<T>));
      }
    }

    return flattened;
  },
  /**
   * Pass through each node of a given tree
   */
  forEach<T extends Tree<object>>(
    source: Array<T>,
    callback: (entry: T, parent: T | undefined, index: number, array: Array<T>) => void,
    parent?: T
  ): void {
    if (!source?.length) return;

    const copy = shallowCopy(source);

    for (let i = 0; i < source.length; i += 1) {
      const entry = shallowCopy(copy[i]);

      callback(entry, parent, i, source);

      if (entry.children?.length) {
        tree.forEach(entry.children as Array<T>, callback, entry);
      }
    }
  },
  /**
   * Pass through each node of a given tree
   */
  map<T extends Tree<object>, U extends T>(
    source: Array<T>,
    callback: (entry: T, parent: U | undefined, index: number, array: Array<T>) => U,
    parent?: U
  ): Array<U> {
    if (!source?.length) return [];

    const copy: Array<U> = [];

    for (let i = 0; i < source.length; i += 1) {
      const entry = shallowCopy(source[i]);

      copy[i] = callback(entry, parent, i, source);

      if (entry.children?.length) {
        copy[i].children = tree.map(copy[i].children as Array<T>, callback, copy[i]);
      }
    }

    return copy;
  },

  /**
   * Pass through each node of a given tree
   */
  flatMap<T extends Tree<object>, U extends T>(
    source: Array<T>,
    callback: (entry: T, parent: U | undefined, index: number, array: Array<T>) => U | Array<U>,
    parent?: U
  ): Array<U> {
    if (!source?.length) return [];

    const copy: Array<U> = [];

    for (let i = 0; i < source.length; i += 1) {
      const entry = shallowCopy(source[i]);

      let results: Array<U> = toArray(callback(entry, parent, i, source));

      if (entry.children?.length) {
        results = results.map((result) => ({
          ...result,
          children: tree.flatMap(result.children as Array<T>, callback, result),
        }));
      }

      copy.push(...results);
    }

    return copy;
  },
  /**
   * Mount tree structure from flatten or nested array
   */
  mount<T extends Tree<object>>(source: Array<T>, callback?: TreeMountCallback<T>): Array<T> {
    const flattened = tree.flatten(source);
    const returnCalls: Required<TreeMountCallback<T>> = {
      onMountParent(prev, next) {
        return next;
      },
      onNodePush(e) {
        return e;
      },
      ...callback,
    };

    const transverse = (parent: T | undefined): Array<T> => {
      const filtered = flattened.filter((entry) => entry.parent === parent?.name);
      const data: Array<T> = [];

      for (let i = 0; i < filtered.length; i += 1) {
        const node = shallowCopy(filtered[i]);
        const children: Array<Tree<object>> = [...transverse(returnCalls.onMountParent(parent, node))];

        data.push(
          ...toArray(
            returnCalls.onNodePush({
              ...node,
              children: children.length ? children : undefined,
            })
          )
        );
      }

      return data;
    };

    if (!source) return [];

    return transverse(undefined);
  },
  sort<T extends Tree<object>>(source: Array<T>, comparator: (a: T, b: T) => number): Array<T> {
    if (!source) return [];

    return tree.map(source.sort(comparator), (entry) => {
      const node = shallowCopy(entry);

      if (node.children) {
        // @ts-expect-error: .sort method lacks generics notation
        node.children = node.children.sort(comparator);
      }

      return node;
    });
  },
};

export default tree;
