import { setContext } from '@apollo/client/link/context';
import authentication from 'services/authentication';
import authStatus from 'services/authentication/activity';
import ff from 'services/feature-toggle';

const authLink = setContext(async (_, { headers }) => {
  await authStatus.toBeIdle();

  const token = (await authentication.getAccessToken()) ?? '';

  return {
    headers: {
      ...(headers as Record<string, string>),
      Authorization: `Bearer ${token}`,
      'X-Feature-Toggles': ff.toString(),
      'X-Client-Forced-Ip': ff.get('forceGeoIP'),
    },
  };
});

export default authLink;
