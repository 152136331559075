import React from 'react';
import navigation from 'services/navigation';
import Html from 'components/html';
import type { Loyalty } from 'components/loyalty-mask/contracts';
import is from 'utils/is';
import type { TranslationParams } from 'contracts';

import type { NotificationAvatar } from '../notifications.store';

const intlLocaleDefaultsRTF: TranslationParams[1] = {
  b: (message) => <Html.strong>{message}</Html.strong>,
};

const closeNotificationPopover = (): void => document.body.click();

const mountMessengerLink =
  (
    level: 'interaction' | 'thread' | 'none' | 'spending',
    memberId: number | null,
    threadId: number | null
  ): (() => void) =>
  () => {
    closeNotificationPopover();

    if (level === 'interaction') {
      navigation.messages({ page: 'recommended', threadId: memberId?.toString() });

      return;
    }

    navigation.messages({ page: 'current-chats', threadId: threadId?.toString() ?? undefined });
  };

const mountAvatar = {
  loyalty: (memberNick: string | null, loyalty: Loyalty | undefined): NotificationAvatar =>
    ({
      initials: memberNick ?? undefined,
      loyalty: is.nullish(loyalty) ? undefined : loyalty,
      type: 'loyalty',
    }) as NotificationAvatar,
  system: (): NotificationAvatar => ({
    type: 'icon-system',
    loyalty: undefined,
    initials: undefined,
  }),
  interactiveToy: (): NotificationAvatar => ({
    type: 'icon-interactive-toy',
    loyalty: undefined,
    initials: undefined,
  }),
  battle: (): NotificationAvatar => ({
    type: 'icon-battle',
    loyalty: undefined,
    initials: undefined,
  }),
  actionRequest: (): NotificationAvatar => ({
    type: 'icon-action-request',
    loyalty: undefined,
    initials: undefined,
  }),
};

export { intlLocaleDefaultsRTF, closeNotificationPopover, mountMessengerLink, mountAvatar };
