import history from 'services/router/history';
import routesAssistant from 'services/router/routes';
import type { ApplicationRoute, Navigation, NavigationAction } from 'contracts';
import type { InternalRoute } from 'services/router/contracts';

import compileRouteToLocationDescriptor from './utils/compile-route-to-location-descriptor';
import injectCommonRouteParams from './utils/inject-common-route-params';

/**
 * @callback MountNavigation
 * @param {string} path
 * @param {SearchParameters} searchParams
 * @param {HashParameters} hashParams
 * @param {boolean} replace
 * @returns {NavigationAction}
 */
type MountNavigation = <K extends keyof ApplicationRoute>(routes: Array<InternalRoute>) => NavigationAction<K>;

const mountNavigation: MountNavigation = (routes) => {
  return (params = {}, method = undefined) => {
    for (let i = 0; i < routes.length; i += 1) {
      try {
        const route = routes[i];
        const shouldReplaceHistory = method ? method === 'replace' : route.replace;

        const location = compileRouteToLocationDescriptor(
          history,
          route
        )(injectCommonRouteParams(params as Record<string, string | number | undefined>));

        if (shouldReplaceHistory) {
          return history.replace(location, location.state);
        }

        history.push(location, location.state);
      } catch (error) {
        console.error('[NAVIGATION]', error);
      }
    }
  };
};

const navigation: Navigation = routesAssistant.getRoutesName().reduce(
  (acc, name) => ({
    ...acc,
    [name]: mountNavigation(routesAssistant.findRoutesByName(name)),
  }),
  {} as Navigation
);

export default navigation;
