import is from 'utils/is';

import type { ConstraintValidation } from '../contracts';

const regex: ConstraintValidation = (paramName, value, params) => {
  regex.verify(paramName, params);

  const regExp = new RegExp(params);

  if (is.nullish(value) || !params?.trim() || !regExp.test(value.toString())) {
    throw new Error(`[Constraint] Parameter "${paramName}" failed to match the regular expression ${params}'`);
  }
};

regex.verify = () => {};

regex.toRegExp = (params) => {
  let sanitized = params;

  if (params.startsWith('^')) {
    sanitized = sanitized.substring(1);
  }

  if (params.endsWith('$')) {
    sanitized = sanitized.slice(0, -1);
  }

  return sanitized;
};

export default regex;
