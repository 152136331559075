import type { CookieAttributes } from 'js-cookie';
import settings from 'configurations/application';
import env from 'utils/environment';
import cookies from 'utils/cookies';

import location from '../router/location';

import type {
  Authentication,
  OAuthCommonRequest,
  OAuthResponse,
  PasswordGrantRequest,
  RefreshAccessRequest,
  RevokeTokenRequest,
} from './contracts';
// eslint-disable-next-line no-duplicate-imports
import { OAuthAction } from './contracts';

const defaultRefreshTokenLifetime = 604800; // in seconds

const secondsToMilliseconds = (seconds: number): number => seconds * 1000;

const mountDate = (seconds: number): Date => new Date(new Date().getTime() + secondsToMilliseconds(seconds));

/* eslint-disable camelcase */
const utils = {
  request: {
    mountBody<
      A extends OAuthAction,
      T = A extends OAuthAction.GET_TOKEN
        ? PasswordGrantRequest
        : A extends OAuthAction.REFRESH_TOKEN
          ? RefreshAccessRequest
          : RevokeTokenRequest,
    >(action: A, data: T): T & OAuthCommonRequest {
      const credentials = {
        client_id: settings.envVars.oauthClientId,
        client_secret: settings.envVars.oauthClientSecret,
      };

      if (action === OAuthAction.REVOKE_TOKEN) {
        return {
          ...credentials,
          ...data,
        };
      }

      return {
        ...credentials,
        grant_type: action === OAuthAction.GET_TOKEN ? 'password' : 'refresh_token',
        scope: '*',
        ...data,
      };
    },
  },
  response: {
    map({ access_token, refresh_token, expires_in }: OAuthResponse): Authentication {
      return {
        expiresAt: mountDate(expires_in).getTime(),
        accessToken: access_token,
        refreshToken: refresh_token,
      };
    },
  },
  cookies: {
    CACHE_KEY: cookies.nameCreator('essential', 'auth'),
    attributes(refreshTokenLifetime: number = defaultRefreshTokenLifetime): CookieAttributes {
      const domain = !env.isLocalhost
        ? `.${location.url.subdomain!.split('.').splice(-1)[0]}.${location.url.domain}`
        : undefined;

      return {
        expires: mountDate(refreshTokenLifetime),
        domain,
      };
    },
  },
  token: {
    hasExpired(timestamp: number): boolean {
      return new Date().getTime() > timestamp;
    },
  },
};
/* eslint-enable camelcase */

export { mountDate, secondsToMilliseconds };
export default utils;
