import React from 'react';
import { combineLatest, fromEvent, map, tap } from 'rxjs';
import memoizee from 'memoizee';
import application from 'services/application';
import menu from 'services/navigation/menu';
import navigationHistory from 'store/navigation-history';
import location from 'services/router/location';
import useReadonlyObservable from 'enhancers/use-readonly-observable';
import Html from 'components/html';
import tree from 'utils/tree';
import is from 'utils/is';
import type { NavigationMenuItem } from 'contracts';

import Item from './item';
import TopProfile from './top-profile';
import { testId } from './navigation-menu.settings';
import styles from './navigation-menu.module.scss';

const itemsToShimmer = 10;

type NavigationMenuEntries = Required<NavigationMenuItem>['entries'];

type Authorization = Map<NavigationMenuEntries[0]['visibility$'], boolean>;

const pickAllHierarchy = memoizee(tree.pickAllHierarchy);

const NavigationMenu: React.FunctionComponent<unknown> = () => {
  const ref = React.useRef<HTMLDivElement>(undefined);
  const [menuHidden, setMenuHidden] = React.useState<boolean>(false);
  const [showNavigationMenu] = useReadonlyObservable<boolean>(
    application.container.onNavigationMenuVisibilityChange$,
    application.container.data.showNavigationMenu
  );
  const [removeMenu] = useReadonlyObservable<boolean>(
    application.container.onRemoveMenuChange$.pipe(tap((remove) => !remove && setMenuHidden(false))),
    application.container.data.removeMenu
  );
  const [activeMenu, setActiveMenu] = React.useState<NavigationMenuEntries | undefined>(undefined);
  const [authorization] = useReadonlyObservable(
    combineLatest([
      ...tree
        .pickAll(menu.entries, (node) => !is.nullish(node.visibility$))!
        .map(({ name, visibility$ }) => visibility$!.pipe(map((status) => ({ name, status, visibility$ })))),
    ]).pipe(
      map(
        (
          auth: Array<{
            name: string;
            status: boolean | undefined;
            visibility$: Required<NavigationMenuItem>['entries'][0]['visibility$'];
          }>
        ) => {
          if (auth.some(({ status }) => is.nullish(status))) {
            return undefined;
          }

          const mapper = new Map<Required<NavigationMenuItem>['entries'][0]['visibility$'], boolean>();

          auth.forEach(({ visibility$, status }) => {
            mapper.set(visibility$, status!);
          });

          return mapper;
        }
      )
    ),
    undefined as Authorization | undefined
  );

  React.useEffect(() => {
    if (!ref.current) return;

    const subscription = fromEvent(ref.current, 'animationend').subscribe(() => {
      setMenuHidden(true);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  React.useEffect(() => {
    const subscription = navigationHistory.onChange$.subscribe(() => {
      const { current, params, searchParams, hashParams } = location;

      const menuItem = pickAllHierarchy(menu.entries, ({ name }) => {
        const targets = menu.activates[current.name];

        if (!targets) return false;

        return Boolean(
          targets.find((entry) => {
            if (is.string(entry)) {
              return entry === name;
            }

            return entry(params(), searchParams(), hashParams()) === name;
          })
        );
      });

      setActiveMenu(menuItem || undefined);

      if (application.container.data.showNavigationMenu) {
        application.container.toggleMobileNavigationMenu(false);
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const handleOnScrimClick = React.useCallback(() => {
    application.container.toggleMobileNavigationMenu(false);
  }, []);

  const renderMenu = (
    entries: NavigationMenuEntries,
    activeNodes: NavigationMenuEntries | undefined,
    shimmer = false
  ): React.ReactNode => {
    return entries.map((entry, idx) => {
      const activeNode = activeNodes?.find((node) => node?.name === entry.name);

      return (
        <Item
          key={entry.name}
          testId={testId.navigationMenuItem}
          label={entry.label}
          link={entry?.link}
          new={entry?.new}
          icon={entry?.icon}
          notification$={entry?.notification$}
          warning$={entry?.warning$}
          visibility={authorization?.get(entry.visibility$)}
          active={!is.nullish(authorization) && activeNode?.name === entry.name}
          locationPathname={window.location.pathname}
          shimmer={shimmer && idx < itemsToShimmer}
          loading={is.nullish(authorization)}
        >
          {Boolean(entry.children?.length) && renderMenu(entry.children!, activeNode?.children)}
        </Item>
      );
    });
  };

  return (
    <React.Fragment>
      <Html.div className={[styles.scrim, showNavigationMenu && styles.open]} onClick={handleOnScrimClick} />
      <Html.div
        testId={testId.navigationMenu}
        className={[
          'rounded-start-lg-1',
          styles.navigationMenu,
          showNavigationMenu && styles.open,
          removeMenu && styles.navigationMenuAnimate,
          removeMenu && menuHidden && styles.navigationMenuHidden,
        ]}
        ref={ref}
      >
        <TopProfile />
        {renderMenu(menu.entries, activeMenu, true)}
      </Html.div>
    </React.Fragment>
  );
};

export { NavigationMenu };
export default React.memo(NavigationMenu);
