import React from 'react';
import toArray from 'utils/to-array';
import type { StyledElement, AriaAttributes, TestAutomation } from 'contracts';
import { Product } from 'contracts';
import Html from 'components/html';
import Icon, { IconList } from 'components/icon';
import LoyaltyMask from 'components/loyalty-mask';
import LoyaltyMaskHidden from 'components/loyalty-mask-hidden';
import useProductModule from 'enhancers/use-product-module';
import is from 'utils/is';

import type { NotificationAvatar as NotificationAvatarInterface } from '../../notifications.store';

import styles from './notification-avatar.module.scss';

interface NotificationAvatarElement extends StyledElement, AriaAttributes, TestAutomation, NotificationAvatarInterface {
  unread: boolean;
}

const NotificationAvatar: React.FunctionComponent<NotificationAvatarElement> = (props) => {
  const { testId, className, style, type, loyalty, initials, unread, ...rest } = props;
  const id = React.useId();

  const MobileLogo = useProductModule<React.FunctionComponent<Partial<SVGAElement>>>(
    (product) =>
      product === Product.Oranum
        ? import(`assets/logo/header/${product}-mini.svg`)
        : import(`assets/logo/header/${product}-model-mobile.svg`),
    []
  );

  const [content, appendedClassName] = React.useMemo(() => {
    switch (type) {
      case 'loyalty': {
        if (loyalty?.isVisible === false) {
          return [<LoyaltyMaskHidden key={id} size={28} />, styles.loyaltyBackground];
        }

        if (is.nullish(loyalty) || is.nullish(loyalty.level)) {
          return [
            <Html.span key={id} typography="headline3" fontWeight="medium" className="m-0">
              {initials && initials?.substring(0, 1).toUpperCase()}
            </Html.span>,
            styles.loyaltyBackground,
          ];
        }

        return [<LoyaltyMask key={id} loyalty={loyalty.level} size={28} />, styles.loyaltyBackground];
      }

      case 'icon-battle':
        return [<Icon key={id} name={IconList.battleSolid} className={[styles.icon]} />, styles.iconBackground];

      case 'icon-action-request':
        return [<Icon key={id} name={IconList.actionListSolid} className={[styles.icon]} />, styles.iconBackground];

      case 'icon-interactive-toy':
        return [<Icon key={id} name={IconList.interactiveToySolid} className={[styles.icon]} />, styles.iconBackground];

      default:
        return [
          <React.Suspense key={id}>
            <MobileLogo className={[styles.logo]} />
          </React.Suspense>,
          styles.iconBackground,
        ];
    }
  }, [type, loyalty, id, MobileLogo, initials]);

  return (
    <Html.div
      testId={testId}
      className={[
        'rounded-circle',
        styles.notificationAvatar,
        appendedClassName,
        unread && styles.unread,
        ...toArray(className),
      ]}
      style={style}
      arias={rest}
    >
      {content}
    </Html.div>
  );
};

export type { NotificationAvatarElement };
export { NotificationAvatar };
export default React.memo(NotificationAvatar);
