import { of } from 'rxjs';
import evalAuthorization from 'utils/rxjs/eval-authorization';
import type { RouteAuthorization } from 'contracts';
import user from 'store/user';
import is from 'utils/is';

import id from './id';

const authorization: RouteAuthorization = {
  name: id,
  authorization$: user.onChange$.pipe(
    evalAuthorization(
      ({ viewTypeId }) => is.nullish(viewTypeId),
      () => of(user.isModelView())
    )
  ),
};

export default authorization;
