import React from 'react';
import { useIntl } from 'react-intl';
import appLanguage from 'services/i18n/language';
import location from 'services/router/location';
import application from 'services/application';
import ff from 'services/feature-toggle';
import useNavigation from 'enhancers/use-navigation';
import useReadonlyObservable from 'enhancers/use-readonly-observable';
import authentication from 'services/authentication';
import Html from 'components/html';
import Divider from 'components/divider';
import Link from 'components/link';
import Icon from 'components/icon';
import Select, { Option } from 'components/select';
import settings from 'configurations/application/config';
import Asacp from 'assets/badges/asacp.svg';
import Icra from 'assets/badges/icra.svg';
import Rta from 'assets/badges/rta.svg';
import OranumLogo from 'assets/logo/footer/oranum.svg';
import className from 'utils/class-name';
import type { IconList } from 'components/icon';
import type { PublicSelectElement } from 'components/select';

import messages from './footer.i18n';
import { testId, socialNetworks, workWithUs, legalAndSafety, helpAndSupport } from './footer.settings';
import type { FooterLink } from './footer.settings';
import styles from './footer.module.scss';

const Footer: React.FunctionComponent<unknown> = () => {
  const { formatMessage } = useIntl();
  const [navigation, , link] = useNavigation();
  const [currentLanguage] = useReadonlyObservable(appLanguage.onChange$, appLanguage.current);
  const [userAuthenticated] = useReadonlyObservable(authentication.event.onChange$);
  const langCodes = settings.i18n.languages.filter((lang) => lang !== 'hi' || ff.enabled('enableHindi'));

  const handleOnLangChange: PublicSelectElement['onChange'] = React.useCallback(
    (value): void => {
      navigation[location.current.name.toString()](
        { ...location.params(), lang: value, ...location.searchParams(true) },
        'replace'
      );
    },
    [navigation]
  );

  const currentProduct = application.current;

  const renderLinks = React.useCallback(
    function Render({ locale, url }: FooterLink): React.ReactNode {
      return (
        <Html.p key={locale.id} typography="body2" className={['mb-2']}>
          <Link href={url} target="_blank" className={styles.link}>
            {formatMessage(locale)}
          </Link>
        </Html.p>
      );
    },
    [formatMessage]
  );

  return (
    <Html.footer testId={testId.footer} className={styles.footer}>
      <Html.div className={['container-fluid']}>
        <Html.div className={['row', 'py-6']}>
          <Html.div testId={testId.socialNetworks} className={['col-12', 'col-md-3', 'col-xxl-2']}>
            <Html.h6 typography="body2" className={[styles.title, 'pb-2', 'mb-2']} fontWeight="bold">
              {formatMessage(messages.pages.layout.footer.socialNetworks.title, { product: currentProduct })}
            </Html.h6>
            {socialNetworks[currentProduct]?.map(({ icon, locale, url }) => (
              <Html.p key={locale.id} typography="body2" className={['mb-2']}>
                <Link href={url} target="_blank" className={styles.link}>
                  <Icon name={icon as IconList} size={20} className="me-1" />
                  {formatMessage(locale)}
                </Link>
              </Html.p>
            ))}
          </Html.div>
          <Html.div testId={testId.workWithUs} className={['col-12', 'col-md-3', 'col-xxl-2', 'mt-6', 'mt-md-0']}>
            <Html.h6 typography="body2" className={[styles.title, 'pb-2', 'mb-2']} fontWeight="bold">
              {formatMessage(messages.pages.layout.footer.workWithUs.title)}
            </Html.h6>
            {workWithUs(userAuthenticated)
              [currentProduct].filter(({ hidden }) => !hidden)
              .map(renderLinks)}
          </Html.div>
          <Html.div testId={testId.legalAndSafety} className={['col-12', 'col-md-3', 'col-xxl-2', 'mt-6', 'mt-md-0']}>
            <Html.h6 typography="body2" className={[styles.title, 'pb-2', 'mb-2']} fontWeight="bold">
              {formatMessage(messages.pages.layout.footer.legalAndSafety.title)}
            </Html.h6>
            {legalAndSafety.map(renderLinks)}
          </Html.div>
          <Html.div testId={testId.helpAndSupport} className={['col-12', 'col-md-3', 'col-xxl-2', 'mt-6', 'mt-md-0']}>
            <Html.h6 typography="body2" className={[styles.title, 'pb-2', 'mb-2']} fontWeight="bold">
              {formatMessage(messages.pages.layout.footer.helpAndSupport.title)}
            </Html.h6>
            {helpAndSupport[currentProduct]?.map(renderLinks)}
          </Html.div>
          <Html.div className={['col-12', 'col-xxl-4', 'mt-6', 'mt-xxl-0', 'text-xxl-end']}>
            {application.Oranum && (
              <Html.p testId={testId.logo} className={['mt-0', 'mb-16', 'd-none', 'd-xxl-block']}>
                <OranumLogo width={167} />
              </Html.p>
            )}
            {application.LiveJasmin && (
              <Html.p testId={testId.disclaimer} typography="caption" className={['mb-2']}>
                {formatMessage(messages.pages.layout.footer.disclaimer, {
                  a: (value: React.ReactNode) => {
                    return (
                      <Link href={link.cookies()} target="_blank" className={styles.link}>
                        {value}
                      </Link>
                    );
                  },
                })}
              </Html.p>
            )}
            {application.LiveJasmin && (
              <Html.p testId={testId.compliance} typography="caption" className={['mb-2']}>
                {formatMessage(messages.pages.layout.footer.compliance, {
                  a: (value: React.ReactNode) => {
                    return (
                      <Link
                        href="https://www.livejasmin.com/en/terms-and-conditions#address"
                        target="_blank"
                        className={styles.link}
                      >
                        {value}
                      </Link>
                    );
                  },
                })}
              </Html.p>
            )}
            <Html.p testId={testId.operation} typography="caption" className={['mb-2']}>
              {formatMessage(messages.pages.layout.footer.operation, {
                a1: (value: React.ReactNode) => {
                  return (
                    <Link href="https://www.jwsamericas.com/" target="_blank" className={styles.link}>
                      {value}
                    </Link>
                  );
                },
                a2: (value) => {
                  return (
                    <Link href="https://www.jwsinternational.com/" target="_blank" className={styles.link}>
                      {value}
                    </Link>
                  );
                },
              })}
            </Html.p>
            {application.LiveJasmin && (
              <Html.div testId={testId.badges} className={['col-12', 'mt-6', 'mt-xxl-8']}>
                <Link href="https://www.rtalabel.org/" target="_blank">
                  <Rta className={styles.badge} />
                </Link>

                <Link href="https://www.asacp.org/ASACP.php" target="_blank">
                  <Asacp className={className(styles.badge, 'mx-6')} />
                </Link>
                <Link href="https://www.fosi.org/icra/" target="_blank">
                  <Icra className={styles.badge} />
                </Link>
              </Html.div>
            )}
          </Html.div>
        </Html.div>
      </Html.div>
      <Divider theme="dark" />
      <Html.div className={['container-fluid']}>
        <Html.div className={['row', 'pb-4']}>
          <Html.div
            testId={testId.copyright}
            typography="caption"
            className={['col-12', 'col-md-6', 'align-items-center', 'd-flex']}
          >
            {formatMessage(messages.pages.layout.footer.copyright, {
              year: new Date().getFullYear(),
              product: settings.brandDomains[currentProduct],
            })}
          </Html.div>
          <Html.div className={['col-12', 'col-md-6', 'mt-4', 'mt-md-0', 'text-md-end']}>
            <Select testId={testId.languageSelection} onChange={handleOnLangChange} className={styles.language}>
              {langCodes.map((lang) => (
                <Option key={lang} value={lang} selected={lang === currentLanguage}>
                  {formatMessage(
                    messages.pages.layout.footer.languages[lang as keyof typeof messages.pages.layout.footer.languages]
                  )}
                </Option>
              ))}
            </Select>
          </Html.div>
        </Html.div>
      </Html.div>
    </Html.footer>
  );
};

export default Footer;
