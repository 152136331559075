import { switchMap, map, tap, distinctUntilChanged, catchError, of } from 'rxjs';
import type { Observable } from 'rxjs';
import { getDirectPayoutPerformer } from 'services/api/proxy-direct-payout/bff';
import { Store } from 'store';
import user from 'store/user';
import notifyUserOnError from 'store/utils/notify-user-on-error';
import progressiveRetry from 'utils/rxjs/progressive-retry';
import type { DirectPayoutPerformer as APIDirectPayout } from 'services/api/proxy-direct-payout/data-contracts';
import filterWhileNullish from 'utils/rxjs/filter-while-nullish';

type DirectPayoutPerformerStore = Omit<APIDirectPayout, 'profilePictures'>;

class DirectPayoutPerformer extends Store<DirectPayoutPerformerStore | undefined> {
  source$ = user.onModelChange$.pipe(
    tap(() => {
      super.meta.setLoading(true);
    }),
    switchMap(({ viewTypeId }) =>
      getDirectPayoutPerformer(viewTypeId, {
        headers: {
          'X-Actor-Type': 'performer',
          'X-Actor-Id': viewTypeId,
        },
      }).pipe(
        progressiveRetry(),
        notifyUserOnError({ type: 'error', message: 'request-failed', onClick: () => this.reset() }),
        map((response) => response.data.data),
        catchError(() => of(undefined))
      )
    )
  );

  closeOnSignal$ = user.onViewTypeChangeToStudio$;

  constructor() {
    super({ name: 'direct-payout-performer', initialState: undefined });
  }

  get onChange$(): Observable<DirectPayoutPerformerStore> {
    return super.onChange$.pipe(filterWhileNullish());
  }

  get onAvailabilityChange$(): Observable<boolean> {
    return this.onChange$.pipe(
      distinctUntilChanged(),
      map(
        ({ directPayoutStatus, directPayoutFlowStatus }) =>
          directPayoutStatus !== 'missing' &&
          directPayoutFlowStatus !== 'created' &&
          directPayoutFlowStatus !== 'rejectedByPerformerPersons'
      )
    );
  }

  setStatus(status: DirectPayoutPerformerStore['directPayoutStatus']): void {
    this.set('directPayoutStatus', status);
  }

  setTechnicalStudioEmail(email: DirectPayoutPerformerStore['technicalStudioEmail']): void {
    this.set('technicalStudioEmail', email);
  }
}

export type { DirectPayoutPerformerStore };
export default new DirectPayoutPerformer();
