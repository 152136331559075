import React from 'react';
import type { Route } from 'contracts';

import id from './id';

const AsyncPage = React.lazy(() => import('../holiday-awards'));
const AsyncSkeletonPage = React.lazy(() => import('../leaderboard/leaderboard.skeleton'));

const routes: Route = {
  name: id,
  path: '/{lang?}/bestof/holiday-awards',
  element: <AsyncPage />,
  skeleton: <AsyncSkeletonPage />,
};

export default routes;
