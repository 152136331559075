import { distinctUntilChanged } from 'rxjs';
import type { Observable } from 'rxjs';
import location from 'services/router/location';
import settings from 'configurations/application';

import ServiceBase from '../service-base';
import Cache from '../cache';

import { getBrowserPreferredLanguage } from './browser-languages';

const cache = new Cache();

const { i18n } = settings;
const CACHE_KEY_LANGUAGE = 'language';

const persist = (lang: string | null): string | undefined => {
  if (!lang) return;

  cache.set(CACHE_KEY_LANGUAGE, lang?.toLowerCase());

  return lang;
};

const getRouteLanguage = (): string | null => {
  const { lang } = location.params<{ lang: string | null }>();

  if (!i18n.languages.includes(lang?.toLowerCase() ?? '')) {
    return null;
  }

  return lang?.toLowerCase() ?? null;
};

const getCachedLanguage = (): string | null => {
  const lang = cache.get(CACHE_KEY_LANGUAGE);

  if (!i18n.languages.includes(lang?.toLowerCase() ?? '')) {
    return null;
  }

  return lang;
};

class Language extends ServiceBase<string> {
  name = 'language';

  constructor() {
    super({
      initialState:
        persist(getRouteLanguage()) || getCachedLanguage() || getBrowserPreferredLanguage() || i18n.defaultLanguage,
    });
  }

  get onChange$(): Observable<string> {
    return super.onChange$.pipe(distinctUntilChanged());
  }

  get current(): string {
    const { lang } = location.params<{ lang: string | null }>();

    if (i18n.languages.includes(lang ?? '')) {
      this.set(lang!);

      return lang!;
    }

    return this.data;
  }

  set(language: string): void {
    if (!settings.i18n.languages.includes(language?.toLowerCase())) return;

    persist(language?.toLowerCase());

    super.set(language?.toLowerCase());
  }
}

export default new Language();
