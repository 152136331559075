import { isObservable, type Observable } from 'rxjs';
import is from 'utils/is';

class Cache {
  private cache = new Map<string, Observable<unknown>>();

  constructor() {
    // eslint-disable-next-line no-constructor-return
    return new Proxy(this, {
      get: (target, property, receiver) => {
        const isMockFunction =
          process.env.NODE_ENV === 'test' &&
          // @ts-expect-error: custom property defined on create-mock-store-from
          // eslint-disable-next-line no-underscore-dangle
          (Boolean(this._isMockStore) ||
            // @ts-expect-error: jest custom property
            // eslint-disable-next-line no-underscore-dangle
            Boolean(Reflect.getOwnPropertyDescriptor(target, property)?.get?._isMockFunction));

        if (!isMockFunction && is.string(property) && !property.startsWith('__') && isObservable(target[property])) {
          if (!this.cache.has(property)) {
            this.cache.set(property, Reflect.get(target, property, receiver) as Observable<unknown>);
          }

          return this.cache.get(property);
        }

        // Default behavior if it's not an intercepted method
        return Reflect.get(target, property, receiver);
      },
    });
  }

  protected clearCache(): void {
    this.cache.clear();
  }
}

export default Cache;
