import { link } from 'services/navigation';
import wiki from 'services/wiki';
import { IconList } from 'components/icon';
import parse from 'utils/parse';
import { Product } from 'contracts';
import type { MessageDescriptor } from 'react-intl';

import messages from './footer.i18n';

interface FooterLink {
  locale: MessageDescriptor;
  url: string;
}

interface SocialLink extends FooterLink {
  icon: string;
}

const makeTestId = (...ids: string[]): string => parse.toTestId('footer', ...ids);

const testId = Object.freeze({
  footer: makeTestId(),
  socialNetworks: makeTestId('social-networks'),
  workWithUs: makeTestId('work-with-us'),
  legalAndSafety: makeTestId('legal-and-safety'),
  helpAndSupport: makeTestId('help-and-support'),
  logo: makeTestId('logo'),
  disclaimer: makeTestId('disclaimer'),
  compliance: makeTestId('compliance'),
  operation: makeTestId('operation'),
  badges: makeTestId('badges'),
  copyright: makeTestId('copyright'),
  languageSelection: makeTestId('language-selection'),
});

const socialNetworks: Record<Product, Array<SocialLink>> = {
  [Product.LiveJasmin]: [
    {
      icon: IconList.twitter,
      locale: messages.pages.layout.footer.socialNetworks.twitter,
      url: 'https://twitter.com/LiveJasmin',
    },
  ],
  [Product.Oranum]: [
    {
      icon: IconList.twitter,
      locale: messages.pages.layout.footer.socialNetworks.twitter,
      url: 'https://twitter.com/oranumglobal',
    },
    {
      icon: IconList.instagram,
      locale: messages.pages.layout.footer.socialNetworks.instagram,
      url: 'https://www.instagram.com/oranumglobal/',
    },
    {
      icon: IconList.facebook,
      locale: messages.pages.layout.footer.socialNetworks.facebook,
      url: 'https://www.facebook.com/Oranum.Global',
    },
    {
      icon: IconList.tikTok,
      locale: messages.pages.layout.footer.socialNetworks.tikTok,
      url: 'https://www.tiktok.com/@oranum.global',
    },
  ],
};

const workWithUs = (
  userAuthenticated: boolean | undefined
): Record<Product, Array<FooterLink & { hidden?: boolean }>> => ({
  [Product.LiveJasmin]: [
    {
      locale: messages.pages.layout.footer.workWithUs.becomeAModel,
      url: link.signup(),
      hidden: userAuthenticated === true,
    },
    {
      locale: messages.pages.layout.footer.workWithUs.studioSignUp,
      url: link.signup(),
      hidden: userAuthenticated === true,
    },
    {
      locale: messages.pages.layout.footer.workWithUs.adultAffiliateProgram,
      url: 'https://www.awempire.com/offers',
    },
    {
      locale: messages.pages.layout.footer.workWithUs.certifiedPartners,
      url: link.certifiedPartners(),
    },
  ],
  [Product.Oranum]: [
    {
      locale: messages.pages.layout.footer.workWithUs.expertsLogin,
      url: link.login(),
      hidden: userAuthenticated === true,
    },
    {
      locale: messages.pages.layout.footer.workWithUs.expertsWanted,
      url: link.signup(),
      hidden: userAuthenticated === true,
    },
    {
      locale: messages.pages.layout.footer.workWithUs.affiliateProgram,
      url: 'https://www.awempire.com/oranum',
    },
  ],
});

const legalAndSafety: Array<FooterLink> = [
  {
    locale: messages.pages.layout.footer.legalAndSafety.servicesAgreement,
    url: link.terms(),
  },
  {
    locale: messages.pages.layout.footer.legalAndSafety.ownershipStatement,
    url: 'https://jwsbill.com',
  },
  {
    locale: messages.pages.layout.footer.legalAndSafety.privacyPolicy,
    url: link.privacy(),
  },
  {
    locale: messages.pages.layout.footer.legalAndSafety.cookiePolicy,
    url: link.cookies(),
  },
];

const helpAndSupport: Record<Product, Array<FooterLink>> = {
  [Product.LiveJasmin]: [
    {
      locale: messages.pages.layout.footer.helpAndSupport.onlineSupport,
      url: link.onlineSupportChat({ topic: 'other' }),
    },
    {
      locale: messages.pages.layout.footer.helpAndSupport.faq,
      url: link.faq(),
    },
  ],
  [Product.Oranum]: [
    {
      locale: messages.pages.layout.footer.helpAndSupport.onlineSupport,
      url: link.onlineSupportChat({ topic: 'other' }),
    },
    {
      locale: messages.pages.layout.footer.helpAndSupport.faq,
      url: wiki.faq,
    },
  ],
};

export type { FooterLink };
export { testId, socialNetworks, workWithUs, legalAndSafety, helpAndSupport };
