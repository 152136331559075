import React from 'react';

import { useRoute } from './hooks';

const Outlet: React.FunctionComponent<Record<string, unknown>> = (props) => {
  const { outlet } = useRoute();

  return outlet?.(props);
};

export default React.memo(Outlet);
