import { defer, of } from 'rxjs';
import evalAuthorization from 'utils/rxjs/eval-authorization';
import user from 'store/user';
import is from 'utils/is';
import type { RouteAuthorization } from 'contracts';

import id from './id';

const authorization: RouteAuthorization = {
  name: id,
  authorization$: defer(() =>
    user.onChange$.pipe(
      evalAuthorization(
        ({ viewTypeId }) => is.nullish(viewTypeId),
        () => of(!user.isModelAccountType() && !user.hasLimitedAccess())
      )
    )
  ),
};

export default authorization;
