import Cookies from 'js-cookie';
import type { Task } from 'contracts';

import navigation from '../../navigation';
import location from '../../router/location';
import getAuthData from '../get-auth-data';
import refresh from '../refresh-token';
import activity from '../activity';
import { threshold } from '../is-expired';
import utils from '../utils';

const redirect = (): void => {
  if (location.current.name === 'logout') {
    try {
      Cookies.remove(utils.cookies.CACHE_KEY, utils.cookies.attributes());

      window.sessionStorage.clear();
    } catch {
      /* empty */
    }

    navigation.login();

    return;
  }

  navigation.logout();
};

const refreshToken: Task = {
  replay: true,
  type: 'asap',
  onBeforeStart() {
    this.activityDone = activity.setAsBusy(this);
  },
  onFinish() {
    this.activityDone();
  },
  get interval() {
    const { expiresAt } = getAuthData() || { expiresAt: 0 };

    return Math.max(0, expiresAt - new Date().getTime() - threshold);
  },
  async run() {
    /**
     * "getAuthData" methods reads auth cookie, which is set to expire within the same
     * lifetime as our refresh token. Therefore, we can assume that our refresh token
     * is still valid while auth data is retrieved from given cookie.
     */
    const hasAuthCookieExpired = !getAuthData();

    if (hasAuthCookieExpired) {
      redirect();

      return;
    }

    await refresh().catch(redirect);
  },
};

export default refreshToken;
