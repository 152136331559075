import { defineMessages } from 'react-intl';

export default {
  pages: {
    root: {
      monitoring: {
        noInternetConnection: {
          ...defineMessages({
            title: {
              id: 'pages.root.monitoring.noInternetConnection.title',
              defaultMessage: "<b>You're offline.</b>",
            },
            description: {
              id: 'pages.root.monitoring.noInternetConnection.description',
              defaultMessage: 'Please check your connection and reload this page.',
            },
          }),
        },
      },
    },
  },
};
