import React from 'react';
import type { Route } from 'contracts';

import id from './id';

const AsyncPage = React.lazy(() => import('../playlist'));
const AsyncSkeletonPage = React.lazy(() => import('../playlist.skeleton'));

const routes: Route = {
  name: id.musicLibraryPlaylist,
  path: '/{lang?}/music-library/playlist/{categoryName}',
  element: <AsyncPage />,
  skeleton: <AsyncSkeletonPage />,
};

export default routes;
