// eslint-disable-next-line no-restricted-imports
import location from 'services/router/location';

enum Environment {
  Production = 'production',
  Staging = 'staging',
  Development = 'development',
  Localhost = 'localhost',
}

type TestEnvironments = { readonly [K in keyof typeof Environment as `is${Capitalize<K>}`]: boolean };

interface AppEnvironment extends TestEnvironments {
  readonly current: Environment;
}

const productionDomains = ['livejasmin.com', 'lj.com', 'oranum.com'];

const environment: AppEnvironment = {
  get current(): Environment {
    if (location.url.localhost) {
      return Environment.Localhost;
    }

    if (productionDomains.includes(location.url.domain)) {
      if (location.url.subdomain?.startsWith('lubet')) {
        return Environment.Staging;
      }

      return Environment.Production;
    }

    return Environment.Development;
  },
  get isProduction() {
    return environment.current === Environment.Production;
  },
  get isStaging() {
    return environment.current === Environment.Staging;
  },
  get isDevelopment() {
    return environment.current === Environment.Development;
  },
  get isLocalhost() {
    return environment.current === Environment.Localhost;
  },
};

export type { AppEnvironment };
export { Environment };
export default environment;
