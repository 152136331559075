import tokenize from '../pathway/tokenize';
import type { ParameterSegment, LiteralSegment, RouteSegment } from '../pathway/contracts';

const DYNAMIC_SEGMENT_WEIGHT = 3;
const EMPTY_SEGMENT_WEIGHT = 1;
const STATIC_SEGMENT_WEIGHT = 10;
const INDEX_ROUTE_WEIGHT = 2;
const SPLAT_PENALTY_WEIGHT = -2;

const isParameterToken = (token: LiteralSegment | ParameterSegment): token is ParameterSegment =>
  token.type === 'parameter';
const hasWildcardParam = (token: RouteSegment): boolean => isParameterToken(token) && token.wildcard;
const excludeWildcardParam = (token: RouteSegment): boolean => !hasWildcardParam(token);

const computeScore = (path: string, index: boolean): number => {
  const tokens = tokenize(path).flatMap<RouteSegment>((token) => {
    if (token.type === 'literal') {
      return token.value === '/'
        ? { type: 'literal', value: '' }
        : token.value!.split('/').map((segment) => ({ type: 'literal', value: segment }));
    }

    return token;
  });
  let initialScore = tokens.length;

  if (tokens.some(hasWildcardParam)) {
    initialScore += SPLAT_PENALTY_WEIGHT;
  }

  if (index) {
    initialScore += INDEX_ROUTE_WEIGHT;
  }

  return tokens.filter(excludeWildcardParam).reduce((score, token) => {
    if (isParameterToken(token)) {
      return score + DYNAMIC_SEGMENT_WEIGHT;
    }

    return score + (!token?.value?.trim() ? EMPTY_SEGMENT_WEIGHT : STATIC_SEGMENT_WEIGHT);
  }, initialScore);
};

export {
  DYNAMIC_SEGMENT_WEIGHT,
  EMPTY_SEGMENT_WEIGHT,
  STATIC_SEGMENT_WEIGHT,
  INDEX_ROUTE_WEIGHT,
  SPLAT_PENALTY_WEIGHT,
};
export default computeScore;
