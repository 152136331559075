import React from 'react';
import { debounce, throttle } from 'throttle-debounce';
import useKeydownEvent from 'enhancers/use-keydown-event';
import diacritics from 'utils/diacritics';
import toArray from 'utils/to-array';
import is from 'utils/is';
import happen from 'utils/happen';
import nodeElement from 'utils/element';
import { KeyboardKey } from 'contracts';

import Html from '../../../html';
import Loading from '../../../loading';
import Icon, { IconList } from '../../../icon';
import type { PopoverControl, PopoverOnToggle } from '../../../popover';
import Popover from '../../../popover';
import element from '../../utils/element';
import Group from '../group';
import Option from '../option';
import Divider from '../divider';
import type {
  InternalComponent,
  InternalComponentProps,
  InternalDividerElement,
  InternalOptionElement,
  InternalOptionGroupElement,
  InternalSelectElement,
  Template,
  TemplateOption,
} from '../../contracts';

import styles from './select.module.scss';

const searchThrottleTime = 500;
const clearSearchThrottleTime = 1_000;
const automaticScrollThreshold = 50;
const wordsPattern = /[^\d\sA-Za-zÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÑÒÓÔÕÖÙÚÛÜÝàáâãäåæçèéêëìíîïñòóôõöùúûüýÿŒœŸ]+/g;
/*
 offset represents select item padding top value,
 which must be subtracted from its position top
 in order to value proper positioning on automatic scroll
 */
const nodeTopOffset = 8;

const findNode = (elements: Array<Template>, match: (element: Template) => boolean): TemplateOption | undefined => {
  for (let i = 0; i < elements.length; i += 1) {
    const node = elements[i];

    if (!React.isValidElement(node)) continue;

    if (match(node)) {
      return node;
    }

    const result = findNode(React.Children.toArray(node.props?.children) as Array<Template>, match);

    if (result) {
      return result;
    }
  }
};

const sanitizeText = (value: string): string => {
  if (is.nullish(value)) return '';

  return diacritics.replace(
    value
      .replace(/<[^>]*>/g, '')
      .replace(wordsPattern, '')
      .trim()
  );
};

const Select: InternalComponent<HTMLButtonElement, InternalSelectElement> = (props) => {
  const {
    testId,
    placeholder,
    searchable,
    error,
    disabled,
    onChange,
    className,
    style,
    children,
    loading,
    containerMaxHeight,
    isDefaultOpen,
    onBottomReached,
    ...rest
  } = props;

  const [status, setStatus] = React.useState({ open: Boolean(isDefaultOpen), direction: 'bottom' });
  const [highlightedNode, setNodeHighlight] = React.useState<Template>();
  const popover = React.useRef<PopoverControl>(null);
  const selectRef = React.useRef<HTMLButtonElement>(null);
  const listRef = React.useRef<HTMLDivElement>(null);
  const searchQuery = React.useRef<string>('');
  const searchInput = React.useRef<HTMLInputElement>(null);
  const id = React.useId();

  const elements = React.useMemo(() => {
    return toArray<Template>(children);
  }, [children]);

  const knowledge = React.useMemo(() => {
    const nodes: Array<[text: string, node: Template]> = [];

    findNode(elements, (node) => {
      if (element(node).isPublicOption) {
        const text = sanitizeText(toArray(node.props.children).join(''));

        nodes.push([text, node]);
      }

      return false;
    });

    return nodes;
  }, [elements]);

  const isMobileDevice = React.useMemo(() => {
    return is.mobileDevice;
  }, []);

  const selected = React.useMemo(() => {
    return findNode(elements, (node) => {
      if (!element(node).isPublicOption) return false;

      if (highlightedNode) {
        return highlightedNode.props.value === node.props.value;
      }

      return node.props?.selected === true;
    });
  }, [elements, highlightedNode]);

  const handleOnSelect: InternalOptionElement['onSelect'] = React.useCallback(
    (value): void => {
      if (disabled) return;

      onChange(value);
    },
    [onChange, disabled]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const clearQuery = React.useCallback(
    debounce(clearSearchThrottleTime, () => {
      if (searchable) return;

      searchQuery.current = '';
    }),
    [searchable]
  );

  const findItemIndexByText = React.useCallback(
    (search: string) =>
      knowledge.findIndex(([text]) => {
        return text.match(new RegExp(search, 'gi'));
      }),
    [knowledge]
  );

  const findItemIndexByValue = React.useCallback(
    (search: string) =>
      knowledge.findIndex(([, node]) => {
        return node.props.value === search;
      }),
    [knowledge]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchByText = React.useCallback(
    throttle(searchThrottleTime, (query: string): void => {
      const matchIndex = findItemIndexByText(query);

      const { 1: node } = knowledge[matchIndex] ?? {};

      if (node) {
        setNodeHighlight(node);
        followElement(matchIndex);
      }

      clearQuery();
    }),
    [findItemIndexByText, clearQuery]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const followElement = React.useCallback(
    throttle(searchThrottleTime, (idx: number) => {
      if (!listRef.current) return;

      const selectedElement = listRef.current.querySelectorAll('[aria-selected]')[idx] as HTMLButtonElement;

      if (
        !happen(selectedElement.offsetTop).between(
          listRef.current.scrollTop,
          listRef.current.scrollTop + listRef.current.offsetHeight - automaticScrollThreshold
        )
      ) {
        nodeElement(listRef.current).scrollTo({ behavior: 'instant', top: selectedElement.offsetTop - nodeTopOffset });
      }
    }),
    []
  );

  const goToOption = React.useCallback(
    (direction: 'next' | 'prev') => {
      if (!selected) {
        const nextIdx = direction === 'next' ? 0 : knowledge.length - 1;

        setNodeHighlight(knowledge[nextIdx][1]);
        followElement(nextIdx);

        return;
      }

      const selectedIdx = knowledge.findIndex(([, el]) => {
        return selected.props.value === el.props.value;
      });

      let nextIdx: number = direction === 'next' ? selectedIdx + 1 : selectedIdx - 1;

      nextIdx = nextIdx >= knowledge.length ? 0 : nextIdx;
      nextIdx = nextIdx < 0 ? knowledge.length - 1 : nextIdx;

      if (happen(nextIdx).greaterThanOrEqual(0)) {
        setNodeHighlight(knowledge[nextIdx][1]);
        followElement(nextIdx);
      }
    },
    [knowledge, selected, followElement]
  );

  const handleOnListKeyDown = useKeydownEvent((key, event) => {
    if (disabled || isMobileDevice) return;

    switch (key) {
      case KeyboardKey.ArrowUp:
        event.preventDefault();
        event.stopPropagation();

        if (!popover.current!.isOpen) {
          popover.current!.open();
        }

        goToOption('prev');
        break;

      case KeyboardKey.ArrowDown:
        event.preventDefault();
        event.stopPropagation();

        if (!popover.current!.isOpen) {
          popover.current!.open();
        }

        goToOption('next');
        break;

      case KeyboardKey.Enter:
      case KeyboardKey.Spacebar:
        event.preventDefault();
        event.stopPropagation();

        popover.current!.toggle();

        if (highlightedNode && !highlightedNode.props.disabled) {
          handleOnSelect(highlightedNode.props.value);
        }

        break;

      case KeyboardKey.Escape:
        event.preventDefault();
        event.stopPropagation();
        popover.current!.close();

        break;

      default:
        break;
    }
  });

  const handleOnListKeyUp = useKeydownEvent((key, event) => {
    if (disabled || isMobileDevice) return;

    switch (key) {
      case KeyboardKey.Tab:
        if (popover.current!.isOpen) {
          popover.current!.close();
        }
        break;

      case KeyboardKey.Enter:
      case KeyboardKey.Spacebar:
        break;

      default:
        if (!popover.current!.isOpen) {
          popover.current!.open();
        }

        if (!searchInput.current) {
          if (event.key === KeyboardKey.Backspace) {
            searchQuery.current = searchQuery.current.substring(0, searchQuery.current.length - 1);
          } else {
            searchQuery.current += sanitizeText(event.key);
          }
        } else {
          searchQuery.current = searchInput.current.value;
        }

        searchByText(searchQuery.current);

        break;
    }
  });

  const getAriaLabel = React.useCallback((): string | undefined => {
    if (is.string(placeholder)) {
      return placeholder;
    }

    return is.string(selected?.props?.children) ? selected?.props?.children : undefined;
  }, [placeholder, selected]);

  const handleOnToggle: PopoverOnToggle = React.useCallback(
    (open, direction, mounted): void => {
      setStatus({ open, direction });

      if (open && Boolean(selected?.props?.children?.toString()?.trim())) {
        const idx = findItemIndexByValue(selected!.props.value);

        followElement(idx);
      }
      if (!open && mounted) {
        setNodeHighlight(undefined);
      }
    },
    [findItemIndexByValue, followElement, selected]
  );

  const renderElementType = React.useCallback(
    (target: React.ReactElement, selectedValue: InternalOptionElement['value'] | undefined) => {
      switch (element(target).type) {
        case 'divider': {
          const el = target as React.ReactElement<InternalComponentProps<HTMLHRElement, InternalDividerElement>>;

          return (
            <Divider
              key={el.key}
              {...el.props}
              testId={el.props?.testId}
              className={el.props?.className}
              style={el.props?.style}
            />
          );
        }

        case 'option-group': {
          const el = target as React.ReactElement<InternalComponentProps<HTMLDivElement, InternalOptionGroupElement>>;

          return (
            <Group
              key={el.key}
              {...el.props}
              testId={el.props?.testId}
              title={el.props.title}
              selectedValue={selectedValue}
              className={el.props?.className}
              style={el.props?.style}
              onSelect={handleOnSelect}
            >
              {el.props.children}
            </Group>
          );
        }

        case 'option': {
          const el = target as React.ReactElement<InternalComponentProps<HTMLButtonElement, InternalOptionElement>>;

          return (
            <Option
              key={el.key}
              {...el.props}
              testId={el.props?.testId}
              value={el.props.value}
              selected={selectedValue === el.props.value}
              disabled={el.props.disabled}
              className={el.props?.className}
              style={el.props?.style}
              onSelect={handleOnSelect}
            >
              {el.props.children}
            </Option>
          );
        }

        default:
          return null;
      }
    },
    [handleOnSelect]
  );

  const handleScroll = React.useCallback(
    (event: React.UIEvent<HTMLDivElement>) => {
      if (!onBottomReached) return;

      const target = event.target as HTMLDivElement;
      const { scrollTop, scrollHeight, clientHeight } = target;

      if (scrollTop + clientHeight >= scrollHeight) {
        onBottomReached();
      }
    },
    [onBottomReached]
  );

  const renderNativeSelect = React.useCallback(
    (): React.ReactNode => (
      <Html.select
        onChange={({ target: { value } }) => handleOnSelect(value)}
        className={[styles.nativeSelect, !selected?.props.value && styles.nativePlaceholder, ...toArray(className)]}
        value={selected?.props.value ?? ''}
        disabled={disabled || loading}
        aria-label={is.string(placeholder) ? placeholder : undefined}
      >
        {placeholder && (
          <Html.option value="" disabled>
            {placeholder}
          </Html.option>
        )}
        {React.Children.toArray(elements).map((el) =>
          renderElementType(el as React.ReactElement, selected?.props.value)
        )}
      </Html.select>
    ),
    [className, disabled, loading, placeholder, elements, selected?.props.value, handleOnSelect, renderElementType]
  );

  const renderContent = React.useCallback(
    () => (
      <Html.div className={['mt-1', styles.listWrapper, status.open && styles.open]}>
        <Html.div
          role="listbox"
          aria-labelledby={id}
          className={styles.list}
          style={{ width: selectRef.current?.getBoundingClientRect().width, maxHeight: containerMaxHeight }}
          ref={listRef}
          {...(onBottomReached && { onScroll: handleScroll })}
        >
          {React.Children.toArray(elements).map((el) =>
            renderElementType(el as React.ReactElement, selected?.props.value)
          )}
        </Html.div>
      </Html.div>
    ),
    [
      status.open,
      selected?.props.value,
      id,
      containerMaxHeight,
      elements,
      renderElementType,
      handleScroll,
      onBottomReached,
    ]
  );

  return (
    <Popover
      testId={testId}
      aria-disabled={disabled}
      content={renderContent}
      onToggle={handleOnToggle}
      trigger="onClick"
      position="bottom-right"
      disabled={disabled || loading || isMobileDevice}
      className={className}
      style={style}
      ref={popover}
      open={Boolean(isDefaultOpen)}
      closeOnContentClick
    >
      <Html.label
        testId={testId && `${testId}-header`}
        id={id}
        aria-label={getAriaLabel()}
        aria-disabled={disabled}
        tabIndex={disabled ? -1 : 0}
        onKeyUp={handleOnListKeyUp}
        onKeyDown={handleOnListKeyDown}
        className={[
          'd-block',
          styles.select,
          status.open && styles.open,
          disabled && styles.disabled,
          !disabled && !loading && 'cursor-pointer',
          isMobileDevice && 'p-0',
          error && styles.error,
          ...toArray(className),
        ]}
        arias={rest}
        ref={selectRef}
      >
        {loading && (
          <Html.span className={styles.loading} aria-hidden="true">
            <Loading testId={testId && `${testId}-loading`} delay={0} size={18} thickness={2} />
          </Html.span>
        )}
        {!loading && (
          <Html.span className={['rounded-1', styles.iconWrapper]} aria-hidden="true">
            <Icon
              testId={testId && `${testId}-caret`}
              name={status.open ? IconList.caretUp : IconList.caretDown}
              size={24}
              className={styles.icon}
              aria-hidden="true"
            />
          </Html.span>
        )}
        {isMobileDevice && renderNativeSelect()}
        {!isMobileDevice && (
          <Html.div className={[selected?.props.children ? styles.label : styles.placeholder, error && styles.error]}>
            {(!searchable || !status.open) && (selected?.props.children || placeholder)}
            {searchable && status.open && (
              <Html.input
                type="text"
                testId={testId && `${testId}-search-input`}
                placeholder={placeholder as string}
                defaultValue={selected?.props.children as string | undefined}
                className={['w-100', styles.searchInput]}
                ref={searchInput}
                autoFocus
              />
            )}
          </Html.div>
        )}
      </Html.label>
    </Popover>
  );
};

export default Select;
