import React from 'react';
import type { Route } from 'contracts';

import id from './id';

const AsyncPage = React.lazy(() => import('../models'));
const AsyncSkeletonPage = React.lazy(() => import('../models.skeleton'));

const routes: Route = {
  name: id,
  path: '/{lang?}/models',
  hashParams: ['firstStudioLoginNew'],
  element: <AsyncPage />,
  skeleton: <AsyncSkeletonPage />,
};

export default routes;
