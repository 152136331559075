import { map, of } from 'rxjs';
import evalAuthorization from 'utils/rxjs/eval-authorization';
import is from 'utils/is';
import type { RouteAuthorization } from 'contracts';
import user from 'store/user';
import performer from 'store/performer';

import id from './id';

const authorization: RouteAuthorization = {
  name: id,
  authorization$: () =>
    user.onChange$.pipe(
      evalAuthorization(
        ({ viewTypeId }) => is.nullish(viewTypeId),
        () =>
          user.isStudioView() || user.isLimitedStudioAccountType()
            ? of(false)
            : performer.onChange$.pipe(
                map((performerData) => user.isImpersonating(performerData.personIds)),
                map((impersonating) => !impersonating)
              )
      )
    ),
};

export default authorization;
