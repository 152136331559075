import type { FetchResponse, RequestParams } from 'services/fetch';
import fetch, { ContentType } from 'services/fetch';

import type {
  ChatDiscount,
  ChatDiscountAvailability,
  CompetitionCompetitorListResponse,
  CompetitionGameListResponse,
  CompetitionPerformerStandingListResponse,
  DiscountCollection,
  GetBeMyFanReferralTokenResponse,
  HasNotesResponse,
  HiddenFaceSetting,
  IncomeGroupsPeriod,
  InnerApiSuccess,
  LanguageCode,
  PerformerActionListResponse,
  PerformerActionPatchResponse,
  PerformerActionTierReadModelListResponse,
  PerformerAggregatedOnlineTimeResponse,
  PerformerEarningsResponse,
  PerformerGameListResponse,
  PerformerGroupedEarningsResponse,
  PerformerIncomeGroupsResponse,
  PerformerNewbieStatusResponse,
  PerformerPricingResponse,
  PerformerShareResponse,
  PerformerStreamingStatus,
  PerformerTagListResponse,
  PerformerUnreadCountsResponse,
  PredefinedMessagesResponse,
  PromoPeriodResetCounterResponse,
  PromoVideos,
  SharedLiveAvailablePerformersResponse,
  StudioUnreadCountsResponse,
  MemberReferralSharesResponse,
  MemberReferralStatisticResponse,
} from './data-contracts';

/**
 * No description
*
 * @tags Performers
 * @name GetPerformerPricing
 * @summary Get the pricing of performer
 * @request GET:/gw/inner/v2/performers/{performerId}/pricing
 * @secure
 * @response `200` `PerformerPricingResponse`
 * @response `401` `void`
 * @response `403` `(InnerApiError & {
  \** @example 403 *\
    errorCode?: any,

})`
 * @response `404` `(InnerApiError & {
  \** @example 404 *\
    errorCode?: any,

})`

*/
export const getPerformerPricing = (
  performerId: string,
  params: RequestParams = {}
): FetchResponse<PerformerPricingResponse> => {
  return fetch<PerformerPricingResponse>(`/gw/inner/v2/performers/${performerId}/pricing`, {
    method: 'GET',
    ...params,
  });
};

/**
 * No description
*
 * @tags Actions
 * @name GetPerformerActions
 * @summary Get the actions requests of a performer
 * @request GET:/gw/inner/v2/performers/{performerId}/actions
 * @secure
 * @response `200` `PerformerActionListResponse`
 * @response `400` `(InnerApiError & {
  \** @example 400 *\
    errorCode?: any,

})`
 * @response `401` `void`
 * @response `403` `(InnerApiError & {
  \** @example 403 *\
    errorCode?: any,

})`
 * @response `500` `(InnerApiError & {
  \** @example 500 *\
    errorCode?: any,

})`

*/
export const getPerformerActions = (
  performerId: string,
  query?: {
    /** The performer action status filter. */
    criteria?: {
      /** @example "status" */
      field?: 'status';
      /** @example "=" */
      operator?: '=';
      /** @example "enabled" */
      value?: 'enabled' | 'disabled' | 'support_disabled';
    }[];
  },
  params: RequestParams = {}
): FetchResponse<PerformerActionListResponse> => {
  return fetch<PerformerActionListResponse>(`/gw/inner/v2/performers/${performerId}/actions`, {
    method: 'GET',
    query: query,
    ...params,
  });
};

/**
 * No description
*
 * @tags Actions
 * @name PatchPerformerAction
 * @summary Update performer action status and price
 * @request PATCH:/gw/inner/v2/performers/{performerId}/actions
 * @secure
 * @response `200` `PerformerActionPatchResponse`
 * @response `400` `(InnerApiError & {
  \** @example 400 *\
    errorCode?: any,

})`
 * @response `401` `void`
 * @response `403` `(InnerApiError & {
  \** @example 403 *\
    errorCode?: any,

})`
 * @response `500` `(InnerApiError & {
  \** @example 500 *\
    errorCode?: any,

})`

*/
export const patchPerformerAction = (
  performerId: string,
  data: {
    actionId: number;
    /** @format float */
    price?: number;
    status?: 'enabled' | 'disabled';
  },
  params: RequestParams = {}
): FetchResponse<PerformerActionPatchResponse> => {
  return fetch<PerformerActionPatchResponse>(`/gw/inner/v2/performers/${performerId}/actions`, {
    method: 'PATCH',
    body: data,
    ...params,
  });
};

/**
 * No description
*
 * @tags Actions
 * @name GetPerformerTiers
 * @summary Get the actions requests of a performer grouped by action tiers
 * @request GET:/gw/inner/v2/performers/{performerId}/tiers
 * @secure
 * @response `200` `PerformerActionTierReadModelListResponse`
 * @response `400` `(InnerApiError & {
  \** @example 400 *\
    errorCode?: any,

})`
 * @response `401` `void`
 * @response `403` `(InnerApiError & {
  \** @example 403 *\
    errorCode?: any,

})`
 * @response `500` `(InnerApiError & {
  \** @example 500 *\
    errorCode?: any,

})`

*/
export const getPerformerTiers = (
  performerId: number,
  query?: {
    /** The performer action status filter. */
    criteria?: {
      /** @example "status" */
      field?: 'status';
      /** @example "=" */
      operator?: '=';
      /** @example "enabled" */
      value?: 'enabled' | 'disabled' | 'support_disabled';
    }[];
  },
  params: RequestParams = {}
): FetchResponse<PerformerActionTierReadModelListResponse> => {
  return fetch<PerformerActionTierReadModelListResponse>(`/gw/inner/v2/performers/${performerId}/tiers`, {
    method: 'GET',
    query: query,
    ...params,
  });
};

/**
 * No description
*
 * @tags Performers
 * @name GetPerformerAggregatedOnlineTime
 * @summary Get the aggregated online time of a performer between two dates
 * @request GET:/gw/inner/v2/performers/{performerId}/aggregated-online-time
 * @secure
 * @response `200` `PerformerAggregatedOnlineTimeResponse`
 * @response `400` `(InnerApiError & {
  \** @example 400 *\
    errorCode?: any,

})`
 * @response `401` `void`
 * @response `403` `(InnerApiError & {
  \** @example 403 *\
    errorCode?: any,

})`
 * @response `404` `(InnerApiError & {
  \** @example 404 *\
    errorCode?: any,

})`
 * @response `500` `(InnerApiError & {
  \** @example 500 *\
    errorCode?: any,

})`

*/
export const getPerformerAggregatedOnlineTime = (
  performerId: string,
  query: {
    /**
     * @format date
     * @example "2020-12-01"
     */
    fromDate: string;
    /**
     * @format date
     * @example "2020-12-01"
     */
    toDate: string;
  },
  params: RequestParams = {}
): FetchResponse<PerformerAggregatedOnlineTimeResponse> => {
  return fetch<PerformerAggregatedOnlineTimeResponse>(`/gw/inner/v2/performers/${performerId}/aggregated-online-time`, {
    method: 'GET',
    query: query,
    ...params,
  });
};

/**
 * No description
*
 * @tags Performers
 * @name GetPromoVideos
 * @summary Get promo videos
 * @request GET:/gw/inner/v2/performers/{performerId}/promo-videos
 * @secure
 * @response `200` `(InnerApiSuccess & PromoVideos)`
 * @response `401` `void`
 * @response `403` `(InnerApiError & {
  \** @example 403 *\
    errorCode?: any,

})`
 * @response `404` `(InnerApiError & {
  \** @example 404 *\
    errorCode?: any,

})`

*/
export const getPromoVideos = (
  performerId: string,
  params: RequestParams = {}
): FetchResponse<InnerApiSuccess & PromoVideos> => {
  return fetch<InnerApiSuccess & PromoVideos>(`/gw/inner/v2/performers/${performerId}/promo-videos`, {
    method: 'GET',
    ...params,
  });
};

/**
 * No description
*
 * @tags Tags
 * @name GetPerformerTagGroups
 * @summary Get the list of performer tag groups
 * @request GET:/gw/inner/v2/performers/{performerId}/tag-groups
 * @secure
 * @response `200` `(InnerApiSuccess & PerformerTagListResponse)`
 * @response `400` `(InnerApiError & {
  \** @example 400 *\
    errorCode?: any,

})`
 * @response `401` `void`
 * @response `500` `(InnerApiError & {
  \** @example 500 *\
    errorCode?: any,

})`

*/
export const getPerformerTagGroups = (
  performerId: string,
  query?: {
    /**
     * Filter for canHaveProfilePicture value
     * @example true
     */
    canHaveProfilePicture?: boolean;
  },
  params: RequestParams = {}
): FetchResponse<InnerApiSuccess & PerformerTagListResponse> => {
  return fetch<InnerApiSuccess & PerformerTagListResponse>(`/gw/inner/v2/performers/${performerId}/tag-groups`, {
    method: 'GET',
    query: query,
    ...params,
  });
};

/**
 * No description
*
 * @tags Tags
 * @name ReplacePerformerTagGroups
 * @summary Replace performer's tags
 * @request PUT:/gw/inner/v2/performers/{performerId}/tag-groups
 * @secure
 * @response `204` `void` Update successful
 * @response `400` `(InnerApiError & {
  \** @example 400 *\
    errorCode?: any,

})`
 * @response `401` `void`
 * @response `500` `(InnerApiError & {
  \** @example 500 *\
    errorCode?: any,

})`

*/
export const replacePerformerTagGroups = <T>(
  performerId: string,
  data: {
    tags: {
      id?: number;
    }[];
  },
  params: RequestParams = {}
): FetchResponse<T> => {
  return fetch<T>(`/gw/inner/v2/performers/${performerId}/tag-groups`, {
    method: 'PUT',
    body: data,
    contentType: ContentType.Json,
    ...params,
  });
};

/**
 * No description
*
 * @tags Competition
 * @name InnerV2CompetitionGamesList
 * @summary Returns a list of competition games.
 * @request GET:/gw/inner/v2/competition/games
 * @secure
 * @response `200` `CompetitionGameListResponse`
 * @response `400` `(InnerApiError & {
  \** @example 400 *\
    errorCode?: any,

})`
 * @response `401` `void`
 * @response `403` `(InnerApiError & {
  \** @example 403 *\
    errorCode?: any,

})`
 * @response `500` `(InnerApiError & {
  \** @example 500 *\
    errorCode?: any,

})`

*/
export const innerV2CompetitionGamesList = (
  query: {
    /** Maximum number of elements in the response. */
    limit: number;
    /** Offset parameter */
    offset?: number;
    /** Filtering criteria */
    criteria?: string[][];
  },
  params: RequestParams = {}
): FetchResponse<CompetitionGameListResponse> => {
  return fetch<CompetitionGameListResponse>(`/gw/inner/v2/competition/games`, {
    method: 'GET',
    query: query,
    ...params,
  });
};

/**
 * No description
*
 * @tags Competition
 * @name FindPerformerGames
 * @request GET:/gw/inner/v2/performers/{performerId}/competition/games
 * @secure
 * @response `200` `PerformerGameListResponse`
 * @response `400` `(InnerApiError & {
  \** @example 400 *\
    errorCode?: any,

})`
 * @response `401` `void`
 * @response `403` `(InnerApiError & {
  \** @example 403 *\
    errorCode?: any,

})`
 * @response `404` `(InnerApiError & {
  \** @example 404 *\
    errorCode?: any,

})`
 * @response `500` `(InnerApiError & {
  \** @example 500 *\
    errorCode?: any,

})`

*/
export const findPerformerGames = (
  performerId: number,
  query: {
    /** Maximum number of elements in the response. */
    limit: number;
    /** Offset parameter */
    offset?: number;
    /** Filtering criteria */
    criteria?: string[][];
  },
  params: RequestParams = {}
): FetchResponse<PerformerGameListResponse> => {
  return fetch<PerformerGameListResponse>(`/gw/inner/v2/performers/${performerId}/competition/games`, {
    method: 'GET',
    query: query,
    ...params,
  });
};

/**
 * No description
*
 * @tags Competition
 * @name InnerV2PerformersCompetitionGamesCompetitorsDetail
 * @summary Returns a list of competition competitors.
 * @request GET:/gw/inner/v2/performers/{performerId}/competition/games/{gameId}/competitors
 * @secure
 * @response `200` `CompetitionCompetitorListResponse`
 * @response `401` `void`
 * @response `403` `(InnerApiError & {
  \** @example 403 *\
    errorCode?: any,

})`
 * @response `500` `(InnerApiError & {
  \** @example 500 *\
    errorCode?: any,

})`

*/
export const innerV2PerformersCompetitionGamesCompetitorsDetail = (
  performerId: number,
  gameId: string,
  query: {
    /** Maximum number of elements in the response. */
    limit: number;
    /** Offset parameter */
    offset?: number;
  },
  params: RequestParams = {}
): FetchResponse<CompetitionCompetitorListResponse> => {
  return fetch<CompetitionCompetitorListResponse>(
    `/gw/inner/v2/performers/${performerId}/competition/games/${gameId}/competitors`,
    {
      method: 'GET',
      query: query,
      ...params,
    }
  );
};

/**
 * No description
*
 * @tags Competition
 * @name FindPerformerStandings
 * @request GET:/gw/inner/v2/performers/{performerId}/competition/standings
 * @secure
 * @response `200` `CompetitionPerformerStandingListResponse`
 * @response `400` `(InnerApiError & {
  \** @example 400 *\
    errorCode?: any,

})`
 * @response `401` `void`
 * @response `403` `(InnerApiError & {
  \** @example 403 *\
    errorCode?: any,

})`
 * @response `404` `(InnerApiError & {
  \** @example 404 *\
    errorCode?: any,

})`
 * @response `500` `(InnerApiError & {
  \** @example 500 *\
    errorCode?: any,

})`

*/
export const findPerformerStandings = (
  performerId: number,
  query?: {
    /** Filtering criteria */
    criteria?: string[][];
  },
  params: RequestParams = {}
): FetchResponse<CompetitionPerformerStandingListResponse> => {
  return fetch<CompetitionPerformerStandingListResponse>(
    `/gw/inner/v2/performers/${performerId}/competition/standings`,
    {
      method: 'GET',
      query: query,
      ...params,
    }
  );
};

/**
 * No description
*
 * @tags Performers
 * @name GetBeMyFanUsernameReferralToken
 * @request GET:/gw/inner/v2/performers/{performerId}/be-my-fan/referral-token
 * @secure
 * @response `200` `GetBeMyFanReferralTokenResponse`
 * @response `400` `(InnerApiError & {
  \** @example 400 *\
    errorCode?: any,

})`
 * @response `401` `void`
 * @response `404` `(InnerApiError & {
  \** @example 404 *\
    errorCode?: any,

})`
 * @response `500` `(InnerApiError & {
  \** @example 500 *\
    errorCode?: any,

})`

*/
export const getBeMyFanUsernameReferralToken = (
  performerId: number,
  params: RequestParams = {}
): FetchResponse<GetBeMyFanReferralTokenResponse> => {
  return fetch<GetBeMyFanReferralTokenResponse>(`/gw/inner/v2/performers/${performerId}/be-my-fan/referral-token`, {
    method: 'GET',
    ...params,
  });
};

/**
 * No description
*
 * @tags PerformerShare
 * @name GetPerformerShare
 * @summary Get the current share of a performer for a given pretence
 * @request GET:/gw/inner/v2/performers/{performerId}/shares/{pretenceId}
 * @secure
 * @response `200` `PerformerShareResponse`
 * @response `401` `void`
 * @response `403` `(InnerApiError & {
  \** @example 403 *\
    errorCode?: any,

})`
 * @response `404` `(InnerApiError & {
  \** @example 404 *\
    errorCode?: any,

})`

*/
export const getPerformerShare = (
  pretenceId: number,
  performerId: string,
  params: RequestParams = {}
): FetchResponse<PerformerShareResponse> => {
  return fetch<PerformerShareResponse>(`/gw/inner/v2/performers/${performerId}/shares/${pretenceId}`, {
    method: 'GET',
    ...params,
  });
};

/**
 * No description
*
 * @tags Performers
 * @name InnerV2PerformersMembersNotesDetail
 * @request GET:/gw/inner/v2/performers/{performerId}/members/{memberId}/notes
 * @secure
 * @response `200` `(InnerApiSuccess & HasNotesResponse)` Success
 * @response `401` `void`
 * @response `403` `(InnerApiError & {
  \** @example 403 *\
    errorCode?: any,

})`

*/
export const innerV2PerformersMembersNotesDetail = (
  performerId: number,
  memberId: number,
  params: RequestParams = {}
): FetchResponse<InnerApiSuccess & HasNotesResponse> => {
  return fetch<InnerApiSuccess & HasNotesResponse>(`/gw/inner/v2/performers/${performerId}/members/${memberId}/notes`, {
    method: 'GET',
    responseType: 'json',
    ...params,
  });
};

/**
 * No description
*
 * @tags PredefinedMessages
 * @name GetPredefinedMessages
 * @summary Get predefined messages
 * @request GET:/gw/inner/v2/predefined-message
 * @secure
 * @response `200` `PredefinedMessagesResponse`
 * @response `400` `(InnerApiError & {
  \** @example 400 *\
    errorCode?: any,

})`
 * @response `401` `void`
 * @response `403` `(InnerApiError & {
  \** @example 403 *\
    errorCode?: any,

})`

*/
export const getPredefinedMessages = (
  query?: {
    /** Language code */
    languageCode?: LanguageCode;
  },
  params: RequestParams = {}
): FetchResponse<PredefinedMessagesResponse> => {
  return fetch<PredefinedMessagesResponse>(`/gw/inner/v2/predefined-message`, {
    method: 'GET',
    query: query,
    ...params,
  });
};

/**
 * No description
*
 * @tags PredefinedMessages
 * @name GetConfigurationsPredefinedMessages
 * @summary Get configuration predefined messages
 * @request GET:/gw/inner/v2/predefined-message/configurations/messages/{languageCode}
 * @secure
 * @response `200` `PredefinedMessagesResponse`
 * @response `400` `(InnerApiError & {
  \** @example 400 *\
    errorCode?: any,

})`
 * @response `401` `void`
 * @response `403` `(InnerApiError & {
  \** @example 403 *\
    errorCode?: any,

})`

*/
export const getConfigurationsPredefinedMessages = (
  languageCode: LanguageCode,
  params: RequestParams = {}
): FetchResponse<PredefinedMessagesResponse> => {
  return fetch<PredefinedMessagesResponse>(`/gw/inner/v2/predefined-message/configurations/messages/${languageCode}`, {
    method: 'GET',
    ...params,
  });
};

/**
 * No description
*
 * @tags Accounting
 * @name GetIncomeGroups
 * @summary Returns the income groups and level progressions of a performer for the current period.
 * @request GET:/gw/inner/v2/performers/{performerId}/income-groups
 * @secure
 * @response `200` `PerformerIncomeGroupsResponse`
 * @response `400` `(InnerApiError & {
  \** @example 400 *\
    errorCode?: any,

})`
 * @response `401` `void`
 * @response `403` `(InnerApiError & {
  \** @example 403 *\
    errorCode?: any,

})`
 * @response `500` `(InnerApiError & {
  \** @example 500 *\
    errorCode?: any,

})`

*/
export const getIncomeGroups = (
  performerId: string,
  query?: {
    /** The date of period */
    period?: IncomeGroupsPeriod;
    /** The datetime of period start */
    fromDate?: any;
    /**
     * The datetime of period end
     * @format date-time
     * @example "2019-12-03T15:31:51+01:00"
     */
    toDate?: string | null;
  },
  params: RequestParams = {}
): FetchResponse<PerformerIncomeGroupsResponse> => {
  return fetch<PerformerIncomeGroupsResponse>(`/gw/inner/v2/performers/${performerId}/income-groups`, {
    method: 'GET',
    query: query,
    ...params,
  });
};

/**
 * No description
*
 * @tags Accounting
 * @name GetGroupedEarnings
 * @summary Get grouped earnings
 * @request GET:/gw/inner/v2/performers/{performerId}/grouped-earnings
 * @secure
 * @response `200` `PerformerGroupedEarningsResponse`
 * @response `401` `void`
 * @response `403` `(InnerApiError & {
  \** @example 403 *\
    errorCode?: any,

})`
 * @response `404` `(InnerApiError & {
  \** @example 404 *\
    errorCode?: any,

})`
 * @response `500` `(InnerApiError & {
  \** @example 500 *\
    errorCode?: any,

})`

*/
export const getGroupedEarnings = (
  performerId: string,
  query: {
    /**
     * @format date
     * @example "2020-12-01"
     */
    fromDate: string;
    /**
     * @format date
     * @example "2020-12-01"
     */
    toDate: string;
  },
  params: RequestParams = {}
): FetchResponse<PerformerGroupedEarningsResponse> => {
  return fetch<PerformerGroupedEarningsResponse>(`/gw/inner/v2/performers/${performerId}/grouped-earnings`, {
    method: 'GET',
    query: query,
    ...params,
  });
};

/**
 * No description
*
 * @tags Accounting
 * @name GetEarnings
 * @summary Get earnings
 * @request GET:/gw/inner/v2/performers/{performerId}/earnings
 * @secure
 * @response `200` `PerformerEarningsResponse`
 * @response `401` `void`
 * @response `403` `(InnerApiError & {
  \** @example 403 *\
    errorCode?: any,

})`
 * @response `404` `(InnerApiError & {
  \** @example 404 *\
    errorCode?: any,

})`
 * @response `500` `(InnerApiError & {
  \** @example 500 *\
    errorCode?: any,

})`

*/
export const getEarnings = (
  performerId: string,
  query: {
    /**
     * @format date
     * @example "2020-12-01"
     */
    fromDate: string;
    /**
     * @format date
     * @example "2020-12-01"
     */
    toDate: string;
    /** @example "coin" */
    creditType: 'coin' | 'credit';
  },
  params: RequestParams = {}
): FetchResponse<PerformerEarningsResponse> => {
  return fetch<PerformerEarningsResponse>(`/gw/inner/v2/performers/${performerId}/earnings`, {
    method: 'GET',
    query: query,
    ...params,
  });
};

/**
 * No description
*
 * @tags Performers
 * @name GetPerformerUnreadCounts
 * @summary For the given performer get unread news, messages and my content channels creation flags
 * @request GET:/gw/inner/v2/performers/{performerId}/unread-counts
 * @secure
 * @response `200` `PerformerUnreadCountsResponse`
 * @response `400` `(InnerApiError & {
  \** @example 400 *\
    errorCode?: any,

})`
 * @response `401` `void`
 * @response `403` `(InnerApiError & {
  \** @example 403 *\
    errorCode?: any,

})`
 * @response `500` `(InnerApiError & {
  \** @example 500 *\
    errorCode?: any,

})`

*/
export const getPerformerUnreadCounts = (
  performerId: string,
  query?: {
    /** Language code */
    languageCode?: LanguageCode;
    /**
     * Country code(ISO alpha-2). If not provided, X-Client-Ip from header will be used to geodecode the country.
     * @example "US"
     */
    countryCode?: string;
  },
  params: RequestParams = {}
): FetchResponse<PerformerUnreadCountsResponse> => {
  return fetch<PerformerUnreadCountsResponse>(`/gw/inner/v2/performers/${performerId}/unread-counts`, {
    method: 'GET',
    query: query,
    ...params,
  });
};

/**
 * No description
*
 * @tags Performers
 * @name GetNewbiePerformerStatus
 * @summary Get the newbie status for a performer
 * @request GET:/gw/inner/v2/performers/{performerId}/newbie/status
 * @secure
 * @response `200` `PerformerNewbieStatusResponse`
 * @response `400` `(InnerApiError & {
  \** @example 400 *\
    errorCode?: any,

})`
 * @response `401` `void`
 * @response `403` `(InnerApiError & {
  \** @example 403 *\
    errorCode?: any,

})`
 * @response `500` `(InnerApiError & {
  \** @example 500 *\
    errorCode?: any,

})`

*/
export const getNewbiePerformerStatus = (
  performerId: string,
  params: RequestParams = {}
): FetchResponse<PerformerNewbieStatusResponse> => {
  return fetch<PerformerNewbieStatusResponse>(`/gw/inner/v2/performers/${performerId}/newbie/status`, {
    method: 'GET',
    ...params,
  });
};

/**
 * No description
*
 * @tags Studios
 * @name GetStudioUnreadCounts
 * @summary For the given studio get unread news and support messages countings
 * @request GET:/gw/inner/v2/studios/{studioId}/unread-counts
 * @secure
 * @response `200` `StudioUnreadCountsResponse`
 * @response `400` `(InnerApiError & {
  \** @example 400 *\
    errorCode?: any,

})`
 * @response `401` `void`
 * @response `403` `(InnerApiError & {
  \** @example 403 *\
    errorCode?: any,

})`
 * @response `500` `(InnerApiError & {
  \** @example 500 *\
    errorCode?: any,

})`

*/
export const getStudioUnreadCounts = (
  studioId: number,
  query?: {
    /** Language code */
    languageCode?: LanguageCode;
    /**
     * Country code(ISO alpha-2). If not provided, X-Client-Ip from header will be used to geodecode the country.
     * @example "US"
     */
    countryCode?: string;
  },
  params: RequestParams = {}
): FetchResponse<StudioUnreadCountsResponse> => {
  return fetch<StudioUnreadCountsResponse>(`/gw/inner/v2/studios/${studioId}/unread-counts`, {
    method: 'GET',
    query: query,
    ...params,
  });
};

/**
 * No description
*
 * @tags PromoPeriods
 * @name ResetPromoPeriods
 * @summary Resets a promo period for a given person
 * @request POST:/gw/inner/v2/studios/{studioId}/performers/{performerId}/persons/{personId}/promo-periods/reset
 * @secure
 * @response `204` `void` Successfully processed the request
 * @response `400` `(InnerApiError & {
  \** @example 400 *\
    errorCode?: any,

})`
 * @response `401` `void`
 * @response `403` `(InnerApiError & {
  \** @example 403 *\
    errorCode?: any,

})`
 * @response `404` `(InnerApiError & {
  \** @example 404 *\
    errorCode?: any,

})`
 * @response `500` `(InnerApiError & {
  \** @example 500 *\
    errorCode?: any,

})`

*/
export const resetPromoPeriods = <T>(
  performerId: number,
  personId: number,
  studioId: string,
  params: RequestParams = {}
): FetchResponse<T> => {
  return fetch<T>(
    `/gw/inner/v2/studios/${studioId}/performers/${performerId}/persons/${personId}/promo-periods/reset`,
    {
      method: 'POST',
      ...params,
    }
  );
};

/**
 * No description
*
 * @tags PromoPeriods
 * @name GetPromoPeriodsResetCounters
 * @summary Returns the promo periods reset counters for a given person
 * @request GET:/gw/inner/v2/studios/{studioId}/performers/{performerId}/persons/{personId}/promo-periods/reset/counters
 * @secure
 * @response `200` `PromoPeriodResetCounterResponse`
 * @response `400` `(InnerApiError & {
  \** @example 400 *\
    errorCode?: any,

})`
 * @response `401` `void`
 * @response `500` `(InnerApiError & {
  \** @example 500 *\
    errorCode?: any,

})`

*/
export const getPromoPeriodsResetCounters = (
  performerId: number,
  personId: number,
  studioId: string,
  params: RequestParams = {}
): FetchResponse<PromoPeriodResetCounterResponse> => {
  return fetch<PromoPeriodResetCounterResponse>(
    `/gw/inner/v2/studios/${studioId}/performers/${performerId}/persons/${personId}/promo-periods/reset/counters`,
    {
      method: 'GET',
      ...params,
    }
  );
};

/**
 * No description
*
 * @tags Performers
 * @name GetSharedLiveAvailablePerformers
 * @summary Get a list of shared live available performers
 * @request GET:/gw/inner/v2/performers/{performerId}/shared-live-available-performers
 * @secure
 * @response `200` `SharedLiveAvailablePerformersResponse`
 * @response `401` `void`
 * @response `403` `(InnerApiError & {
  \** @example 403 *\
    errorCode?: any,

})`
 * @response `404` `(InnerApiError & {
  \** @example 404 *\
    errorCode?: any,

})`
 * @response `500` `(InnerApiError & {
  \** @example 500 *\
    errorCode?: any,

})`

*/
export const getSharedLiveAvailablePerformers = (
  performerId: number,
  query?: {
    /** The display name. */
    displayName?: string;
  },
  params: RequestParams = {}
): FetchResponse<SharedLiveAvailablePerformersResponse> => {
  return fetch<SharedLiveAvailablePerformersResponse>(
    `/gw/inner/v2/performers/${performerId}/shared-live-available-performers`,
    {
      method: 'GET',
      query: query,
      ...params,
    }
  );
};

/**
 * No description
*
 * @tags Studios
 * @name PatchStudioPerformer
 * @summary Studio updates performer direct properties
 * @request PATCH:/gw/inner/v2/studios/{studioId}/performers/{performerId}
 * @secure
 * @response `204` `void` 204 No Content
 * @response `400` `(InnerApiError & {
  \** @example 400 *\
    errorCode?: any,

})`
 * @response `401` `void`
 * @response `403` `(InnerApiError & {
  \** @example 403 *\
    errorCode?: any,

})`
 * @response `500` `(InnerApiError & {
  \** @example 500 *\
    errorCode?: any,

})`

*/
export const patchStudioPerformer = <T>(
  studioId: string,
  performerId: string,
  data: {
    /** @example "active" */
    status: 'active' | 'inactive' | 'closed';
  },
  params: RequestParams = {}
): FetchResponse<T> => {
  return fetch<T>(`/gw/inner/v2/studios/${studioId}/performers/${performerId}`, {
    method: 'PATCH',
    body: data,
    ...params,
  });
};

/**
 * @description Each **key** must be the **name** of a performer property. Each **value** must be the **value** of the property. Possible keys: * canSeeStatistics * used to set statistics visibility
*
 * @tags Studios
 * @name PatchStudioPerformerProperties
 * @summary Studio updates performer properties
 * @request PATCH:/gw/inner/v2/studios/{studioId}/performers/{performerId}/properties
 * @secure
 * @response `204` `void` 204 No Content
 * @response `400` `(InnerApiError & {
  \** @example 400 *\
    errorCode?: any,

})`
 * @response `401` `void`
 * @response `403` `(InnerApiError & {
  \** @example 403 *\
    errorCode?: any,

})`
 * @response `500` `(InnerApiError & {
  \** @example 500 *\
    errorCode?: any,

})`

*/
export const patchStudioPerformerProperties = <T>(
  studioId: string,
  performerId: string,
  data: {
    /**
     * Used to set statistics visibility
     * @example false
     */
    canSeeStatistics?: boolean;
  },
  params: RequestParams = {}
): FetchResponse<T> => {
  return fetch<T>(`/gw/inner/v2/studios/${studioId}/performers/${performerId}/properties`, {
    method: 'PATCH',
    body: data,
    ...params,
  });
};

/**
 * No description
*
 * @tags Performers
 * @name GetPerformerStreamingStatus
 * @summary Get streaming status performers
 * @request GET:/gw/inner/v2/performers/{performerId}/streaming-status
 * @secure
 * @response `200` `(InnerApiSuccess & {
    data?: (PerformerStreamingStatus)[],

})`
 * @response `400` `(InnerApiError & {
  \** @example 400 *\
    errorCode?: any,

})`
 * @response `401` `void`
 * @response `403` `(InnerApiError & {
  \** @example 403 *\
    errorCode?: any,

})`
 * @response `500` `(InnerApiError & {
  \** @example 500 *\
    errorCode?: any,

})`

*/
export const getPerformerStreamingStatus = (
  performerId: number,
  params: RequestParams = {}
): FetchResponse<
  InnerApiSuccess & {
    data?: PerformerStreamingStatus[];
  }
> => {
  return fetch<
    InnerApiSuccess & {
      data?: PerformerStreamingStatus[];
    }
  >(`/gw/inner/v2/performers/${performerId}/streaming-status`, {
    method: 'GET',
    ...params,
  });
};

/**
 * No description
*
 * @tags Performers
 * @name CreateChatDiscount
 * @request POST:/gw/inner/v2/performers/{performerId}/chat-discount
 * @secure
 * @response `200` `ChatDiscount`
 * @response `400` `(InnerApiError & {
  \** @example 400 *\
    errorCode?: any,

})`
 * @response `401` `void`
 * @response `403` `(InnerApiError & {
  \** @example 403 *\
    errorCode?: any,

})`
 * @response `404` `(InnerApiError & {
  \** @example 404 *\
    errorCode?: any,

})`
 * @response `500` `(InnerApiError & {
  \** @example 500 *\
    errorCode?: any,

})`

*/
export const createChatDiscount = (
  performerId: number,
  data: {
    /**
     * @min 0
     * @max 100
     * @example 10
     */
    percentage: number;
    /** @example 1 */
    memberNumericId: number;
    /** @example "test_referrer_name" */
    message: string;
  },
  params: RequestParams = {}
): FetchResponse<ChatDiscount> => {
  return fetch<ChatDiscount>(`/gw/inner/v2/performers/${performerId}/chat-discount`, {
    method: 'POST',
    body: data,
    ...params,
  });
};

/**
 * No description
*
 * @tags Performers
 * @name CheckChatDiscountAvailability
 * @request GET:/gw/inner/v2/performers/{performerId}/chat-discount/availability
 * @secure
 * @response `200` `ChatDiscountAvailability`
 * @response `400` `(InnerApiError & {
  \** @example 400 *\
    errorCode?: any,

})`
 * @response `401` `void`
 * @response `403` `(InnerApiError & {
  \** @example 403 *\
    errorCode?: any,

})`
 * @response `404` `(InnerApiError & {
  \** @example 404 *\
    errorCode?: any,

})`
 * @response `500` `(InnerApiError & {
  \** @example 500 *\
    errorCode?: any,

})`

*/
export const checkChatDiscountAvailability = (
  performerId: number,
  params: RequestParams = {}
): FetchResponse<ChatDiscountAvailability> => {
  return fetch<ChatDiscountAvailability>(`/gw/inner/v2/performers/${performerId}/chat-discount/availability`, {
    method: 'GET',
    ...params,
  });
};

/**
 * @description The only actorType/role allowed is "user"
*
 * @tags Users
 * @name PatchPerson
 * @summary Update person attributes
 * @request PATCH:/gw/inner/v2/persons/{personId}
 * @secure
 * @response `204` `void` Person was successfully updated.
 * @response `400` `(InnerApiError & {
  \** @example 400 *\
    errorCode?: any,

})`
 * @response `401` `void`
 * @response `403` `(InnerApiError & {
  \** @example 403 *\
    errorCode?: any,

})`
 * @response `500` `(InnerApiError & {
  \** @example 500 *\
    errorCode?: any,

})`

*/
export const patchPerson = <T>(
  personId: number,
  data: {
    /** @example 1 */
    guidedTourLevel: 0 | 1 | 2 | 3;
  },
  params: RequestParams = {}
): FetchResponse<T> => {
  return fetch<T>(`/gw/inner/v2/persons/${personId}`, {
    method: 'PATCH',
    body: data,
    ...params,
  });
};

/**
 * No description
*
 * @tags Discount
 * @name GetDiscounts
 * @summary Get a discount by member_numeric_id and performer_numeric_id
 * @request GET:/gw/inner/v2/performers/{performerId}/chat-discount/chat-discounts
 * @secure
 * @response `200` `{
    data?: DiscountCollection,

}` the details of a specific Discount
 * @response `400` `(InnerApiError & {
  \** @example 400 *\
    errorCode?: any,

})`
 * @response `401` `void`

*/
export const getDiscounts = (
  performerId: number,
  query: {
    /**
     * @min 0
     * @exclusiveMin true
     * @example 1234
     */
    memberId: number;
    status?: 'pending' | 'expired' | 'cancelled' | 'used';
    source?: 'private_invitation';
  },
  params: RequestParams = {}
): FetchResponse<{
  data?: DiscountCollection;
}> => {
  return fetch<{
    data?: DiscountCollection;
  }>(`/gw/inner/v2/performers/${performerId}/chat-discount/chat-discounts`, {
    method: 'GET',
    query: query,
    responseType: 'json',
    ...params,
  });
};

/**
 * No description
*
 * @tags Hidden Face
 * @name GetHiddenFaceSetting
 * @summary Returns performer hidden face setting
 * @request GET:/gw/inner/v2/performers/{performerId}/settings/hidden-face
 * @secure
 * @response `200` `{
    data: HiddenFaceSetting,

}`
 * @response `400` `(InnerApiError & {
  \** @example 400 *\
    errorCode?: any,

})`
 * @response `404` `void`

*/
export const getHiddenFaceSetting = (
  performerId: number,
  params: RequestParams = {}
): FetchResponse<{
  data: HiddenFaceSetting;
}> => {
  return fetch<{
    data: HiddenFaceSetting;
  }>(`/gw/inner/v2/performers/${performerId}/settings/hidden-face`, {
    method: 'GET',
    ...params,
  });
};

/**
 * No description
*
 * @tags Hidden Face
 * @name PutHiddenFaceSetting
 * @summary Toggle performer hidden face setting
 * @request PUT:/gw/inner/v2/performers/{performerId}/settings/hidden-face
 * @secure
 * @response `204` `void` Successfully processed the request
 * @response `400` `(InnerApiError & {
  \** @example 400 *\
    errorCode?: any,

})`

*/
export const putHiddenFaceSetting = <T>(
  performerId: number,
  data: {
    /** @example true */
    isEnabled: boolean;
  },
  params: RequestParams = {}
): FetchResponse<T> => {
  return fetch<T>(`/gw/inner/v2/performers/${performerId}/settings/hidden-face`, {
    method: 'PUT',
    body: data,
    ...params,
  });
};

/**
 * @description Get member referrals shares for specific referrer
*
 * @tags Member Referrals
 * @name GetMemberReferralsShares
 * @request GET:/gw/inner/v2/member-referrals/shares
 * @secure
 * @response `200` `(InnerApiSuccess & MemberReferralSharesResponse)` OK
 * @response `400` `(InnerApiError & {
  \** @example 400 *\
    errorCode?: any,

})`
 * @response `401` `void`
 * @response `403` `{
  \** @example "Forbidden" *\
    errorMessage: string,
  \** @example 403 *\
    errorCode: number,
  \** @example "ERROR" *\
    status: string,
  \** @example "" *\
    data: string,

}`
 * @response `404` `(InnerApiError & {
  \** @example 404 *\
    errorCode?: any,

})`
 * @response `429` `void`

*/
export const getMemberReferralsShares = (
  query?: {
    /**
     * Limit of results.
     * @default 10
     */
    limit?: number;
    /**
     * The offset of the results.
     * @default 0
     */
    offset?: number;
  },
  params: RequestParams = {}
): FetchResponse<InnerApiSuccess & MemberReferralSharesResponse> => {
  return fetch<InnerApiSuccess & MemberReferralSharesResponse>(`/gw/inner/v2/member-referrals/shares`, {
    method: 'GET',
    query: query,
    ...params,
  });
};

/**
 * @description Get member referrals statistic for specific referrer
*
 * @tags Member Referrals
 * @name GetMemberReferralsStatistic
 * @request GET:/gw/inner/v2/member-referrals/statistic
 * @secure
 * @response `200` `(InnerApiSuccess & MemberReferralStatisticResponse)` OK
 * @response `400` `(InnerApiError & {
  \** @example 400 *\
    errorCode?: any,

})`
 * @response `401` `void`
 * @response `403` `{
  \** @example "Forbidden" *\
    errorMessage: string,
  \** @example 403 *\
    errorCode: number,
  \** @example "ERROR" *\
    status: string,
  \** @example "" *\
    data: string,

}`
 * @response `404` `(InnerApiError & {
  \** @example 404 *\
    errorCode?: any,

})`
 * @response `429` `void`

*/
export const getMemberReferralsStatistic = (
  params: RequestParams = {}
): FetchResponse<InnerApiSuccess & MemberReferralStatisticResponse> => {
  return fetch<InnerApiSuccess & MemberReferralStatisticResponse>(`/gw/inner/v2/member-referrals/statistic`, {
    method: 'GET',
    ...params,
  });
};
