interface TimerControls {
  resume(): void;
  pause(): void;
  stop(): void;
}

interface TimerOptions {
  timeout: number;
  enabled: boolean;
}
const timer = (callback: Function, options: TimerOptions): TimerControls => {
  const { timeout, enabled } = options;
  let startedAt: number;
  let timeRemaining: number = timeout;
  let id: number;

  return {
    stop() {
      if (!enabled) return;

      timeRemaining = timeout;
      window.clearTimeout(id);
      id = 0;
    },
    pause() {
      if (!enabled) return;

      timeRemaining -= Date.now() - startedAt;
      window.clearTimeout(id);
      id = 0;
    },
    resume() {
      if (!enabled) return;

      startedAt = Date.now();
      id = window.setTimeout(callback, Math.max(0, timeRemaining));
    },
  };
};

export default timer;
