import { defer } from 'rxjs';
import { link } from 'services/navigation';
import authorization from 'services/authorization';
import type { NavigationMenuItem } from 'contracts';
import unreadCounter from 'store/unread-counter';

import messages from '../news.i18n';

import id from './id';

const menu: NavigationMenuItem = {
  entries: [
    {
      name: id,
      parent: 'helpAndInfo',
      label: messages.pages.news.navigationMenu.label,
      link: () => link[id](),
      weight: 1,
      visibility$: authorization.getAuthorization$(id),
      notification$: defer(() => unreadCounter.onNewsChange$),
    },
  ],
  activates: {
    [id]: [id],
  },
};

export default menu;
