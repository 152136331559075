import React from 'react';
import type { History } from 'history';

import Routing from './routing';
import { RouterContext, RouteRequestContext, type RouterProvider } from './context';
import createNavigationHistory, { mountInitialState } from './create-navigation-history';
import type { RoutesAssistant } from './utils/create-routes-assistant';
import type { NavigationHistory, RouteMiddleware, RequestContext } from './contracts';

interface RouterProviderConfig {
  assistant: RoutesAssistant;
  history: History;
  middlewares?: Array<RouteMiddleware>;
  defaultSkeleton?: React.ReactNode;
  children?: React.ReactNode;
}

const Router: React.FunctionComponent<RouterProviderConfig> = (props) => {
  const { assistant, history, middlewares, defaultSkeleton, children } = props;
  const [{ current, previous }, setRouteState] = React.useState<NavigationHistory>({
    current: mountInitialState(history),
    previous: undefined,
  } satisfies NavigationHistory);
  const unblockNavigation = React.useRef<ReturnType<typeof history.block>>(undefined);
  const requestContext = React.useRef<RequestContext>(new Map<string, unknown>(undefined));

  React.useEffect(() => {
    const { onHistoryChange$ } = createNavigationHistory(history);

    const subscription = onHistoryChange$.subscribe((state) => {
      document.documentElement.scrollTo({ behavior: 'smooth', top: 0, left: 0 });

      setRouteState(state);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [history]);

  const context = React.useMemo(
    () =>
      ({
        current,
        previous,
        routesAssistant: assistant,
        navigation: {
          block: (callback) => {
            unblockNavigation.current = history.block((tx) => {
              const unblock = (): void => {
                unblockNavigation.current?.();

                tx.retry();
              };

              callback?.(unblock);
            });
          },
          unblock: () => {
            unblockNavigation.current?.();
          },
        },
      }) satisfies RouterProvider,
    [current, previous, assistant, history]
  );

  return (
    <RouterContext.Provider value={context}>
      <RouteRequestContext.Provider value={requestContext.current}>
        <Routing middlewares={middlewares} defaultSkeleton={defaultSkeleton}>
          {children}
        </Routing>
      </RouteRequestContext.Provider>
    </RouterContext.Provider>
  );
};

export default React.memo(Router, (prev, next) => {
  return prev.children === next.children;
});
