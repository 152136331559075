import { combineLatest, defer, iif, map, of, switchMap } from 'rxjs';
import user from 'store/user';
import evalAuthorization from 'utils/rxjs/eval-authorization';
import is from 'utils/is';
import type { RouteAuthorization } from 'contracts';
import performer from 'store/performer';
import application from 'services/application';
import performerProfilePictures from 'store/performer-profile-pictures';
import moduleAuth from 'store/module-authorization';

import id from './id';

const authorization: RouteAuthorization[] = [
  {
    name: id.dashboard,
    authorization$: user.onChange$.pipe(
      evalAuthorization(
        ({ viewTypeId }) => is.nullish(viewTypeId),
        () => of(user.isModelView())
      )
    ),
  },
  {
    name: id.statisticsStudio,
    authorization$: user.onChange$.pipe(
      evalAuthorization(
        ({ viewTypeId }) => is.nullish(viewTypeId),
        () => of(user.isStudioView() && !user.hasLimitedAccess())
      )
    ),
  },
  {
    name: id.messengerSupport,
    authorization$: user.onChange$.pipe(
      evalAuthorization(
        ({ viewTypeId }) => is.nullish(viewTypeId),
        () =>
          iif(
            () => user.isModelView(),
            performer.onStatusChange$.pipe(
              switchMap(() => moduleAuth.onChange$.pipe(map(() => moduleAuth.hasSupportMessagesPermission())))
            ),
            of(true)
          )
      )
    ),
  },
  {
    name: id.messengerSystem,
    authorization$: user.onChange$.pipe(
      evalAuthorization(
        ({ viewTypeId }) => is.nullish(viewTypeId),
        () =>
          iif(
            () => user.isModelView(),
            performer.onStatusChange$.pipe(
              switchMap(() => moduleAuth.onChange$.pipe(map(() => moduleAuth.hasSystemMessagesPermission())))
            ),
            of(false)
          )
      )
    ),
  },
  {
    name: id.statisticsFanClub,
    authorization$: () =>
      user.onChange$.pipe(
        evalAuthorization(
          ({ viewTypeId }) => is.nullish(viewTypeId),
          () =>
            iif(
              () => user.isModelView(),
              defer(() =>
                performer.onCategoryChange$.pipe(map(() => application.LiveJasmin && !performer.hasFreeShowCategory()))
              ),
              of(false)
            )
        )
      ),
  },
  {
    name: id.statisticsIncome,
    authorization$: user.onChange$.pipe(
      evalAuthorization(
        ({ viewTypeId }) => is.nullish(viewTypeId),
        () => iif(() => user.isModelView(), of(true), of(false))
      )
    ),
  },
  {
    name: id.myContent,
    authorization$: (params) =>
      user.onChange$.pipe(
        evalAuthorization(
          ({ viewTypeId }) => is.nullish(viewTypeId),
          () =>
            iif(
              () => user.isModelView(),
              iif(
                () => params?.page === 'paying-snapshot',
                defer(() =>
                  application.onViewportTypeChange$.pipe(
                    map((viewport) => application.LiveJasmin && viewport === 'desktop')
                  )
                ),
                of(true)
              ),
              of(false)
            )
        )
      ),
  },
  {
    name: id.myContentShorts,
    authorization$: () =>
      user.onChange$.pipe(
        evalAuthorization(
          ({ viewTypeId }) => is.nullish(viewTypeId),
          () =>
            iif(
              () => application.LiveJasmin && user.isModelView(),
              defer(() => performer.onStatusChange$.pipe(map(() => performer.hasActiveStatus()))),
              of(false)
            )
        )
      ),
  },
  {
    name: id.myContentEditor,
    authorization$: (params) =>
      user.onChange$.pipe(
        evalAuthorization(
          ({ viewTypeId }) => is.nullish(viewTypeId),
          () =>
            iif(
              () => user.isModelView(),
              defer(() => {
                switch (params?.type) {
                  case 'free':
                  case 'premium':
                    return performer.onStatusChange$.pipe(map(() => performer.hasActiveStatus()));
                  default:
                    return of(true);
                }
              }),
              of(false)
            )
        )
      ),
  },
  {
    name: id.messages,
    authorization$: user.onChange$.pipe(
      evalAuthorization(
        ({ viewTypeId }) => is.nullish(viewTypeId),
        () =>
          iif(
            () => user.isModelView(),
            performer.onStatusChange$.pipe(
              switchMap(() => moduleAuth.onChange$.pipe(map(() => moduleAuth.hasMemberMessagesPermission())))
            ),
            of(false)
          )
      )
    ),
  },
  {
    name: id.profile,
    authorization$: (params) =>
      user.onChange$.pipe(
        evalAuthorization(
          ({ viewTypeId }) => is.nullish(viewTypeId),
          () =>
            iif(
              () => user.isModelView(),
              defer(() => {
                if (application.Oranum) {
                  switch (params?.page) {
                    case 'introduction':
                      return of(false);
                    default:
                      return of(true);
                  }
                }

                switch (params?.page) {
                  case 'prices':
                    return performer.onStatusChange$.pipe(map(() => !performer.hasMissingDataStatus()));
                  default:
                    return of(true);
                }
              }),
              defer(() => {
                switch (params?.page) {
                  default:
                    return of(false);
                }
              })
            )
        )
      ),
  },
  {
    name: id.promotionToolsPromoTeaser,
    authorization$: () =>
      user.onChange$.pipe(
        evalAuthorization(
          ({ viewTypeId }) => is.nullish(viewTypeId),
          () =>
            iif(
              () => user.isModelView(),
              performer.onCategoryChange$.pipe(
                map(
                  () => application.LiveJasmin && (performer.hasCelebrityCategory() || performer.hasHotFlirtCategory())
                )
              ),
              of(false)
            )
        )
      ),
  },
  {
    name: id.promotionToolsEroticTeaser,
    authorization$: () =>
      user.onChange$.pipe(
        evalAuthorization(
          ({ viewTypeId }) => is.nullish(viewTypeId),
          () =>
            iif(
              () => user.isModelView(),
              performer.onCategoryChange$.pipe(
                map(
                  () =>
                    application.LiveJasmin &&
                    (performer.hasNudeCategory() || performer.hasAmateurCategory() || performer.hasFreeShowCategory())
                )
              ),
              of(false)
            )
        )
      ),
  },
  {
    name: id.promotionToolsVideoCall,
    authorization$: () =>
      user.onChange$.pipe(
        evalAuthorization(
          ({ viewTypeId }) => is.nullish(viewTypeId),
          () =>
            iif(
              () => user.isModelView(),
              combineLatest([performer.onCategoryChange$, performer.onStatusChange$]).pipe(
                map(() => application.LiveJasmin && !(performer.hasFreeShowCategory() || performer.hasClosedStatus()))
              ),
              of(false)
            )
        )
      ),
  },
  {
    name: id.promotionToolsVideoAds,
    authorization$: () =>
      user.onChange$.pipe(
        evalAuthorization(
          ({ viewTypeId }) => is.nullish(viewTypeId),
          () => of(application.Oranum && user.isModelView())
        )
      ),
  },
  {
    name: id.bestOfTopModels,
    authorization$: user.onChange$.pipe(
      evalAuthorization(
        ({ viewTypeId }) => is.nullish(viewTypeId),
        () => of(user.isModelView())
      )
    ),
  },
  {
    name: id.awards,
    authorization$: user.onChange$.pipe(
      evalAuthorization(
        ({ viewTypeId }) => is.nullish(viewTypeId),
        () =>
          iif(
            () => user.isModelView(),
            defer(() => {
              return combineLatest([
                performer.onStatusChange$,
                performerProfilePictures.onAcceptedGlamourProfilePictureStatusChange$,
              ]).pipe(
                map(
                  () =>
                    user.isModelView() &&
                    performer.hasActiveStatus() &&
                    performerProfilePictures.hasAcceptedGlamourProfilePicture()
                )
              );
            }),
            of(false)
          )
      )
    ),
  },
  {
    name: id.referrals,
    authorization$: (params) =>
      user.onChange$.pipe(
        evalAuthorization(
          ({ viewTypeId }) => is.nullish(viewTypeId),
          () =>
            iif(
              () => user.isModelView(),
              defer(() => {
                if (application.Oranum) {
                  return of(false);
                }

                if (params?.page === 'member-referral') {
                  return combineLatest([application.onViewportTypeChange$, performer.onCategoryChange$]).pipe(
                    map(([viewport]) => !performer.hasFreeShowCategory() && viewport === 'desktop')
                  );
                }

                return of(true);
              }),
              defer(() => {
                if (application.Oranum) {
                  return of(false);
                }

                if (params?.page === 'member-referral') {
                  return application.onViewportTypeChange$.pipe(map((viewport) => viewport === 'desktop'));
                }

                return of(true);
              })
            )
        )
      ),
  },
  {
    name: id.payout,
    authorization$: (params) =>
      user.onChange$.pipe(
        evalAuthorization(
          ({ viewTypeId }) => is.nullish(viewTypeId),
          () =>
            iif(
              () => user.isModelView(),
              defer(() => {
                if (application.Oranum) {
                  switch (params?.page) {
                    case 'income-statistics':
                      return of(false);

                    case 'payout-overview':
                      return combineLatest([application.onViewportTypeChange$, of(user.isSingleAccountType())]).pipe(
                        map(([viewport, status]) => status && viewport === 'desktop')
                      );

                    default:
                      return of(true);
                  }
                }

                switch (params?.page) {
                  case 'income-statistics':
                    return of(false);

                  case 'payout-overview':
                    return combineLatest([application.onViewportTypeChange$, of(user.isSingleAccountType())]).pipe(
                      map(([viewport, status]) => status && viewport === 'desktop')
                    );

                  default:
                    return of(true);
                }
              }),
              defer(() => {
                switch (params?.page) {
                  case 'income-statistics':
                  case 'payout-overview':
                    return combineLatest([application.onViewportTypeChange$, of(!user.hasLimitedAccess())]).pipe(
                      map(([viewport, status]) => status && viewport === 'desktop')
                    );

                  default:
                    return of(true);
                }
              })
            )
        )
      ),
  },
  {
    name: id.payoutOptions,
    authorization$: user.onChange$.pipe(
      evalAuthorization(
        ({ viewTypeId }) => is.nullish(viewTypeId),
        () =>
          iif(
            () => user.isModelView(),
            defer(() => {
              if (application.Oranum) {
                return combineLatest([application.onViewportTypeChange$, of(user.isSingleAccountType())]).pipe(
                  map(([viewport, status]) => (viewport === 'desktop' ? status : true))
                );
              }

              return of(user.isSingleAccountType());
            }),
            of(!user.hasLimitedAccess())
          )
      )
    ),
  },
  {
    name: id.earnMore,
    authorization$: (params) =>
      user.onChange$.pipe(
        evalAuthorization(
          ({ viewTypeId }) => is.nullish(viewTypeId),
          () =>
            iif(
              () => user.isModelView(),
              defer(() => {
                switch (params?.page) {
                  case 'special-requests':
                  case 'private-shows':
                  case 'stories':
                  case 'group-session':
                  case 'private-session-discounts':
                  case 'top-member':
                  case 'videos':
                  case 'rewards-club':
                    return of(application.Oranum);

                  case 'tips':
                    return performer.onCategoryChange$.pipe(map(() => performer.hasFreeShowCategory()));

                  case 'fan-club':
                    return performer.onCategoryChange$.pipe(
                      map(() => application.LiveJasmin && !performer.hasFreeShowCategory())
                    );

                  case 'action-requests':
                    return performer.onCategoryChange$.pipe(
                      map(
                        () =>
                          application.LiveJasmin &&
                          !(
                            performer.hasNudeAndTransgenderCategory() ||
                            performer.hasNudeAndShemaleCategory() ||
                            performer.hasNudeAndHermaphroditeCategory() ||
                            performer.hasNudeAndGayCategory()
                          )
                      )
                    );

                  case 'messaging-streak':
                  case 'vip-show':
                  case 'protection':
                  case 'interactive-toy':
                  case 'bemyfan':
                  case 'club-elite':
                    return of(application.LiveJasmin);

                  case 'exclusive-model':
                    return combineLatest([
                      application.onViewportTypeChange$,
                      performer.onCategoryChange$.pipe(
                        map(() => !(performer.hasAmateurCategory() || performer.hasFreeShowCategory()))
                      ),
                    ]).pipe(map(([viewport, status]) => application.LiveJasmin && status && viewport === 'desktop'));

                  case 'video-ads':
                    return application.onViewportTypeChange$.pipe(
                      map((viewport) => application.Oranum && viewport === 'desktop')
                    );

                  case 'my-story':
                  case 'video-call':
                    return application.onViewportTypeChange$.pipe(
                      map((viewport) => (application.LiveJasmin && viewport === 'desktop') || application.Oranum)
                    );

                  case 'private-show-discount':
                  case 'king-of-the-room':
                  case 'two-way-audio':
                  case 'cam-to-cam':
                    return application.onViewportTypeChange$.pipe(
                      map((viewport) => application.LiveJasmin && viewport === 'desktop')
                    );

                  case 'promo-teasers':
                    return performer.onCategoryChange$.pipe(
                      map(
                        () =>
                          application.LiveJasmin &&
                          (performer.hasCelebrityCategory() || performer.hasHotFlirtCategory())
                      )
                    );

                  case 'erotic-teasers':
                    return performer.onCategoryChange$.pipe(
                      map(
                        () =>
                          application.LiveJasmin &&
                          (performer.hasNudeCategory() ||
                            performer.hasAmateurCategory() ||
                            performer.hasFreeShowCategory())
                      )
                    );

                  case 'promo-video':
                    // MSC-28809 - Hiding premium video uploads
                    return of(false);

                  case 'private-game':
                    return combineLatest([
                      application.onViewportTypeChange$,
                      performer.onCategoryChange$.pipe(map(() => !performer.hasCelebrityCategory())),
                    ]).pipe(map(([viewport, status]) => application.LiveJasmin && status && viewport === 'desktop'));

                  case 'jasmin-selection':
                    return combineLatest([
                      application.onViewportTypeChange$,
                      performer.onCategoryChange$.pipe(map(() => !performer.hasAmateurCategory())),
                    ]).pipe(map(([viewport, status]) => application.LiveJasmin && status && viewport === 'desktop'));

                  default:
                    return of(true);
                }
              }),
              of(false)
            )
        )
      ),
  },
  {
    name: id.help,
    authorization$: (params) =>
      user.onChange$.pipe(
        evalAuthorization(
          ({ viewTypeId }) => is.nullish(viewTypeId),
          () =>
            iif(
              () => user.isModelView(),
              defer(() => {
                if (application.Oranum) {
                  return of(true);
                }

                switch (params?.type) {
                  case 'income-shares':
                    return performer.onCategoryChange$.pipe(map(() => !performer.hasAmateurCategory()));
                  default:
                    return of(true);
                }
              }),
              of(true)
            )
        )
      ),
  },
];

export default authorization;
