import { defineTracking } from 'services/google-analytics';

const category = 'landing page';
const action = 'gdpr pop-up';

export default {
  view: {
    ...defineTracking({
      banner: {
        category,
        action,
        label: 'appeared',
        description: 'This event triggers when the user views the cookie consent banner.',
      },
      policy: {
        category,
        action,
        label: 'cookie policy',
        description: 'This event triggers when the user clicks on the Cookie Policy link.',
      },
    }),
  },
  cookies: {
    ...defineTracking({
      accept: {
        category,
        action,
        label: 'accept cookies',
        description: 'This event triggers when the user clicks on the Accept Cookies button.',
      },
      confirm: {
        category,
        action,
        label: 'confirm my choices: analytical {analytical}, functionality {functionality}',
        description: 'This event triggers when the user clicks the Confirm My Choices button.',
      },
      manage: {
        category,
        action,
        label: 'manage cookies',
        description: 'This event triggers when the user clicks the Manage Cookies text button.',
      },
      reject: {
        category,
        action,
        label: 'reject cookies',
        description: 'This event triggers when the user clicks on the Reject Cookies button.',
      },
    }),
  },
};
