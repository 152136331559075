import React from 'react';
import type { StyledElement, AriaAttributes, TestAutomation } from 'contracts';
import toArray from 'utils/to-array';
import is from 'utils/is';

import type { ButtonElement } from '../../button';
import Html from '../../html';

import styles from './template.module.scss';

interface TemplateElement extends StyledElement, AriaAttributes, TestAutomation {
  title?: string;
  content: React.ReactNode | string;
  button?: React.ReactElement<ButtonElement>;
}

const Template: React.FunctionComponent<TemplateElement> = (props) => {
  const { testId, className, style, title, button, content, ...rest } = props;
  const shouldStyleContent = is.string(content);

  if (!content) {
    return null;
  }

  return (
    <Html.div
      testId={testId}
      className={[styles.template, shouldStyleContent && 'text-center text-md-start', ...toArray(className)]}
      style={style}
      arias={rest}
    >
      {title && (
        <Html.div className={[styles.title, 'mb-1']} typography="title2" fontWeight="bold">
          {title}
        </Html.div>
      )}
      {(!shouldStyleContent && content) || (
        <Html.div className={styles.content} typography="body2" fontWeight="regular">
          {content}
        </Html.div>
      )}
      {button && <Html.div className="mt-2">{button}</Html.div>}
    </Html.div>
  );
};

export type { TemplateElement };
export default React.memo(Template);
