import { defineMessages } from 'react-intl';
import type { LocalePerProduct } from 'contracts';

const seo: LocalePerProduct = {
  livejasmin: defineMessages({
    title: {
      id: 'pages.payout.payinfo.seo.livejasmin.title',
      defaultMessage: 'Payout FAQ',
    },
    description: {
      id: 'pages.payout.payinfo.seo.livejasmin.description',
      defaultMessage: 'Payout FAQ',
    },
  }),
  oranum: defineMessages({
    title: {
      id: 'pages.payout.payinfo.seo.oranum.title',
      defaultMessage: 'Payout FAQ',
    },
    description: {
      id: 'pages.payout.payinfo.seo.oranum.description',
      defaultMessage: 'Payout FAQ',
    },
  }),
};

export default {
  pages: {
    payout: {
      payinfo: {
        seo,
        navigationMenu: {
          ...defineMessages({
            label: {
              id: 'pages.payout.payinfo.navigationMenu.label',
              defaultMessage: 'Payout FAQ',
            },
          }),
        },
        ...defineMessages({
          payout: {
            id: 'pages.payout.payinfo.payout',
            defaultMessage: 'Payout',
          },
          readMore: {
            id: 'pages.payout.payinfo.readMore',
            defaultMessage: 'Read more...',
          },
          keywords: {
            id: 'pages.payout.payinfo.keywords',
            defaultMessage: 'Keywords',
          },
        }),
        generalInfo: {
          ...defineMessages({
            title: {
              id: 'pages.payout.payinfo.generalInfo.title',
              defaultMessage: 'General Information on Payouts',
            },
            info: {
              id: 'pages.payout.payinfo.generalInfo.info',
              defaultMessage: `A Payout Period consists of {periodDuration, plural, one {# day} other {# days}}
                and payments are made {paymentDelay, plural, one {# day} other {# days}} after each Payout Period End Date.
                If you choose Fast Payout option, your payments will happen one day after each Period closure.
                You need to reach your Payout Threshold within a period in order to get paid on the payout date of that given period.
                Payout Thresholds can be reached with Level Dependent Incomes only. 
                (The lowest Payout Threshold is {lowestThreshold} and the highest is {highestThreshold}, depending on the Payment Method you choose).
                A Payout Period is closed {periodClosingDayRange} days after the calendar end date of the Period.
                If you do not reach your Payout Threshold in a period, your earnings are transferred over to the next period until you reach the Payout Threshold.`,
            },
          }),
        },
        paymentMethods: {
          ...defineMessages({
            title: {
              id: 'pages.payout.payinfo.paymentMethods.title',
              defaultMessage: 'Payment Methods',
            },
            info: {
              id: 'pages.payout.payinfo.paymentMethods.info',
              defaultMessage:
                'We currently have two payment options available on our website: a prepaid card connected to an e-wallet account and bank transfer. Each option is offered through different processing companies. Further payment methods may continue to be added to satisfy every need.',
            },
          }),
        },
        payoutProcessors: {
          ...defineMessages({
            title: {
              id: 'pages.payout.payinfo.payoutProcessors.title',
              defaultMessage: 'Payout Processors',
            },
            info: {
              id: 'pages.payout.payinfo.payoutProcessors.info',
              defaultMessage:
                'We currently have two payment options available on our website: a prepaid card connected to an e-wallet account and bank transfer. Each option is offered through different processing companies. Further payment methods may continue to be added to satisfy every need.',
            },
          }),
        },
        payoneer: {
          ...defineMessages({
            title: {
              id: 'pages.payout.payinfo.payoneer.title',
              defaultMessage: 'Payoneer:',
            },
            info: {
              id: 'pages.payout.payinfo.payoneer.info',
              defaultMessage:
                'Payoneer is an e-Wallet based payout solution. By choosing this option, you will be paid on your Payoneer account from where you can easily use the funds with your Payoneer Prepaid MasterCard® card.',
            },
          }),
        },
        payPal: {
          ...defineMessages({
            title: {
              id: 'pages.payout.payinfo.payPal.title',
              defaultMessage: 'PayPal:',
            },
            info: {
              id: 'pages.payout.payinfo.payPal.info',
              defaultMessage:
                'PayPal payout method allows any Business or Individual with a PayPal account to securely receive payments from {brandDomain}. As PayPal is being increasingly used on other e-commerce websites, one of the main advantages using PayPal is that you do not need to withdraw your funds but instead you can use them straight on your PayPal account for online purchases. Each payout we execute to your PayPal account – whether it is an Individual or a Business account – will cost you {transferFeePercent}% of the transferred amount, deducted from the payment we send.',
            },
          }),
        },
        skrill: {
          ...defineMessages({
            title: {
              id: 'pages.payout.payinfo.skrill.title',
              defaultMessage: 'Skrill',
            },
            info: {
              id: 'pages.payout.payinfo.skrill.info',
              defaultMessage:
                'It takes just a few minutes to create a Skrill wallet and bring all your payment details together in one place. You can choose from 40 currencies and move your funds from your wallet to your Skrill MasterCard prepaid card, to your bank account or to any 3rd party VISA card.',
            },
          }),
        },
        webMoney: {
          ...defineMessages({
            title: {
              id: 'pages.payout.payinfo.webMoney.title',
              defaultMessage: 'WebMoney:',
            },
            info: {
              id: 'pages.payout.payinfo.webMoney.info',
              defaultMessage:
                'WebMoney Transfer is a global settlement system and environment for online business activities, established in 1998. Since then, over 36 million people have joined WebMoney, mainly from the eastern region of the world like Russia or Ukraine, however, it is now used widely worldwide.',
            },
          }),
        },
        ePayService: {
          ...defineMessages({
            title: {
              id: 'pages.payout.payinfo.ePayService.title',
              defaultMessage: 'ePayService:',
            },
            info: {
              id: 'pages.payout.payinfo.ePayService.info',
              defaultMessage:
                'ePayService is an e-Wallet-based payout solution. By choosing this option, you will be paid to your ePayService virtual account from where you can easily transfer funds to your ePayService MasterCard®, to your local bank account, to your WebMoney account or you can issue yourself a paper check.',
            },
          }),
        },
        paxum: {
          ...defineMessages({
            title: {
              id: 'pages.payout.payinfo.paxum.title',
              defaultMessage: 'Paxum:',
            },
            info: {
              id: 'pages.payout.payinfo.paxum.info',
              defaultMessage:
                'Paxum is an e-Wallet-based payout solution. By choosing this option, you will be paid to your Paxum virtual account from where you can easily transfer funds to your Paxum MasterCard®, to your local bank account or you can also issue yourself a paper check.',
            },
          }),
        },
        cosmo: {
          ...defineMessages({
            title: {
              id: 'pages.payout.payinfo.cosmo.title',
              defaultMessage: 'Cosmo:',
            },
            info: {
              id: 'pages.payout.payinfo.cosmo.info',
              defaultMessage:
                "Cosmo Payment is a leading provider of global payment solutions. With over two decades of experience, their dynamic payment platform operates in a variety of industries and provides great flexibility to support our Partners' payment needs.",
            },
          }),
        },
        crypto: {
          ...defineMessages({
            title: {
              id: 'pages.payout.payinfo.crypto.title',
              defaultMessage: 'Crypto:',
            },
            info: {
              id: 'pages.payout.payinfo.crypto.info',
              defaultMessage: 'We can send crypto payments safely and securely.',
            },
          }),
        },
        bankSEPA: {
          ...defineMessages({
            title: {
              id: 'pages.payout.payinfo.bankSEPA.title',
              defaultMessage: 'Bank transfer - SEPA:',
            },
            info: {
              id: 'pages.payout.payinfo.bankSEPA.info',
              defaultMessage:
                'If you are located in the SEPA area and receive EUR payouts, you may receive your earnings via SEPA bank transfer, which has a fee of {percent} of the transferred amount, with a min. charge of {minimumCharge} and a max. charge of {maximumCharge} for each transaction.',
            },
          }),
        },
        bankEUR: {
          ...defineMessages({
            title: {
              id: 'pages.payout.payinfo.bankEUR.title',
              defaultMessage: 'Bank transfer - International EUR:',
            },
            info: {
              id: 'pages.payout.payinfo.bankEUR.info',
              defaultMessage:
                'If you receive your earnings in EUR, but your bank is located outside of the SEPA area, bank transfers will have a fee of {percent} of the transferred amount, with a min. charge of {minimumCharge} and a max. charge of {maximumCharge} for each transaction.',
            },
          }),
        },
        bankACH: {
          ...defineMessages({
            title: {
              id: 'pages.payout.payinfo.bankACH.title',
              defaultMessage: 'Bank transfer - ACH:',
            },
            info: {
              id: 'pages.payout.payinfo.bankACH.info',
              defaultMessage:
                'If your payout currency is set to USD, you have the option to receive affordable and swift ACH bank transfers in the USA. Each transfer will have a fee of {percent} of the transferred amount, with a min. charge of {minimumCharge} and a max. charge of {maximumCharge} for each transaction.',
            },
          }),
        },
        pagomundo: {
          ...defineMessages({
            title: {
              id: 'pages.payout.payinfo.pagomundo.title',
              defaultMessage: 'Pagomundo:',
            },
            info: {
              id: 'pages.payout.payinfo.pagomundo.info',
              defaultMessage:
                "Pagomundo's extensive network of global payment solutions currently provides localized payments throughout Brazil, Mexico, Colombia, Chile, Argentina, and Peru.",
            },
          }),
        },
        pandablue: {
          ...defineMessages({
            title: {
              id: 'pages.payout.payinfo.pandablue.title',
              defaultMessage: 'Pandablue:',
            },
            info: {
              id: 'pages.payout.payinfo.pandablue.info',
              defaultMessage:
                'If you choose to receive your earnings in USD, and you are located in LATAM (Brazil, Chile, Colombia, Mexico, Peru) or in Thailand, you have the opportunity to request your payments via Pandablue. Transfers to the listed countries have a fee of {fee} of the transacted amount.',
            },
            info2: {
              id: 'pages.payout.payinfo.pandablue.info2',
              defaultMessage: 'Our examples below demonstrate how the fee is applied:',
            },
            info3: {
              id: 'pages.payout.payinfo.pandablue.info3',
              defaultMessage: 'max. {rangeMaxFee} if your payout is between {rangeMin} and {rangeMax}',
            },
            info4: {
              id: 'pages.payout.payinfo.pandablue.info4',
              defaultMessage: 'max. {rangeMaxFee} if your payout is between {rangeMin} and above',
            },
          }),
        },
        changeDetails: {
          ...defineMessages({
            title: {
              id: 'pages.payout.payinfo.changeDetails.title',
              defaultMessage: 'Change Payment Details',
            },
            info: {
              id: 'pages.payout.payinfo.changeDetails.info',
              defaultMessage:
                "To change your current payment method please log in to your {product, select, livejasmin {Studio} other {Agency}} account, go to Payout/Payout Options, and use the 'To change current payout provider click here' link at the bottom of the page. Please make sure that you take and upload a brand-new Face+ID picture during the process.",
            },
          }),
        },
        paymentPeriod: {
          ...defineMessages({
            title: {
              id: 'pages.payout.payinfo.paymentPeriod.title',
              defaultMessage: 'Payment period:',
            },
            info: {
              id: 'pages.payout.payinfo.paymentPeriod.info',
              defaultMessage:
                'A period consists of {periodDays} days. A Payout Period end date is indicated after each period closure in the Payout Overview menu. The Period Start Date is the day following the previous Payout Period End Date. If you have no previous closure, the Period Start Date is your account registration date.',
            },
          }),
        },
        payoutThreshold: {
          ...defineMessages({
            title: {
              id: 'pages.payout.payinfo.payoutThreshold.title',
              defaultMessage: 'Payout Threshold:',
            },
            info: {
              id: 'pages.payout.payinfo.payoutThreshold.info',
              defaultMessage:
                'The Payout Threshold is the amount of earnings you must reach before getting paid. Normally, you are advised to choose the lowest payout threshold ({lowestPayout}) to ensure that you will get paid more frequently.',
            },
          }),
        },
        payoutDate: {
          ...defineMessages({
            title: {
              id: 'pages.payout.payinfo.payoutDate.title',
              defaultMessage: 'Payout Date:',
            },
            info: {
              id: 'pages.payout.payinfo.payoutDate.info',
              defaultMessage:
                'If you choose the Standard Payout option, your payouts are performed {numDays} days after the Payout Period End Date. This is indicated under the Due Date column in the Payout Overview menu. If you use the Fast Payout, your payout is performed one day after the period closure - instead of {numDays} days.',
            },
          }),
        },
        fastPayout: {
          ...defineMessages({
            title: {
              id: 'pages.payout.payinfo.fastPayout.title',
              defaultMessage: 'Fast Payout:',
            },
            info1: {
              id: 'pages.payout.payinfo.fastPayout.info1',
              defaultMessage:
                'Fast payout withdrawal is available. You can withdraw your payment immediately after the period closure, for a fee of {feePercentage} of the payout amount, meaning minimum {minAmount} or maximum {maxAmount}.',
            },
            info2: {
              id: 'pages.payout.payinfo.fastPayout.info2',
              defaultMessage:
                'The withdrawn payment is executed within {numHours} hours excluding weekends and holidays. Please note that fast payouts can be subject to processing failures due to incorrect payout data and to transition times.',
            },
            info3: {
              id: 'pages.payout.payinfo.fastPayout.info3',
              defaultMessage:
                'E-Wallet payments arrive on the date of execution, while wire transfers need {numDays} days to arrive.',
            },
            info4: {
              id: 'pages.payout.payinfo.fastPayout.info4',
              defaultMessage:
                'You have the option of setting the fast payout function to Automatic. If the automatic feature is active, it will automatically send your payments every period, so you will not have to manually request an Fast Payout. Please note that the fee for Fast Payouts will be deducted each period this service is active. The service is applied to future payments only; if you already have a closed but pending payout before you activate the feature, you will need to manually withdraw that one payment in the Payout Overview section. You may enable/disable Automatic Fast Payouts at any time.',
            },
          }),
        },
        instantPayout: {
          ...defineMessages({
            title: {
              id: 'pages.payout.payinfo.instantPayout.title',
              defaultMessage: 'Instant Payout:',
            },
            info1: {
              id: 'pages.payout.payinfo.instantPayout.info1',
              defaultMessage:
                'Provided that you reached your Payout Threshold, you can ask for Instant Payout of your earnings, without waiting the end of the Standard invoicing period. The fee of the Instant Payout is {feePercentage} of the payout amount (min {minPayoutAmount}), and it will be processed within one business day after your request. To use this feature, Studios need to earn at least {minStudioEarnings} in three consecutive periods, while individual Performers need to make at least {minPerformerEarnings} during two consecutive periods.',
            },
            info2: {
              id: 'pages.payout.payinfo.instantPayout.info2',
              defaultMessage:
                'In order to maximize the number of your payouts, you can also set your Payout Type to Automatic Instant Payout and receive your earnings automatically as soon as you reach the Payout Threshold.',
            },
          }),
        },
        payoutMethod: {
          ...defineMessages({
            title: {
              id: 'pages.payout.payinfo.payoutMethod.title',
              defaultMessage: 'Payout Method:',
            },
            info: {
              id: 'pages.payout.payinfo.payoutMethod.info',
              defaultMessage:
                'The Payment Method is the way we send your earnings; it can be done by bank wire transfer, or as an electronic payment to an e-wallet account.',
            },
          }),
        },
        paymentReference: {
          ...defineMessages({
            title: {
              id: 'pages.payout.payinfo.paymentReference.title',
              defaultMessage: 'Payment Reference:',
            },
            info: {
              id: 'pages.payout.payinfo.paymentReference.info',
              defaultMessage:
                'When sending out payouts (whether to e-wallet/prepaid card or by wire transfer) we use the following payment reference: Electronically Delivered Services. There is no option to change this text.',
            },
          }),
        },
        payoutCurrency: {
          ...defineMessages({
            title: {
              id: 'pages.payout.payinfo.payoutCurrency.title',
              defaultMessage: 'Payout Currency:',
            },
            info: {
              id: 'pages.payout.payinfo.payoutCurrency.info',
              defaultMessage:
                'Please note that {BRAND_DOMAIN} sends payouts in US Dollars or in Euro. Therefore, when you select the wire transfer or any other e-wallet payout method, please make sure that the currency of your account matches the currency of your payout. This way you can avoid additional costs and unfavorable exchange rates. Payouts in any other currency are exchanged by 3rd party processors using their own exchange rates.',
            },
          }),
        },
        payoutsInEuro: {
          ...defineMessages({
            title: {
              id: 'pages.payout.payinfo.payoutsInEuro.title',
              defaultMessage: 'Payouts in EURO:',
            },
            info: {
              id: 'pages.payout.payinfo.payoutsInEuro.info1',
              defaultMessage:
                'You can receive your payout in EUR instead of USD. If you choose the EUR Payout option, the final {product, select, livejasmin {Studio} other {Agency}} payout amount will be exchanged from USD to EUR. The FX rate we apply depends on the global market and the value is updated every day. The USD is exchanged to EUR based on the date when the earning was recorded (i.e., every day a different FX rate is applied). Receiving payment in EUR will open up new, cheaper and swifter payment possibilities. You can only decide the currency of your payout for the ongoing and future periods. You cannot change the currency for an already closed period listed on the Payout Overview page. If your billing address is in the Single Euro Payments Area (SEPA), the only available Payout currency of your account will be EUR.',
            },
          }),
        },
        autoInvoice: {
          ...defineMessages({
            title: {
              id: 'pages.payout.payinfo.autoInvoice.title',
              defaultMessage: 'Auto-Invoice:',
            },
            info: {
              id: 'pages.payout.payinfo.autoInvoice.info',
              defaultMessage:
                'When receiving funds as a Company, you must choose if you want to upload your own invoice after each period closes or use auto-invoice and let us handle it for you. If you want to use your own invoicing, you must turn off the auto-invoice option. With auto-invoice turned on, your invoices will automatically be prepared based on the Company data you have provided.',
            },
          }),
        },
        ...defineMessages({
          footerText: {
            id: 'pages.payout.payinfo.footerText',
            defaultMessage:
              'If you have any further questions related to payment issues, please contact {payInfoEmail}.',
          },
        }),
      },
    },
  },
};
