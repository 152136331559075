import { defineMessages } from 'react-intl';
import type { LocalePerProduct } from 'contracts';

const seo: LocalePerProduct = {
  livejasmin: defineMessages({
    title: {
      id: 'pages.scheduledShows.seo.livejasmin.title',
      defaultMessage: 'Scheduled Shows',
    },
    description: {
      id: 'pages.scheduledShows.seo.livejasmin.description',
      defaultMessage: 'Scheduled Shows',
    },
  }),
  oranum: defineMessages({
    title: {
      id: 'pages.scheduledShows.seo.oranum.title',
      defaultMessage: 'Scheduled Shows',
    },
    description: {
      id: 'pages.scheduledShows.seo.oranum.description',
      defaultMessage: 'Scheduled Shows',
    },
  }),
};

const feedbacks = defineMessages({
  showCanceled: {
    id: 'pages.scheduledShows.feedbacks.showCanceled',
    defaultMessage: 'Your Scheduled Show was canceled',
  },
  showBooked: {
    id: 'pages.scheduledShows.feedbacks.showBooked',
    defaultMessage: 'Your show was scheduled successfully',
  },
});

const info = {
  ...defineMessages({
    title: {
      id: 'pages.scheduledShows.info.title',
      defaultMessage: 'Schedule your Show',
    },
    setup: {
      id: 'pages.scheduledShows.info.setup',
      defaultMessage: 'Set up your show with just a few clicks and <b>easily manage</b> your hectic schedule.',
    },
    plan: {
      id: 'pages.scheduledShows.info.plan',
      defaultMessage:
        'Plan your show and then promote it on Messenger, your social media, and in your room to reach your audience,' +
        ' <b>sell tickets to your show</b>, and <b>make even more money</b>!',
    },
    tipsTitle: {
      id: 'pages.scheduledShows.info.tipsTitle',
      defaultMessage: 'Tips',
    },
    tipsList: {
      id: 'pages.scheduledShows.info.tipsList',
      defaultMessage:
        '<li>Always give your show an engaging description to entice members to join!</li>' +
        '<li>Regularly schedule shows in advance to build an even larger and more devoted following!</li>' +
        '<li>Experiment with different themes. Discover what drives your audience crazy and use it!</li>',
    },
  }),
};

const emptyState = {
  ...defineMessages({
    title: {
      id: 'pages.scheduledShows.emptyState.title',
      defaultMessage: 'No Scheduled Shows yet',
    },
    description: {
      id: 'pages.scheduledShows.emptyState.description',
      defaultMessage: "Set up some shows for the future to start building your audience's excitement.",
    },
  }),
};

export default {
  pages: {
    scheduledShows: {
      seo,
      info,
      feedbacks,
      emptyState,
      navigationMenu: {
        ...defineMessages({
          label: {
            id: 'pages.scheduledShows.navigationMenu.label',
            defaultMessage: 'Scheduled Shows',
          },
        }),
      },
    },
  },
};
