import { defineMessages } from 'react-intl';
import type { LocalePerProduct } from 'contracts';

const seo: LocalePerProduct = {
  livejasmin: defineMessages({
    title: {
      id: 'pages.rules.seo.livejasmin.title',
      defaultMessage: 'Rules',
    },
    description: {
      id: 'pages.rules.seo.livejasmin.description',
      defaultMessage: 'Rules',
    },
  }),
  oranum: defineMessages({
    title: {
      id: 'pages.rules.seo.oranum.title',
      defaultMessage: 'Rules',
    },
    description: {
      id: 'pages.rules.seo.oranum.description',
      defaultMessage: 'Rules',
    },
  }),
};

const basicRules = defineMessages({
  title: {
    id: 'pages.rules.basicRules.title',
    defaultMessage: 'Basic Rules',
  },
  paragraph1: {
    id: 'pages.rules.basicRules.paragraph1',
    defaultMessage:
      '{domain} reserves the right to deduct money from performers for a breach of contract or for breaking the rules. The following rules must be followed by all performers on {domain}:',
  },
  paragraph3: {
    id: 'pages.rules.basicRules.paragraph3',
    defaultMessage:
      'Upon violation of any of these rules, {domain} reserves the right to ban the performers from the service and/or apply a monetary deduction. Further sanctions for serious violations of the rules may include the suspension from {domain} Protection.',
  },
  paragraph4: {
    id: 'pages.rules.basicRules.paragraph4',
    defaultMessage: '{domain} reserves the right to withhold entire payments in the following cases:',
  },
  rule1: {
    id: 'pages.rules.basicRules.rule1',
    defaultMessage:
      'Nudity - even partial - and sexually explicit content are prohibited in Free Chats. Performers must ensure that their private parts are always properly covered at least by appropriate underwear. Please note that covering your genitals with your hand(s) is not sufficient.',
  },
  rule2: {
    id: 'pages.rules.basicRules.rule2',
    defaultMessage:
      'Performers are strongly advised to refrain from any kind of sexually explicit activity (e.g., oral sex, use of sex toys, masturbation, etc.), or the imitation of such in Free Chats. Touching your genitals in any way (even through underwear) is prohibited, as well.',
  },
  rule3: {
    id: 'pages.rules.basicRules.rule3',
    defaultMessage:
      'These rules cannot be overridden by any offer, including Surprises, from our Members. For detailed information, please check our <a>Wiki</a>.',
  },
  rule4: {
    id: 'pages.rules.basicRules.rule4',
    defaultMessage:
      'Performers in the Nude categories, as the title suggests, are obligated to satisfy the customers’ requests about nude content during a Private session. For detailed information, please check our <a>Wiki</a>.',
  },
  rule5: {
    id: 'pages.rules.basicRules.rule5',
    defaultMessage:
      'A person appearing in front of the camera must be registered under the given account. If the account is used by an unregistered person, all accounts associated with the given ID will be automatically suspended. Please note that to reactivate a Model account, the unregistered person must first be identified. If a Model does not assist in the identification process within the specified timeframe, all their accounts will be closed, and they will be prohibited from registering new accounts. For detailed information, please check our <a>Wiki</a>.',
  },
  rule6: {
    id: 'pages.rules.basicRules.rule6',
    defaultMessage:
      'Unregistered person(s) must not appear in the camera frame, not even for a brief period of time, neither by accident, nor upon a Member’s request. Please pay special attention to abiding by this rule. For detailed information, please check our <a>Wiki</a>.',
  },
  rule7: {
    id: 'pages.rules.basicRules.rule7',
    defaultMessage:
      'Underage person(s) must not appear in the camera frame under any circumstances. Those who violate this rule will have their accounts permanently closed and may not register again. For detailed information, please check our <a>Wiki</a>.',
  },
  rule8: {
    id: 'pages.rules.basicRules.rule8',
    defaultMessage:
      'Performers are prohibited from leaving the camera area while online and are expected to be visible in all chat areas. For detailed information, please check our <a>Wiki</a>.',
  },
  rule9: {
    id: 'pages.rules.basicRules.rule9',
    defaultMessage:
      'Performers are prohibited from sleeping while online. For detailed information, please check our <a>Wiki</a>.',
  },
  rule10: {
    id: 'pages.rules.basicRules.rule10',
    defaultMessage:
      'Performers should be able to communicate on a basic level with guests and Members from all around the world. For detailed information, please check our <a>Wiki</a>.',
  },
  rule11: {
    id: 'pages.rules.basicRules.rule11',
    defaultMessage:
      'Performers must follow Members’ instructions in Private Chats according to their specified ‘Willingness List’ and category rules so long as these instructions follow the regulations of the website. Please note that stalling for time, being inactive, and asking for money during Free or Private Chats are strictly forbidden. For detailed information, please check our <a>Wiki</a>.',
  },
  rule12: {
    id: 'pages.rules.basicRules.rule12',
    defaultMessage:
      'Eating or using a phone are not allowed in Free Chats as these actions may divert your attention and be annoying to visitors. Please note, however, that the above-mentioned activities are allowed in Member Chats. For detailed information, please check our <a>Wiki</a>.',
  },
  rule13: {
    id: 'pages.rules.basicRules.rule13',
    defaultMessage:
      'Performers are prohibited from going online without a live camera feed on any part of the website. For detailed information, please check our <a>Wiki</a>.',
  },
  rule14: {
    id: 'pages.rules.basicRules.rule14',
    defaultMessage:
      'Performers must not broadcast pre-recorded videos instead of live camera feeds. A live camera feed is always necessary to be online. Violation of this rule will result in permanent account closure and being banned from the site. Please also note that in this case, all earnings shall be deducted from the violators. For detailed information, please check our <a>Wiki</a>.',
  },
  rule15: {
    id: 'pages.rules.basicRules.rule15',
    defaultMessage:
      'Performers are prohibited from disclosing, accepting, or encouraging anyone to exchange personal details (real name, address, etc.) or contact information (phone number, email address, Messenger, Yahoo ID, etc.). Those who violate this rule will have their accounts suspended. For detailed information, please check our <a>Wiki</a>.',
  },
  rule16: {
    id: 'pages.rules.basicRules.rule16',
    defaultMessage:
      'Promoting other websites and services is prohibited. Please keep in mind that we are strictly against any kind of spamming (unwanted promotion of other sites and services). Those who violate this rule will have their accounts suspended. For detailed information, please check our <a>Wiki</a>.',
  },
  rule17: {
    id: 'pages.rules.basicRules.rule17',
    defaultMessage:
      'Performers are required to communicate with the Administrators of the website whenever contacted in the Admin Chat. Those who ignore these requests or close the chat window with an Admin will have their accounts suspended until they return to finish the conversation. For detailed information, please check our <a>Wiki</a>.',
  },
  rule18: {
    id: 'pages.rules.basicRules.rule18',
    defaultMessage:
      'Proper background conditions and an adequate camera feed are essential to being online. Setting up your background and adjusting the focus of your web camera might be difficult. In case you have any problems, please visit our Online Support Chat. For detailed information, please check our <a>Wiki</a>.',
  },
  rule19a: {
    id: 'pages.rules.basicRules.rule191',
    defaultMessage:
      'The following are certain prohibited content and activities which must never take place on the site. Even the imitation/passive participation in such activities is forbidden. These are the following:',
  },
  rule19b: {
    id: 'pages.rules.basicRules.rule192',
    defaultMessage:
      'Those who violate these rules may have their accounts suspended or even closed. For detailed information, please check our <a>Wiki</a>.',
  },
  rule20: {
    id: 'pages.rules.basicRules.rule20',
    defaultMessage:
      'Asking for money or Surprises in exchange for extra services is forbidden on all parts of the website. For detailed information, please check our illustrated guide for the violation here or check our <a>Wiki</a>.',
  },
  rule21: {
    id: 'pages.rules.basicRules.rule21',
    defaultMessage:
      'It is strictly forbidden to mislead visitors regarding the performer’s gender or appear in an inappropriate category. Accounts found in violation of these rules will be suspended. For detailed information, please check our <a>Wiki</a>.',
  },
  rule22: {
    id: 'pages.rules.basicRules.rule22',
    defaultMessage:
      'When you have two or more Private Chats at the same time during your promotion period, it would still count as a single Chat session. For detailed information, please check our <a>Wiki</a>.',
  },
  forbiddenActivity1: {
    id: 'pages.rules.basicRules.forbiddenActivity1',
    defaultMessage: 'Incest roleplay (when the participants pretend to be relatives)',
  },
  forbiddenActivity2: {
    id: 'pages.rules.basicRules.forbiddenActivity2',
    defaultMessage: 'Underage roleplay (when one of the participants pretends to be underage)',
  },
  forbiddenActivity3: {
    id: 'pages.rules.basicRules.forbiddenActivity3',
    defaultMessage: 'Bestiality (animal participation)',
  },
  forbiddenActivity4: {
    id: 'pages.rules.basicRules.forbiddenActivity4',
    defaultMessage: 'Violence (suffocation, causing any injury, etc.)',
  },
  forbiddenActivity5: {
    id: 'pages.rules.basicRules.forbiddenActivity5',
    defaultMessage: 'Showing blood in any form',
  },
  forbiddenActivity6: {
    id: 'pages.rules.basicRules.forbiddenActivity6',
    defaultMessage: 'Any reference to pedophilia',
  },
  forbiddenActivity7: {
    id: 'pages.rules.basicRules.forbiddenActivity7',
    defaultMessage: 'Drug or alcohol use',
  },
  forbiddenActivity8: {
    id: 'pages.rules.basicRules.forbiddenActivity8',
    defaultMessage: 'Offering or accepting escort service',
  },
  forbiddenActivity9: {
    id: 'pages.rules.basicRules.forbiddenActivity9',
    defaultMessage: 'Providing an erotic show to a Member who claims to be underage',
  },
  forbiddenActivity10: {
    id: 'pages.rules.basicRules.forbiddenActivity10',
    defaultMessage: 'Referring to or performing the elimination or consumption of body waste',
  },
  forbiddenActivity11: {
    id: 'pages.rules.basicRules.forbiddenActivity11',
    defaultMessage: 'Presenting any kind of weapon or dangerous equipment',
  },
  forbiddenActivity12: {
    id: 'pages.rules.basicRules.forbiddenActivity12',
    defaultMessage: 'Offensive or hate speeches and actions',
  },
  payment1: {
    id: 'pages.rules.basicRules.payment1',
    defaultMessage:
      'Unauthorized use of Performer accounts (used by another person than its registered owner, especially if the unauthorized user is unverified or has been previously banned).',
  },
  payment2: {
    id: 'pages.rules.basicRules.payment2',
    defaultMessage:
      'If the performer is proven to have registered with a counterfeit/digitally altered ID on our site.',
  },
  payment3: {
    id: 'pages.rules.basicRules.payment3',
    defaultMessage:
      'Unauthorized use of Member account, for example if the account holder did not give permission for the use of his/her account to another individual. Sanctions may be utilized (such as withholding funds, deductions and terminating the account) against all persons who profit from this activity.',
  },
  payment4: {
    id: 'pages.rules.basicRules.payment4',
    defaultMessage:
      'If the Model/Studio is proven to have provided a prerecorded medium in place of a live performance and attempted to deceive the paying visitors with this practice. For such acts, the payout for the given period and any pending payment shall be withheld.',
  },
});

const categoryRules = defineMessages({
  title: {
    id: 'pages.rules.categoryRules.title',
    defaultMessage: 'Category Rules',
  },
  moreInfo: {
    id: 'pages.rules.categoryRules.moreInfo',
    defaultMessage: 'For more info about categories, please check here',
  },
});

const nudeCategories = defineMessages({
  title: {
    id: 'pages.rules.nudeCategories.title',
    defaultMessage: 'Nude Categories',
  },
  girl: {
    id: 'pages.rules.nudeCategories.girl',
    defaultMessage:
      'Girl: Only one registered female performer may appear online. You are supposed to fulfil the Member requests during a Private Show according to your ‘Willingness’ settings.',
  },
  mature: {
    id: 'pages.rules.nudeCategories.mature',
    defaultMessage:
      'Mature female: Only one registered female performer who’s at least 30 years old may appear online. You are supposed to fulfil the Member requests during a Private Show according to your ‘Willingness’ settings.',
  },
  fetish: {
    id: 'pages.rules.nudeCategories.fetish',
    defaultMessage:
      'Fetish: Only one registered female performer may appear online. Your live camera feed and pictures must always reflect the category characteristics (e.g. as a dominatrix, dark background, leather/latex outfit, appropriate accessories). You are supposed to fulfil the Member requests during a Private Show according to your ‘Willingness’ settings.',
  },
  gay: {
    id: 'pages.rules.nudeCategories.gay',
    defaultMessage:
      'Gay: Only one registered male performer may appear online. You are supposed to fulfil the Member requests during a Private Show according to your ‘Willingness’ settings.',
  },
  straightMale: {
    id: 'pages.rules.nudeCategories.straightMale',
    defaultMessage:
      'Straight Male: Only one registered male performer may appear online. You are supposed to fulfil the Member requests during a Private Show according to your ‘Willingness’ settings.',
  },
  transsexual: {
    id: 'pages.rules.nudeCategories.transsexual',
    defaultMessage:
      'Transsexual: Only one transsexual performer may appear online. You are supposed to fulfil the Member requests during a Private Show according to your ‘Willingness’ settings.',
  },
  straight: {
    id: 'pages.rules.nudeCategories.straight',
    defaultMessage:
      'Straight Couple: A male and a female performers may appear online. You are supposed to fulfil the Member requests during a Private Show according to your ‘Willingness’ settings.',
  },
  lesbian: {
    id: 'pages.rules.nudeCategories.lesbian',
    defaultMessage:
      'Lesbian Couple: Two female performers may appear online. You are supposed to fulfil the Member requests during a Private Show according to your ‘Willingness’ settings.',
  },
});

const hotFlirt = defineMessages({
  title: {
    id: 'pages.rules.hotFlirt.title',
    defaultMessage: 'Hot Flirt Category',
  },
  info: {
    id: 'pages.rules.hotFlirt.description',
    defaultMessage:
      'Full or partial nudity is prohibited in Free Chats in the Hot Flirt Category. Violating this rule may result in an immediate category change to a Nude category. What happens in Private Chats is completely between you and the member, so long as the activity does not violate any of the website’s rules, including rules regarding prohibited content and the exchange of personal contact information. Also pay attention to having a room decorated accordingly to fit your category, since communication is your main activity. A tasteful, organized room is advantageous to attracting more visitors. Please be advised that making fake promises that you will not fulfill are not allowed and might result in a penalty.',
  },
});

const soulMates = defineMessages({
  title: {
    id: 'pages.rules.soulMates.title',
    defaultMessage: 'Soul Mates Category',
  },
  info: {
    id: 'pages.rules.soulMates.description',
    defaultMessage:
      'In this category, you should attract the visitors’ attention with your personality and your talkative, nice attitude, instead of with your outfit. It is important to choose a camera angle that focuses on your face and upper body, since this category is more for talking and sharing your thoughts with each other. The outfit should be like what you wear on a regular day at work or to go grocery shopping. It is also important to have a tasteful background; however, heavy makeup should be avoided. Full or partial nudity is prohibited in Free Chats in the Soul Mates Category. Violating this rule may result in an immediate category change to a Nude category. What happens in Private Chats is completely between you and the member, so long as the activity does not violate any of the website’s rules, including rules regarding prohibited content and the exchange of personal contact information.',
  },
});

const hiddenFace = defineMessages({
  title: {
    id: 'pages.rules.hiddenFace.title',
    defaultMessage: '“Hide My Face” Option',
  },
  info: {
    id: 'pages.rules.hiddenFace.description',
    defaultMessage:
      'In all categories, some Models may have the possibility to activate the “Hide My Face” option to keep their faces hidden and may choose not to reveal their faces during Private and Free Chats, VIP shows or Video Calls. Access to this option is subject to the sole discretion of the Website Operator who reserves the right to approve, deny, modify or revoke access to this option at any time without notice. No entitlement to access this option shall be assumed. For detailed information, please check our <a>Wiki</a>.',
  },
});

const oranumRules = defineMessages({
  title: {
    id: 'pages.rules.oranumRules.title',
    defaultMessage: 'Rules',
  },
  paragraph1: {
    id: 'pages.rules.oranumRules.paragraph1',
    defaultMessage:
      '{domain} reserves the right to deduct money from broadcasters for breach of contract or for breaking the rules. The following rules must be followed by all Broadcasters in all chat areas and in the awards game:',
  },
  paragraph2: {
    id: 'pages.rules.oranumRules.paragraph2',
    defaultMessage:
      'Upon violation of any of the aforementioned rules, {domain} reserves the right to suspend and/or ban the performers from the service and/or apply a money deduction. Further sanctions for serious violations of the rules may include suspension from {domain}.',
  },
  paragraph3: {
    id: 'pages.rules.oranumRules.paragraph3',
    defaultMessage: '{domain} reserves the right to withhold entire payments in the following cases:',
  },
  paragraph4: {
    id: 'pages.rules.oranumRules.paragraph4',
    defaultMessage: 'For detailed information about our rules please make sure you review our <a>Rules on Wiki</a>.',
  },
  rule1: {
    id: 'pages.rules.oranumRules.rule1',
    defaultMessage:
      'Nudity - even partial - and sexually explicit content are prohibited. Broadcasters are strongly advised to refrain from any kind of sexually explicit activity or the imitation of such.',
  },
  rule2: {
    id: 'pages.rules.oranumRules.rule2',
    defaultMessage:
      'A person appearing in front of the camera must be registered under the given account. If the account is being used by an unregistered person, all the accounts belonging to the given ID are going to be automatically suspended.',
  },
  rule3: {
    id: 'pages.rules.oranumRules.rule3',
    defaultMessage:
      'Children under 18 must not appear in the camera area under any circumstances. Those who violate this rule will have their accounts permanently closed and may not register again.',
  },
  rule4: {
    id: 'pages.rules.oranumRules.rule4',
    defaultMessage:
      'Broadcasters are prohibited from leaving the camera area while being online and are expected to be visible in all chat areas.',
  },
  rule5: {
    id: 'pages.rules.oranumRules.rule5',
    defaultMessage:
      'Broadcasters may not broadcast a pre-recorded video instead of a live camera feed. You always need to have a live camera feed in order to be online. Those who violate this rule will have their accounts closed and be banned from the site. Please also note that in this case all earnings will be deducted from the violators.',
  },
  rule6: {
    id: 'pages.rules.oranumRules.rule6',
    defaultMessage:
      'Broadcasters are prohibited from giving out, accepting, or encouraging anyone to exchange personal details (real name, address, etc.) or personal contact information (phone number, personal email address etc.), Those who violate this rule will have their accounts suspended.',
  },
  rule7: {
    id: 'pages.rules.oranumRules.rule7',
    defaultMessage:
      'Promoting other websites and services is prohibited. Please keep in mind that we are strictly against any kind of spamming (unwanted promotion of other sites and services). Those who violate this rule will have their accounts suspended.',
  },
  rule8: {
    id: 'pages.rules.oranumRules.rule8',
    defaultMessage:
      'Proper background conditions and an adequate camera feed are essential to do a live stream. Setting up your background and adjusting the focus of your web camera might be difficult. In case you have any problems, please visit our Online Support chat.',
  },
  rule9: {
    id: 'pages.rules.oranumRules.rule9',
    defaultMessage:
      'Inappropriate broadcaster behavior and attire, hate speech or such activities is strictly forbidden.',
  },
  rule10: {
    id: 'pages.rules.oranumRules.rule10',
    defaultMessage:
      'Asking for money or gifts in exchange for extra services is strictly forbidden on any part of the website.',
  },
  rule11: {
    id: 'pages.rules.oranumRules.rule11',
    defaultMessage:
      'Misleading the Member, inactivity in private session or refusing requests that are within the rules is prohibited.',
  },
  rule12: {
    id: 'pages.rules.oranumRules.rule12',
    defaultMessage:
      'The following are certain prohibited contents and activities which must not take place on the site. Even the imitation/passive participation of such activities is strictly forbidden. These are the following:',
  },
  forbiddenActivity1: {
    id: 'pages.rules.oranumRules.forbiddenActivity1',
    defaultMessage: 'Violence',
  },
  forbiddenActivity2: {
    id: 'pages.rules.oranumRules.forbiddenActivity2',
    defaultMessage: 'Showing blood in any form',
  },
  forbiddenActivity3: {
    id: 'pages.rules.oranumRules.forbiddenActivity3',
    defaultMessage: 'Drug or alcohol use',
  },
  forbiddenActivity4: {
    id: 'pages.rules.oranumRules.forbiddenActivity4',
    defaultMessage: 'Presenting any kind of weapon and such equipment',
  },
  forbiddenActivity5: {
    id: 'pages.rules.oranumRules.forbiddenActivity5',
    defaultMessage: 'Offensive or hate speech and display',
  },
  payment1: {
    id: 'pages.rules.oranumRules.payment1',
    defaultMessage:
      'Unauthorized use of Broadcaster account (used by another person than its registered owner, especially if the unauthorized user is unverified or has been previously banned).',
  },
  payment2: {
    id: 'pages.rules.oranumRules.payment2',
    defaultMessage:
      'If the Broadcaster is proven to have registered with a counterfeit/digitally altered I.D. on our site.',
  },
  payment3: {
    id: 'pages.rules.oranumRules.payment3',
    defaultMessage:
      'Unauthorized use of a member’s account, such as when the account holder has not granted permission for its use by another individual, may result in sanctions. These measures may include withholding funds, deductions, and account closure for all individuals involved in such activity.',
  },
  payment4: {
    id: 'pages.rules.oranumRules.payment4',
    defaultMessage:
      'If the Broadcaster/Studio is found to have substituted a prerecorded medium for a live performance in an attempt to deceive paying visitors, the payout for the given period and any pending payments will be withheld.',
  },
});

const moreInfo = defineMessages({
  title: {
    id: 'pages.rules.moreInfo.title',
    defaultMessage: 'Click here for more information',
  },
});

export default {
  pages: {
    rules: {
      seo,
      basicRules,
      categoryRules,
      nudeCategories,
      hotFlirt,
      soulMates,
      hiddenFace,
      oranumRules,
      moreInfo,
      navigationMenu: {
        ...defineMessages({
          label: {
            id: 'pages.rules.navigationMenu.label',
            defaultMessage: 'Rules',
          },
        }),
      },
    },
  },
};
