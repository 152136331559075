import axios from 'axios';
import type { AxiosError } from 'axios';
import authentication from 'services/authentication';
import authenticationStatus from 'services/authentication/activity';
import navigation from 'services/navigation';
import application from 'services/application';
import appConfig from 'configurations/application';

const apiGatewayRequest = (url: string | undefined): boolean => {
  return appConfig.envVars.apiGwBaseUri === url;
};

const axiosInstance = axios.create();

const logoutHttpStatusCodes = [401, 403];

axiosInstance.interceptors.request.use(async (config) => {
  application.startLoading(this!);

  if (!apiGatewayRequest(config.baseURL)) {
    return config;
  }

  const configuration = { ...config };

  await authenticationStatus.toBeIdle();

  const token = await authentication.getAccessToken();

  if (token) {
    configuration.headers.set('Authorization', `Bearer ${token}`);
  }

  return configuration;
});

axiosInstance.interceptors.response.use(
  (res) => {
    application.stopLoading(this!);

    return res;
  },
  (error: typeof AxiosError) => {
    application.stopLoading(this!);

    if (axios.isCancel(error)) {
      return;
    }

    if (
      axios.isAxiosError(error) &&
      apiGatewayRequest(error.config?.baseURL) &&
      logoutHttpStatusCodes.includes(error.response?.status ?? 0)
    ) {
      navigation.logout();
    }

    throw error;
  }
);

export { apiGatewayRequest };
export default axiosInstance;
