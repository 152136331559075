import React from 'react';

import type { Parameters, HashParameters, RequestContext, NavigationHistory, NavigationBlock } from './contracts';
import type { RoutesAssistant } from './utils/create-routes-assistant';

interface RouterProvider extends NavigationHistory {
  routesAssistant: RoutesAssistant;
  navigation: NavigationBlock;
}

interface RouteProvider {
  params: Parameters;
  searchParams: Parameters;
  hashParams: HashParameters;
  outlet: null | ((props?: React.PropsWithChildren<Record<string, unknown>>) => React.ReactNode);
  intercepted: null | ((props?: React.PropsWithChildren<Record<string, unknown>>) => React.ReactNode);
}

const RouterContext = React.createContext<RouterProvider>({
  // @ts-expect-error: ignoring initial state
  current: undefined,
  previous: undefined,
  routesAssistant: {} as RoutesAssistant,
  navigation: { block: () => null, unblock: () => null },
});

const RouteContext = React.createContext<RouteProvider>({
  params: {},
  searchParams: {},
  hashParams: [],
  outlet: () => null,
  intercepted: () => null,
});

const RouteRequestContext = React.createContext<RequestContext>(new Map<string, unknown>());

export type { RouterProvider, RouteProvider };
export { RouterContext, RouteContext, RouteRequestContext };
