import { map } from 'rxjs';
import type { Observable } from 'rxjs';
import { ServiceBase } from 'services';
import type { PolicyTypeEnum, UserRoleEnum } from 'services/api/proxy-consent/data-contracts';
import { getGeneratedConsent, updateConsentSignature, createConsent } from 'services/api/proxy-consent/consent';
// eslint-disable-next-line no-restricted-imports
import user from 'store/user';
// eslint-disable-next-line no-restricted-imports
import account from 'store/account';

import type { ConsentDetails } from './contracts';

class Consent extends ServiceBase {
  name = 'consent';

  getConsent$(consentId: string): Observable<ConsentDetails> {
    return this.call$(
      getGeneratedConsent(consentId, {
        headers: {
          'X-Actor-Type': 'performer',
          'X-Actor-Id': user.data.viewTypeId!,
        },
      })
    ).pipe(
      map((response) => response.data.data),
      map((generatedConsent) => ({
        ...generatedConsent,
        isFirstConsent: generatedConsent.createdAt === generatedConsent.shouldBeSignedUntil,
      }))
    );
  }

  updateConsentSignature$(consentId: string, isSigned: boolean): Observable<boolean> {
    return this.call$(
      updateConsentSignature(
        consentId,
        {
          isSigned,
        },
        {
          headers: {
            'X-Actor-Type': user.isModelView() ? 'performer' : 'studio',
            'X-Actor-Id': user.data.viewTypeId!,
          },
        }
      )
    ).pipe(map(() => true));
  }

  createConsent$(policyType: PolicyTypeEnum, userId: number, userRole: UserRoleEnum): Observable<ConsentDetails> {
    return this.call$(
      createConsent(
        policyType,
        {
          users: [{ userId, userRole }],
        },
        {
          headers: {
            'X-Actor-Type': 'user',
            'X-Actor-Id': account.data?.userId,
          },
        }
      )
    ).pipe(
      map((data) => data.data.data),
      map((generatedConsent) => ({
        ...generatedConsent,
        isFirstConsent: generatedConsent.createdAt === generatedConsent.shouldBeSignedUntil,
      }))
    );
  }
}

export default new Consent();
