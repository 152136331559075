import { map, switchMap, iif, of, tap, distinctUntilChanged } from 'rxjs';
import type { Observable } from 'rxjs';
import type { UserDetailsStudio } from 'services/api/proxy-account-services/data-contracts';
import authentication from 'services/authentication';
import { AccountType } from 'services/graphql/generated/types';
import is from 'utils/is';
import date, { type DateInput } from 'utils/date';
import progressiveRetry from 'utils/rxjs/progressive-retry';
import filterWhileNullish from 'utils/rxjs/filter-while-nullish';

import Store from '../store';
import notifyUserOnError from '../utils/notify-user-on-error';
import graphQLQuery from '../utils/graphql-query';

import { GetAccountData } from './account.graphql';
import type { GetAccountDataQuery } from './account.contracts';

const accountTypeMap: Record<AccountType, AccountStore['type']> = {
  [AccountType.Model]: 'model',
  [AccountType.Single]: 'single',
  [AccountType.Studio]: 'studio',
  [AccountType.User]: 'user',
};

type StudioStatus = UserDetailsStudio['status'];

interface AccountStore {
  /**
   * Registration of:
   * - Performer single account = "single".
   * - Studio = "studio".
   * - When studio is registering new performer via add model flow = "model".
   */
  type: 'user' | 'single' | 'model' | 'studio';
  userId: number;
  gender: 'male' | 'female' | undefined;
  birthday: DateInput;
  studio: {
    id: number;
    status: StudioStatus;
    name: string;
    ownerUserId: number;
    createdAt: string;
  };
  defaultPerformerId: number | undefined;
  email: string;
  personFirstName: string;
  payoutActiveMethod?: string;
  flags: {
    isTestAccount: boolean;
    emailConfirmed: boolean;
    registrationPending: boolean;
    registrationFlowId: string | undefined;
    isLimitedAccess: boolean;
    personalDataDownloadRequestStatus: string | undefined;
    hasMultiplePerformers: boolean;
    studioCertified: boolean;
    payoutOptionAdded: boolean;
    isCompany: boolean;
  };
  settings: {
    hasLimitedAccess: boolean;
    marketingCommunicationConsent: boolean;
  };
}

const fetchAccountData$ = (instance: Store<AccountStore | undefined>): Observable<AccountStore | undefined> =>
  graphQLQuery<GetAccountDataQuery>(GetAccountData, {
    context: {
      name: 'GetAccountData',
      important: true,
      headers: {
        'X-Actor-Type': 'user',
      },
    },
  }).pipe(
    progressiveRetry(),
    notifyUserOnError({
      type: 'error',
      message: 'request-failed',
      onClick() {
        instance.reset();
      },
    }),
    map((response) => response?.data?.me),
    switchMap((accountData) => {
      if (is.nullish(accountData) || is.nullish(accountData.studio)) {
        return of(undefined);
      }

      const { defaultPerformerId, id, studio, persons } = accountData;

      const mappedAccountData: AccountStore = {
        type: accountTypeMap[studio.owner.type],
        userId: parseInt(id, 10),
        gender: persons?.[0].gender ?? undefined,
        birthday: persons?.[0].birthDate ? date(persons?.[0].birthDate).toISODateString() : '',
        studio: {
          ownerUserId: Number(studio.owner.id),
          id: parseInt(studio.id, 10),
          name: studio.name,
          status: studio?.status || 'unknown',
          createdAt: studio.createdAt ?? '',
        },
        defaultPerformerId: defaultPerformerId ?? undefined,
        email: studio.owner.email || '',
        personFirstName: persons?.[0].firstName || '',
        payoutActiveMethod: studio.payoutActiveMethod ? studio.payoutActiveMethod : undefined,
        flags: {
          emailConfirmed: studio.owner.flags.emailConfirmed,
          registrationFlowId: studio.owner.flags.registrationFlowId || undefined,
          registrationPending: studio.owner.flags.registrationPending,
          isTestAccount: studio.owner.flags.isTestAccount,
          isLimitedAccess: studio.owner.flags.isLimitedAccess,
          personalDataDownloadRequestStatus: studio.owner.flags.personalDataDownloadRequestStatus || undefined,
          hasMultiplePerformers: studio.flags.hasMultiplePerformers,
          studioCertified: studio.flags.studioCertified,
          payoutOptionAdded: studio.flags.payoutOptionAdded,
          isCompany: studio.owner.flags.isCompany,
        },
        settings: {
          hasLimitedAccess: studio.owner.hasLimitedAccess,
          marketingCommunicationConsent: Boolean(studio.owner.marketingCommunicationConsent.status),
        },
      };

      return of(mappedAccountData);
    })
  );

class Account extends Store<AccountStore | undefined> {
  source$ = authentication.event.onChange$.pipe(
    // flags the user store as loading
    tap(() => {
      super.meta.setLoading(true);
    }),
    // if user is authenticated, then API call is made to request
    // otherwise, it is set back to undefined
    switchMap((authenticated) => iif(() => authenticated, fetchAccountData$(this), of(undefined))),
    switchMap((accountData) => {
      if (is.nullish(accountData)) {
        return of(this.initialState);
      }

      return of(accountData);
    })
  );

  constructor() {
    super({
      name: 'account',
      initialState: undefined,
    });
  }

  delayStoreUpdateWhen = (): boolean => {
    return is.nullish(this.data);
  };

  get onChange$(): Observable<AccountStore> {
    return super.onChange$.pipe(filterWhileNullish());
  }

  get onPayoutOptionChange$(): Observable<boolean> {
    return this.onChange$.pipe(
      map((data) => !data.flags.payoutOptionAdded),
      distinctUntilChanged()
    );
  }

  get onEmailConfirmedChange$(): Observable<boolean> {
    return this.onChange$.pipe(
      map((data) => data.flags.emailConfirmed),
      distinctUntilChanged()
    );
  }

  get onEmailChange$(): Observable<string | undefined> {
    return this.onChange$.pipe(
      map((data) => data.email),
      distinctUntilChanged()
    );
  }

  get onStudioNameChange$(): Observable<string> {
    return this.onChange$.pipe(
      map((data) => data.studio.name),
      distinctUntilChanged()
    );
  }

  /**
   * Flags if user account has a completed registration process.
   * @return {boolean}
   */
  get hasEnrollmentCompleted(): boolean {
    return this.data?.flags?.registrationPending === false;
  }

  /**
   * Flags user account registration process as completed.
   * @return {boolean}
   */
  markEnrollmentAsCompleted(): void {
    // @ts-expect-error: ignore type assertion until better assessment
    this.set('flags', {
      ...this.data?.flags,
      registrationPending: false,
      registrationFlowId: undefined,
    });
  }

  hasStudioClosedStatus(): boolean {
    return this.data?.studio.status === 'closed';
  }

  isStudio(): boolean {
    return this.data?.type === 'studio';
  }

  setTestAccount(value: boolean): void {
    // @ts-expect-error: ignore type assertion until better assessment
    this.set('flags', {
      ...this.data?.flags,
      isTestAccount: value,
    });
  }

  setEmail(email: string): void {
    this.set('email', email);
  }

  setMarketingCommunicationConsent(value: boolean): void {
    this.set('settings', {
      ...(this.data!.settings ?? {}),
      marketingCommunicationConsent: value,
    });
  }
}

export type { AccountStore };
export default new Account();
