import type { NavigationMenuItem } from 'contracts';

import parentId from '../../@@global/id';

import id from './id';

const menu: NavigationMenuItem = {
  activates: {
    [id.performanceEngagementScore]: [parentId],
  },
};

export default menu;
