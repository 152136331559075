import React from 'react';
import toArray from 'utils/to-array';
import is from 'utils/is';
import { ErrorTemplate, WarningTemplate } from 'contracts/internals/toast';
import type {
  StyledElement,
  AriaAttributes,
  TestAutomation,
  ToastNotification,
  CustomToastNotification,
  SuccessToastNotification,
  WarningToastNotification,
  ErrorToastNotification,
  ToastAPI,
} from 'contracts';

import Html from '../html';

import Toast from './toast';
import { priorityWeights, maxToastVisible, generateId, defaultIcons } from './toast.settings';
import messages from './toast.i18n';
import styles from './toast-hub.module.scss';

type ToastHubElement = StyledElement & AriaAttributes & TestAutomation;

type Template = SuccessToastNotification | WarningToastNotification | ErrorToastNotification;

interface NotificationQueueItem extends CustomToastNotification {
  key: string;
}

const ToastHub = React.forwardRef<ToastAPI, ToastHubElement>((props, ref) => {
  const { testId, className, style, ...rest } = props;
  const [onDisplay, setDisplayQueue] = React.useState<Array<NotificationQueueItem>>([]);
  const queue = React.useRef<Array<NotificationQueueItem>>([]);

  const mountNotification = React.useCallback((notification: ToastNotification): CustomToastNotification => {
    // @ts-expect-error: type assertion
    if (is.keyOf(notification, 'type')) {
      const { type, message, ...config } = notification as Template;

      if (type === 'success') {
        return { ...config, message, icon: defaultIcons.success } satisfies CustomToastNotification;
      }

      if (type === 'warning') {
        switch (message) {
          case WarningTemplate.Generic:
            return {
              ...config,
              icon: defaultIcons.warning,
              message: [messages.components.toast.generic.error],
            } satisfies CustomToastNotification;

          default:
            return {
              ...config,
              icon: defaultIcons.warning,
              message,
            } satisfies CustomToastNotification;
        }
      }

      if (type === 'error') {
        switch (message) {
          case ErrorTemplate.RequestFailed:
            return {
              ...config,
              icon: defaultIcons.error,
              message: [messages.components.toast.requestError.title],
              caption: [messages.components.toast.requestError.message],
            } satisfies CustomToastNotification;

          case ErrorTemplate.Generic:
            return {
              ...config,
              icon: defaultIcons.error,
              message: [messages.components.toast.generic.error],
            } satisfies CustomToastNotification;

          default:
            return {
              ...config,
              icon: defaultIcons.error,
              message,
            } satisfies CustomToastNotification;
        }
      }
    }

    return notification as CustomToastNotification;
  }, []);

  const manageQueue = React.useCallback(() => {
    setDisplayQueue((current) => {
      const shouldDispatch = queue.current.length && current.length < maxToastVisible;

      if (!shouldDispatch) return current;

      return queue.current.slice(0, maxToastVisible);
    });
  }, []);

  const addToastToQueue = React.useCallback(
    (toast: NotificationQueueItem): void => {
      if (queue.current.some((item) => item.id === toast.id)) {
        return;
      }

      queue.current = [...queue.current, toast].sort(
        (a, b) => priorityWeights[b.priority || 'medium'] - priorityWeights[a.priority || 'medium']
      );

      manageQueue();
    },
    [manageQueue]
  );

  const dispatch: ToastAPI['dispatch'] = React.useCallback(
    (toast) => {
      addToastToQueue({ ...mountNotification(toast), key: generateId() });
    },
    [addToastToQueue]
  );

  const remove: ToastAPI['remove'] = React.useCallback(
    (id) => {
      const removeItem = (current: Array<NotificationQueueItem>): Array<NotificationQueueItem> =>
        current.filter((item) => item.id !== id);

      const notification = queue.current.find((item) => item.id === id);

      queue.current = removeItem(queue.current);
      setDisplayQueue(removeItem);

      notification?.onClose?.();

      manageQueue();
    },
    [manageQueue]
  );

  const clear: ToastAPI['clear'] = React.useCallback(() => {
    queue.current = [];
    setDisplayQueue([]);
  }, []);

  React.useImperativeHandle(ref, () => ({
    dispatch,
    remove,
    clear,
  }));

  React.useEffect(() => {
    return () => {
      clear();
    };
  }, [clear]);

  if (!onDisplay.length) return null;

  return (
    <Html.div
      testId={testId ?? 'toast-hub'}
      className={[styles.toast, 'd-flex', 'align-items-end', 'flex-column-reverse', ...toArray(className)]}
      style={style}
      arias={rest}
    >
      {onDisplay.map((toast, idx) => (
        <Toast
          key={toast.key}
          testId={toast.testId ?? 'toast-item'}
          id={toast.id}
          icon={toast.icon}
          message={toast.message}
          caption={toast.caption}
          priority={toast.priority}
          closable={toast.closable}
          timeout={toast.timeout}
          exitDelay={idx * 1500}
          freezeOnHover={toast.freezeOnHover}
          style={toast.style}
          className={toast.className}
          onClick={toast.onClick}
          onCloseRequest={remove}
        />
      ))}
    </Html.div>
  );
});

export type { ToastHubElement };
export default ToastHub;
