import React from 'react';
import { of } from 'rxjs';
import render from 'utils/render';
import is from 'utils/is';
import createObservableResource from 'utils/create-observable-resource';

import RouteLifeCycle from './route-life-cycle';
import ErrorBoundary from './error-boundary';
import { useRequestContext, useRouter } from './hooks';
import { RouteContext, type RouteProvider } from './context';
import { getRouteParams, getRoutePartialParams, getSearchParams, getHashParams } from './utils/route-params';
import middlewareRegistry from './utils/middleware-registry';
import type { RoutesAssistant } from './utils/create-routes-assistant';
import type { InternalRoute, NavigationHistory, RouteMiddleware } from './contracts';

interface ActiveRoutes {
  views: Array<InternalRoute>;
  intercepted: Array<InternalRoute> | undefined;
}

interface RoutingElement {
  middlewares: Array<RouteMiddleware> | undefined;
  defaultSkeleton: React.ReactNode;
  children?: React.ReactNode;
}

const shouldInterceptRoute = (activeRoutes: Array<InternalRoute>, nextRoute: InternalRoute | undefined): boolean => {
  return activeRoutes.some((route) => {
    return route?.intercept?.some((interceptRequest) => {
      if (is.string(interceptRequest)) {
        return nextRoute?.name === interceptRequest;
      }

      const params = getRouteParams(nextRoute!.path, window.location.pathname);
      const searchParams = getSearchParams(nextRoute!.searchParams, window.location.search);
      const hashParams = getHashParams(nextRoute!.hashParams, window.location.hash);

      return interceptRequest.routeName === nextRoute?.name
        ? interceptRequest.callback(new URL(window.location.href), params, searchParams, hashParams)
        : false;
    });
  });
};

const routerState = (routesAssistant: RoutesAssistant) => (activeRoutes: ActiveRoutes, history: NavigationHistory) => {
  const nextRoutes = routesAssistant.findMatches(history.current.location.pathname);
  const nextRouteName = nextRoutes?.at(-1);

  const intercept = Boolean(history.previous) && shouldInterceptRoute(activeRoutes.views, nextRouteName);

  return {
    views: intercept ? activeRoutes.views : nextRoutes,
    intercepted: intercept ? nextRoutes : undefined,
  } satisfies ActiveRoutes;
};

const Router: React.FunctionComponent<RoutingElement> = ({ middlewares = [], defaultSkeleton, children }) => {
  const { current, previous, routesAssistant } = useRouter();
  const context = useRequestContext();
  const routingMemory = React.useRef<ActiveRoutes>({
    views: routesAssistant.findMatches(current.location.pathname),
    intercepted: undefined,
  });
  const { views, intercepted } = React.useMemo(() => {
    routingMemory.current = routerState(routesAssistant)(routingMemory.current, { current, previous });

    // clears request context on route change
    context.clear();

    return routingMemory.current;
  }, [routesAssistant, current, previous, context]);

  React.useEffect(() => {
    if (!middlewares?.length) return;

    middlewares?.forEach((middleware) => middlewareRegistry.register(middleware));

    const watcher = middlewareRegistry.watch$.subscribe();

    return () => {
      watcher.unsubscribe();
    };
  }, [middlewares]);

  React.useEffect(() => {
    if (!middlewares?.length) return;

    middlewareRegistry.run(views, context);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...views.map((view) => view.path)]);

  const mountRouteContext = React.useCallback(
    (
      params: ReturnType<typeof getRouteParams>,
      searchParams: ReturnType<typeof getSearchParams>,
      hashParams: ReturnType<typeof getHashParams>,
      descendants: Array<InternalRoute>,
      interceptedRoutes: Array<InternalRoute>
    ): RouteProvider => ({
      params,
      searchParams,
      hashParams,
      outlet: !descendants?.length
        ? null
        : (properties = {}) => {
            return renderRoute(descendants, [], properties);
          },
      intercepted: !interceptedRoutes?.length
        ? null
        : (properties = {}) => {
            return renderRoute(interceptedRoutes, [], properties);
          },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const renderRoute = React.useCallback(
    (
      [root, ...descendants]: Array<InternalRoute>,
      interceptedRoutes: Array<InternalRoute> = [],
      props: React.PropsWithChildren = {}
    ): React.ReactNode => {
      if (!root) return children;

      const params = getRoutePartialParams(root.path, descendants.at(-1)?.path ?? root.path, current.location.pathname);
      const searchParams = getSearchParams(root.searchParams, current.location.search);
      const hashParams = getHashParams(root.hashParams, current.location.hash);

      return (
        <RouteContext.Provider
          key={root.path}
          value={mountRouteContext(params, searchParams, hashParams, descendants, interceptedRoutes)}
        >
          <ErrorBoundary key={root.path} fallback={root?.errorElement}>
            <React.Suspense key={root.path} fallback={root?.skeleton}>
              <RouteLifeCycle
                key={root.path}
                requestContext={context}
                onBeforeEnter={createObservableResource(
                  root?.onBeforeEnter?.({ path: root.path, params, searchParams, hashParams }, context) ?? of(true)
                )}
                onBeforeLeave={root?.onBeforeLeave}
              >
                {render.element(root.element, props)}
              </RouteLifeCycle>
            </React.Suspense>
          </ErrorBoundary>
        </RouteContext.Provider>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return <React.Suspense fallback={defaultSkeleton}>{renderRoute(views, intercepted)}</React.Suspense>;
};

export default React.memo(Router);
