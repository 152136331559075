import React from 'react';
import Html from 'components/html';
import Seo from 'pages/shared/seo';
import type { SeoElement } from 'pages/shared/seo';
import toArray from 'utils/to-array';
import is from 'utils/is';
import type { StyledElement, TestAutomation, AriaAttributes } from 'contracts';

import Row from '../row';
import element from '../../element';
import { Signature } from '../../signatures';
import type { ElementSignature } from '../../contracts';

import styles from './page.module.scss';

interface LayoutPageTitle extends StyledElement {
  title: string | React.ReactNode;
}

interface LayoutPage extends StyledElement, TestAutomation, AriaAttributes {
  title?: string | React.ReactNode | LayoutPageTitle;
  seo?: SeoElement;
  /**
   * Removes layout container (mobile view) top and/or bottom margins.
   */
  spaceless?: boolean | 'top' | 'bottom';
  children: React.ReactNode;
}

const Page: React.FunctionComponent<LayoutPage> & ElementSignature & { Row: typeof Row } = (props) => {
  const { testId, title, seo, spaceless, className, style, children, ...rest } = props;

  const childrenHasRows = React.useMemo(() => {
    const elements = React.Children.toArray(children);
    const rowsFound = elements.filter((node) => element(node).isPageRow);

    return Boolean(rowsFound.length);
  }, [children]);

  const pageTitle = React.useMemo<LayoutPageTitle>(() => {
    if (!React.isValidElement(title) && is.object<LayoutPageTitle>(title)) return title;

    return {
      className: undefined,
      style: undefined,
      title,
    };
  }, [title]);

  return (
    <Html.div
      testId={testId}
      className={[
        styles.page,
        ['top', true].includes(spaceless!) && styles.spacelessTop,
        ['bottom', true].includes(spaceless!) && styles.spacelessBottom,
        ...toArray(className),
      ]}
      style={style}
      arias={rest}
    >
      {seo && <Seo title={seo.title} description={seo.description} keywords={seo.keywords} />}
      {title && (
        <Row
          className={[styles.header, 'align-items-center', 'flex-row', ...toArray(pageTitle.className)]}
          style={pageTitle.style}
        >
          {pageTitle.title}
        </Row>
      )}
      {!childrenHasRows && <Row>{children}</Row>}
      {childrenHasRows && children}
    </Html.div>
  );
};

Page.Row = Row;
Page.signature = Signature.PAGE;

export type { LayoutPage, LayoutPageTitle };
export default Page;
