import Hotjar from '@hotjar/browser';
import appConfig from 'configurations/application';
import environment from 'utils/environment';
import shallowEqual from 'utils/shallow-equal';

import { convertKeyToSnakeCase } from './google-analytics/utils';
import type { Parameters } from './google-analytics/init';
import lang from './i18n/language';
import authentication from './authentication';

const siteId = 5044235;
const hotjarVersion = 6;

let initialized = false;
let customParams: Parameters;

const init = (params: Partial<Parameters> = {}): void => {
  if (!environment.isProduction) return;

  initialized = true;

  customParams = {
    userId: params.userId ?? '',
    viewType: 'model',
    userAccountType: 'user',
    language: lang.current,
    limitedAccess: false,
    multiplePerformers: false,
    testAccount: false,
    authenticated: authentication.isAuthenticated(),
    ...params,
  };

  Hotjar.init(siteId, hotjarVersion, {
    nonce: appConfig.envVars.hotjarNonce,
  });

  persist(customParams);
};

const persist = (params: Partial<Parameters>): void => {
  if (!initialized || shallowEqual({ ...customParams, ...params }, customParams)) {
    return;
  }

  Hotjar.identify(
    params?.userId ?? null,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    convertKeyToSnakeCase({
      ...customParams,
      application: 'PWA Nautilus',
      trafficType: environment.current,
    })
  );
};

const onHistoryChange = (language: string): void => {
  if (!initialized) return;

  const langParam = new RegExp(`^/${language}/?`, 'i');

  Hotjar.stateChange(window.location.pathname.replace(langParam, '/'));
};

export { init, persist, onHistoryChange };
