import { retry, interval, type Observable, throwError } from 'rxjs';

interface ProgressiveRetryOptions {
  baseInterval?: number;
  retryCount?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  skipWhen?: (error: any) => boolean;
}

const progressiveRetry = <T>(options?: ProgressiveRetryOptions): ((source$: Observable<T>) => Observable<T>) => {
  const { retryCount = 3, baseInterval = 5_000, skipWhen } = options ?? {};

  return (source$) =>
    source$.pipe(
      retry({
        count: retryCount,
        delay: (e, count) => {
          if (skipWhen?.(e)) return throwError(() => e);

          return interval(count * baseInterval);
        },
        resetOnSuccess: true,
      })
    );
};

export default progressiveRetry;
