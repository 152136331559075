import {
  BehaviorSubject,
  of,
  switchMap,
  map,
  tap,
  combineLatest,
  iif,
  distinctUntilChanged,
  take,
  defer,
  lastValueFrom,
  withLatestFrom,
} from 'rxjs';
import type { Observable, Subscription } from 'rxjs';
import { markAllAsRead, updateNotification, getNotificationList } from 'services/api/notifications-service/me';
import type { MemberRelatedNotification } from 'services/api/notifications-service/data-contracts';
import websocket from 'services/websocket';
import type { LiveNotificationEvent } from 'services/websocket';
import { Store } from 'store';
import account from 'store/account';
import user from 'store/user';
import is from 'utils/is';
import progressiveRetry from 'utils/rxjs/progressive-retry';
import { isLoyaltyLevel } from 'components/loyalty-mask';
import type { TranslationParams } from 'contracts';

import { mapNotificationType } from './notifications.mapping';
import { getLiveEventNamespace } from './utils';
import { NotificationEvent } from './contracts';

interface Pagination {
  cursor: string | undefined;
  limit: number;
}

interface NotificationAvatar {
  type: 'loyalty' | 'icon-system' | 'icon-battle' | 'icon-interactive-toy' | 'icon-action-request';
  loyalty?: {
    level: Exclude<MemberRelatedNotification['member']['loyaltyLevel'], null | 'Not in loyalty'> | undefined;
    isVisible: boolean;
  };
  initials: string | undefined;
}

interface NotificationData {
  id: string;
  read: boolean;
  today: boolean;
  time: number;
  avatar: NotificationAvatar;
  /**
   * Preview Image.
   * "true" = display "preview placeholder"
   * "string" = image url
   * "null" = do not display preview
   */
  preview: true | string | null;
  title: TranslationParams | string;
  description: TranslationParams | string | null;
  handleClick?: (() => void) | undefined;
  unfetched?: boolean;
}

interface NotificationsStore {
  enabled: boolean;
  unread: number;
  unfetched: number;
  hasMorePages: boolean;
  history: Array<NotificationData>;
}

const onAuthorizationChange$: Observable<boolean> = combineLatest([
  account.onChange$.pipe(
    map((data) => data?.flags?.registrationPending === false),
    distinctUntilChanged()
  ),
  user.onChange$.pipe(
    map((data) => {
      switch (data.userAccountType) {
        case 'studio':
          return user.isModelView();

        case 'model':
        case 'single':
        default:
          return true;
      }
    }),
    distinctUntilChanged()
  ),
]).pipe(
  map(([hasEnrollmentCompleted, hasRequiredProperties]) => hasEnrollmentCompleted && hasRequiredProperties),
  distinctUntilChanged()
);

const today = new Date().setHours(0, 0, 0, 0);

class Notifications extends Store<NotificationsStore> {
  private request$ = new BehaviorSubject<void>(undefined);

  private performerId$: Observable<number> = user.onModelChange$.pipe(map(({ viewTypeId }) => viewTypeId));

  private pagination: Pagination = { cursor: undefined, limit: 50 };

  private websocketSubscription: Subscription | undefined = undefined;

  private watchList = Object.values(NotificationEvent);

  public outdated = false;

  public received = 0;

  source$ = combineLatest([
    onAuthorizationChange$,
    this.request$,
    this.performerId$.pipe(
      tap((): void => {
        this.pagination.cursor = undefined;
      })
    ),
  ]).pipe(
    tap(() => {
      super.meta.setLoading(true);
    }),
    switchMap(([enabled]) =>
      iif(
        () => !enabled,
        defer(() => {
          this.pagination.cursor = undefined;

          this.closeWebsocket();

          return of({ enabled: false, unread: 0, unfetched: 0, history: [], hasMorePages: false });
        }),
        defer(() => {
          this.subscribeToWebSocket();

          return this.fetchHistory$.pipe(
            switchMap(({ history, unread, cursor }) => {
              const concat = !is.nullish(this.pagination.cursor);

              if (!concat) {
                this.outdated = false;
                this.received = 0;
              }

              this.pagination.cursor = cursor;

              return of({
                enabled: true,
                unread,
                unfetched: 0,
                history: concat
                  ? [...this.data.history, ...this.mapHistoryData(history)]
                  : this.mapHistoryData(history),
                hasMorePages: !is.nullish(cursor),
              });
            })
          );
        })
      )
    )
  );

  constructor() {
    super({
      name: 'notifications',
      initialState: { enabled: false, unread: 0, unfetched: 0, history: [], hasMorePages: false },
    });
  }

  private mapHistoryData(historyList: NotificationData[]): NotificationData[] {
    return historyList.map((history) => ({
      ...history,
      avatar: {
        ...history.avatar,
        loyalty: {
          isVisible: history.avatar.loyalty?.isVisible ?? true,
          level: isLoyaltyLevel(history.avatar.loyalty?.level) ? history.avatar.loyalty?.level : undefined,
        },
      },
    }));
  }

  private subscribeToWebSocket(): void {
    if (this.websocketSubscription) return;

    const events = this.watchList.map(getLiveEventNamespace).filter(Boolean) as Array<LiveNotificationEvent>;

    this.websocketSubscription = websocket
      .on$(events)
      .pipe(map(({ event }) => event))
      .subscribe(() => {
        this.set('unread', Math.max(0, this.data.unread + 1));
        this.set('unfetched', this.data.unfetched + 1);
        this.outdated = true;
        this.received += 1;
      });
  }

  private closeWebsocket(): void {
    this.websocketSubscription?.unsubscribe?.();
    this.websocketSubscription = undefined;
  }

  private get fetchHistory$(): Observable<Pick<NotificationsStore, 'unread' | 'history'> & Pick<Pagination, 'cursor'>> {
    return this.performerId$.pipe(
      take(1),
      switchMap((id) =>
        getNotificationList(id, {
          'filter[types]': this.watchList,
          'filter[status]': undefined,
          'page[limit]': this.pagination.limit,
          'page[cursor]': this.pagination.cursor,
        })
      ),
      progressiveRetry(),
      map(({ data: { data, meta } }) => ({
        unread: meta?.unreadCount ?? 0,
        cursor: meta?.nextCursor ?? undefined,
        history: (data ?? []).flatMap((notificationResponse) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const { id, isRead, createdAt } = notificationResponse;
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          const time = createdAt ? new Date(createdAt).getTime() : new Date().getTime();
          const mapping = mapNotificationType(notificationResponse);

          return mapping
            ? {
                id,
                read: isRead,
                today: time >= today,
                time,
                ...mapping,
              }
            : [];
        }),
      }))
    );
  }

  delayStoreUpdateWhen = function Delay(): boolean {
    return is.nullish(this.data);
  };

  public onNotificationGroup$(type: 'new' | 'today' | 'earlier'): Observable<NotificationsStore['history']> {
    return this.onChange$.pipe(
      map((data) => data.history),
      map((list) =>
        list.filter((el) => {
          switch (type) {
            case 'new':
              return !el.read;
            case 'today':
              return el.read && el.today;
            case 'earlier':
            default:
              return el.read && !el.today;
          }
        })
      ),
      distinctUntilChanged((prev, next) => {
        if (prev.length === next.length) {
          const ids = prev.reduce(
            (acc, item) => {
              return [...acc, item.id];
            },
            [] as Array<string>
          );

          return next.every((item) => ids.includes(item.id));
        }

        return false;
      })
    );
  }

  public markAsRead$(notificationId: string): Observable<unknown> {
    return this.performerId$
      .pipe(
        take(1),
        switchMap((id) =>
          updateNotification(notificationId, id.toString(), {
            data: [{ op: 'replace', path: '/isRead', value: true }],
          })
        )
      )
      .pipe(
        map((response) => response.data),
        tap(() => {
          this.set('unread', Math.max(0, this.data.unread - 1));
        })
      );
  }

  public markAllAsRead(): Promise<void> {
    const request$ = this.performerId$.pipe(
      withLatestFrom(this.onChange$),
      switchMap(([id, { history }]) =>
        iif(
          () => history.some((item) => !item.read),
          defer(() =>
            markAllAsRead(id.toString(), {
              'filter[olderThan]': new Date(history[0].time).toISOString(),
            }).pipe(
              map(() => undefined),
              tap(() => {
                this.set('unread', this.data.unfetched);
                this.set(
                  'history',
                  this.data.history.map((hist) => ({ ...hist, read: true }))
                );
              })
            )
          ),
          of(undefined)
        )
      )
    );

    return lastValueFrom(request$);
  }

  public refresh(): void {
    if (!this.outdated) return;

    this.pagination.cursor = undefined;
    this.request$.next();
  }

  public loadNextPage(): void {
    if (is.nullish(this.pagination.cursor) || this.loading) return;

    this.request$.next();
  }
}

export type { NotificationsStore, NotificationData, NotificationAvatar };
export default new Notifications();
