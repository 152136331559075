import React from 'react';
import type { Route } from 'contracts';

import id from './id';

const AsyncPage = React.lazy(() => import(/* webpackPreload: true, webpackPrefetch: true */ '../select'));

const routes: Route[] = [
  {
    name: id,
    path: '/{lang?}/profile/select',
    searchParams: ['key', 'redirect'],
    element: <AsyncPage />,
  },
];

export default routes;
