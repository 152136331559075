import React from 'react';
import { useIntl } from 'react-intl';
import Button from 'components/button';
import Html from 'components/html';
import Link from 'components/link';
import useNavigation from 'enhancers/use-navigation';
import type { StyledElement, AriaAttributes, TestAutomation } from 'contracts';

import tracking from '../cookie-consent.tracking';

import messages from './banner.i18n';
import { testId } from './banner.settings';
import styles from './banner.module.scss';

interface BannerElement extends StyledElement, AriaAttributes, TestAutomation {
  onAcceptClick: () => void;
  onRejectClick: () => void;
  onManageClick: () => void;
  loading?: boolean;
}

const Banner: React.FunctionComponent<BannerElement> = (props) => {
  const { onAcceptClick, onRejectClick, onManageClick, loading } = props;
  const { formatMessage } = useIntl();
  const [, , link] = useNavigation();

  return (
    <Html.div
      testId={testId.banner}
      className={[
        styles.cookieBanner,
        'position-sticky bottom-0 start-0 w-100 d-flex flex-column flex-md-row p-6 px-md-6 py-md-0',
      ]}
    >
      <Html.div className="me-md-13 flex-grow-1">
        <Html.h6 className={[styles.header, 'mt-0 mt-md-6 mb-0 fw-bold']} typography="title2" fontWeight="bold">
          {formatMessage(messages.components.cookieConsentBanner.title)}
        </Html.h6>
        <Html.p className={[styles.body, 'mt-2 mb-6']} typography="body2">
          {formatMessage(messages.components.cookieConsentBanner.content, {
            a: (text: React.ReactNode) => (
              <Link
                href={link.cookies()}
                target="_blank"
                variant="primary"
                testId={testId.policyLink}
                tracking={tracking.view.policy}
              >
                {text}
              </Link>
            ),
          })}
        </Html.p>
      </Html.div>
      <Button
        onClick={onAcceptClick}
        testId={testId.accept}
        className="flex-shrink-0 align-self-start w-100 w-md-auto mt-md-12 me-md-2 mb-md-6"
        disabled={loading}
      >
        {formatMessage(messages.components.cookieConsentBanner.accept)}
      </Button>
      <Button
        onClick={onRejectClick}
        testId={testId.reject}
        className="flex-shrink-0 align-self-start w-100 w-md-auto mt-3 mt-md-12 me-md-6 mb-md-6"
        disabled={loading}
      >
        {formatMessage(messages.components.cookieConsentBanner.reject)}
      </Button>
      <Button
        variant="minimal"
        onClick={onManageClick}
        testId={testId.manage}
        className={[
          styles.manageButton,
          'flex-shrink-0 align-self-start w-100 w-md-auto mt-1 mt-md-12 mb-md-6 px-0 text-center fw-bold',
        ]}
        disabled={loading}
      >
        {formatMessage(messages.components.cookieConsentBanner.manage)}
      </Button>
    </Html.div>
  );
};

export type { BannerElement };
export { Banner };
export default Banner;
