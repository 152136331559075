import history from 'services/router/history';
import routesAssistant from 'services/router/routes';
import parse from 'utils/parse';
import type { ApplicationRoute } from 'contracts';
import type { NavigationLinkAction, NavigationLink } from 'contracts/internals/navigation';
import type { InternalRoute } from 'services/router/contracts';

import compileRouteToLocationDescriptor from './utils/compile-route-to-location-descriptor';
import injectCommonRouteParams from './utils/inject-common-route-params';

/**
 * @callback MountLink
 * @param {string} path
 * @param {SearchParameters} searchParams
 * @param {HashParameters} hashParams
 * @returns {NavigationLinkAction}
 */
type MountLink = <K extends keyof ApplicationRoute>(routes: Array<InternalRoute>) => NavigationLinkAction<K>;

const mountLink: MountLink = (routes) => {
  return (params = {}) => {
    for (let i = 0; i < routes.length; i += 1) {
      try {
        const route = routes[i];

        const location = compileRouteToLocationDescriptor(
          history,
          route
        )(injectCommonRouteParams(params as Record<string, string | number | undefined>));

        if (!location?.pathname) return '';

        return parse.url(location.pathname, location.search, location.hash);
      } catch (error) {
        console.error('[NAVIGATION LINK]', error);
      }
    }

    return '';
  };
};

const link: NavigationLink = routesAssistant.getRoutesName().reduce(
  (acc, name) => ({
    ...acc,
    [name]: mountLink(routesAssistant.findRoutesByName(name)),
  }),
  {} as NavigationLink
);

export default link;
