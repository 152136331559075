import Cookies from 'js-cookie';
import is from 'utils/is';
import toArray from 'utils/to-array';
import environment from 'utils/environment';
import appSettings from 'configurations/application';

import location from '../router/location';
import ServiceBase from '../service-base';

import { Flags, settings } from './flags';
import { cookiesAttributes } from './utils';
import { defaultFlagSettings, type FlagSettings } from './contracts';

type FeatureFlags = Partial<Record<keyof typeof Flags, unknown>>;

class FeatureToggle extends ServiceBase<FeatureFlags> {
  name = 'feature-toggle';

  private readonly flags = Object.keys(Flags);

  constructor() {
    super();

    this.init();
  }

  private getFlagSettings(flag: string): Required<FlagSettings> {
    return { ...defaultFlagSettings, ...settings[flag] };
  }

  private readUrlSearchParams(): FeatureFlags {
    const searchParams = location.searchParams(true);

    return Object.keys(searchParams).reduce((acc, param) => {
      const { lifetime, parser, envBlacklist } = this.getFlagSettings(param);
      const value = parser(searchParams[param]);

      if (!this.flags.includes(param) || toArray(envBlacklist).includes(environment.current) || is.nullish(value))
        return acc;

      Cookies.set(param, String(value), cookiesAttributes(lifetime === 'session' ? 0 : undefined));

      return { ...acc, [param]: value };
    }, {} as FeatureFlags);
  }

  private readCookies(): FeatureFlags {
    return this.flags.reduce((acc, flag) => {
      const { parser } = this.getFlagSettings(flag);
      const cookieFlag = Cookies.get(flag);

      if (cookieFlag) return { ...acc, [flag]: parser(cookieFlag) };

      return acc;
    }, {} as FeatureFlags);
  }

  private readFeatureFlagModule(): FeatureFlags {
    const serverFlags = appSettings.envVars.featureFlags;

    return this.flags.reduce((acc, flag) => {
      const { parser, envBlacklist } = this.getFlagSettings(flag);
      const value = serverFlags?.[flag] as string | undefined;

      if (is.nullish(value) || toArray(envBlacklist).includes(environment.current)) return acc;

      return { ...acc, [flag]: parser(value) };
    }, {} as FeatureFlags);
  }

  public init(): void {
    this.set({ ...this.readFeatureFlagModule(), ...this.readCookies(), ...this.readUrlSearchParams() });
  }

  public get<K extends keyof FeatureFlags>(flag: K): unknown {
    return this.data?.[flag];
  }

  public enabled<K extends keyof FeatureFlags>(flag: K): boolean {
    const { enabled } = this.getFlagSettings(flag);

    return enabled(this.data?.[flag]);
  }

  public disabled<K extends keyof FeatureFlags>(flag: K): boolean {
    return !this.enabled(flag);
  }

  public toString(): string {
    return Object.entries(this.data ?? {})
      .map(([key, value]) => `${key}=${String(value)}`)
      .join(';');
  }
}

export type { FeatureFlags };
export default new FeatureToggle();
